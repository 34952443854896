<template>
    <div>
        <div class="align-center">
            <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M24.5148 26.9483L40.4294 6.76181C41.1133 5.89439 42.3708 5.74558 43.2382 6.42944C44.1056 7.11329 44.2545 8.37085 43.5706 9.23827L26.2263 31.2383C25.4721 32.1949 24.045 32.2608 23.2059 31.3777L14.5502 22.2688C13.7893 21.4681 13.8216 20.2022 14.6223 19.4413C15.423 18.6804 16.689 18.7127 17.4498 19.5134L24.5148 26.9483ZM40 22.2C40 21.0954 40.8954 20.2 42 20.2C43.1046 20.2 44 21.0954 44 22.2V36.6C44 40.6869 40.6869 44 36.6 44H11.4C7.31309 44 4 40.6869 4 36.6V11.4C4 7.31309 7.31309 4 11.4 4H32.8C33.9046 4 34.8 4.89543 34.8 6C34.8 7.10457 33.9046 8 32.8 8H11.4C9.52223 8 8 9.52223 8 11.4V36.6C8 38.4778 9.52223 40 11.4 40H36.6C38.4778 40 40 38.4778 40 36.6V22.2Z" fill="white"/>
            </svg>
        </div>
        <div class="steps-tracker">Step 3 of 3</div>
        <h2 class="align-center">Your terminal account is now active.</h2>
        <div class="form-text-div dimmed align-center subtitle">{{ subText }}</div>
        <form @submit.prevent class="container">
            <div class="button-div mt-10">
                <base-button
                mode="o-blue fullwidth"
                :link="false"
                @click.native="continuePressed"
                >Enter The Terminal</base-button>
            </div>
        </form>
    </div>
</template>

<script>
import { isFreeMTARoute, talkToServer } from '../../../utility/helper-functions';

export default {
    props: ["details", "promoID"],
    created() {
        let slug = "free-entry";
        if(isFreeMTARoute(this.promoID))
            slug = slug + "-mta";

        talkToServer("/journey/" + slug, "GET", null, null, true);
        
    },
    computed: {
        subText() {
            if(isFreeMTARoute(this.promoID))
                return "Click below to enter the terminal and watch the macro-masterclass taster videos.";

            return "Click below to enter the terminal and view all our tools. You can upgrade at any time.";
        }
    },
    methods: {
        continuePressed() {
            this.$emit("update-step", {
                step: "9"
            });
        }
    }
}
</script>