<template>

    <!-- SPECIAL PROMO -->
    <div v-if="isErbPromo" class="product-item">


        <div class="product-item-top">
            <div class="product-icon" style="line-height:56px;">
                <font-awesome-icon class="check"  :icon="['fa', 'check']" size="sm"/>
            </div>            
            <div>
                <h3 style="margin: 0">Economic Research Bundle - ${{ specialPrice }} 12 month access
                    <span v-if="product.product_type === 'promotion'">
                    </span></h3>
                <div class="plan-description">
                    12-month platform licence. Layout templates. Proprietary models. Chat support.
                </div>
            </div>
            <div
            class="plan-description-price"
            >
            </div>
            <div style="display: flex; align-items: center; justify-content: center;">
        </div>
        </div>

        <!-- <div 
        v-if="commitments.length"
        class="form-group featured label-transition field-input">
            <label for="commitment" class="focused" :class="{'commitment-error': noCommitmentError}"><span>Commitment</span></label>
            <div class="field-wrap">
                <div class="wrapper">
                    <select id="commitment" type="text" placeholder="Select a commitment *" required="required" class="commitment form-control-fs delay-blur" 
                    :class="{'commitment-error': noCommitmentError}"
                    v-model="commitment"
                    @click="commitmentChanged">
                        <option value="0" 
                        disabled="disabled" selected="selected" hidden="hidden" class="select-prompt">Select a commitment *</option>
                        <option v-for="commitment in commitments" 
                        :key="commitment.id" 
                        :value="commitment.id" 
                        class="select-prompt">{{ renderCommitment(commitment) }}</option>
                    </select>
                </div>
            </div>
        </div> -->
       
        <div
        style="margin-top: 25px;"
        >
            <div class="order-summary-items-main">
                
                <div class="order-summary-items-section" >
                
                    Includes:
                        <div style="margin-top:10px;" class="promo-includes">
                            
                            
                            <ul class="included-widgets">
                                <li style="display: block; width: fit-content;"
                                >
                                <font-awesome-icon :icon="['fa', 'check']" size="sm"/>
                                <span style="margin: 2px;padding: 2px">12-month platform licence</span>
                                </li>
                                <li style="display: block; width: fit-content;"
                                >
                                <font-awesome-icon :icon="['fa', 'check']" size="sm"/>
                                <span style="margin: 2px;padding: 2px">Financial Source Templates</span>
                                </li>
                                <li style="display: block; width: fit-content;">
                                <font-awesome-icon :icon="['fa', 'check']" size="sm"/>
                                <span style="margin: 2px;padding: 2px">Platform User Guides</span>
                                </li>
                                <li style="display: block; width: fit-content;"
                                >
                                <font-awesome-icon :icon="['fa', 'check']" size="sm"/>
                                <span style="margin: 2px;padding: 2px">Financial Source Models</span>
                                </li>
                                <li style="display: block; width: fit-content;"
                                >
                                <font-awesome-icon :icon="['fa', 'check']" size="sm"/>
                                <span style="margin: 2px;padding: 2px">Analyst Chat</span>
                                </li>
                                
                            </ul>
                           
                        </div>
                  
                </div>
                
            </div>
            
        </div> 
    
    
    </div>
    <!-- SPECIAL PROMO ENDS -->

    <div v-else class="product-item">
        <div class="product-item-top">
            <div class="product-icon" style="line-height:56px;">
                <font-awesome-icon class="check"  :icon="['fa', 'check']" size="sm"/>
            </div>
                <!-- <font-awesome-icon v-if="product.product_type==='plan'" :icon="['fa', 'folder']"
                                    size="2x"
                                    style="background-color: #b8b82c; border-radius: 15%; padding: 5px"/> -->
                <!-- <font-awesome-icon v-if="product.product_type==='addon'" :icon="['fa', 'chart-line']"
                                    size="2x"
                                    style="background-color: #1890a0; border-radius: 15%; padding: 5px"/>
                <font-awesome-icon v-if="product.product_type==='promotion'" :icon="['fa', 'bullhorn']"
                                    size="2x"
                                    style="background-color: #7b3799; border-radius: 15%; padding: 5px"/> -->
            
            <div>
                <h3 style="margin: 0">{{ productName }}
                    <!-- <span v-if="product.product_type === 'promotion' && !product.specific_finish_date && !isPromoPlan">
                        {{ product.subscription_length }}
                        {{ product.subscription_length_type }}
                    </span> -->
                    </h3>
                <div class="plan-description">
                    {{ description }}
                </div>
            </div>
            <div
            class="plan-description-price"
            >
            <!-- removed from below div 07 03 2023 v-if="product.product_type !== 'plan' || (product.commitments && product.commitments.length === 0)" -->
                <div>
                    <div v-if="product.product_type === 'addon'"
                    class="plan-description-price"
                    >
                        <span class="plan-description-price-number"
                        >${{ preciseOrFree(product.currentPrice)
                            }} /</span>
                        
                        <span class="plan-description-price-period"
                        >
                            {{ product.planDetails.addonProducts[0].subscription_length }}
                            {{ product.planDetails.addonProducts[0].subscription_length_type }}</span>
                    </div>
                    <div v-else
                    class="plan-description-price"
                    >
                        <span v-if="isRealBundle && !commitment"
                        class="plan-description-price-number"
                        >From ${{ preciseOrFree(cheapestBundle)
                            }} </span>
                        <span v-else>
                            <span  class="plan-description-price-number">${{ preciseOrFree(product.currentPrice)
                            }}</span><span v-if="!product.specific_finish_date && !isLifeTime" style="margin: 0 7px;"> /</span> 
                            <span class="plan-description-price-period" v-if="!product.specific_finish_date && isLifeTime">
                            {{ subscriptionLengthType }}</span>
                            <span class="plan-description-price-period" v-else-if="!product.specific_finish_date && !isLifeTime">
                            {{ product.subscription_length }}
                            {{ subscriptionLengthType }}</span>
                        </span>
                        
                        
                    </div>

                </div>
                <div
                v-if="product.currentPrice !== product.updatedPrice && product.product_type === 'plan'"
                class="you-pay-today">
                    <div class="you-pay-today-label">You pay today
                        ${{ toSystemPrice(product.updatedPrice) }} 
                        <VueCustomTooltip
                        style="margin-left: 4px;"
                        position="is-left"
                        label="You recently subscribed to an indicator included in this plan. You only pay the difference today." 
                        :multiline="true">
                            <font-awesome-icon :icon="['fas', 'question-circle']"/>
                        </VueCustomTooltip>
                    </div>
                </div>
            </div>
            <div style="display: flex; align-items: center; justify-content: center;">
            <!-- <button
            class="icon-button"
            @click="removeProduct">
                <font-awesome-icon :icon="['fa', 'trash-alt']"/>
            </button> -->
        </div>
        </div>

        <div 
        v-if="!forceCommitmentNoShowDuringPurchase"
        class="form-group featured label-transition field-input">
            <label for="commitment" class="focused" :class="{'commitment-error': noCommitmentError}"><span>Commitment</span></label>
            <div class="field-wrap">
                <div class="wrapper" style="margin-top: 15px;">
                    <select id="commitment" type="text" placeholder="Select a commitment *" required="required" class="commitment form-control-fs delay-blur" 
                    :class="{'commitment-error': noCommitmentError}" style="margin: 0;"
                    v-model="commitment"
                    @click="commitmentChanged">
                        <option :value="0" 
                        hidden="hidden" selected="selected" class="select-prompt">Select a commitment *</option>
                        <option v-for="commitment in commitments" 
                        :key="commitment.id" 
                        :value="commitment.id" 
                        class="select-prompt">{{ renderCommitment(commitment, false) }}</option>
                    </select>
                </div>
            </div>
        </div>
       
        <div v-if="widgetsToShow.length"
        style="margin-top: 25px;"
        >
            <a href="javascript:void(0);"
            class="slide-down-link-style"
            @click="isSlideDownOpen = !isSlideDownOpen"
            >{{ slideDownProducts }} 
            <font-awesome-icon v-if="!isSlideDownOpen" :icon="['fa', 'chevron-down']"/>
            <font-awesome-icon v-else :icon="['fa', 'chevron-up']"/>
            </a>
            <vue-slide-up-down :active="isSlideDownOpen" :duration="250" class="vue-slide-up-down"
            >
                <div style="margin-top:10px;">
                    <div
                    v-if="categorisedWidgetsToShow.widgets.length"
                    >
                        <span class="included-widgets-label">Indicator{{ categorisedWidgetsToShow.widgets.length > 1 ? 's' : '' }}:</span>
                        <ul class="included-widgets">
                        <li
                        v-for="widget in categorisedWidgetsToShow.widgets" :key="widget.name"
                        >
                        <VueCustomTooltip
                        v-if="widget.showcase"
                        :label="widgetInfoLabel(widget)" position="is-bottom" :multiline="true"
                        >
                            <font-awesome-icon class="showcase" :icon="['fa', 'question-circle']" size="sm"/>
                        </VueCustomTooltip>
                        <font-awesome-icon v-else :icon="['fa', 'check']" size="sm"/>
                        <span class="product-widget-name">
                            {{ blackFriday2023Filter(widget) }}</span>
                        </li>
                        </ul>
                    </div>
                    <div class="included-widgets-tc">
                        <div v-if="categorisedWidgetsToShow.livestreams.length">
                            <span class="included-widgets-label">Live stream series:</span>
                            <ul class="included-widgets">
                                <li
                                v-for="widget in categorisedWidgetsToShow.livestreams" :key="widget.name"
                                >
                                <VueCustomTooltip
                                v-if="widget.showcase"
                                :label="widgetInfoLabel(widget)" position="is-bottom" :multiline="true"
                                >
                                    <font-awesome-icon class="showcase" :icon="['fa', 'question-circle']" size="sm"/>
                                </VueCustomTooltip>
                                <font-awesome-icon v-else :icon="['fa', 'check']" size="sm"/>
                                <span class="product-widget-name">{{ widget.name }}</span>
                                </li>
                            </ul>
                        </div>
                        <div v-if="categorisedWidgetsToShow.videos.length">
                            <span class="included-widgets-label">Video course{{ categorisedWidgetsToShow.videos.length > 1 ? 's' : '' }}:</span>
                            <ul class="included-widgets">
                                <li
                                v-for="widget in categorisedWidgetsToShow.videos" :key="widget.name"
                                >
                                <VueCustomTooltip
                                v-if="widget.showcase"
                                :label="widgetInfoLabel(widget)" position="is-bottom" :multiline="true"
                                >
                                    <font-awesome-icon class="showcase" :icon="['fa', 'question-circle']" size="sm"/>
                                </VueCustomTooltip>
                                <font-awesome-icon v-else :icon="['fa', 'check']" size="sm"/>
                                <span class="product-widget-name">{{ widget.name }}</span>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </vue-slide-up-down>
        </div> 
    </div>
</template>

<script>
import SubscriptionMixin from '../../../mixins/SubscriptionMixin';
import PlanDetailsHandler from "../../../mixins/plan.details.handler";
import Constants from "../../../utility/constants";
import _ from 'lodash';
import { getPromoID, getServerEnv, isErbPromo, precise, preciseOrFree } from '../../../utility/helper-functions';

export default {
    props: ["product", "isUpgrading", "firstProductRendering"],
    mixins:[SubscriptionMixin,PlanDetailsHandler],
    created() {
        this.commitment = this.product.id;
        
        if(getPromoID() && this.firstProductRendering) {
            this.commitment = 0;
            if(this.product.commitments?.length < 2) {
                this.commitment = this.product.commitments[0]?.id;
            }
        }
        if(this.forceCommitmentSelection && this.firstProductRendering) {
            if(this.product.commitments?.length < 2) {
                this.commitment = this.product.commitments[0]?.id;
            }else{
                this.commitment = 0;
            }
        }

        // console.log(this.commitment);

        this.commitmentChanged();
        this.forceCommitmentNoShowDuringPurchase = this.commitments?.length < 2;
            
    },
    computed: {
        widgetsToShow() {
            if(this.product.product_type !== 'addon') {
                let prod = this.product;

                if(this.product.parent_id)
                    prod = this.mergedPlans.find(el => el.id === this.product.parent_id);

                if(this.product.parent_id) {
                    if(this.baseProdType === 'plan')
                        prod = this.mergedPlans.find(el => el.id === this.product.parent_id);
                    else
                        prod = Object.values(this.availableProducts).find(el => el.id === this.product.parent_id);
                }

                return  prod.widgets.filter(wid => !wid.showcase);
            }

            return [];
        },
        categorisedWidgetsToShow() {
            if(!this.widgetsToShow.length)
                return {};

            const arrW = [];
            const arrLs = [];
            const arrVi = [];
            const widgetsToShow = _.cloneDeep(this.widgetsToShow);
            widgetsToShow.forEach(wid => {

                    if(wid.code === 'TCSTREAM') {
                        arrLs.push(wid);
                    }
                    else if(wid.code === 'TCVIDEO') {
                        // const env = getServerEnv();
                        // const mtaID = Constants.MTA_PROMO[env];
                        // if(wid.implementation_id === Constants.MTA_COURSE.impID[env] &&
                        // this.product.id !== mtaID && this.product.parent_id !== mtaID) {
                        //     wid.name = wid.name + ' (taster)';
                        
                        // }
                        arrVi.push(wid);
                    }
                    else {
                        arrW.push(wid);
                    }
            });

            const obj = { 
                widgets: arrW, 
                livestreams: arrLs, 
                videos: arrVi
            };

            return obj;
        },
        description() {
            let desc = this.product.description;
            const regex = new RegExp("<[^>]*>?",'gm')
            desc = desc.replace(regex,'');

            return desc;
        },
        baseProdType() {
            if(this.product.product_type === 'plan' || this.mergedPlans.find(el => el.id === this.product.parent_id))
                return 'plan';

            return 'option';
        },
        productName() {
            // if(this.product.product_type !== 'plan' && !this.isPromoPlan)
            //     return this.product.name;
            let prod = this.product;
            if(this.product.parent_id) {
                if(this.baseProdType === 'plan')
                    prod = (this.mergedPlans.find(el => el.id === this.product.parent_id));
                else
                    prod = (Object.values(this.availableProducts).find(el => el.id === this.product.parent_id));
            }

            return prod.name;
        },
        commitments() {
            // if(this.product.product_type !== 'plan' && !this.isPromoPlan)
            //     return [];

            const activeIdToCompare = this.activePlan.product.parent_id ? this.activePlan.product.parent_id : this.activePlan.product.id;
            const currentIdToCompare = this.product.parent_id ? this.product.parent_id : this.product.id;

           
            if(!this.isUpgrading || currentIdToCompare !== activeIdToCompare) {
                

                if(this.baseProdType === 'plan') {
                    return (this.mergedPlans.find(el => el.id === this.product.parent_id || el.id === this.product.id)).commitments || [];
                }
                else
                    return (Object.values(this.availableProducts).find(el => el.id === this.product.parent_id || el.id === this.product.id))?.commitments || [];
                

            }
               
            
            const prod = this.activePlan.product;
            const upgradeable = _.cloneDeep(this.mergedPlans.find(el => el.id === activeIdToCompare));

            const currentCommValue = prod.subscription_length * Constants.PLAN_CONSTANTS.length_values[prod.subscription_length_type];

            
            const upgradeableComms = [];
            const trialsExcluded = upgradeable.commitments.filter(e => !e.trial_of_product_id);
            
            trialsExcluded.forEach(el => {
                const upgradeCommValue = el.subscription_length * Constants.PLAN_CONSTANTS.length_values[el.subscription_length_type];
                if(currentCommValue < upgradeCommValue)
                    upgradeableComms.push(el);
            });

            upgradeable.commitments = upgradeableComms;

            return upgradeable.commitments;

        },
        isPromoPlan() {
            return this.product.commitments || this.product.parent_id;
        },
        slideDownProducts() {
            const prodArr = [];
            let plural = '';
            const widgets = this.categorisedWidgetsToShow.widgets;
            const livestreams = this.categorisedWidgetsToShow.livestreams;
            const videos = this.categorisedWidgetsToShow.videos;

            if(widgets.length) {
                plural = widgets.length > 1 ? 's' : '';
                prodArr.push(`${widgets.length} indicator${plural}`);
            }
            if(livestreams.length) {
                prodArr.push(`${livestreams.length} live stream series`);
            }
            if(videos.length) {
                plural = videos.length > 1 ? 's' : '';
                prodArr.push(`${videos.length} video course${plural}`);
            }
            

            let string = '';
            
            prodArr.forEach((el, i) => {
                if(i < prodArr.length - 1) {
                    string+= el + ', '
                }
                else{
                    string+= el + ' ' 
                }
            });
                return string + 'included';
        },
        // SPECIAL PROMO
        specialPrice() {
            return precise(this.product.currentPrice / 100);
        },
        isErbPromo() {
            return isErbPromo()
        },
        subscriptionLengthType() {
            const env = getServerEnv();
            if(this.product.parent_id === Constants.BLACK_FRIDAY_2023[env])
                return "Lifetime";

            return this.product.subscription_length_type;
        },
        // SPECIAL PROMO ENDS
        isRealBundle() {
            return !!Object.values(this.availableProducts).find(el => el.id === this.product.id || el.id === this.product.parent_id);
        },
        cheapestBundle() {
            // console.log(this.product.id, (Object.values(this.availableProducts))
            const price = Object.values(this.availableProducts).find(el => el.id === this.product.id || el.id === this.product.parent_id).commitments[0].pricing.USD;
            return precise(price);
        },
        forceCommitmentSelection() {
            const env = getServerEnv();
            const conditions = this.product.id === Constants.MTA_PROMO[env] ||
            this.product.id === Constants.MTA_COURSE.prodID[env] ||
            this.product.id === Constants.RISK_COURSE.prodID[env];

            return conditions;
        },
        isBlackFriday2023() {
            const env = getServerEnv();
            if(this.product.parent_id === Constants.BLACK_FRIDAY_2023[env])
                return true;

            return false;
        },
        isLifeTime() {
            if(this.isBlackFriday2023)
                return true;
                
            return  false;
        }
        
    },
    data() {
        return {
            isSlideDownOpen: false,
            commitment: 0,
            noCommitmentError:true,
            forceCommitmentNoShowDuringPurchase: false,
        }
    },
    methods: {
        removeProduct() {
            this.$emit("remove-product", this.product);
        },
        commitmentChanged() {
            this.$emit("commitment-changed-at-checkout", {parent: this.product.parent_id || this.product.id, comm: this.commitment });
        },
        preciseOrFree(x) {
            return preciseOrFree(x);
        },
        blackFriday2023Filter(widget) {
            if(this.isBlackFriday2023 && widget.code === 'ANALCHAT')
                return widget.name + " (2 month access)";

            return widget.name;
        },
    }
}
</script>

<style lang="scss">
    .you-pay-today {

        display: flex;
        justify-content: flex-end;

        & .you-pay-today-label {
            background-color: $faded-green;
            color: $onboard-green;
            font-size: 12px;
            letter-spacing: 0.2px;
            display: inline-block;
            padding: 6px 10px;
            border-radius: 8px;
        }
        
    }

    .icon-button {
        background-color: transparent;
        border: none;
        cursor: pointer;

        & svg {
            color: $input-color-filter;
            font-size: 20px;
        }
    }

    .product-item {
        .form-group.featured.label-transition.field-input {
            margin-top: 10px;
        }
        .commitment {
            max-width: 350px;
            font-family: Poppins;
            font-weight: 200;
            padding: 12px;
        }
        label[for="commitment"] {
            background-color: $onboard-survey-card-background!important;
        }
        .label-transition input,
        .label-transition select {
            background-color: $onboard-survey-card-background!important;
            &:-webkit-autofill,
            &:-webkit-autofill:hover, 
            &:-webkit-autofill:focus, 
            &:-webkit-autofill:active{
                -webkit-box-shadow: 0 0 0 30px $onboard-survey-card-background inset !important;
            }
        }

        .label-transition label.focused {
            top: 10px;
        }

    }

    .order-summary-items-section {
        margin-bottom: 20px;

        & a {
            color: $input-color-filter;
            font-weight: normal;
            font-size: 12px;
        }

        & .original-price {
            color: $input-color-filter;
            font-size: 12px;
            text-transform: capitalize;
            letter-spacing: 0.25px;
        }
    }
    .product-widget-name {
        margin: 2px;
        padding: 2px;
    }
</style>