<template>
    <div style="margin-top:43px;">
        <div class="steps-tracker">Step 2 of 3</div>
        <h2 class="align-center">{{ title }}</h2>
        <div class="form-text-div dimmed align-center subtitle">{{ subText }}</div>
        <form @submit.prevent>
            <div class="plan-container">
                <div class="plan-card1 plan"
                v-for="plan in productsToShow"
                :key="plan.id"
                :class="{'highlighted': selectedPlanID === plan.id}"
                >
                    <label :for="plan.slug"
                    >
                        <plan-card
                        :country="country"
                        :plan="plan"
                        :selected="selectedPlanID"
                        :widgetsByProduct="widgetsByProduct"
                        :noCommitmentError="noCommitmentError"
                        @commitment-changed="commitmentChanged"
                        @plan-changed="(e) => selectedPlanID = e"
                        >
                        </plan-card>
                    </label>
                    <input 
                    type="radio" 
                    :id="plan.slug" 
                    :name="plan.name" 
                    :value="plan.id" 
                    v-model="selectedPlanID"
                    />
                </div>
            </div>
            <div class="container">
                <div class="button-div mt-10">
                    <base-button
                    mode="o-blue fullwidth"
                    :link="false"
                    @click.native="submitForm"
                    >Select {{ selectedPlanObj.name }}</base-button>
                </div>
            </div>
        </form>
    </div>
</template>

<script>
import OnBoardMixin from '../../../mixins/onBoardMixin';
import SubscriptionMixin from '../../../mixins/SubscriptionMixin';
import PlanCard from './components/PlanCard.vue';
import Constants from '../../../utility/constants';
import _ from 'lodash';
import { getCurrentCountry, getServerEnv, isFreeMTARoute, talkToServer } from '../../../utility/helper-functions';

export default {
    props: ["details", "promoID"],
    components: {
        PlanCard
    },
    mixins: [OnBoardMixin, SubscriptionMixin],
    created() {
        // console.log("activePlan",this.activePlan);
        // console.log("availablePlans",this.availablePlans);

        // console.log("availablePlansForComparison", this.availablePlansForComparison);

        // console.log("currentPlans", this.currentPlans);
        // console.log("widgetsByProduct", this.widgetsByProduct);

        // console.log("details",this.details)
        if(this.details?.commitment)
            this.commitment = this.details.commitment;

        this.selectedPlanID = this.details?.plan || this.activePlan.product_id;
        
        if(!this.details.country)
            getCurrentCountry().then(c => {
                if (c.short_name) {
                    this.country = Constants.USER_COUNTRIES.find(el => el.alpha2Code === c.short_name)?.alpha3Code;
                    this.details.country = this.country;
                   
                }
            });
        else {
            this.country = this.details.country;
        }
            
    },
    computed: {
        widgetsByProduct: function () {
            let widgets = this.sortedWidgetsByCreationDate.map(widget => {
                let newWidget = _.cloneDeep(widget);
                newWidget.availablePlans = widget?.planDetails?.products
                    ?.filter(product => product.is_promotion === false)
                    ?.filter(product => product.product_type === Constants.PLAN_TYPE)
                    ?.filter(product => product.is_visible_front_end === true || product.id === this.activePlan?.product?.id)
                    ?.filter(product => product.trial_of_product_id === null)
                    ?.map(product => product.slug) || [];
                return newWidget;
            });
            return _.orderBy(widgets, widget => widget.availablePlans.length, ['desc']);
        },
        productsToShow() {
            if(isFreeMTARoute(this.promoID)) {
                const freeMTA = _.cloneDeep(this.mergedPlans.find(el => !el.pricing.USD));
                freeMTA.name = "Accelerator Taster Videos";
                freeMTA.description = "Enter the terminal and watch free training before committing.";

                const env = getServerEnv();
                const mta = this.promotionalProducts.find(el => el.id === Constants.MTA_PROMO[env]);
                
                return [freeMTA, mta];
            }
            return this.mergedPlans;
        },
        selectedPlanObj() {
            return this.productsToShow.find(el => el.id === this.selectedPlanID);
        },
        title() {
            if(isFreeMTARoute(this.promoID))
                return "Choose the training that's right for you";

            return "Choose the plan that's right for you";
        },
        subText() {
            if(isFreeMTARoute(this.promoID))
                return "Don't worry, you can change this at any time.";

            return "Don't worry, you can downgrade or upgrade your plan at any time.";
        }
    },
    data() {
        return {
            selectedPlanID: 0,
            country: '',
            commitment: 0,
            noCommitmentError: false
        }
    },
    methods: {
        submitForm() {
            // console.log("commitment",this.commitment, this.selectedPlanObj);
            if(!this.commitment) {
                // console.log(this.commitment);
                this.noCommitmentError = true;
                return;
            }

            //TEMP
            // if(!this.commitment && this.selectedPlanObj.slug.indexOf('explorer') === -1 {
            //     console.log(this.selectedPlanObj);
            //     this.noCommitmentError = true;
            //     this.commitment = 0;
            //     return;
            // }
                

            let nextStep = "5";

            if(this.commitment.pricing.USD === 0) {
                // console.log("explorer", this.commitment);
                nextStep = "8";
            }

            this.$emit("update-step", {
                step: nextStep,
                plan: this.selectedPlanID,
                country: this.details.country,
                commitment: this.commitment.id
            });


            const name = this.commitment?.name.toLowerCase();
            let slug;
            if(name.indexOf('explorer') > -1)
                slug = "explorer";
            else if(name.indexOf('trial') > -1)
                slug = "protrial";
            else if(name.indexOf('pro') > -1)
                slug = "proplan";
            else if(name.indexOf('accelerator') > -1)
                slug = "mta";

            if(slug === "explorer" && isFreeMTARoute(this.promoID))
                slug = "mtataster";

            talkToServer("/journey/"+slug, "GET", null, null, true);
        },
        isPartOfPlan: function (widget, plan) {
            let isPartOfPlan = widget.availablePlans.includes(plan.slug);
            if(this.activePlan.product?.slug !== plan.slug) {
                let isShowcase = plan.widgets.filter(entry => entry.implementation_id === widget.implementation_id)?.[0]?.showcase;
                isPartOfPlan = isPartOfPlan && !isShowcase;
            }
            return isPartOfPlan;

        },
        commitmentChanged(val) {
            // console.log("value", val)
            this.commitment = val;
            this.noCommitmentError = false;
        }
    },
    watch: {
        selectedPlanID(nVal) {
            // console.log("nval",nVal);
            this.commitment = 0;
            const selectedPlan = this.productsToShow.find(el => el.id === nVal);
            // console.log("selectedPlan",selectedPlan);
            if(selectedPlan.commitments.length === 1) {
                this.commitment = selectedPlan.commitments[0];
                // console.log("found the single", this.commitment);
            }
                

            this.noCommitmentError = false;
        }
    }
}
</script>