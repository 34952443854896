// import { useFrontEndSourcesIfNeeded } from "../../utility/helper-functions";
// import _ from 'lodash';

export default {
    setWidgets(context, payload) {

        // payload = payload.filter(widget => widget.code !== "TERMINAL");
        //check to see if headline tyle widgets are set up correctly
        const filteredWidgets = payload.filter(widget => {

            if(widget.code === "HEADLINES" || widget.code === "VIDCOM") 
                if(widget.categories?.length && widget.sources?.length)
                    return true;
                else 
                    return false;
            else
                return true;
        });


        filteredWidgets.forEach(widget => {
            //settings
            if(widget.settings) {
                const stgs = widget.settings;

                for(const item in stgs) {
                    if(stgs[item].formJSON && stgs[item].editableByUser) {
                        let type = typeof stgs[item].formJSON;
                        if(type !== 'object') {
                            stgs[item].formJSON = JSON.parse(stgs[item].formJSON);

                            if(stgs[item].value) {
                                stgs[item].formJSON.model[item] = stgs[item].value;
                            }
                        }

                    }
                }

            }

            // if(widget.code === "HEADLINES") {
            //     context.dispatch("squawk/setSubscribedToSquawk", true, { root: true });

            // }

            if(widget.code === "HEADLINES") {
                const obj = {
                    model: {
                        sources: []
                    },
                    schema: {
                        type: 'checklist',
                        label: 'Content Source(s)',
                        model: 'sources',
                        values: [],
                        validator: (value, _, model) => {
                            if(!value.length) {
                                setTimeout(() => {
                                    model.sources.push(widget.sources[0]);
                                },100);

                                return ["At least one news source must always be active."];

                            }else{
                                return [];
                            }
                        }
                    },
                    formOptions: {
                        validateAfterLoad: false,
                        validateAfterChanged: true,
                        validateAsync: false
                    }
                };
                
                window.bootUp.entity.headline_sources.forEach(source => {
                    if(widget.sources && widget.sources.findIndex(element => element === source.id) > -1) {
                        
                        // const {sourceID, sourceName} = useFrontEndSourcesIfNeeded(source.id, source.source_name);

                        // const found = obj.schema.values.find(el => el.value === sourceID);
                        // console.log(found);
                        // if(!found) {
                            obj.schema.values.push({
                                value: source.id,
                                name: source.source_name
                            });

                            obj.model.sources.push(source.id);
                        // }


                    }

                });

                if(widget.sources) {
                    if(!widget.settings)
                        widget.settings = {};
                    
                    widget.settings.sources = {
                        area: "Options",
                        editableByUser: true,
                        formJSON: obj
                    };
                }

                

            }

        });

        // console.log('setWidgets', filteredWidgets);

        context.commit('setWidgets', filteredWidgets);
    },

    addLookupCategories(context) { 

        const codes = ["HEADLINES"]; //"VIDCOM"

        codes.forEach(widgetCode => {

            const widgetsToPrepare = (context.getters["widgets"]).filter(wdg => wdg.code === widgetCode);

            widgetsToPrepare.forEach(implementation => {
                const availableCatsForWidget = implementation.categories;
                // console.log(availableCatsForWidget);


                const obj = {
                    model: {
                        categories: []
                    },
                    schema: {
                        type: 'checklist',
                        label: 'Content Categories',
                        model: 'categories',
                        values: [],
                        validator: (value, field, model) => {
                            if(!value.length) {
                                setTimeout(() => {
                                    model.categories.push(field.values[0].value);
                                },100);
        
                                return ["At least one category must always be active."];
        
                            }else{
                                return [];
                            }
                        }
                    },
                    formOptions: {
                        validateAfterLoad: false,
                        validateAfterChanged: true,
                        validateAsync: false
                    }
                };



                let categories = [];

                const allCategories = window.bootUp.entity.categories;
                Object.entries(allCategories).forEach(([key, value]) => {
                    if(availableCatsForWidget.indexOf(+key) > -1)
                    categories.push(value);
                });

                categories.forEach(cat => {
                    obj.schema.values.push({
                        value: cat.id,
                        name: cat.display_name
                    });
                    obj.model.categories.push(cat.id);
                });
    
                const wrapperObj = {
                    implementation_id: implementation.implementation_id,
                    obj: obj
                }
        
                // console.log("addLookupCategories", wrapperObj);
                context.commit("addLookupCategories", wrapperObj);

            });

            
    
            
    
            
        });

    },

    // setStoreStartIndex(context, payload) {
    //     context.commit('setStoreStartIndex', payload);
    // },
    // setStoreSearchMode(context, payload) {
    //     context.commit('setStoreSearchMode', payload);
    // },
    // setSearchStartIndex(context, payload) {
    //     context.commit('setSearchStartIndex', payload);
    // },
    // setStoreEndIndex(context, payload) {
    //     context.commit('setStoreEndIndex', payload);
    // },
    // setSearchEndIndex(context, payload) {
    //     context.commit('setSearchEndIndex', payload);
    // },

    addPusherChannel(context, payload) {
        context.commit("addPusherChannel", payload);
    },
    removePusherChannel(context, payload) {
        context.commit("removePusherChannel", payload);
    },
    clearPusherChannels(context) {
        context.commit("clearPusherChannels");
    },

    setRatings(context, payload) {
        context.commit("setRatings", payload);
    },

    setSingleRating(context, payload) {
        context.commit("setSingleRating", payload);
    }

};
