<template>
    <div class="tutorial-modal">
        <div>
            <h2>{{ title }}</h2>
        </div>
        <div>
            <div class="responsive-16-9-frame">

                    <iframe
                    class="terminal-embed-video" :src="'https://player.vimeo.com/video/' + videoID"
                    frameborder="0" 
                    allow="accelerometer; 
                    autoplay; 
                    encrypted-media; 
                    gyroscope; 
                    picture-in-picture; 
                    fullscreen" 
                    allowfullscreen="" 
                    data-ready="true">
                    </iframe>

                </div>
        </div>
    </div>
</template>
<script>
export default {
    props: ["panel"],
    computed: {
        videoID() {
            return this.videoIDs[this.panel.widget.code];
        },
        title() {
            return "Trading with the " + this.panel.widget.name;
        }
    },
    // created() {
    //     console.log(this.panel.widget)
    // },
    data() {
        return {
            videoIDs: {
                "CURRMETER": "693302556"
            }
        }
    }
}
</script>

<style lang="scss" scoped>
    .tutorial-modal {
        padding: 0 20px 35px 20px;
    }
    h2 {
        @include H4;
        text-align: center;

        @include respond(sm) {
            @include H2;
            margin-bottom: 30px;
        }
        
    }
</style>