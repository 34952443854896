<template>
    <div class="news--item disable-select" 
    :class="{'u-highlight-latest-item': highlightAsNew, 'u-highlighted-news' : news.highlighted}"
    :id="'news-'+news.id + '-' + randomNumbers" 
    :data-published-utc="news.published_at"
    >
        <div class="news--item-expandable" 
        :class="{'u-f-weight-600' : news.highlighted}"
        @click="openNews">
            <div class="news--item__time">
                <span class="output-time">{{ outputTime }}</span>&nbsp;
                <!-- <span class="output-date">{{ outputDate }}</span> -->
            </div>
        
            <div class="news--item__headline u-shorten-headline-on-mobile expandable"
            :class="{'u-f-weight-600' : news.highlighted}">
                <span
                v-if="attachmentType === 'pdf'">
                    <a href="javascript:void(0)" @click="openPDF()"><font-awesome-icon :icon="['fas', 'file-pdf']" />&nbsp; {{ news.subject }}</a>    
                </span>
                <span
                v-else-if="attachmentType === 'image'">
                    <a href="javascript:void(0)" @click="enlargeIMG(news.attachment_url)"><font-awesome-icon :icon="['fas', 'file-image']" />&nbsp; {{ news.subject }}</a>    
                </span>
                <span
                v-else
                >{{ subject }}
                </span>
            </div>
            <div 
            v-if="isExpandable"
            class="news--item__plus green">
                <font-awesome-icon v-if="!newsIsOpen" :icon="['fas', faIcon]" />
                <font-awesome-icon v-else :icon="['fas', 'minus-circle']" />
            </div>
        </div> 
    
        <vue-slide-up-down :active="newsIsOpen" :duration="250" class="vue-slide-up-down">

            <div 
            v-if="isVideoHeadline"
            class="news--item__news video-news-frame"
            >
                <component 
                :is="'video-player'" 
                :video="processedVideo" 
                :key="processedVideo.src"
                :isLocked="false"
                :code="'VIDCOM'"
                :noTitle="true"
                :showProgressBar="true"
                ></component>
                <!-- <div class="responsive-16-9-frame"> -->
                    <!-- <vimeo-player ref="player" :video-id="headlineVideoID"/> -->
                    <!-- <vimeo-player ref="player" :video-url="videoURL"/> -->
                <!-- </div> -->
            </div>

            <div 
            v-else
            class="news--item__news"
            v-html="content"
            ></div>

        </vue-slide-up-down>

        <div 
        v-if="news.reaction_details"
        class="news--item__reaction expandable" 
        @click="openNews"><font-awesome-icon :icon="['fas', 'clock']" 
        />&nbsp;Reaction at <span class="news--item__reaction-time">{{ reactionTime }}</span>&nbsp;
            <font-awesome-icon v-if="newsIsOpen" :icon="['fas', 'chevron-up']" />
            <font-awesome-icon v-else :icon="['fas', 'chevron-down']" />
        </div>
        <vue-slide-up-down :active="newsIsOpen" :duration="250" class="vue-slide-up-down">
            <div v-if="newsIsOpen && news.reaction_details" 
            class="news--item__analysis-details"
            v-html="news.reaction_details"
            ></div>
        </vue-slide-up-down>

        <div 
        v-if="news.analysis_details"
        class="news--item__analysis expandable" 
        @click="openNews"><font-awesome-icon :icon="['fas', 'file']" 
        />&nbsp;Analysis at <span class="news--item__analysis-time">{{ analysisTime }}</span>&nbsp;
            <font-awesome-icon v-if="!newsIsOpen" :icon="['fas', 'chevron-down']" />
            <font-awesome-icon v-else :icon="['fas', 'chevron-up']" />
        </div>
        <vue-slide-up-down :active="newsIsOpen" :duration="250" class="vue-slide-up-down">
            <div v-if="newsIsOpen && news.analysis_details" 
            class="news--item__analysis-details"
            v-html="analysisDetails"
            ></div>
        </vue-slide-up-down>
    </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';
import { eventBus } from '../../../../main';
import { usersTime } from '../../../../utility/datetime';
import { randomizeBetween } from '../../../../utility/helper-functions';
import VideoPlayer from '../VideoPlayers/VideoPlayer.vue';

const general = createNamespacedHelpers("general");

export default {
    props: ["news"],
    components: {
        VideoPlayer
    },
    created() {

        if(this.isVideoHeadline) {

            const regExRule = /\s(\w*src=+?)"(.+?)"/g;
            
            this.processedVideo.src = (regExRule.exec(this.news.content))[2];
            this.processedVideo.id = this.news.id;
            this.processedVideo.title = this.news.subject;
            this.processedVideo.time = this.news.published_at;
            this.processedVideo.asked_by_user_id = this.news.asked_by_user_id;
            this.processedVideo.question_text = this.news.question_text;
            // this.processedVideo.progress = this.watchHistory.VIDCOM[this.news.id] ? this.watchHistory.VIDCOM[this.news.id].progress : 0;
        }

        if(this.news.new)
            this.highlightAsNew = true;
    },
    computed: {
        ...general.mapGetters(['user', 'watchHistory']),
        timezone() {
            return this.user.timezone;
        },
        outputDate() {
            return usersTime(this.news.published_at, true, false, false, false, false, this.timezone);
        },
        outputTime() {
            return usersTime(this.news.published_at, false, true, false, false, false, this.timezone);
        },
        analysisTime() {
            const time = this.news.analysis_updated_at ? 
            usersTime(this.news.analysis_updated_at, false, true, false, false, false, this.timezone) :
            '';

            return time;
        },
        reactionTime() {
            const time = this.news.reaction_updated_at ?
            usersTime(this.news.reaction_updated_at, false, true, false, false, false, this.timezone) :
            '';
            
            return time;
        },
        subject() {
            let subject = this.news.subject;

            if(subject.indexOf("[#domain#]") > -1) 
                return subject.replaceAll("[#domain#]", window.bootUp.entity.mapped_domain);

            if(subject.indexOf("[#entity_name#]") > -1) 
                return subject.replaceAll("[#entity_name#]", window.bootUp.entity.name);

            return subject;
        },
        content() {
            let content = this.news.content;
            if(!content)
                return "";
                
            if(content.indexOf("[#domain#]") > -1) 
                return content.replaceAll("[#domain#]", window.bootUp.entity.mapped_domain);

            if(content.indexOf("[#entity_name#]") > -1) 
                return content.replaceAll("[#entity_name#]", window.bootUp.entity.name);

            return content;
        },
        analysisDetails() {
            let details = this.news.analysis_details;
            if(details.indexOf("[#domain#]") > -1) 
                return details.replaceAll("[#domain#]", window.bootUp.entity.mapped_domain);

            if(details.indexOf("[#entity_name#]") > -1) 
                return details.replaceAll("[#entity_name#]", window.bootUp.entity.name);

            return details;
        },
        reactionDetails() {
            let details = this.news.reaction_details;
            if(details.indexOf("[#domain#]") > -1) 
                return details.replaceAll("[#domain#]", window.bootUp.entity.mapped_domain);

            if(details.indexOf("[#entity_name#]") > -1) 
                return details.replaceAll("[#entity_name#]", window.bootUp.entity.name);

            return details;
        },
        isExpandable() {
            return this.news.content || this.news.analysis_details || this.news.reaction_details;
        },
        faIcon() {
            if(this.isVideoHeadline)
                return 'headphones';

            return 'comment';
        },
        attachmentType() {
            const attachment = this.news.attachment_url;
            let type = "";

            if(attachment) {
                if(attachment.toLowerCase().indexOf('.pdf') > -1 ) 
                    type = "pdf";
                else if(attachment.toLowerCase().indexOf('.jpg')  > -1  || 
                attachment.toLowerCase().indexOf('.jpeg')  > -1 || 
                attachment.toLowerCase().indexOf('.png')  > -1 || 
                attachment.toLowerCase().indexOf('.bmp') > -1 ) 
                    type = "image";

            }

            return type;
        },
        randomNumbers() {
            return randomizeBetween(100000,999999);
        },
        isVideoHeadline() {
            return this.news.content?.indexOf('<iframe') > -1 && 
            this.news.content?.indexOf('vimeo') > -1
        },
        // videoURL() {
        //     if(this.isVideoHeadline)
        //         return "https://player.vimeo.com/video/" + this.news.video_id;

        //     return '';
        // }
    },
    data() {
        return {
            processedContent: '',
            newsIsOpen: false,
            headlineVideoID: null,
            highlightAsNew: false,
            processedVideo: {},

        }
    },
    methods: {
        openNews() {

            if(!this.isExpandable)
                return;

            this.newsIsOpen = !this.newsIsOpen;
            if(this.newsIsOpen) {

                const figures = document.querySelectorAll(`#news-${this.news.id}-${this.randomNumbers} figure a`);
                if(figures) {
                    figures.forEach(figure => {
                        figure.addEventListener("click", (event) => {
                            event.preventDefault();
                        });
                    });
                }

                const images = document.querySelectorAll(`#news-${this.news.id}-${this.randomNumbers} img`);
                if(images) {
                    images.forEach(image => {
                        const src = image.getAttribute("src");
                        console.log(src)
                        image.addEventListener("click", () => {
                            // console.log("src", src);
                            this.enlargeIMG(src);
                        });
                    });
                    // for(const image of images) {
                        
                        // 
                        // 
                    // }
                }
            }else{
                if(this.videoHeadline) {
                    this.$refs.player.pause();
                }

            }
        },
        openPDF() {
            eventBus.$emit('open-modal', {
                instanceID : this.$root.instanceID,
                componentInModal: "pdf-viewer",
                classes: "terminal-modal simplified auto-height",
                mode: "wide",
                panel: {
                    url: window.bootUp.entity.mapped_domain + '/api/widgets/headline/attachment/' + this.news.attachment_url + '?token=' + localStorage.getItem('token')
                }
            });
        },
        enlargeIMG(src) {
            // let url = window.bootUp.entity.mapped_domain + '/api/widgets/headline/attachment/' + src;
            let url = src;

            // if(src.indexOf('get-remote-image') > -1)
            //     url = src;

            url = url + '?token=' + localStorage.getItem('token');

            eventBus.$emit('open-modal', {
                instanceID : this.$root.instanceID,
                componentInModal: "image-viewer",
                classes: "terminal-modal simplified auto-height",
                panel: {
                    url: url
                },
                mode: "wide"
            });
        }
    },
    watch: {
        news: {
            handler(value) {
                this.highlightAsNew = value.new;
                // console.log(this.news.subject, this.highlightAsNew);
            },
            deep: true
        },
        // user: {
        //     deep: true,
        //     handler: function () {
        //         console.log(window.user.timezone);
        //     }
        // }
    }
}
</script>

<style lang="scss" scoped>
    .vue-slide-up-down {
        display: grid;
        @include news-item-cols;
    }
</style>