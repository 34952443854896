<template>
    <div>
        <div class="inner-card">
            <div class="inner-card-prompt">
                <a href="javascript:void(0);" @click="isOpen = !isOpen">
                    <span v-if="card.step === 1">Installed on {{ count }} URL{{ count > 1 ? 's' : ''}}:</span>
                    <span v-if="card.step === 2">{{ count }} rule{{ count > 1 ? 's' : ''}} active:</span>
                    <span>
                    <font-awesome-icon v-if="isOpen" :icon="['fa', 'chevron-up']"/>
                    <font-awesome-icon v-else :icon="['fa', 'chevron-down']"/>
                </span>
                </a>
            </div>
            <vue-slide-up-down :active="isOpen" :duration="250" class="vue-slide-up-down">
                <div>
                    <div v-for="(data, index) in dynamicSets"
                    :key="index">
                        <div class="partner-card-input">
                            <div
                            class="partner-card-input-dynamic"
                            :class="{'url-set-up': dataComponent && dataComponent.length}"
                            >{{ data }}
                            </div>
                            <div v-if="dataComponent && dataComponent.length && card.step === 1" class="partner-card-confirmation"><font-awesome-icon  :icon="['fas', 'check']" /> Activated</div>
                        </div>
                        <div 
                        v-if="index === 0 && dynamicSets.length > 1 || index > 0 && dynamicSets.length - 1 > index"
                        class="partner-card-input-separator"></div>
                    </div>
                    <div class="inner-card-note">Want to install on multiple URLs?<br />
                    Submit a <a href="javascript:void(0);">support request</a> or <a href="javascript:void(0);">join our slack channel</a> to communicate directly with our experts in real-time.</div>
                </div>
               
            </vue-slide-up-down>
        </div>
    </div>
</template>

<script>
export default {
    props: ["card"],
    computed: {
        details() {
            return this.card.details;
        },
        dataComponent() {
            return this.details?.data;
        },
        dynamicSets() {
            if(this.dataComponent) {
                if(!this.dataComponent?.length)
                    return [this.details.noDataMessage]
                
                return this.dataComponent;
            }
            
            return [];
        },
        count() {
            if(this.dataComponent && this.dataComponent.length)
                return this.dataComponent.length;

            return 0;
        }
    },
    data() {
        return {
            isOpen: false,
            urls: "Click on the “Install Software” button to activate Analyst desk"
        }
    }
}
</script>