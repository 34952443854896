<template>
    <div>
        <div class="frontend-controls">
            <h5>Indicators</h5>
            <div class="frontend-controls-indicators">
                <div>
                    <nav
                    style="margin-top: 20px;">
                    <h6>ENABLED</h6>
                    <ul>
                        <li v-for="widget in widgetsToSelectFromForHomeScreenSorted"
                        :key="widget.implementation_id" :id="'li-widget-' +widget.implementation_id">
                        <label :for="'widget_' + widget.implementation_id" style="display: flex; align-items: center; height: 24px;">
                            <input type="checkbox" :id="'widget_' + widget.implementation_id" :value="widget.implementation_id" v-model="widgetsChoice" @change="widgetSelectionChange" style="margin-right: 15px; height: auto;">
                            <span class="widget-selection-label">{{ widget.name }}</span>
                        </label>
                        </li>
                    </ul>
                </nav>
                </div>
                <div>
                    <nav
                    style="margin-top: 20px;">
                    <h6>UNLOCKED</h6>
                    <ul>
                        <li v-for="wid in widgetsForLocking"
                        :key="wid.implementation_id">
                        <label :for="'lock_widget_' + wid.implementation_id" style="display: flex; align-items: center">
                            <input type="checkbox" :id="'lock_widget_' + wid.implementation_id" :value="wid.implementation_id" v-model="ruleModel" @change="widgetLockChange" style="margin-right: 15px; height: auto;">
                            {{ wid.name }}
                        </label>
                        </li>
                    </ul>
                    </nav>
                </div>
            </div>
            <div class="frontend-controls-misc">
                <div>
                    <nav
                    style="margin-top: 20px;">
                        <h5>Menu</h5>
                        <ul>
                            <li>
                                <label for="fulldemo" style="display: flex; align-items: center">
                                    <input type="radio" id="fulldemo" value="fulldemo" v-model="menuState" @change="menuSelectionChange" style="margin-right: 15px; height: auto;">
                                    Full Menu
                                </label>
                            </li>
                            <li>
                                <label for="indicators" style="display: flex; align-items: center">
                                    <input type="radio" id="indicators" value="indicators" v-model="menuState" @change="menuSelectionChange" style="margin-right: 15px; height: auto;">
                                    Indicators only
                                </label>
                            </li>
                            <li>
                                <label for="videos" style="display: flex; align-items: center">
                                    <input type="radio" id="videos" value="videos" v-model="menuState" @change="menuSelectionChange" style="margin-right: 15px; height: auto;">
                                    Training Centre only
                                </label>
                            </li>
                        </ul>
                    </nav>
                </div>
                <div>
                    <nav
                    style="margin-top: 20px;">
                        <h5>Q&A</h5>
                        <ul>
                        <li>
                            <label for="qnaon" style="display: flex; align-items: center">
                                <input type="radio" id="qnaon" value="on" v-model="qnaOnLocally" @change="qnaChange" style="margin-right: 15px; height: auto;">
                                On
                            </label>
                        </li>
                        <li>
                            <label for="qnaoff" style="display: flex; align-items: center">
                                <input type="radio" id="qnaoff" value="off" v-model="qnaOnLocally" @change="qnaChange" style="margin-right: 15px; height: auto;">
                                Off
                            </label>
                        </li>
                        <li>
                            <label for="qnacustom" style="display: flex; align-items: center">
                                <input type="radio" id="qnacustom" value="custom" v-model="qnaOnLocally" @change="qnaChange" style="margin-right: 15px; height: auto;">
                                Custom
                            </label>
                        </li>
                         <li>
                            <label for="userqnaon" style="display: flex; align-items: center">
                                <input type="checkbox" id="userqnaon" :disabled="qnaOnLocally === 'no'" v-model="userQnaOnLocally" @change="userQnaChange" style="margin-right: 15px; height: auto;">
                                User access to QnA
                            </label>
                        </li>
                        </ul>
                    </nav>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';
import { eventBus, tabCommSender } from '../../main';
import _ from 'lodash';

const general = createNamespacedHelpers('general');
const payments = createNamespacedHelpers('payments');
const widgets = createNamespacedHelpers('widget');

export default {
    created() {
        this.widgetsChoice = [...this.widgetsChoiceOnDemo];
        this.ruleModel = [...this.ruleSet];
        if(this.terminalMenu.length === 3)
            this.menuState = "fulldemo";
        else if(this.terminalMenu.length === 2)
            this.menuState = "indicators";
        else 
            this.menuState = 'videos';

        this.qnaOnLocally = this.entityQna;
        this.userQnaOnLocally = this.user.qanda_enabled;
    },
    computed: {
        ...general.mapState(["widgetsChoiceOnDemo", "terminalMenu"]),
        ...general.mapGetters(["widgetsChoiceOnDemo", "terminalMenu", "entityQna", "ruleSet", "user"]),
        ...payments.mapState(["demoProducts"]),
        ...payments.mapGetters(["widgetsToSelectFromForHomeScreen", "widgetsOnTheHomeScreen"]),
        ...widgets.mapGetters(["widgets"]),
        widgetsForLocking() {
            return _.orderBy(this.widgets.filter(el => this.toShow.includes(el.implementation_id)), el => el.implementation_id, ["asc"]);
        },
        widgetsToSelectFromForHomeScreenSorted() {
            return _.orderBy(this.widgetsToSelectFromForHomeScreen.filter(el => this.toShow.includes(el.implementation_id)), el => el.implementation_id, ["asc"])
        },
        toShow() {
            const toShow = [];
            this.demoProducts.forEach(element => {
                const impID = element.product.widgets[0].pivot.widget_implementation_id;
                if(!toShow.includes(impID))
                    toShow.push(element.product.widgets[0].pivot.widget_implementation_id);
            });
            return toShow;
        }
    },
    data() {
        return {
            widgetsChoice: [],
            ruleModel: [],
            menuState: "",
            qnaOnLocally: "off",
            userQnaOnLocally: true
        }
    },
    methods: {
        ...general.mapActions(["setWidgetsChoiceOnDemo", "setTerminalMenu", "setEntity", "setRuleSet", "setCurrentUser"]),
        widgetSelectionChange() {
            this.setWidgetsChoiceOnDemo(this.widgetsChoice);
            eventBus.$emit("widget-selection-change");
        },
        widgetLockChange() {
            tabCommSender.postMessage({ unlockedWidgets: this.ruleModel });
            this.setRuleSet(this.ruleModel);
        },
        menuSelectionChange() {
            let menuToDisplay = [];
            if(this.menuState === 'fulldemo')
                menuToDisplay = ['home', 'dashboard', 'videos'];
            if(this.menuState === 'indicators')
                menuToDisplay = ['home', 'dashboard'];
            if(this.menuState === 'videos')
                menuToDisplay = ['videos'];
     
            this.setTerminalMenu(menuToDisplay);
        },
        qnaChange() {
            if(this.qnaOnLocally !== 'off' && !this.widgetsChoice.includes(57))
                this.widgetsChoice.push(57);


            if(this.qnaOnLocally === 'off') {
                this.widgetsChoice = this.widgetsChoice.filter(el => el !== 57);
                this.userQnaOnLocally = false;
            }
            else if(this.qnaOnLocally === 'on') {
                this.userQnaOnLocally = true;
            }

            this.widgetSelectionChange();

            this.userQnaChange();

            const entity = {
                ..._.cloneDeep(this.entity),
                qanda_enabled: this.qnaOnLocally
            };
                
            this.setEntity(entity);
            tabCommSender.postMessage({ entity: entity });
        },
        userQnaChange() {
            let profile = _.cloneDeep(this.user);
            profile = {
                ...profile,
                qanda_enabled: this.userQnaOnLocally
            }
            if(this.userQnaOnLocally && !this.ruleModel.includes(57))
                this.ruleModel.push(57);
            else if(!this.userQnaOnLocally)
                this.ruleModel = this.ruleModel.filter(el => el !== 57);
            
            this.widgetLockChange();

            this.setCurrentUser(profile);
            tabCommSender.postMessage({ userQna: this.userQnaOnLocally });
            eventBus.$emit("ANALCHAT_CHECK");
        }
    },
    watch: {
        widgetsChoice(nval, oval) {
            if(!nval.includes(57) && oval.includes(57)) {
                this.qnaOnLocally = 'off';
                this.qnaChange();
            }
            if(nval.includes(57) && !oval.includes(57) && oval.length) {
                this.qnaOnLocally = 'custom';
                this.qnaChange();
            }
        },
        ruleModel(nval,oval) {
            if(nval.includes(57) && !oval.includes(57) && oval.length) {
                this.qnaOnLocally = 'on';
                this.qnaChange();
            }
            if(!nval.includes(57) && oval.includes(57) && this.qnaOnLocally !== 'off') {
                this.qnaOnLocally = 'custom';
                this.userQnaOnLocally = false;
                this.qnaChange();
            }
        }
    }
}
</script>

<style lang="scss">
    .frontend-controls {
        padding: 20px;

        & h5 {
                padding-left: 0;
            }

        & nav {
            
            & h6 {
                font-size: 10px;
                color: $input-color-filter;
                margin-bottom: 5px;
                padding-left: 25px;
            }
        }

        & nav ul {
            & li {
                list-style: none;
            }
        }

        &-indicators {
            @include respond(md) {
                display: grid;
                grid-template-columns: 70px 1fr;
            }

            .widget-selection-label {
                @include respond(md) {
                    display: none;
                }
            }
        }

        &-misc {
            @include respond(md) {
                display: grid;
                grid-template-columns: 1fr 1fr;
            }
        }


        & input[type=radio] {
            width: auto;
        }
    }
</style>