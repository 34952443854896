import { createNamespacedHelpers } from "vuex";
import { talkToServer } from "../utility/helper-functions";
const general = createNamespacedHelpers("general");

export default {
    mounted() {
        if(this.checkoutLoginMode)
            return;

        this.$nextTick(() => {
            const labels = document.querySelectorAll(".label-transition");
         
            if(labels)
                labels.forEach(label => {
                    label.addEventListener("focus", event => {
                        this.focusInput(event)
                    }, true);
                    label.addEventListener("blur", event => {
                        if(!event.target.value) {
                            setTimeout(() => event.target.classList.remove("delay-blur"), 200);
                            event.target.parentElement.parentElement.parentElement.querySelector("label").classList.remove("focused");
                        }
                        
                    }, true);
                }); 

        });

    },
    computed: {
        ...general.mapState(["checkoutLoginMode"]),
    },
    methods: {
        focusInput(event) {
      
            setTimeout(() => event.target.classList.add("delay-blur"), 0);
            this.$emit('emit-error', null);
            event.target.parentElement.parentElement.parentElement.querySelector("label").classList.add("focused");
            const select = event.target.parentElement.querySelector(".select-prompt");
            if(select)
                select.selected = false;
            
        },
        updateStepProgress(obj) {
            talkToServer('/setting/user/onboard-steps', "POST", null, obj, true);
        }
    },

}