<template>
    <div class="video-player">
        <!-- <div class="video-player-resizer"> -->
            <div class="video-player__iframe">
                
                <padlock-overlay
                :impID="impID"
                v-if="isLocked"
                :instructions="true"
                class="blur"
                ></padlock-overlay>

                <vimeo-player 
                ref="player"
                :video-url="vimeoLink"
                @playing="blockPlayerRender"
                @pause="paused"
                @ended="allowPlayerRender"
                @timeupdate="trackProgress"
                @durationchange="getDuration"
                />

                <span 
                v-if="showProgressBar"
                class="progress-bar" :style="progressWidth"></span>
            </div>
            <sunset-ribbon v-if="this.impID === 65"></sunset-ribbon>
        <div class="video-player__description" v-if="!noTitle">
            <div class="video-player__description--title disable-select">{{ video.title }}</div>
            <div 
            v-if="showPurchaseButton"
            class="video-player__description-details" style="text-align: center; margin: 30px 0;">
                <base-button
                @click.native="purchase"
                mode="video-button o-blue">Unlock Full Course
                </base-button>
            </div>
            <!-- <div class="video-player__description-details u-mt-2" 
            v-if="courseRootText"
            :style="descriptionHeight"
            v-html="courseRootText"> -->
                <!-- <div class="video-player__description-details--profile">
                </div> -->
                <!-- <div class="video-player__description-details--time"><span class="output-time disable-select">{{ outputTime }}</span> <span class="output-date disable-select">{{ outputDate }}</span></div>
            </div> -->
        </div>
        <div v-if="showQnA">
            <div class="answer-card">
                <div class="answer-card-icon yellow"><span><img style="height: 16px;" :src="questionAnswerSrc" /> <span>Our reply</span></span></div>
                <div class="answer-card-legend">This video was added in reply to your question.</div>
                <div class="answer-card-question">{{ questionText }}</div>
            </div>
        </div>
            <!-- <div 
            v-if="questions && questions.length"
            class="video-player__content">
                <h3>Viewer questions below: {{ questions.length }}</h3>
                <horizontal-scrollable
                :isHorizontal="false"
                :height="200"
                >
                    <live-question
                    v-for="question in questions"
                    :key="question.id"
                    :question="question"
                    :layoutID="layoutID"
                    ></live-question>
                </horizontal-scrollable>
            </div> -->
        <!-- </div> -->
    </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';
import { eventBus } from '../../../../main';
import { usersTime } from '../../../../utility/datetime';
import VideoTrackerMixin from '../../../../mixins/videoTrackerMixin';
import SunsetRibbon from './SunsetRibbon.vue';
import * as workerTimers from 'worker-timers';
import { dontTrackOnLocalOrRemote, frontFacingAssets, getServerEnv } from '../../../../utility/helper-functions';
import Constants from '../../../../utility/constants';
import PadlockOverlay from '../../../../assets/PadlockOverlay.vue';

const general = createNamespacedHelpers("general");
const payments = createNamespacedHelpers("payments");
const dashboards = createNamespacedHelpers("dashboard");
const headlinesNS = createNamespacedHelpers("headlines");

export default {
    props: ["video","layoutID", "code", "impID", "videoAutoPlay", "firstTemp", "isLocked", "noQnA", 'noTitle', "showProgressBar"],
    mixins: [VideoTrackerMixin],
    components: {
        PadlockOverlay,
        SunsetRibbon
    },
    created() {
        
        eventBus.$on("live-question-timestamp-clicked", this.jumpToSection);
        this.askedByUser = this.video.asked_by_user_id;
        this.question = this.video.question_text;

        this.watched = this.getGTrackVideoProgress();
    
        this.tracked = this.watchHistory[this.code][this.video.id] ? this.watchHistory[this.code][this.video.id].progress : 0;
    },
    mounted() {
        this.$nextTick(() => {
            if(this.$refs?.player && this.videoAutoPlay && !this.isLocked)
                this.$refs.player.play();
        });
    },
    beforeDestroy() {
        eventBus.$off("live-question-timestamp-clicked", this.jumpToSection);
        this.clrInterval();
    },
    computed: {
        ...general.mapState(['demoPageMode']),
        ...general.mapGetters(['user', 'userQnaOn']),
        ...payments.mapGetters(['activePlan', 'currentAddons']),
        ...payments.mapState(['availableProducts']),
        ...dashboards.mapGetters(['systemDashboards']),
        ...headlinesNS.mapGetters(['lastHeadlineUpdate']),
        questionAnswerSrc() {
            let src = frontFacingAssets() + 'question-answer-black.svg';
            return src;
        },
        timezone() {
            return this.user.timezone;
        },
        outputDate() {
            return usersTime(this.video.time, true, false, false, false, false, this.timezone);
        },
        outputTime() {
            return usersTime(this.video.time, false, true, false, false, false, this.timezone);
        },
        vimeoLink() {
            if(this.video.video_id)
                return 'https://player.vimeo.com/video/' + this.video.video_id;

            return this.video.src;
        },
        showPurchaseButton() {
            // const env = getServerEnv();

            // const prodAccessIDs = [this.activePlan.product?.id, ...this.currentAddons.map(el => el.product.parent_id ? el.product.parent_id : el.product.id)];
            // const restrictedProduct = Constants.TC_FREE_VIDEOS[env][this.impID];
            // if(!restrictedProduct)
            //     return false;

            // const fullAccessProds = restrictedProduct.full_access_prod_ids;

            // if((arrayIntersect(prodAccessIDs, fullAccessProds)))
                return false; //

            // return true;

        },
        showQnA() {
            if(this.noQnA)
                return false;
                
            if(this.demoPageMode) {
                return this.firstTemp === this.video.id && this.userQnaOn;
            }

            return this.askedByUser === window.loggedInUserId && this.question && this.userQnaOn;
    
        },
        questionText() {
            if(this.demoPageMode)
                return "What's the status of your WTI trade?";
            
            return this.question;
        },
        progressWidth() {
            let width = this.tracked;
            if(dontTrackOnLocalOrRemote())
                width = 0;
            return {width: width + '%'};
        }
        // courseRootText() {
        //     if(this.code !== 'TCVIDEO')
        //         return ``;

        //     return `<div>
        //                 Lorem ipsum dolor sit amet, consectetur adipiscing elit.
        //                 <p>Proin suscipit ipsum vitae mi volutpat fringilla.</p> 
        //                 <p>Curabitur velit diam, imperdiet mollis dui et, ultrices faucibus elit. Vestibulum eget tortor at diam convallis blandit in et nisi. </p>
        //                 <h1>Donec tincidunt lobortis velit.</h1>  
        //                 <h2>Donec tincidunt lobortis velit.</h2>  
        //                 <h3>Donec tincidunt lobortis velit.</h3>  
        //                 <p>Vestibulum <a href="javascript:void(0);">ut viverra nulla.</a> Etiam nibh justo, euismod sit amet aliquet sed, sagittis vitae augue. Suspendisse potenti. Nunc rhoncus tristique pretium. Fusce congue nec urna eu elementum. Duis ut hendrerit velit. Donec at metus arcu. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia curae; Donec quis erat a lacus sollicitudin molestie. Vestibulum efficitur, massa nec auctor laoreet, nunc purus posuere erat, in accumsan magna arcu in leo. Nunc posuere ex eget commodo viverra.</p>
        //             </div>`;
        // }
    },
    data() {
        return {
            duration: 0,
            interval: null,
            askedByUser: null,
            question: null
        }
    },
    methods: {
        ...general.mapActions(['setWatchHistory']),

        purchase() {
            const env = getServerEnv();
            let prodID
            const widget = this.systemDashboards.filter(el => el.vidpage).find(el => el.impID === this.impID)?.widget;

            if(widget)
                prodID = widget.planDetails.products.find(el => el.id === Constants.TC_FREE_VIDEOS[env][this.impID]?.full_access_prod_ids[0])?.id;

            if(!prodID)
                return;

            const instanceID = this.$root.instanceID;

            const modal = {
                instanceID
            };

            //SPECIAL PROMO
            // if(this.item.impID === 80) {

            const panel = {
                view: 'product-config',
                id: prodID,
                
            };

            // modal.header = false;
            //this.item.promotion_ends_at
            const promoEndDate = Object.values(this.availableProducts).find(el => el.id === prodID)?.promotion_ends_at;
            // console.log(promoEndDate);
            modal.countdown = promoEndDate;
            
            // }
            // SPECIAL PROMO ENDS

            // modal.header = false;
            modal.componentInModal = "widget-shop";
            modal.classes = "terminal-modal simplified";
            modal.panel = panel;
            modal.mode = "wide";

            eventBus.$emit('open-modal', modal);
        },

        blockPlayerRender() {
            // console.log("blocking player render");
            this.$emit("allow-player-render", false);
            this.clrInterval();
            this.interval = workerTimers.setInterval(() => {
                this.watched+= (this.step[this.impID] || 0);
                this.gTrackVideoProgress();
                // console.log("watched", this.watched);

            }, (this.step[this.impID] || 60) * 1000);
        },
        allowPlayerRender() {
            // console.log("allowing player render");
            this.$emit("allow-player-render", true);
            this.clrInterval();
        },
        trackProgress(event) {
            this.progress = event.percent;
        },
        getDuration(event) {
            this.duration = event.duration;
        },
        paused() {
            this.clrInterval();
        },
        jumpToSection(obj) {
            console.log(obj)
            if(obj.layoutID === this.layoutID && this.$refs?.player) {
                this.$refs.player.play();
                this.$refs.player.player.setCurrentTime(obj.seconds);
            }
        },
    },
    watch: {
        progress(nVal) {
            const val = nVal * 100;
            //reset previous plays
            if(val < 26 && this.tracked > 25)
                this.tracked = 0;

            if(val > 0 && val < 26 && this.tracked < 25) {
                this.tracked = 25;
                // console.log(25);
                this.trackVideoProgress(25);
            }
            else if(val > 25 && val < 76 && this.tracked < 50) {

                this.tracked = 50;
                this.trackVideoProgress(50);
                // console.log(50);
            }
            else if(val > 75 && val < 101 && this.tracked < 100) {
                this.tracked = 100;
                this.trackVideoProgress(100)
                // console.log(100);
            }

        },
        lastHeadlineUpdate(val) {

            const item = val[0];

            if(item.id !== this.video.id) 
                return;
                
            setTimeout(() => {
                this.askedByUser = item.asked_by_user_id;
                this.question = item.question_text;
            },300);
            
        },
        video: {
            handler: function() {
                this.askedByUser = this.video.asked_by_user_id;
                this.question = this.video.question_text;
             
            },
            deep: true
        }
    }
}
</script>