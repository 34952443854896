export default {
    subscribedToSquawk(state) {
        return state.subscribedToSquawk;
    },
    squawkAvailable(state) {
        return state.squawkAvailable;
    },
    switchedToFullScreen(state) {
        return state.switchedToFullScreen;
    },
    squawkOnDemo(state, getters, rootState, rootGetters) {
        if(!rootState.general.demoPageMode)
            return true;

        const headlinesAvailable = rootGetters["payments/widgetsOnTheHomeScreen"].find(el => el.implementation_id === 5);
        
        const headlinesUnlocked = rootGetters["general/unlockedWidgets"].includes(5);
        
        if(headlinesAvailable && headlinesUnlocked)
            return true;

        return false;

    }
}