<template>
    <a
    class="dashboard-mini-menu"
    :style="{opacity: inactive ? '0': '1'}"
    href="javascript:void(0);"
    v-click-outside="closeMenu"
    @click.stop="openDashboardMenu"
    ><font-awesome-icon :icon="['fas', 'ellipsis-v']" />
        <div
        v-if="isDashboardMenuOpen"
        class="dashboard-mini-menu--menu card-fs"
        >
            <nav>
                <ul>
                    <li v-if="isDashboardScreen && !isAutoPilotMode"><a href="javascript:void(0);" @click="popoutDashboard(item.id)">Pop-out</a></li>
                    <li><a href="javascript:void(0);" @click="editDashboard(item.id)">Re-name</a></li>
                    <li v-if="isDashboardScreen"><a href="javascript:void(0);" @click="removeAsTab(item)">Remove</a></li>
                    <li v-if="isHomeScreen"><a href="javascript:void(0);" @click="deleteDashboard(item.id)">Delete</a></li>
                </ul>
            </nav>
        </div>
    </a>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';
import { eventBus, tabCommSender } from '../../main';
import Swal from 'sweetalert2';
import _ from 'lodash';
import { isAutoPilotMode } from '../../utility/helper-functions';

const dashboards = createNamespacedHelpers('dashboard');
const general = createNamespacedHelpers('general');

export default {
    props: {
        item: {
            type: Object,
            required: true,
            default: () => {}
        },
        inactive: {
            type: Boolean,
            required: false,
            default: false
        },
        mode: {
            type: String, //home-screen, dashboard-screen
            required: true,
            default: 'home-screen'
        }
    },
    computed: {
        ...general.mapState(['demoPageMode', 'unlockedWidgets']),
        ...general.mapGetters(['user', 'entity']),
        ...dashboards.mapGetters(['layouts', 'defaultDashboards', 'getPoppedoutDashboardByID', 'activeDashboardByInstanceID', 'tabbedDashboards', 'dashboards']),

        isHomeScreen() {
            return this.mode === 'home-screen';
        },
        isDashboardScreen() {
            return this.mode === 'dashboard-screen';
        },
        isAutoPilotMode() {
            return isAutoPilotMode();
        },

        activeDashboard() {
            return this.activeDashboardByInstanceID(this.$root.instanceID, "dashboard");
        }
    },
    data() {
        return {
            isDashboardMenuOpen: false,
            dashboardId: -1
        }
    },
    methods: {
        ...dashboards.mapActions(['setSystemDashboards', 'deleteDashboardById', 'setActiveDashboardAndTab', 'removeDashboard', 'updateDashboardById']),

        openDashboardMenu() {
            this.isDashboardMenuOpen = !this.isDashboardMenuOpen;
        },
        closeMenu() {
            this.isDashboardMenuOpen = false;
        },
        editDashboard(id) {
            const modal = {
                instanceID: this.$root.instanceID,
                mode: 'wide',
                classes: 'terminal-modal',
                footer: true,
                // panel: {}
            };

            if (id) {
                modal.title = "Edit Dashboard";
                modal.id = id;
            } else {
                modal.title = "Create a New Dashboard";

            }

            modal.componentInModal = "edit-dashboard";

            eventBus.$emit('open-modal', modal);
        },
        popoutDashboard() {
            this.dashboardId = this.activeDashboard;
            let currentDashboard = _.cloneDeep(this.layouts[this.dashboardId]);
            currentDashboard = {...currentDashboard, popout: true};
            this.updateDashboardById(currentDashboard);
            this.setNextActiveDashboardId();
            const height = window.innerHeight;
            const newWindow = window.open("/?popout=y&dashboard=" + this.dashboardId, `dashboard-${this.dashboardId}`, "target=_blank,toolbar=yes,scrollbars=yes,resizable=yes,top=100,left=100,width=920,height=" + height);

            const popout = {};

            popout.id = this.dashboardId;
            popout.name = `dashboard-${this.dashboardId}`;
            // popout.h = newWindow.outerHeight;
            // popout.w = newWindow.outerWidth;
            // popout.x = newWindow.screenX;
            // popout.y = newWindow.screenY;
            popout.window = newWindow;
            this.$store.dispatch('dashboard/addDashboard', popout);
            this.$emit("unhighlight-tab-menu-item")

            if(this.demoPageMode) {

                setTimeout(() => {
                    tabCommSender.postMessage({ unlockedWidgets: this.unlockedWidgets });
                    tabCommSender.postMessage({ entity: this.entity });
                    tabCommSender.postMessage({ userQna: this.user.qnaOn });
                },15000);

            }
        },
        setNextActiveDashboardId() {
            let tabbedDashboardsNotInPopups = this.tabbedDashboards.filter(entry => !entry.popout);
            if (tabbedDashboardsNotInPopups.length > 0) {
                this.setActiveDashboardAndTab({
                id: tabbedDashboardsNotInPopups[0].id,
                instanceID: this.$root.instanceID
            });
            } else {
                this.setActiveDashboardAndTab({
                id: -1,
                instanceID: this.$root.instanceID
            });
            }
        },
        removeAsTab(dashboard) {
            event.stopImmediatePropagation();

            Swal.fire({
                title: 'Are you sure?',
                type: 'warning',
                text: 'Do you want to close this tab?',
                showCancelButton: true,
                cancelButtonColor: '#1d9deb',
                confirmButtonColor: '#ff5656',
                confirmButtonText: 'Close'
            }).then((result) => {
                if (result.value) {
                    if (this.tabbedDashboards.length === 1) {
                        this.$router.push("/home");
                    }
                    this.closeDashboardTab(dashboard);
                }
            });

        },
        closeDashboardTab(dashboard) {
            const poppedOut = this.getPoppedoutDashboardByID(dashboard.id);
            if (poppedOut && poppedOut.window) {
                poppedOut.window.close();
            }
            dashboard.tab = false;
            dashboard.popout = false;
            this.updateDashboardById(dashboard);
            this.updateActiveDashboard();
        },
        updateActiveDashboard: function () {
            if (this.tabbedDashboards.length > 0) {
                this.setActiveDashboardAndTab({
                  id: this.tabbedDashboards[0].id,
                  instanceID: this.$root.instanceID
                });
            }
        },
        deleteDashboard(id) {

            Swal.fire({
                title: 'Are you sure?',
                type: 'warning',
                text: 'You\'ll need to create a new dashboard later.',
                showCancelButton: true,
                cancelButtonColor: '#383A42',
                confirmButtonColor: '#ff5656',
                confirmButtonText: 'Yes, delete it!'
            }).then((result) => {
                if (result.value) {

                    // console.log('delete',id);
                    const deleted = this.getPoppedoutDashboardByID(id);

                    const defaultID = this.activeDashboard;

                    if (deleted)
                        deleted.window.close();

                    this.removeDashboard(id);
                    this.deleteDashboardById({
                        id: id,
                        instanceID: this.$root.instanceID
                    });
                    console.log(this.dashboards);
                    const dashboards = this.dashboards.filter(el => !el.mobile && !el.vidpage)
                    if (defaultID === id &&
                        dashboards.length > 0) {
                        this.makeDefaultDashboard(dashboards[0].id, false);
                    } else if(dashboards?.length === 0) {
                        this.setActiveDashboard({
                            target: "dashboard",
                            id: -1,
                            instanceID: this.$root.instanceID
                        });
                    }

                }
            });

        },
        makeDefaultDashboard(id, notify = true) {
            let dashboards = _.cloneDeep(this.dashboards);

            if (dashboards) {

                dashboards.forEach(dashboard => {
                    if (dashboard.id === id) {
                        dashboard.default = true;
                        dashboard.tab = true;
                        const text = "Default dashboard is now <strong>" + dashboard.name + ".</strong>";

                        if (notify)
                            Swal.fire({
                                title: 'Done',
                                html: text,
                                type: 'info',
                                confirmButtonColor: '##1d9deb',
                                confirmButtonText: 'OK'
                                // timer: 1500
                            });

                    } else {
                        dashboard.default = false;
                    }
                })
            }
            this.setSystemDashboards(dashboards);
            this.setActiveDashboardAndTab({
                id: id,
                instanceID: this.$root.instanceID
            });
        },
    }
}
</script>

<style lang="scss">
    a.dashboard-mini-menu {
        color: $input-color;

        &:hover,
        &:active,
        &:visited {
            color: $input-color;
        }
    }
    .dashboard-mini-menu {

        color: $input-color;

        position: relative;

        & a {
            text-decoration: none;
            padding: 2px 3px;

        }

        &--menu {

            position: absolute;
            z-index: 3;
           

            // &.dashboards {

            //     top: 26px;
            //     left: -65px;
            //     z-index: 1;
            //     width: 110px;
            // }

            &.card-fs {
                width:110px;
                left: -95px;
                top: 25px;

            }

            &.widget {
                left: -75px;
                top: 17px;

            }

            &.account {

                top: 10px;
                z-index: 10;
                width: 125px;
            }

            &.dashboard {
                width: 230px;
                left: 50%;
                transform: translateX(-50%);

                & nav ul li a {
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    padding-right: 11px;
                    text-decoration: none;
                }
            }

            & nav ul {

                margin: 0;
                padding: 2px;
                border: 1px solid $separator-line-color;
                border-radius: 8px;
                background-color: $onboard-survey-background;

                & li {
                    list-style: none;

                    font-size: 12px;
                    font-weight: 600;


                    & a {
                        display: block;
                        border-radius: 4px;
                        padding: 4px 26px 4px 11px;
                        text-align: left;
                        font-size: 12px;
                        color: $input-color;
                        text-decoration: none;

                        &:hover,
                        &:active,
                        &:visited {
                            background-color: $onboard-survey-card-background;
                            color: $input-color;
                        }
                    }
                }
            }
        }

    }
</style>