<template>
    <div class="account-screen">
        <div class="account-screen-inner">
            <div 
            v-if="!isExternalClientMode && !isAutoPilotMode"
            class="account-screen-left">
                <div class="account-screen-menu">
                    <nav>
                        <ul>
                            <li :class="(selectedTab === '#profileTab')? 'active': ''" >
                                <a href="#profileTab">Profile
                                </a>
                            </li>
                            <li :class="(selectedTab === '#terminalTab')? 'active': ''" >
                                <a href="#terminalTab">Terminal
                                </a>
                            </li>
                            <li :class="(selectedTab === '#subscriptionTab')? 'active': ''" >
                                <a href="#subscriptionTab">Subscription Details
                                </a>
                            </li>
                            <li :class="(selectedTab === '#billingTab')? 'active': ''">
                                <a href="#billingTab">Billing
                                Details
                                </a>
                            </li>
                            <li :class="(selectedTab === '#invoicesTab')? 'active': ''" href="#invoicesTab">
                                <a href="#invoicesTab">Invoice
                                History
                                </a>
                            </li>
                        </ul>
                    </nav>
                </div>
            </div>
            <div v-else></div>
            <div>
                <AccountWrapper></AccountWrapper>
            </div>
        </div>
    </div>
</template>

<script>
import { isAutoPilotMode, isExternalClientMode } from '../../../utility/helper-functions';

import AccountWrapper from "./AccountWrapper";

export default {
    name: "AccountOverview",
    components: {AccountWrapper},
    computed: {
        isExternalClientMode() {
            return isExternalClientMode();
        },
        isAutoPilotMode() {
            return isAutoPilotMode();
        },
        selectedTab: function () {
            return this.$route.hash || '#profileTab';
        }
    },
    methods: {
        scrollTo: function (name) {
            return this.$scrollTo(name);
        }
    }
}
</script>

<style lang="scss" scoped>
.account-screen {
    @include standardContainer;
    margin-top: 30px;
}
// .external-fs .account-screen {
//     min-height: unset;
// }

.account-screen-inner {

    display: grid;
    grid-template-columns: 1fr;
    gap: 30px;

    @include respond(lg) {
        grid-template-columns: 220px 1fr;
    }

    @include respond(xl) {
        grid-template-columns: 350px 1fr;
    }
}

.account-screen-left {
    
    @include respond(lg) {
        position: relative;
    }
}

.account-screen-menu {
    background-color: $onboard-survey-card-background;
    border-radius: 10px;
    padding: 10px;

    @include respond(lg) {
        position: fixed;
        width: 220px;
    }

    @include respond(xl) {
        width: 350px;
    }

    & nav {

        & ul {

            padding: 0;
            margin: 0;
            list-style: none;

            & li {
                padding: 10px 15px;
                border-radius: 8px;

                & a {
                    text-decoration: none;
                    @include SubHead2;
                    color: $input-color-filter;

                    
                }

                &:hover {
                    background-color: $onboard-survey-background!important;
                }
            }
        }
    }
}

.active {
  background-color: $onboard-survey-background!important;

    & a {
        color: $input-color!important;
    }
  
}
</style>

<style lang="scss">
    .account-card {
        background-color: $onboard-survey-card-background;
        border-radius: 10px;
        padding: 20px;

        & h2 {
            margin: 0;
            @include H2;
            color: $input-color;
        }


        & .form-control-fs,
        .vue-form-generator fieldset .form-group {
            width: 100%;
            display: grid;
            grid-template-columns: 1fr;
            align-items: center;

             @include respond(lg) {
                grid-template-columns: 1.4fr 2fr;
            }

            & input,
            & select {
                font-size: 16px;
            }
        }

        .profile-main {
            display: grid;
            grid-template-columns: 1fr;
            gap: 30px;

            @include respond(lg) {
                grid-template-columns: 2fr 1fr;
            }
        }

        & .account-card-section {

            display: grid;
            grid-template-columns: 1fr;
            gap: 30px;

            color: $input-color;
            border-bottom: 1px solid $separator-line-color;
            padding: 20px 0;

            &:first-child {
                padding-top: 0;
            }

            &:last-child {
                border-bottom: none;
                padding-bottom: 0;
            }

            @include respond(lg) {
                grid-template-columns: 210px 1fr;
            }

            @include respond(xl) {
                grid-template-columns: 350px 1fr;
            }
           
            
        }

        .account-widget-details {
            margin-top: 5px;
            margin-bottom: 10px;
        }

        .addon-item:last-child .account-widget-details {
            margin-bottom: 0px;
        }

        .transparent-link {
            color: $input-color-filter;
            text-decoration: none;
        }

        .plan-notification {
            font-size: 12px;
            color: $input-color;

            &.red {
                background-color: transparent;
                color: $core-lightred;
            }
        }

        .no-item-message {
            
            @include H5;

            color: $input-color;
            margin: 0;
         
            
        }

        .product-icon {
            @include productIcon;
        }

        .plan-description {
            &.red {
                background: transparent;
                color: $core-lightred;
            }
        }

        .product-name .vue-custom-tooltip.is-left.is-medium:after,
        .addon-item .vue-custom-tooltip.is-left.is-medium:after {
            width: auto!important;
        }

        & #MazPhoneNumberInput .country-selector__input,
        & #MazPhoneNumberInput .input-tel__input {
            height: 48px!important;
            border: none!important;
            background-color: $onboard-survey-background!important;
            padding-top: 0!important;
        }
        & #MazPhoneNumberInput .country-selector__input {
            border-radius: 10px!important;
            
            

            font-family: sans-serif;
        }
        & #MazPhoneNumberInput .input-tel__input {
            border-radius: 10px!important;
            margin-left: 15px;
            width: calc(100% - 15px);
            cursor: unset;
         
        }
        // & #MazPhoneNumberInput .country-selector__input:hover, 
        // & #MazPhoneNumberInput .input-tel__input:hover {
        //     border-color: #565E7A!important;
        // }
        // & #MazPhoneNumberInput .country-selector__input:focus,
        // & #MazPhoneNumberInput .input-tel__input:focus {
        //     box-shadow: none!important;
        //     border-color: $input-focus-color!important;
        // }
        & #MazPhoneNumberInput .country-selector__label,
        & #MazPhoneNumberInput .input-tel__label {
            display: none!important;
        }

        & #MazPhoneNumberInput.invalid-input .input-tel__input,
        & #MazPhoneNumberInput.invalid-input .country-selector__input {
            border-color: none!important;
        }
    }
    .account-error {
        font-size: 12px;
        color: $core-lightred;
    }

    .slide-down-link-style {
        color: $input-color;
        text-decoration: none;
        font-size: 14px;

        font-weight: bold;

        & svg {
            color: $input-color-filter;
        }
    }

    .included-widgets {
        list-style-type: none;
        padding: 0;
        margin: 5px 0;


        & li {
            display: inline-block;
            background-color: $onboard-survey-background;
            font-size: 11px;
            color: $input-color-filter;

            margin: 5px;
            padding: 5px 12px;
            border-radius: 8px;

            & svg {
                color: $core-lightgreen;
            } 

            & .showcase {
                color: $input-color-filter;
            }
        }

        &-label {
            font-size: 12px;
            font-weight: bold;
            text-transform: uppercase;
            letter-spacing: 0.2px;           
        }

        &-tc {
            @include respond(md) {
                display: grid;
                grid-template-columns: 1fr 1fr;
            }
        }
    }
</style>
