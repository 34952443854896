<script>
    import { Bar, mixins } from 'vue-chartjs'
    import {precise} from "../../../../utility/helper-functions";
    const { reactiveProp } = mixins

    export default {
        extends: Bar,
        mixins: [reactiveProp],
        props: {
            yScaleLabel: String,
            maintainAspectRatio: {
                type: Boolean,
                default: false
            },
            title: {
                type: String,
                default: ""
                },
            chartData: Object,
            handler: {
                type: Function,
                default: () => {}
            },
            min: Number,
            max: Number,
            yTicks: {
                type: Function
            },
            legendOn: {
                type: Boolean
            },
            iteration: Number,
            labelText: Function
        },
        data: function () {
            return {
                options : {
                    onClick: this.handler,
                    responsive: false,
                    maintainAspectRatio: this.maintainAspectRatio,
                    legend: {
                        display: this.legendOn,
                    },
                    title: {
                        display: true,
                        text: this.title
                    },
                    scales: {
                        xAxes: [{
                            display: true,
                            gridLines: {
                                display:false
                            },
                            ticks: {
                                beginAtZero: true,
                                fontSize: 11,
                                fontColor: "white",
                            },
                        }],
                        yAxes: [{
                            id: "yAxis",
                            gridLines: {
                                zeroLineColor: "#292C38",
                                drawBorder: false,
                                color: "#292C38"
                            },
                            ticks: {
                                beginAtZero: true,
                                fontSize: 11,
                                callback: (value) => this.yTicks(value)
                            },
                            position: 'right',
                            display: true
                        }]
                    },
                    tooltips: {
                        enabled: true,
                        mode: 'single',
                        intersect: true,
                        callbacks: {
                            label: context => this.labelText(context)
                        }

                    },
                    events: ['click'],
                    plugins: {
                        datalabels: {
                            color: '#f0f8fa',
                            clamp: true,
                            anchor: 'end',
                            offset: 0,
                            align: 'bottom',
                            events: ['click'],
                            formatter: function(value) {
                                let type = typeof value;
                                if(type === "object") {
                                    return precise(value) + '%';
                                } else {
                                    return precise(value) + '%';
                                }
                            }
                        },
                    },
                    
                }
            }
        },
        created() {
            this.setChartProps();
        },
        mounted () {
            this.renderChart(this.chartData, this.options)
        },
        methods: {
            setChartProps() {
                if(this.min || this.min === 0)
                    this.options.scales.yAxes[0].ticks.min = this.min;
                if(this.max || this.max === 0)
                    this.options.scales.yAxes[0].ticks.max = this.max;
                // if(this.stepSize)
                //     this.options.scales.yAxes[0].ticks.stepSize = this.stepSize;
                // if(this.yLabelString) {
                //     this.options.scales.yAxes[0].scaleLabel.display = true;
                //     this.options.scales.yAxes[0].scaleLabel.labelString = this.yLabelString;
                // }
            }
        },
        watch: {
            iteration() {
                // console.log("updated", this.iteration);
                this.setChartProps();
                this.renderChart(this.chartData, this.options)
            }
        }
    }
</script>

<style scoped>

</style>
