<template>
    <div 
    class="embed-widget-header"
    :class="{'block-view': smallerThanXs1Widget}">

        <div class="embed-widget-header-unit optin">
            <div>
                <div class="embed-widget-header-unit-content">
                    <form @submit.prevent :class="{'fs-d-block': smallerThanSmWidget}">
                        <div class="fs-cta align-center"
                        :class="{'form-control-fs': !smallerThanSmWidget, 'mr-15': !smallerThanSmWidget, 'form-control-fs-my-5': smallerThanSmWidget}"
                        >Be the first to get new deals and updates from us</div>
                        <div class="input-div-fs">
                            <base-input
                            elementType="input"
                            :class="{invalid: error.email, 'form-control-fs': !smallerThanSmWidget, 'form-control-fs-my-5': smallerThanSmWidget}"
                            inputType="email"
                            v-model="profile.email"
                            :placeholder="'Email address'"
                            inputClass="partner-input light-border o-height-40"
                            formControlClass=""
                            style="margin-right: 15px;"
                            :invalid="error.email"
                            :invalidMessage="error.email"
                            @clear-validity="clearValidity"
                            ></base-input>
                            <base-button
                            mode="o-blue"
                            class="o-height-40 padding-x-25 font-size-12 wh-space-no-wrap"
                            :class="{'form-control-fs': !smallerThanSmWidget, 'form-control-fs-my-5': smallerThanSmWidget}"
                            :link="false"
                            @click.native="submit"
                            :disabled="isProcessingSubmit"
                            :showSpinner="isProcessingSubmit"
                            >Submit email
                            </base-button>
                        </div>

                       
                    </form>
                </div>
            </div>
        </div>

        <div class="embed-widget-header-unit rating">
            <div>
                <div class="embed-widget-header-unit-content">
                    <form @submit.prevent :class="{'fs-d-block': smallerThanSmWidget}" style="margin-top: 3px;">
                        <div class="fs-cta align-center"
                        :class="{'form-control-fs': !smallerThanSmWidget, 'mr-15': !smallerThanSmWidget, 'form-control-fs-my-5': smallerThanSmWidget}"
                        >{{ ratingMessage }}</div>
                        <div class="input-div-fs"
                        >
                            <base-button
                            v-if="!rated"
                            mode="o-tranparent-grey"
                            class="o-height-40 padding-x-25 font-size-12 wh-space-no-wrap"
                            :class="{'form-control-fs': !smallerThanSmWidget, 'mr-15': !smallerThanSmWidget, 'form-control-fs-my-5': smallerThanSmWidget}"
                            style="margin-right: 15px;"
                            :link="false"
                            @click.native="submitRating"
                            >Rate it<font-awesome-icon :icon="['fas', 'thumbs-up']" style="margin-left: 7.5px;"/>
                            </base-button>
                            <base-button
                            mode="o-tranparent-grey"
                            class="o-height-40 padding-x-14 font-size-12 wh-space-no-wrap"
                            style="border-radius: 100px;"
                            :link="false"
                            @click.native="openBugReport"
                            ><font-awesome-icon :icon="['fas', 'bug']"/>
                            </base-button>
                        </div>

                       
                    </form>
                </div>
            </div>
        </div>
        <div class="embed-widget-header-unit copy">
            <div class="embed-widget-header-unit-content">
                <div 
                class="embed-code-fs"
                :class="{'pb-10': showBranding}"
                >
                    <textarea :id="'click-to-copy-'+details.implementation_id" hidden><div class="fs-loading-notification-app_REFGOESHERE" style="background-color:#1C1C1C;text-align:center;color:#FFFFFF;padding:50px 0;">Loading indicator...</div><a href="https://financialsource.co" class="fs-b-link" style="display:none;"></a><div id="app_REFGOESHERE"></div><script>var s, r, t; r=false; s=document.createElement('script'); s.type='module'; s.src="https://assets.analyst-desk.com/analystDesk.umd.min.js"; s.onload=s.onreadystatechange=function(){if ( !r && (!this.readyState || this.readyState=='complete') ){r=true; window.analystDesk.startWidget("#app_REFGOESHERE", "ab0155e4-d240-4fcb-944d-76445e071cd8",{"widget": REFGOESHERE, "userref":+(new Date().toISOString().replace(/\D/g,'')),});}}; t=document.getElementsByTagName('script')[0]; t.parentNode.insertBefore(s, t);</script></textarea>
                    <base-button
                    mode="o-tranparent-grey o-white-border o-white-text"
                    class="o-height-40 padding-x-25 font-size-12 wh-space-no-wrap"
                    :class="{'o-success-button': copiedToClipBoard}"
                    style="margin-right: 7px;"
                    :style="buttonStyle"
                    :link="false"
                    @click.native="clickToCopy"
                    >   
                        <span v-if="copiedToClipBoard">
                            <font-awesome-icon class="check"  :icon="['fa', 'check-circle']" size="sm"/> <span v-if="!smallerThanSmWidget">Success</span>
                        </span>
                        <span v-else style="text-align: center">
                            <span v-if="smallerThanSmWidget">[Embed]</span>
                            <span v-else>[Embed code]</span>
                             
                        </span>
                    </base-button>
                    <div class="click-to-copy-cta">- click to copy</div>
                </div>
                <div v-if="showBranding" class="powered-by-fs with-border">
                    <a href="https://financialsource.co" target="_blank">
                        <img :src="logoSrc" alt="Financial Source logo" />
                        <span>Powered by Financial Source</span>
                    </a>
                </div>
                
                
            </div>
        </div>
    </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';
import { eventBus } from '../../../main';
import { frontFacingAssets } from '../../../utility/helper-functions';

const widgets = createNamespacedHelpers("widget");

export default {
    props: ["details"],
    computed: {
        ...widgets.mapGetters(["ratings"]),

        smallerThanXs1Widget() {
            return !this.details.isXs1Widget;
        },
        smallerThanSmWidget() {
            return !this.details.isSmWidget;
        },
        logoSrc() {
            return frontFacingAssets() + 'fs-white-stacked-logo.png';
        },
        buttonStyle() {
            const width = this.smallerThanSmWidget ? 106 : 140;
            return 'width:' + width + 'px';
        },
        showBranding() {
            return false;
        },
        ratingMessage() {
            if(this.rated)
                return "Thank you for your feedback.";

            return "Was this indicator helpful?";
        },
        rated() {
            return this.ratings[this.details.implementation_id]
        }
    },
    data() {
        return {
            profile: {
                email: ''
            },
            error: {
                email: ''
            },
            isProcessingSubmit: false,
            copiedToClipBoard: false,
        }
    },
    methods: {
        clearValidity() {

        },
        submit() {

        },
        submitRating() {

			const modal = {
                instanceID: this.$root.instanceID,
                componentInModal: "submit-rating",
                classes: "terminal-modal simplified auto-height",
				mode: 'wide',
				panel: {
					implementation_id: this.details.implementation_id,
                    widgetTitle: this.details.widgetTitle
				}
			}
            // console.log("emitting");

			eventBus.$emit('open-modal', modal);
		},
        openBugReport() {
            eventBus.$emit('open-modal', {
                instanceID : this.$root.instanceID,
                classes: "terminal-modal simplified",
                componentInModal: "bug-report",
                mode: "wide",

            });
        },
        clickToCopy() {  
            let copyText = document.getElementById('click-to-copy-'+this.details.implementation_id).value;
            copyText = copyText.replaceAll("REFGOESHERE", this.details.implementation_id)
            navigator.clipboard.writeText(copyText).then(() => {
                this.copiedToClipBoard = true;
                setTimeout(() => this.copiedToClipBoard = false, 2500);
            });
        }
    }
}
</script>

<style lang="scss" scoped>
    .embed-widget-header {
        display: grid;
        gap: 20px;
        grid-template-columns: 1fr 1fr 1fr 1fr 1fr;

        & .embed-widget-header-unit {
            border-width: 1px;
            border-style: solid;
            border-radius: 10px;
            padding: 0 10px;
        }
        & .embed-widget-header-unit.optin {
            grid-column: 1 / -1;
            border-color: $client-border-4;
            background-color: $partner-border-1;
            display: none;

            & .embed-widget-header-unit-content {
                
                
            }
        }
        & .embed-widget-header-unit.rating {
            grid-column: 1 / 4;
            background-color: $partner-background-2-25;
            border-color: $partner-border-1;
            // display: none;

            & .embed-widget-header-unit-content {
                
            }
        }
        & .embed-widget-header-unit.copy {
            grid-column: 4 / -1;
            background-color: $partner-background-2-25;
            border-color: $partner-border-1;
            display: flex;
            justify-content: center;
            align-items: center;

             & .embed-widget-header-unit-content {
                

                & > div {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }
            }

            & .click-to-copy-cta {
                font-size: 10px;
                color: $input-color;
            }
        }
        &.block-view {
            padding: 0 20px;
            & .embed-widget-header-unit,
            & .embed-widget-header-rating, 
            & .embed-widget-header-copy {
                grid-column: 1 / -1;
            }
        }
        & .embed-widget-header-unit-content {
            // border-width: 1px;
            // border-style: solid;
            
        }
        & .embed-widget-header-unit-content form {
            display: flex;
            align-items: center;
            justify-content: center;

            & .input-div-fs {
                display: flex;
                align-items: center;
                justify-content: center;
            }

            & .fs-cta {
                font-size: 14px;
                color: $input-icon-color;
            }
            &.fs-d-block {
                display: block;
            }
        }


        .powered-by-fs {
            font-size: 10px;
            color: $checkbox-active;
            font-weight: 500;

            & a {
                display: flex;
                align-items: center;
                cursor: pointer;
            }

            & a:link,
            & a:visited,
            & a:active,
            & a:hover {
                color: $checkbox-active;
            }

            &.with-border {
                padding-top: 7.5px;
                border-top: 1px solid $separator-line-color-2;
            }

            & img {
                height: 20px;
                margin-right: 5px;
            }
        }
    }
</style>