<template>
    <form @submit.prevent>
        <base-input
        elementType="input"
        :class="{invalid: !name.isValid}"
        inputType="text"
        v-model="name.val"
        inputClass=""
        formControlClass=""
        label="Add Name"
        forID="dashboard-step-name"
        :invalid="!name.isValid"
        invalidMessage="Name must be 3 - 30 characters long"
        @clear-validity="clearValidity"
        ></base-input>
        
        <div class="layout-panel-section">
            <div class="edit-dashboard-prompt">
                <span v-if="!formObj.id">Select configuration</span>
                <span v-else>Dashboard configuration</span>
            </div>
            <div class="edit-dashboard-prompt-sub">
                <span v-if="!formObj.id">Decide how many indicators you want to display on this dashboard.</span>
                <span v-else>To use a different configuration, please create a new dashboard</span>
                </div>
            <dashboard-layouts
            :initialLayout="panelLayoutID"
            @layout-selection-change="details => this.panelLayoutID = details"
            ></dashboard-layouts>
        </div>
        

        <p v-if="errorMessage" class="error-message">{{ this.errorMessage }}</p>
        <div class="form-control-fs">
            <base-button id="create-dashboard-button-step" mode="o-blue" @click.native="submitForm">{{ formMode === "new" ? "Create Dashboard" : "Save Dashboard" }}</base-button>
        </div>

    </form>
</template>

<script>
import { eventBus } from '../../../main';
import {createNamespacedHelpers} from 'vuex';
import _ from "lodash"

import DashboardLayouts from '../DashboardLayouts.vue';
import TrackingService from '../../../services/tracking/tracking.service';

const dashboards = createNamespacedHelpers('dashboard');
export default {
    inject: ['formObj'],
    components: {
        DashboardLayouts
    },
    data() {
        return {
            name: {
                val: '',
                isValid: true
            },
            formIsValid: true,
            errorMessage: null,
            formMode: this.formObj.mode,
            dashboardID: this.formObj.id,
            panelLayoutID: null
        }
    },
    computed: {
        ...dashboards.mapGetters(['layouts', 'getDashboardById', 'getDashboardNameByID', 'nextDashboardId', 'defaultDashboards']),
    },
    // watch: {
    //     name: {
    //         deep: true,
    //         handler: function () {
    //             if(this.$tours['myTour'].currentStep === 2) {
    //                 this.$tours['myTour'].nextStep();
    //             }
    //         }
    //     }
    // },
    methods: {
        ...dashboards.mapActions(['addDashboard', 'updateDashboardById', 'setActiveDashboardAndTab']),
        clearValidity(input) {
            if(this[input]) {
                this[input].isValid = true;
            }
            this.errorMessage = null;
        },
        submitForm() {

            this.validateForm();
            console.log("selected layout on submission", this.selectedLayoutID);

            if(!this.formIsValid)
                return;

            if(this.formMode === "new") {
                this.dashboardID = this.nextDashboardId;
                let dashboard = {
                    id: this.dashboardID,
                    panelLayoutID: this.panelLayoutID,
                    elements: {},
                    name: this.name.val
                }
                this.updateDashboardById(dashboard);
                this.setActiveDashboardAndTab({
                  id: dashboard.id,
                  instanceID: this.$root.instanceID
                });
                TrackingService.sendEvent("created_new_dashboard", {panel_layout: this.panelLayoutID});
            } else {
                let dashboard = _.clone(this.getDashboardById(this.dashboardID));
                dashboard.name = this.name.val;
                this.updateDashboardById(dashboard);
                this.setActiveDashboardAndTab({
                  id: dashboard.id,
                  instanceID: this.$root.instanceID
                });
            }

             eventBus.$emit("nav-change", {
                instanceID: this.$root.instanceID,
                comp: "dashboard"
            });

            eventBus.$emit('close-modal');
            
        },
        validateForm() {
            this.formIsValid = true;
            if(this.name.val.length < 3 || this.name.val.length > 30 ) {
                this.name.isValid = false;
                this.formIsValid = false;
            }
        }
    },
    created() {
        this.name.val = this.formObj.id ? this.getDashboardNameByID(this.formObj.id) : '';
        this.panelLayoutID = this.formObj.id ? this.getDashboardById(this.dashboardID).panelLayoutID : null;
    }
}
</script>

<style scoped lang="scss">
.form-control-fs, .layout-panel-section {
  margin: 15px;
}

.layout-panel-section { 
    margin-top: 35px;
}

.edit-dashboard-prompt {
    display: block;
    margin-bottom: 5px;
    color: $input-color-filter;
    letter-spacing: 0.1px;
    font-weight: 300;
    font-size: 16px;
}

.edit-dashboard-prompt-sub {
    margin-bottom: 25px;
    color: $input-color-filter;
    font-size: 14px;
}

// .form-control-fs:last-child {
//   margin-top: 45px;
// }

// label {
//   font-weight: bold;
//   display: block;
//   margin-bottom: 5px;
// }

// input {
//   display: block;
//   width: 100%;
//   border: none;
//   border-bottom: 1px solid $color-01;
//   font: inherit;
//   height: 35px;
// }

// input:focus {
//     outline: none;
// }





// .invalid input,
// .invalid textarea {
//   border: 1px solid red;
// }

// .invalid p {
//     color: red;
// }
</style>
