<template>
    <div
    :style="innerElementHeight">
        <div class="squawk-note">Please keep this window open for an uninterrupted audio squawk service.</div>
    
        <div
        
        class="squawk-controls"
        >
            <button 
            style="margin-top: -1.5px;"
            @click="playTest">
                <VueCustomTooltip :label="testToolTip" :position="ttPosition">
                    <!-- <font-awesome-icon :icon="['fas', 'play-circle']" /> -->
                    <play-button></play-button>
                </VueCustomTooltip>
            </button>
            <button
            @click="squawkPlaying = !squawkPlaying">
                <VueCustomTooltip :label="playToolTip" :position="ttPosition">
                    <vertical-bars v-if="testPlaying"></vertical-bars>
                    <sound-on v-else-if="squawkPlaying"></sound-on>
                    <sound-off v-else></sound-off>
                </VueCustomTooltip>
            </button>
            <VueCustomTooltip class="volume-bar" :label="volumeToolTip" :position="ttPosition">
                <base-input
                style="margin:0;"
                class="no-focus rangle-input volume-slider transparent"
                :class="{muted: isMuted}"
                elementType="input"
                
                inputType="range"
                min="0"
                max="10"
                v-model="volume"
                >
                </base-input>
            </VueCustomTooltip>
            
        </div>
    </div>
</template>

<script>
import PlayButton from '../../assets/PlayButton.vue';
import SoundOn from '../../assets/SoundOn.vue';
import SoundOff from '../../assets/SoundOff.vue';
import VerticalBars from '../../assets/VerticalBars.vue';

import Swal from 'sweetalert2';
import { isWeekend } from '../../utility/datetime';
import { isMobileForDashboards } from '../../utility/helper-functions';
import { createNamespacedHelpers } from 'vuex';
import * as workerTimers from 'worker-timers';
import Constants from '../../utility/constants.js';
import TrackingService from '../../services/tracking/tracking.service.js';
import { eventBus } from '../../main';
import Squawk from '../../mixins/squawk';

const dashboards = createNamespacedHelpers('dashboard');
const squawk = createNamespacedHelpers('squawk');
const general = createNamespacedHelpers('general');

export default {
    props: ["details"],
    mixins: [Squawk],
    components: {
        PlayButton,
        SoundOn,
        SoundOff,
        VerticalBars
    },
    created() {
        // if(localStorage.getItem("playingSquawk") && !getParameterByName('popout')) {
        //     this.squawkPlaying = true;
        // }
        this.setOrGetAudioTokens();

        eventBus.$on("widget-resize", obj => {
            if(obj.id === this.details.layoutID) {
                this.height = obj.height;

            }
            
        });
        
        

    },
    computed: {
        ...dashboards.mapState(['fullscreenId']),
        ...squawk.mapGetters(['switchedToFullScreen']),
        ...general.mapGetters(['warningDismissed']),
        innerElementHeight() {
            return {height: this.height + 70 + 'px', 'overflow-y': 'auto', 'overflow-x': 'auto'};
        },
        testToolTip() {
            if(this.testPlaying)
                return "Stop audio test, activate Squawk";
            else if(this.squawkPlaying)
                return "Test the audio";
            else
                return "Test the audio";
        },
        playToolTip() {
            if(this.testPlaying)
                return "Activate squawk";
            else if(this.squawkPlaying)
                return "De-activate squawk";
            else
                return "Activate squawk";
        },
        volumeToolTip() {
            if(this.nothingPlaying)
                return "Activate squawk";

            return "Adjust volume";
        },
        nothingPlaying() {
            return !(this.squawkPlaying || this.testPlaying);
        },
        isMuted() {
            return +this.volume === 0;
        },
        ttPosition() {
            return isMobileForDashboards() ? "is-left" : "is-bottom";
        }
        // message() {
        //     if(this.testPlaying)
        //         return "Audio Test";
        //     if(this.squawkPlaying)
        //         return "Squawk On";

        //     return "Squawk Off";
        // }
    },
    data() {
        return {
            testPlaying: false,
            squawkPlaying: false,
            volume: 0,
            heartbeatInterval: null,
            height: 0,
            // timeout: null,
            // squawkTimeout: null,
        }
    },
    // beforeDestroy() {
        // if(window.webrtc)
        //      window.webrtc.stop_audio();
    // },
    methods: {
        ...squawk.mapActions(['setSwitchedToFullScreen']),
        playTest() {
            if(this.testPlaying) {
                this.testPlaying = false;
                this.squawkPlaying = true;
                this.volume = 0;
                return;
            }
            this.squawkPlaying = false;
            setTimeout(() => {
                this.testPlaying = true;
                this.volume = 10;
                 
    
                const text = "<p>We are connecting you to our audio test channel. Music should begin playing shortly.</p><p>Please retry the test or contact support if you do not get any audio in the <strong>next 30 seconds.</strong></p><p>Thanks.</p>"
                Swal.fire({
                    title: 'Testing Audio',
                    html: text,
                    type: 'info',
                    confirmButtonColor: '##1d9deb',
                    confirmButtonText: 'OK'
                });
                //because of multitab, we need some delay but there are no promises available
                // setTimeout(() => {
                    window.webrtc.play_channel("audio_test");
                // }, 6000);
            }, 100); 
            
        },
    },
    watch: {
        squawkPlaying(val) {
            if(this.switchedToFullScreen) {
                this.setSwitchedToFullScreen(false);

                if(val) {
                    if(!this.volume)
                        this.volume = 10;
                }
                return;
            }
                

            this.testPlaying = false;
            if(val) {
                console.log("setPlayingSquawk", val);
                // localStorage.setItem("playingSquawk", val);
                if(!this.warningDismissed)
                    eventBus.$emit('open-modal', {
                        classes: "terminal-modal simplified",
                        componentInModal: "squawk-modal",
                        mode: "wide",

                    });
                if(window.webrtc) {
                    window.webrtc.stop_audio();
                    if(this.heartbeatInterval)
                        workerTimers.clearInterval(this.heartbeatInterval);
                }
                if(isWeekend()) {
                    const text = "<p>The Audio service is <strong>not active between 10pm Friday and 10pm Sunday (UK Time).</strong> Please check back when the markets open for our audio service.</p>"
                    Swal.fire({
                        title: 'Weekend Break',
                        html: text,
                        type: 'info',
                        confirmButtonColor: '##1d9deb',
                        confirmButtonText: 'OK'
                    });
                }
                else {
                    TrackingService.trackHeartBeat({
                        lookingAt: "squawk_playing"
                    });
                    
                    this.heartbeatInterval = workerTimers.setInterval(() => {
                        TrackingService.trackHeartBeat({
                                lookingAt: "squawk_playing"
                            });
                        // console.log("heartbeat", "home");
                    }, Constants.TRACK_INTERVAL);
                }
                // this.squawkTimeout = setTimeout(() => {
                    window.webrtc.play_channel("forex");
                    console.log("initialiting fx");
                // }, 6000);
                if(!this.volume)
                    this.volume = 10;
            }else{
                // console.log("squawkStopping");
                if(this.nothingPlaying)
                    this.volume = 0;

                // localStorage.removeItem("playingSquawk");

                if(window.webrtc) {
                    window.webrtc.stop_audio();
                    workerTimers.clearInterval(this.heartbeatInterval);
                }
            }
        },
        volume(nVal, oVal) {
            // console.log(nVal);
            if(nVal)
                window.webrtc.change_volume(nVal * 10);
            if(this.nothingPlaying &&
            nVal &&
            +nVal > 0 &&
            +oVal === 0 ) {
                 this.squawkPlaying = true;
                //  localStorage.setItem("playingSquawk", true);
            }else if(this.squawkPlaying && +nVal === 0) {
                this.squawkPlaying = false;
            }
        }
    },
    beforeDestroy() {
        this.setSwitchedToFullScreen(this.fullscreenId);
        if(this.heartbeatInterval)
            workerTimers.clearInterval(this.heartbeatInterval);
    }
}


</script>

<style lang="scss" scoped>
    .squawk-note {
        font-size: 14px;
        color: $input-color-filter;
        text-align: center;
        margin-bottom: 35px;
    }
    .squawk-controls {
        font-size: 10px;
        display: grid;
        grid-template-columns: 20px 20px;
        gap: 10px;
        justify-items: normal;
        max-width: 266px;
        align-items: flex-start;
        margin: 0 auto;
        padding-right: 50px;
        padding-left: 50px;

        & > .volume-bar {
            display: none;
        }

        // @include respond(lg) {
        //     grid-template-columns: 20px 20px 105px;
        // }

        & .vue-custom-tooltip:after {
            width: max-content;
            
        }

    }

    button {
        background-color: transparent;
        border: none;
        color: $color-20;
        transition: color 0.2s ease;
        padding-top: 8px;
        padding-bottom: 3px;
        border-radius: 2px;
        cursor: pointer;

        // &:hover {
        //     color: $color-15;
        // }
        // &:active {
        //     color: $color-155;
        // }
    }

    .volume-toggler {
        width: 25px;
        text-align: left;
    }

    .volume-toggler-muted {
        color: $color-12;
    }

    .channel-data {
        padding-left: 5px;
        color: $input-color;
        margin-left: 15px;
    }

    // .squawk-switch {
    //     margin: 0;
    //     margin-bottom: 20px;
        
    // }

    .volume {
        display: none;
        align-items: center;
        margin-left: 15px;

        @include respond(lg) {
            display: flex;
        }

        
    }
    



    .volume-info {
        display: none;
    }

    .test button {
        font-size: 13px;
        display: none;

        @include respond(sm) {
            display: unset;
        }
    }

    .size-xxs .volume-info {
        display: block;
    }
    .size-xxs .squawk-controls {
            grid-template-columns: 20px 20px 93px;
            padding-right: 0;

            & > .volume-bar {
                display: unset;
            }
        }

</style>

