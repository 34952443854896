<template>
    <div> 
        <div>
            <div class="form-header">
                <h2>Let's get your account setup</h2>
                <p>So we can give you access to our software code and begin your onboarding</p>
            </div>
            <form @submit.prevent>

                <base-input
                elementType="input"
                :class="{invalid: error.name}"
                inputType="text"
                :required="true"
                v-model="profile.name"
                forID="name"
                :placeholder="'Name'"
                inputClass="partner-input"
                formControlClass=""
                label="Business (Broker) Name"
                labelClass="medium-font"
                :invalid="error.name"
                :invalidMessage="error.name"
                @clear-validity="clearValidity"
                ></base-input>

                <base-input
                elementType="input"
                :class="{invalid: error.email}"
                inputType="email"
                :required="true"
                v-model="profile.email"
                forID="email"
                :placeholder="'sample@email.com'"
                inputClass="partner-input"
                formControlClass=""
                label="Business email"
                labelClass="medium-font"
                :invalid="error.email"
                :invalidMessage="error.email"
                @clear-validity="clearValidity"
                ></base-input>

                <base-input
                elementType="select"
                :class="{invalid: error.source}"
                :required="true"
                v-model="profile.source"
                forID="source"
                :inputClass="'small-font partner-input'"
                formControlClass=""
                label="Where did you hear about Financial Source"
                labelClass="medium-font"
                :invalid="error.source"
                :invalidMessage="error.source"
                @clear-validity="clearValidity"
                @update="error.source = ''"
                >
                    <option class="small-font" selected disabled hidden value="">Select from the options below</option>
                    <option class="small-font" value="facebook">Facebook</option>
                    <option class="small-font" value="google">Google</option>
                    <option class="small-font" value="other">Other</option>
                </base-input>

                <base-input
                elementType="input"
                :class="{invalid: error.password} "
                inputType="password"
                :required="true"
                v-model="profile.password"
                forID="password"
                :placeholder="'Password'"
                inputClass="partner-input"
                formControlClass=""
                label="Password"
                labelClass="medium-font"
                :invalid="error.password"
                :invalidMessage="error.password"
                @clear-validity="clearValidity"
                >
                </base-input>

                <div class="button-bar" style="justify-content: flex-end;">

                    <!-- <base-button
                    mode="o-tranparent-grey o-width-125"
                    :link="false"
                    @click.native="back"
                    >Back
                    </base-button> -->

                    <base-button
                    mode="o-blue o-width-125"
                    :link="false"
                    @click.native="next"
                    >Continue
                    </base-button>
                    
                </div>
            </form>
        </div>
    </div>
</template>
<script>
import _ from 'lodash';
import { createNamespacedHelpers } from 'vuex';

const general = createNamespacedHelpers("general");

const CONDITIONS = [
    {test: (model) => model.name?.length > 2 && model.name?.length < 31, message: 'Name must be 3 - 30 characters long', key: "name"},
    {test: (model) => model.email?.length > 5 && model.email?.length < 64 && model.email?.indexOf('@') > 0 && model.email?.indexOf('.') > 0, message: 'Please fill email field.', key: "email"},
    {test: (model) => model.source, message: 'Must select a source.', key: "source"},
    {test: (model) => model.password.length > 7 && model.password?.length < 33, message: 'Passwprd must be 8 - 32 characters long', key: "password"},
];

export default {
    created() {
        this.profile = {...this.profile, ...this.brokerRegistrationData};
    },
    computed: {
        ...general.mapGetters(["brokerRegistrationData"]),
    },
    data() {
        return {
            profile: {},
            error: {}
        }
    },
    methods: {
        clearValidity(name) {
            delete this.error[name];
        },
        validateData() {
        this.error = {};
            CONDITIONS.forEach((condition) => {
                if (!condition.test(this.profile)) {
                    this.error[condition.key] = condition.message;
                }
            });
            // console.log(this.error)
        },
        next() {

            this.validateData();
            if(!_.isEmpty(this.error))
                return;

            this.$emit("partner-new-step", this.profile);
        }
    }
}

</script>
