<template>
    <div class="e-client"
    :style="elementHeight"
    >
        <external-login
        v-if="loginOn"
        @register-login-successful="registerLoginSuccessful"
        @password-reset="openPasswordReset"
        @back-to-home="loginOn = false"
        ></external-login>
        <external-register
        v-if="requestOn"
        @back-to-home="requestOn = false"
        ></external-register>
        <external-passwordreset
        v-if="passwordResetOn"
        @back-to-home="passwordResetOn = false"
        ></external-passwordreset>
        <external-newpassword
        v-if="newPasswordOn"
        @back-to-home="newPasswordClose"
        @register-login-successful="registerLoginSuccessful"
        :token="token"
        :email="email"
        :initial="initial"
        ></external-newpassword>
        <div v-if="entityLocked">
            <entity-locked></entity-locked>
        </div>
        <div v-else>
        <div>
            <div 
            :style="heroIMG"
            class="dark-section e-desktop">

                <div class="e-client-container">

                    <external-client-nav @open-login="loginOn = true"></external-client-nav>
                    <div 
                    class="e-client-hero">
                        <div class="e-client-hero-prompt">
                            <h1>Understand why markets move and how to trade them</h1>
                            <p>Our team of analysts create courses, live streams, indicators and <span v-if="entityQnaToShow">Q&A</span><span v-else>video explainers</span> to help you become a confident trader.</p>
                            <base-button
                            mode="o-blue o-border-radius-0"
                            :link="false"
                            style="margin-top: 10px;"
                            @click.native="requestOn = true"
                            >Get Access
                            </base-button>
                        </div>
                        <div class="e-client-hero-video">
                            <iframe v-if="entityQnaToShow" src="https://player.vimeo.com/video/818838630?h=2a45355b6a&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen style="position:absolute;top:0;left:0;width:100%;height:100%;border-radius:40px;" title="EDITED_Broker Analyst Desk VSL_V1.mp4"></iframe>
                            <iframe v-else src="https://player.vimeo.com/video/834176950?h=a75cc8daa0&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen style="position:absolute;top:0;left:0;width:100%;height:100%;border-radius:40px;" title="EDITED_Broker Analyst Desk VSL_V1.mp4"></iframe>
                        </div>
                    </div>
                </div>
            </div>

            <div 
            class="dark-section e-mobile">
                <div class="e-client-container">

                    <external-client-nav @open-login="loginOn = true"></external-client-nav>
                    <div 
                    class="e-client-hero">
                        <!-- <img :src="heroSrc" alt="" srcset="" style="height: 100px;"> -->
                        <div class="e-client-hero-prompt" style="margin-top: 40px;">
                            <h1>Understand why markets move and how to trade them</h1>
                            <p>Our team of analysts create courses, live streams, indicators and <span v-if="entityQnaToShow">Q&A</span><span v-else>video explainers</span> to help you become a confident trader.</p>
                            <base-button
                            mode="o-blue o-border-radius-0"
                            :link="false"
                            style="margin-top: 10px;"
                            @click.native="requestOn = true"
                            >Get Access
                            </base-button>
                        </div>
                        <div class="e-client-hero-video">
                            <iframe v-if="entityQnaToShow" src="https://player.vimeo.com/video/818838630?h=2a45355b6a&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen style="position:absolute;top:0;left:0;width:100%;height:100%;border-radius:40px;" title="EDITED_Broker Analyst Desk VSL_V1.mp4"></iframe>
                            <iframe v-else src="https://player.vimeo.com/video/834176950?h=a75cc8daa0&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen style="position:absolute;top:0;left:0;width:100%;height:100%;border-radius:40px;" title="EDITED_Broker Analyst Desk VSL_V1.mp4"></iframe>
                        </div>
                    </div>
                </div>
            </div>
        </div>

            <div class="light-section">

                <div class="e-client-container">
                    <div class="e-client-testimonials">
                        <div class="e-client-section-header">
                            <div class="e-client-section-header-title"><span></span><h2>Testimonials</h2></div>
                            <div class="e-client-section-header-rating">Rated 4.7 <font-awesome-icon class="fs-yellow-star" :icon="['fas', 'star']"/> out of 5 by 5,410 users.
                            </div>
                        </div>
                        <div class="e-client-section-body">
                        <horizontal-scrollable
                        >
                            <div 
                            v-for="testimonial in testimonials"
                            :key="testimonial.id"
                            class="e-client-testimonial">
                                    <div
                                    class="e-client-testimonial-card"
                                    >

                                        <div class="e-client-testimonial-quote"><font-awesome-icon :icon="['fas', 'quote-right']"/></div>
                                        <div class="e-client-testimonial-text">{{testimonial.text}}</div>
                                        <div class="e-client-testimonial-ratings"><font-awesome-icon v-for="(star, i) in testimonial.stars" :key="i" class="fs-yellow-star" :icon="['fas', 'star']"/></div>
                                        <div class="e-client-testimonial-profile">
                                            <img :src="getFrontFaceAsset(testimonial.img)" alt="" />
                                            <div>{{ testimonial.name }}</div>
                                        </div>
                                    </div>
                            </div>
                            </horizontal-scrollable>
                        </div>
                    </div>
                </div>
            </div>

            <div class="medium-dark-section">

                <div class="e-client-container">
                    <div class="e-client-included">
                        <div class="e-client-section-header">
                            <div class="e-client-section-header-title"><span></span><h2>Analyst Desk Benefits</h2></div>
                            <div class="e-client-section-header-rating uppercase-fs">Understand why prices move and how to trade them.
                            </div>
                        </div>
                        <div class="e-client-section-body">
                            <div class="e-client-benefit-grid">
                            <div 
                            v-for="benefit in benefits"
                            :key="benefit.id"
                            class="e-client-benefit">
                                    <div
                                    class="e-client-benefit-card"
                                    >
                                        <div class="e-client-benefit-icon"><img :src="getFrontFaceAsset(benefit.img)" alt="" /></div>
                                        <div class="e-client-benefit-title">{{benefit.title}}</div>
                                        <div class="e-client-benefit-text">{{ benefit.text }}</div>
                                        <div class="e-client-benefit-text-bold">{{ benefit.textBold }}</div>
                                        
                                    </div>
                            </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="dark-section">

                <div class="e-client-container">
                    <div class="e-client-included">
                        <div class="e-client-section-header">
                            <div class="e-client-section-header-title"><span></span><h2>What's included</h2></div>
                            <div class="e-client-section-header-rating uppercase-fs">Real-time indicators and live, personalised training
                            </div>
                        </div>
                        <div class="e-client-section-body">
                            <div class="e-client-included-grid">
                            <div 
                            v-for="included in includedWidgets"
                            :key="included.id"
                            class="e-client-included-card"
                            :class="{'centered': included.centered && entityQnaToShow}"
                            >
                                    <div class="e-client-included-icon responsive-16-9-frame"><img :src="getFrontFaceAsset(included.img)" alt="" /></div>
                                    <div class="e-client-included-title">{{included.title}}</div>
                                    <div class="e-client-included-text">{{ included.text }}</div>
                                    <div class="e-client-included-text-bold">{{ included.textBold }}</div>
                                    <div class="e-client-included-button">
                                        <base-button
                                        mode="o-tranparent-grey o-blue-border o-white-text o-border-radius-0 o-auto-height"
                                        :link="true"
                                        :to="included.route"
                                        target="_blank"
                                        >Learn more
                                        </base-button>
                                    </div>
                                
                            </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div 
            :style="backgroundIMG"
            class="light-section start-now">
                <div class="e-client-container">
                    <div class="e-client-start">
                        <h2 class="uppercase-fs">Unlock our analyst desk<br/> today</h2>
                        <base-button
                        mode="o-blue o-border-radius-0"
                        :link="false"
                        style="margin-top: 30px;"
                        @click.native="requestOn = true"
                        >Get Access
                        </base-button>
                    </div>
                </div>
            </div>

            <div 
            class="light-section ad-footer">
                <div class="e-client-container">
                    <div class="e-client-footer">
                        <div v-if="aa" style="color: white; line-height: 14px; width: 200px; margin: 0 auto;">

                            <div style="font-size: 6px; font-weight: 300; letter-spacing: 1.5px; margin-left: 0%; text-align: left;">THE</div>
                            <div style="font-size: 16px;">AA Financial Research</div>
                            <div style="font-size: 6px; font-weight: 300; letter-spacing: 1.5px; margin: 0 auto; text-align: center;">ANALYST DESK</div>
                        </div>
                        <img v-else class="site-logo" :src="siteLogo" alt="Site logo" srcset="">
                        <p>Our products and commentary provides general advice that do not take into account your personal objectives, financial situation or needs. The content of this website must not be construed as personal advice.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { frontFacingAssets, isExternalClientMode } from '../../utility/helper-functions';
import ExternalLogin from './external/ExternalLogin.vue';
import ExternalRegister from './external/ExternalRegister.vue';
import ExternalNewpassword from './external/ExternalNewpassword.vue';
import ExternalPasswordreset from './external/ExternalPasswordreset.vue';
import HorizontalScrollable from './HorizontalScrollable.vue';
import ExternalClientNav from './external/ExternalClientNav.vue';
import EntityLocked from './external/EntityLocked.vue'
import { createNamespacedHelpers } from 'vuex';

const general = createNamespacedHelpers("general");

export default {
    props: ["token", "email", "redirect", "initial"],
    components: {
        HorizontalScrollable,
        EntityLocked,
        ExternalLogin,
        ExternalNewpassword,
        ExternalPasswordreset,
        ExternalRegister,
        ExternalClientNav
    },
    created() {
        if(!isExternalClientMode())
            this.$router.push("/login");

        
        if(this.email && this.token)
            this.newPasswordOn = true;
            // .email?.length > 0 && to.query?.nonce?.length > 0)
        if(this.redirect && this.redirect === 'login') {
            this.loginOn = true;
            this.$router.replace({ query: ""});
        }
        else if(this.redirect && this.redirect === 'reset') {
            this.passwordResetOn = true;
            this.$router.replace({ query: ""});
        }
    },
    mounted() {
        setTimeout(() => window.scrollTo(0,0), 500);
    },
    computed: {
        ...general.mapGetters(["entityQnaToShow", "entityLocked"]),
        elementHeight() {
            if(this.loginOn || this.passwordResetOn || this.newPasswordOn)
                return 'height: 100vh;';
            else if(this.requestOn)
                return 'height: 1200px;';

            return 'height: auto;';
        },
        videoThumbnail() {
            return frontFacingAssets() + 'Hero Video Thumbnail.png';
        },
        backgroundIMG() {
            return "background: url('" + frontFacingAssets() + "Footer CTA BG.png') no-repeat center center; ";
        },
        heroSrc() {
            return frontFacingAssets() + "LP Header_s.png";
        },
        heroIMG() {
            return "background: url('" + this.heroSrc + "'); background-repeat: no-repeat; background-position: center top; background-size: cover;";
        },
        siteLogo() {
            return frontFacingAssets() + window.bootUp.entity.name + '.png';
        },
        aa() {
            return window.bootUp.entity.name.indexOf('aafinancialr') > -1;
        },
        benefits() {
            return [
                {
                    id: 1,
                    img: "e-chartblue.svg",
                    title: "Understand why prices move",
                    text: "Understanding price moves helps you predict them more accurately.",
                    textBold: "Catch big price moves instead of being caught out by them."
                },
                {
                    id: 2,
                    img: "e-drowdownblue.svg",
                    title: "Navigate drowdowns",
                    text: "Trading drawdowns better will improve your results over time.",
                    textBold: "Always know which trades to hold and which trades to fold."
                },
                {
                    id: 3,
                    img: "e-brainblue.svg",
                    title: "Know which assets to trade",
                    text: "Remove confusion and focus on the same assets as everyone else.",
                    textBold: "Trade in-line with the market instead of fighting it."
                },
                {
                    id: 4,
                    img: "e-calendarblue.svg",
                    title: "Master economic data",
                    text: "The economic calendar is a weekly source of high-quality trades.",
                    textBold: "Scheduled events that you can trade around your work."
                },
                {
                    id: 5,
                    img: "e-chatblue.svg",
                    title: "Combine analysis types",
                    text: "Integrate the fundamentals with your favourite technical strategy.",
                    textBold: "Always get the complete picture before risking your money."
                },
                {
                    id: 6,
                    img: "e-magnifierblue.svg",
                    title: this.entityQnaToShow ? "Personalised feedback" : "Real-time education",
                    text: this.entityQnaToShow ? "Ask questions and get answers via chat, video, livestream and email." : "Learn about market-moving events as they're happening and relevant.",
                    textBold: this.entityQnaToShow ? "We're dedicated to helping you master the art of trading." : "Always understand the most important concepts.",
                    entityQnaToShow: true
                },
            ]
        },
        includedWidgets() {
            const includedWidgets = [
                {
                    id: 1,
                    img: "Real-time Video Trade Ideas.png",
                    title: "Real-time Video Trade Ideas",
                    text: "Learn to navigate prices as they move. " + (this.entityQnaToShow ? "Includes personalised Q&A." : "10-20 videos per day."),
                    textBold: "Watch our trade ideas unfold through the EU/US sessions.",
                    route: "video-ideas"
                },
                {
                    id: 2,
                    img: "News-Trading livestreams.png",
                    title: "News-Trading livestreams",
                    text: "Follow as we share specific trading scenarios through each release.",
                    textBold: this.entityQnaToShow ? "Type your questions and receive our replies live." : "Understand news events instead of fearing them.",
                    route: "news-trading-livestreams"
                },
                {
                    id: 3,
                    img: "Macro-Fundamental Masterclass Video Course.png",
                    title: "Trading Masterclass Course",
                    text: "Progress from beginner to competent trader. Watch on-demand.",
                    textBold: "Learn how markets operate and how to predict price moves.",
                    route: "macro-masterclass"
                },
                {
                    id: 4,
                    img: "News Trading Strategy Video Course.png",
                    title: "News Trading Course",
                    text: "Learn how to trade any high-impact news release with conviction.",
                    textBold: "Trade scheduled events around your busy schedule every week.",
                    route: "risk-event-trading-course"
                },
                {
                    id: 5,
                    img: "Analyst chat access.png",
                    title: "Professional Analyst chat",
                    text: "Bounce your trade ideas off our expert team and receive guidance.",
                    textBold: "All trading questions are welcome in a totally private setting.",
                    route: "analyst-chat",
                    entityQnaToShow: true
                },
                {
                    id: 6,
                    img: "Trading Q&A Video Library.png",
                    title: "Trading Q&A Video Library",
                    text: this.entityQnaToShow ? "Submit your questions and watch every answer we ever created." : "Quickly get context when market catalysts change.",
                    textBold: "Learn specific concepts on-demand at your own pace.",
                    route: "trading-library"
                },
                {
                    id: 7,
                    img: "Real-time Indicator Suite.png",
                    centered: true,
                    title: "Real-time Indicator Suite",
                    text: "Stay tuned in to the baseline expectations of the markets all day.",
                    textBold: "React instantly when expectation change for maximum results.",
                    route: "real-time-indicator-suite" 
                }
            ]

            return includedWidgets.filter(el => !Object.prototype.hasOwnProperty.call(el, "entityQnaToShow") || el.entityQnaToShow === this.entityQnaToShow);
        }
    },
    data() {
        return {
            loginOn: false,
            requestOn: false,
            newPasswordOn: false,
            passwordResetOn: false,
            testimonials: [
                {
                    id: 1,
                    text: "Amazing",
                    stars: 5,
                    name: "Jake Deakin",
                    img: "jake_deakin.jpg"
                },
                {
                    id: 2,
                    text: "I honestly can't trade without this. Clarity and objectivity.",
                    stars: 5,
                    name: "Alex Salazar",
                    img: "alex_salazar.jpg"
                },
                {
                    id: 3,
                    text: "Wow! Love this! Trading fundamentals just became so much easier.",
                    stars: 5,
                    name: "Kacper Gladysz",
                    img: "kacper_gladysz.jpg"
                },
                {
                    id: 4,
                    text: "Thank you for the detailed work you guys put into every session, it's so helpful.",
                    stars: 5,
                    name: "Kunter Armstrong",
                    img: "kunter_armstrong.jpg"
                },
                {
                    id: 5,
                    text: "Excellent Indicators that compliment my strategies.",
                    stars: 5,
                    name: "Patrick Gordon",
                    img: "patrick_gordon.jpg"
                },
                {
                    id: 6,
                    text: "The economic calendar high/low forecasts are a gamechanger!",
                    stars: 5,
                    name: "Ian Megarry",
                    img: "ian_megarry.jpg"
                },
                {
                    id: 7,
                    text: "Wow. Being able to view timestamped questions saves so much time.",
                    stars: 5,
                    name: "Jason Fuller",
                    img: "jason_fuller.jpg"
                },
                {
                    id: 8,
                    text: "This is absolutely perfect!",
                    stars: 5,
                    name: "Carmelo Keimig",
                    img: "carmelo_keimig.jpg"
                },
                {
                    id: 9,
                    text: "Beautiful tools, very useful",
                    stars: 5,
                    name: "Michael Panerio",
                    img: "michael_panerio.jpg"
                },
                {
                    id: 10,
                    text: "Indicators are 100% accurate",
                    stars: 5,
                    name: "Charles Bryan",
                    img: "charles_bryan.jpg"
                },
                {
                    id: 11,
                    text: "Essential to my trading",
                    stars: 5,
                    name: "Joshua Moses",
                    img: "joshua_moses.jpg"
                },
                {
                    id: 12,
                    text: "Unparalleled analysis",
                    stars: 5,
                    name: "Horacio Barba",
                    img: "horacio_barba.jpg"
                },
                {
                    id: 13,
                    text: "This is just perfect for me",
                    stars: 5,
                    name: "Mostafa",
                    img: "mostafa.jpg"
                },
                {
                    id: 14,
                    text: "The best",
                    stars: 5,
                    name: "Mark Lyons",
                    img: "mark_lyons.jpg"
                },
                {
                    id: 15,
                    text: "The training is very helpful for understanding how to predict prices",
                    stars: 5,
                    name: "Simon Centocinque",
                    img: "simon_centocinque.jpg"
                },
                {
                    id: 16,
                    text: "Awesome to help you keep your head around what's happening in the markets",
                    stars: 5,
                    name: "Alfonzo Jae",
                    img: "alfonzo_jae.jpg"
                },
                {
                    id: 17,
                    text: "Thank you so much for this gem of a calendar",
                    stars: 5,
                    name: "Mohammed Assudais",
                    img: "mohammed_assudais.jpg"
                },
                {
                    id: 18,
                    text: "This economic calendar is badass!",
                    stars: 5,
                    name: "Laverne Parette",
                    img: "laverne_parette.jpg"
                },
                {
                    id: 19,
                    text: "This is awesome and easy to understand",
                    stars: 5,
                    name: "Kevinjun Igwe",
                    img: "kevinjun_igwe.jpg"
                },
                

            ],
        }
    },
    methods: {
        getFrontFaceAsset(img) {
            return frontFacingAssets() + img;
        },
        registerLoginSuccessful(val) {
            // console.log(val);
            this.$emit("register-login-successful", val);
        },
        newPasswordClose() {
            this.newPasswordOn = false;
            this.$router.replace('/home');
        },
        openPasswordReset() {
            this.loginOn = false;
            this.passwordResetOn = true;
        }
    }
}
</script>

<style lang="scss">
    .e-client {
        color: $input-color;
        position: relative;

        & .login-overlay {
            position: absolute;
            width: 100vw;
            height: 100vh;
            z-index: 2;
            letter-spacing: 0.1px;

            &.register {
                background-color: rgba($client-background-1, 0.8);
                backdrop-filter: blur(6px);
                height: 100%;
                position: absolute;

                & .e-login-form {
                    background-color: $client-background-1;
                    border: 1px solid $client-border-2;
                    border-radius: 10px;
                    padding: 15px;
                    margin-top: 100px;
                    transform: none;
                }

            }


            & .rule-setup .rule-setup-body .rule-setup-body-user-selector, 
            & .rule-setup .rule-setup-body .rule-setup-body-item-selector,
            & .rule-setup .rule-setup-body .rule-setup-body-item-selector .rule-setup-body-item-selector-action {
                background-color: $client-background-3;
                border: none;
                border-radius: 0;
            }

            & .rule-setup .rule-setup-body .rule-setup-body-item-selector h3 {
                font-weight: 400;
                color: $input-color-filter;
                margin-bottom: 5px;
            }

            & .rule-setup .rule-setup-body .rule-setup-body-item-selector .rule-setup-body-item-selector-action .selected-widgets, 
            & .rule-setup .rule-setup-body .rule-setup-body-item-selector .rule-setup-body-item-selector-action .suggested-widgets {
                background-color: $onboard-survey-background;
            }

            & .rule-setup .rule-setup-body .rule-setup-body-item-selector .suggested-widgets .widget-tags {
                background-color: rgba($input-color-filter, 0.1);
            }

            .e-login-form {
                margin: 0 auto;
                max-width: 85%;

                @include respond(md) {
                    max-width: 500px;

                }

            
                margin-top: 50vh;
                transform: translateY(-50%);
          
                & .login-header {
                    font-size: 32px;
                    text-align: center;
                }


            }
            







        }

        & .e-mobile {

            @include respond(lg) {
                display: none;
            }
        }

        .e-desktop {

            display: none;

            @include respond(lg) {
                display: block;
            }
        }

        & > .dark-section {
            background-color: $client-background-1;
        }
        & > .medium-dark-section {
            background-color: $client-background-3;
        }
        & > .light-section {
            background-color: $onboard-survey-background;
        }

        & h1 {

            font-size: 31px;

            @include respond(lg) {
                font-size: 42px;
            }

            font-weight: 400;
            margin: 15px 0;
        }
        & p {
            margin: 15px 0;
        }

        & .e-client-container {
            padding: 0 15px;
            margin: 0 auto;

            @include respond(lg) {
                padding: 0 15px;
                max-width: 992px;
            }

            // @include respond(xl) {
            //     padding: 0;
            //     max-width: 1300px;
            // }


        }

        & .e-client-nav {
            padding-top: 20px;
            display: flex;
            justify-content: space-between;
            @include respond(lg) {
                padding: 20px;
            }

            & .nav-left-right {
                width: 120px;
                display: flex;
                align-items: center;

                @include respond(lg) {
               
                width: 200px;
            }
            }
        }

        & .nav-empty {
            display: none;

            @include respond(sm) {
                display: block;
                width: 100px;
            }
        }

        &.e-subpage {
            & .e-client-hero {
                text-align: center;
                padding-bottom: 140px;

                @include respond(sm) {
                    padding-bottom: 170px;
                    width: 500px;
                    margin: 0 auto;
                }

                @include respond(lg) {
                    padding-bottom: 265px;
                    width: 775px;
                    margin: 0 auto;
                }
            }

            & .e-client-hero .e-client-hero-prompt {

                font-family: Inter, sans-serif;
                margin-top: 40px;

                & p {
                    @include respond(lg) {
                    width: 100%;
                }
                }

                @include respond(lg) {
                    width: 100%;
                    margin-top: 120px;
                }
            }

            & .e-client-hero .e-client-hero-video {
                bottom: -95px;
                width: 252px;
                height: 160px;

                &.padded-img img {
                   
                    padding-top: 25px;
                
                    @include respond(md) {
                        padding-top: 37px;
                    }
                    @include respond(lg) {
                        padding-top: 70px;
                    }
                }
                
                & img {
                    border-radius: 40px;
                }

                @include respond(md) {
                    bottom: -115px;
                    height: 222px;
                    width: 350px;
                }

                @include respond(lg) {
                    bottom: -195px;
                    height: 400px;
                    width: 630px;
                }

            }

            & .e-client-testimonials {
                padding: 90px 0;
                @include respond(lg) {
                    padding: 140px 0;
                }
            }

            & .e-client-benefit {
                
                @include respond(md) {
                    height: auto;
                }

                &.md-centered {
                @include respond(md) {
                    grid-column: 2 / 4;
                }
                @include respond(lg) {
                    grid-column: span 2;
                }
            }

               
            }

            & .e-client-whoisit {
                position: relative;
                padding-bottom: 200px;
                padding-top: 45px;

                @include respond(lg) {
                    padding-bottom: 300px;
                }
            }

            & .e-client-included-video {
                position: absolute;
                bottom: -80px;
                left: 50%;
                transform: translateX(-50%);
                height: 160px;
                width: 280px;
                background-color: $client-background-2;
                border-radius: 40px;

                @include respond(md) {
                    bottom: -110px;
                    height: 222px;
                    width: 380px;
                }

                @include respond(lg) {
                    bottom: -190px;
                    height: 400px;
                    width: 710px;
                }
            }
            
        }

        & .e-client-hero {
            text-align: center;
            padding-bottom: 160px;
            @include respond(lg) {
                text-align: left;
                padding-bottom: 280px;
            }

            
            position: relative;

            & .e-client-hero-prompt {

                font-family: Inter, sans-serif;

                & p {
                    font-size: 20px;

                    @include respond(lg) {
                    width: 480px
                }
                }

                @include respond(lg) {
                    width: 550px;
                }
            }

            & .e-client-hero-video {

                position: absolute;
                bottom: -80px;
                left: 50%;
                transform: translateX(-50%);
                height: 160px;
                width: 280px;
                background-color: $client-background-2;
                border-radius: 40px;

                @include respond(md) {
                    bottom: -110px;
                    height: 222px;
                    width: 380px;
                }

                @include respond(lg) {
                    bottom: -190px;
                    height: 400px;
                    width: 710px;
                }
            }

        }

        & .e-client-testimonials {
            padding: 130px 0 75px;
            @include respond(lg) {
                padding: 240px 0 70px;
            }
        }

        & .e-client-section-body {
            margin-top: 60px;
        }
        & .e-client-section-body, 
        & .e-client-section-header {
            letter-spacing: 0.1px;
        }

        & .e-client-section-header-title {
            font-size: 22px;

            @include respond(lg) {
                font-size: 30px;
            }
            font-weight: 600;
            display: flex;
            align-items: center;

            & h2 {
                margin: 20px 0;
            }

            & > span {
                border: 1px solid $input-color;
                display: inline-block;
                width: 66px;
                margin-right: 25px;
            }

        }

        & .e-client-section-header-rating {
                letter-spacing: 0.1px;
                
                & p {
                    margin: 5px 0;
                }
            }

        & .e-client-testimonial {
            text-align: center;
            width: 300px;
            height: 330px;
            margin-right: 20px;

            &:last-child {
                margin: 0;
            }
            
        }

        & .e-client-testimonial-card {
            height: 260px;
            background-color: $client-background-1;
            padding: 10px;
            position: relative;

            & > div {
                margin: 15px 0;
            }
        }

        & .e-client-testimonial-quote {
            color: $partner-background-4;
            font-size: 25px;
        }
        & .e-client-testimonial-text {
            // height: 40px;
        }
        & .e-client-testimonial-ratings {

        }
        & .e-client-testimonial-profile {
            position: absolute;
            bottom: -82px;
            width: 300px;
            left: 50%;
            transform: translateX(-50%);

            & img {
                width: 60px;
                height: 60px;
                border: 2px solid $input-color;
                border-radius: 60px;

            }

            & > div {
                margin-top: 10px;
                font-weight: 600;
            }
        }

        .e-client-benefits,
        .e-client-included {
            padding: 40px 0 90px;
        }

        .e-client-start {

            padding: 80px 0;

            &.large-top-padding {
                padding-top: 180px;

                @include respond(lg) {
                    padding-top: 280px;
                }
            }
            
        }

        .ad-footer {
            background-color: $separator-line-color;

        }
        .e-client-footer {
            padding: 60px 0;
            text-align: center;
            // max-width: 650px;
            margin: 0 auto;

            & p {
                font-size: 12px;
            }
        }

        & .e-client-benefit-grid {
            display: grid;
                gap: 15px;

            @include respond(md) {
                
                grid-template-columns: 1fr 1fr 1fr 1fr;
            }

            @include respond(lg) {
                display: grid;
                grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
            }
        }

        & .e-client-benefit {
            background-color: $client-border-1;
            border-radius: 10px;
            grid-column: span 2;

            @include respond(md) {
                height: 330px;
            }
            @include respond(lg) {
                height: 350px;
            }

        }

        & .e-client-benefit-card {
            padding: 5px 20px;
            background-color: $onboard-survey-background;
            
            height: calc(100% - 5px);

            
            

            & > div {
                margin: 30px 0;
            }
        }
        & .e-client-benefit-title {
            font-size: 18px;
            font-weight: 600;

            // @include respond(md) {
            //     height: 70px;
            // }
        }
        & .e-client-benefit-text,
        & .e-client-included-text {
            font-size: 14px;
            font-weight: 200;
        }
        & .e-client-benefit-text-bold,
        & .e-client-included-text-bold {
            font-size: 14px;
            font-weight: 500;
        }
        & .e-client-included-title {
            font-size: 18px;
            // @include respond(lg) {
            //         font-size: 18px;
            //     }
            font-weight: 600;
            
        }

        & .e-client-included-grid {
            display: grid;
            gap: 15px;

            grid-template-columns: 1fr;

            @include respond(md) {
                
                grid-template-columns: 1fr 1fr;
            }
            @include respond(lg) {
                
                grid-template-columns: 1fr 1fr 1fr;
            }

        }

        & .e-client-included-card {
            padding: 20px;
            border-radius: 10px;
            border: 1px solid $client-border-2;
            display: flex;
            flex-direction: column;

            // &.full-width {
            //     text-align: center;

            //     @include respond(md) {
            //         grid-column: span 4;
            //     }
            // }

            // &.lg-shifted {
                
            //     @include respond(lg) {
            //         grid-column: 2 / 4;
            //     }
            // }

            &.centered {
                @include respond(md) {
                    grid-column: 1 / 3;
                }
                @include respond(lg) {
                    grid-column: 3 / -3;
                }
            }
            &.md-centered {
                @include respond(md) {
                    grid-column: 2 / 3;
                }
                @include respond(lg) {
                    grid-column: 2 / 3;
                }
            }

            & > div {
                margin: 15px 0;

                &:first-child {
                    margin-top:0;
                }
                &:nth-child(4) {
                    margin-bottom: 35px;
                }
                &:last-child {
                    margin-top:auto;
                }
            }
        }

        & .start-now {
            background-size: contain;
            background-repeat: no-repeat;
            background-position: bottom;
        }

        & .e-client-start {
            text-align: center;

            & h2 {

                font-size: 20px;

                @include respond(lg) {
                    font-size: 26px;
                }
                margin: 0;
                
                font-weight: 400;
            }
        }


        & .error {
            height: 12px;
       
            color: $color-12;
            font-size: 10px;
        }

        & #MazPhoneNumberInput {
            margin-top: 40px;

            .iti-flag {
                background-image: url('https://terminal.financialsource.co/img/flags.9c96e0ed.9c96e0ed.png');
            }
        }
        & #MazPhoneNumberInput .country-selector.sm .country-selector__country-flag{
            top: 20px;
        }

        & #MazPhoneNumberInput .country-selector__input,
        & #MazPhoneNumberInput .input-tel__input,
        & #MazPhoneNumberInput .country-selector__input:hover,
        & #MazPhoneNumberInput .input-tel__input:hover,
        & #MazPhoneNumberInput .country-selector__input:focus,
        & #MazPhoneNumberInput .input-tel__input:focus {
            height: 48px!important;
            border-color: $partner-border-1!important;
            padding-top: 0!important;

            &:disabled {
                color: $partner-dimmed-text-3!important;
            }
        }

        & #MazPhoneNumberInput .country-selector__label,
        & #MazPhoneNumberInput .input-tel__label {
            font-weight: 300!important;
            font-family: Poppins;
            color: $input-color-filter!important;
            background-color: $client-background-1;
            font-size: 16px;
            white-space: nowrap;
            transform: translate(-12px, -30px);
        }
            & #MazPhoneNumberInput .input-tel__label {
            transform: translate(-4px, -30px);
            opacity: 1;
        }

        & .rule-setup {
            & #MazPhoneNumberInput .country-selector__label,
            & #MazPhoneNumberInput .input-tel__label {
                font-weight: 400!important;
                font-family: Poppins;
                color: $input-color!important;
                background-color: transparent!important;
                font-size: 12px;
                white-space: nowrap;
                transform: translate(-12px, -26px);
            }
                & #MazPhoneNumberInput .input-tel__label {
                transform: translate(-4px, -26px);
                opacity: 1;
            }
        }

        & #MazPhoneNumberInput.invalid-input-phone, 
        & #MazPhoneNumberInput.invalid-input-phone {
            border-color: #ff5656 !important;
            height: 50px;
            border: 1px solid $color-12;
            border-radius: 10px;

            & label {
                color: #ff5656 !important;
            }
        }
        & #MazPhoneNumberInput input, 
        & #MazPhoneNumberInput select {
            background-color: $partner-background-2!important;
            &:-webkit-autofill,
            &:-webkit-autofill:hover, 
            &:-webkit-autofill:focus, 
            &:-webkit-autofill:active{
                -webkit-box-shadow: 0 0 0 30px $partner-background-2 inset !important;
                -webkit-text-fill-color: $input-color-filter!important;
            }
        }

        & .unplugged-service-message {
            margin: 20px auto 0 auto;
            max-width: 400px;
            text-align: center;

            & .oops-div {
                font-size: 64px;
                font-weight: 900;

                @include respond(lg) {
                    font-size: 120px;
                }

            }

            & .inactive-message {
                margin-top: 10px;
                font-size: 18px;
            }
        }
    }


    .fs-yellow-star {
        color: $client-yellow;
    }

    .analyst-desk .dot-checkbox {
        & input[type="radio"], 
        & input[type="checkbox"] {
                display: none;
            }

            & input[type="radio"] + span:before,
            & input[type="checkbox"] + span:before {
                width: 12px;
                height: 12px;
                border: 1.5px solid #f0f0f0;
                content: "";
                display: inline-block;
                margin: 5px 10px 0 0;
                padding: 0;
                vertical-align: top;
                border-radius: 50%;
            }

            & input[type="radio"]:checked + span:before,
            & input[type="checkbox"]:checked + span:before {
                background: $checkbox-active;
                border: 1.5px solid $checkbox-active;
                box-shadow: inset 0px 0px 0px 1px $onboard-survey-background;
                color: #333;
                content: "";
                text-align: center;
                border-radius: 50%;
            }

            & input[type="radio"]:focus + span::before,
            & input[type="checkbox"]:focus + span::before {
                outline: 0;
            }

        &.label-white {
            label {
                color: $input-color;
            }
        }
    }
    .admin-login-prompt {
        text-align: center;
        font-size: 10px;
        color: $partner-dimmed-text-2;

        & a,
        & a:hover,
        & a:active,
        & a:visited {
            color: $partner-dimmed-text-2;
        }
    }
    .site-logo {
            display: block;
            margin: 0 auto;
            width: auto;
            height: auto;
            max-width:150px;
                    max-height:50px;
            @include respond(lg) {
                    
                    max-width:180px;
                    max-height:60px;
                    
                }
            
        }

    .platform-background .e-client .login-overlay.register {
        overflow-y: auto;
    }
</style>