<template>
    <div>   
        <div>
            <div class="form-header">
                <h2>Please wait while we build your portal</h2>
            </div>
            <div style="text-align: center;">
                <img style="width: 50%; border-radius: 40px;" :src="animatedBuilding" alt="Animated Platform Building GIF" srcset="">
                <p style="margin-top: 35px;" class="partner-dimmed-text">You will receive an email confirmation when your dedicated terminal has been set up.</p>
                <p class="partner-dimmed-text">You may close with browser tab.</p>
            </div>

            <!-- <div class="button-bar"> -->

                <!-- <base-button
                mode="o-tranparent-grey o-width-125"
                :link="false"
                @click.native="back"
                >Back
                </base-button> -->

                <!-- <base-button
                mode="o-blue o-width-125"
                :link="false"
                @click.native="next"
                >Continue
                </base-button> -->
                
            <!-- </div> -->
        </div>
    </div>
</template>

<script>
import { frontFacingAssets } from '../../../utility/helper-functions'
export default {
    computed: {
        animatedBuilding() {
            return frontFacingAssets() + '23745-building.gif';
        }
    }
}
</script>