<template>
    <div>
        <router-view
        class="router-view"
        @register-login-successful="registerLoginSuccessful"
        ></router-view>
    </div>
</template>

<script>
export default {
    methods: {
        registerLoginSuccessful(val) {
            // console.log(val);
            this.$emit("register-login-successful", val);
        }
    }
}
</script>

<style lang="scss">
    .partner-funnel {

        color: $input-color;
        margin: 55px 5% 0 5%;
        min-height: calc(100vh - 55px);

        &-header {
            @include respond(lg) {
                width: 600px;
                margin-left: auto;
                margin-right: auto;
            }
        }

        &-main {
            @include respond(lg) {
                width: 500px;
                margin-left: auto;
                margin-right: auto;
            }
        }

        
        .form-header {
            text-align: center;
            letter-spacing: 0.1px;
            margin-bottom: 40px;
            h2 {
                font-weight: 500;
                font-size: 28px;
                margin: 10px 0;
            }
            p {
                font-size: 14px;
                margin: 5px 0;
            }
        }

        .button-bar {
            display: flex;
            justify-content: space-between;
            margin-top: 35px;
        }

        & .partner-progress {

            margin-bottom: 30px;

            &-text {
                display: flex;
                justify-content: center;
                align-items: center;
                font-size: 14px;
                color: $input-color-filter;

                & > span {
                    margin: 0 4px;

                    &.circle {
                        height: 5px;
                        width: 5px;
                        border-radius: 5px;
                        background-color: $input-color-filter;
                    }
                }
            }

            &-graph {
                margin-top: 2px;
                height: 14px;
                border: 2px solid $partner-border-1;
                background-color: $partner-background-2;
                border-radius: 12px;
                padding: 2px;

                &-line {
                    display: inline-block;
                    height: 3px;
                    margin-bottom: 13px;
                    background-color: $onboard-green;
                }
            }
        }

        .partner-redirect {
            font-size: 14px;
            text-align: center;
            margin-top: 20px;

            & a {
                
                color: $checkbox-active;
            }
        }

        & .error {
            height: 12px;
       
            color: $color-12;
            font-size: 12px;
        }

        & .partner-dimmed-text {
            color: $partner-dimmed-text-2
        }

    }
</style>