export default {

    lastEcoCalendarUpdate(state, payload) {
        state.lastEcoCalendarUpdate = payload;
    },

    lastEcoCalendarDelete(state, payload) {
        state.lastEcoCalendarDelete = payload;
    },

    setFetchDay(state, payload) {
        state.fetchDay = payload;
    },
    updateMissingEvents(state, payload) {
        if(!state.missingEvents.includes(payload))
            state.missingEvents.push(payload);
    },
    removeMissingEvent(state, payload) {
        const missingEvents = [...state.missingEvents];
        const index = missingEvents.findIndex(el => el === payload);
        if(index > -1) {
            missingEvents.splice(index,1);
            state.missingEvents = missingEvents;
        }


    },
    clearMissingEvents(state) {
        state.missingEvents = [];
    }
}