<template>
    <div>
        <div class="top-ribbon"
        :class="classes">
            <div v-if="ribbonType === 'updateCard'">
                <span v-if="paymentBoundToFail">Please add or update your payment card to keep your products active. <a href="javascript:void(0);" @click="updateCard">Update card</a></span>
                <span v-else>Your card has been updated. We will attempt to charge it on the {{ chargeDate }} <a href="javascript:void(0);" @click="updateCard">Update card</a></span>
                
            </div>
            <div v-else-if="ribbonType === 'planUpgrade'">
                <span v-if="!activeDowngrade">You are on {{ product }}. <!--<a href="javascript:void(0);" @click="changePlan">Change here</a>-->
                </span>
                <span v-else>
                    <span v-if="(activePlan.is_subscription || isTrial) && upcomingPlans.length">You are on {{ product }}. You'll downgrade to {{ upcomingName }} on {{ downgradeDate }}. 
                        <a v-if="!isTrial" href="javascript:void(0);" @click="cancelDowngrade">Cancel this downgrade</a>
                        <a v-else href="javascript:void(0);" @click="keepUpgrade">Keep {{ upgradeName }}</a>
                    </span> 
                    <span v-else>You are on {{ product }}. Your access ends on {{ downgradeDate }}. 
                    </span>
                </span>
            </div>
            <div v-else-if="ribbonType === 'isUserBm'">
                <span>This is what clients will see when they log in to the analyst-desk.</span>
            </div>
            <div v-else-if="ribbonType === 'externalAccess'">
                <span>{{ externalAccessMessage }} </span> <span v-if="!unlockedWidgets.length"><a href="javascript:void(0);" @click="requestAccess">Request access here</a></span>
            </div>
            
        </div>
        
    </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';
import { eventBus } from '../../main';
import { calculateDay, toSystemDate, usersTime } from '../../utility/datetime';

import SubscriptionMixin from '../../mixins/SubscriptionMixin';
import dayjs from 'dayjs';
import { isExternalClientMode } from '../../utility/helper-functions';

const payments = createNamespacedHelpers("payments");
const general = createNamespacedHelpers("general");
const rules = createNamespacedHelpers("rules");
//73617
export default {

    props: ["ribbonType"],
    mixins: [SubscriptionMixin],
    // created() {
        
    // },
    computed: {
        ...payments.mapGetters(['activePlan', 'upcomingPlans']),
        ...general.mapGetters(['paymentBoundToFail', 'unlockedWidgets', 'user']),
        ...rules.mapGetters(['getLockDateForUser']),

        classes() {
            if(this.ribbonType === '')
                return 'd-none';
            else if(this.ribbonType === 'updateCard' || this.ribbonType === 'isUserBm')
                return 'red';
            else if(this.ribbonType === 'planUpgrade' || this.ribbonType === 'externalAccess')
                return 'yellow';

            return '';
        },
        isTrial() {
            return this.activePlan.product.trial_of_product_id;
        },
        isChildCommitment() {   
            return this.activePlan.product.parent_id;
        },
        product() {
            if(!this.activePlan.product)
                return '';
                
            let name = this.activePlan.product.name;

            if(this.isChildCommitment || this.isTrial)
                name = Object.values(this.availableProducts).find(el => el.id === this.activePlan.product.parent_id).name;

            const period = this.activePlan.product.subscription_length_type;
            const length = this.activePlan.product.subscription_length;
            const details = this.isTrial ?  `a ${length}-${period} ${name} trial` : name;

            return details;

        },
        activeDowngrade() {
            return this.activePlan.ended_at;
        },
        upgradeName() {
            if(this.isTrial)
                return this.activePlan.product.mainTrialProduct.name;

            return '';
        },
        upcomingName() {
            let name = '';
            if(this.upcomingPlans.length)
                name = this.upcomingPlans[0].product.name;

            return name;
        },
        downgradeDate() {
            return this.activePlan.ended_at ? toSystemDate(this.activePlan.ended_at) : '';
        },
        chargeDate() {
            const now = usersTime(new Date(), true, true, false, { dateFormat : "d M Y", timeFormat: "24hr" }, false, "UTC");
            const today = usersTime(new Date(), true, false, false, { dateFormat : "d M Y" }, false, "UTC");
            const chargeTime = today + " 09:30:00";

            if(dayjs(now).isAfter(chargeTime))
                return toSystemDate(calculateDay(1, "days"));
            else   
                return toSystemDate(today);
 
        },
        externalAccessMessage() {
            if(this.unlockedWidgets.length)
                return "Analyst-desk unlocked." + (this.lockDate ? " Your access will expire on the " + this.lockDate + "." : "");
            else
                return "Analyst-desk locked. ";
        },
        lockDate() {
            if(isExternalClientMode() && this.getLockDateForUser)
                return  usersTime(this.getLockDateForUser, true, false, false, {
                dateFormat: "jS M Y"
            }, false, this.user.timezone);

            
            return "";
        }
    },
    methods: {
        changePlan() {
           
            const instanceID = this.$root.instanceID;
            const modal = {
                instanceID,
                componentInModal: "terminal-plan-selector",
                classes: "terminal-modal simplified auto-height",
                mode: "auto",
                panel: {
                    isUpgrading: true,
                    isDowngrading: parseFloat(this.activePlan.price) > 0
                }
                
            };

            eventBus.$emit('open-modal', modal);

        },
        updateCard() {
            const instanceID = this.$root.instanceID;
            const modal = {
                instanceID,
                componentInModal: "card-details-modal",
                classes: "terminal-modal simplified auto-height",
                mode: "",
                panel: {
                    view: "plan-mini-overview"
                }
            };

            eventBus.$emit('open-modal', modal);
        },
        cancelDowngrade() {
            // console.log("upcoming plan", this.activePlan, this.upcomingPlans);
            this.reactivatePlan(this.activePlan);
        },
        keepUpgrade() {
            const instanceID = this.$root.instanceID;
            const modal = {
                instanceID,
                componentInModal: "terminal-plan-selector",
                classes: "terminal-modal simplified auto-height",
                mode: "auto",
                panel: {
                    isUpgrading: true
                }
            };

            eventBus.$emit('open-modal', modal);
        },
        requestAccess() {
            eventBus.$emit("request-on");
        }
    }
    

}
</script>

<style lang="scss">
    .red {
        background-color: $core-red;
        color: $input-color;

        // & a {
        //     colo
        // }
    }
    .yellow {
        background-color: $core-yellow;
        color: $onboard-dialog-background
    }
    .top-ribbon {

        display: none;

        @include respond(lg) {
            display: block;
        }
        
        width: 100%; 
        box-sizing: border-box;
        padding: 12px; 
        text-align: center; 
        font-size: 12px;

        & a {
            text-decoration: underline;
            color: inherit;
        }
    }
</style>
