import Swal from 'sweetalert2';
import { createNamespacedHelpers } from 'vuex';
import { eventBus } from '../main';
import { talkToServer } from '../utility/helper-functions';

const livestreamsNS = createNamespacedHelpers("webinars")

export default {
    computed: {
        ...livestreamsNS.mapGetters(['activeReminders']),
    },
    data() {
        return {
            isReminderActive: false,
            showReminder: false
        }
    },
    methods: {
        ...livestreamsNS.mapActions(['removeActiveReminder']),

        setReminder(streamID) {
            eventBus.$emit('open-modal', {
                instanceID : this.$root.instanceID,
                componentInModal: "stream-reminder",
                classes: "terminal-modal simplified auto-height",
                panel: {
                    streamID: streamID 
                }
            });
        },
        cancelReminder(streamID) {
            Swal.fire({
                title: 'Are you sure?',
                type: 'warning',
                text: 'You won\'t be notified about this webinar.',
                showCancelButton: true,
                cancelButtonColor: '#1d9deb',
                cancelButtonText: 'Keep Reminder',
                confirmButtonColor: '#ff5656',
                confirmButtonText: 'Cancel Reminder'
            }).then((result) => {
                if (result.value) {
                   talkToServer(`/widgets/live-streams/${streamID}/reminder/unsubscribe`, "GET", null, null, true)
                    .then(() => {
                        this.removeActiveReminder(streamID);
                        // console.log("unsubscribe",response);
                        eventBus.$emit('close-modal');
                    });
                }
            });
        },
    },
    watch: {
        activeReminders() {
            this.manageReminderStatus();
        }
    }
}