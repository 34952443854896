export default {

    lastIntRateUpdate(state) {
        return JSON.parse(JSON.stringify(state.lastIntRateUpdate));
    },

    lastIntRateDelete(state) {
        return state.lastIntRateDelete;
    },
    humanizedUpdatedAt(state) {
        return state.humanizedUpdatedAt;
    },
}