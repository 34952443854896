<template>
    <div>
        <div v-if="!isExternalClientMode && !isAutoPilotMode" id="profileTab">
            <div>
                <profile></profile>
            </div>
        </div>
        <br/>
        <div id="terminalTab">
            <div>
                <terminal></terminal>
            </div>
        </div>
        <br/>
        <div v-if="!isExternalClientMode && !isAutoPilotMode" id="subscriptionTab">
            <div>
                <subscription-details></subscription-details>
            </div>
        </div>
        <br/>
        <div  v-if="!isExternalClientMode && !isAutoPilotMode" id="billingTab">
            <div>
                <billing-details></billing-details>
            </div>
        </div>
        <br/>
        <div v-if="!isExternalClientMode && !isAutoPilotMode" id="invoicesTab">
            <div>
                <invoice-history></invoice-history>
            </div>
        </div>
    </div>
</template>

<script>
import Profile from "./Profile";
import Terminal from "./Terminal";
import SubscriptionDetails from "./SubscriptionDetails";
import BillingDetails from "./BillingDetails";
import InvoiceHistory from "./InvoiceHistory";
import { isAutoPilotMode, isExternalClientMode } from '../../../utility/helper-functions';

export default {
    name: "AccountWrapper",
    components: {InvoiceHistory, BillingDetails, SubscriptionDetails, Terminal, Profile},
    computed: {
        isExternalClientMode() {
            return isExternalClientMode();
        },
        isAutoPilotMode() {
            return isAutoPilotMode();
        },
    }
}
</script>

<style>

</style>
