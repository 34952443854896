<template>
    <div class="order-summary">
        <div class="order-summary-header">
            <div>
            ORDER SUMMARY
            </div>
            <div class="close-container">
                <button
                @click="close"
                >
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M13.4115 12.0003L18.6886 17.2822C19.079 17.6729 19.0787 18.306 18.688 18.6964C18.2973 19.0867 17.6641 19.0864 17.2738 18.6957L11.9963 13.4135L6.70658 18.6953C6.31577 19.0856 5.6826 19.0851 5.29237 18.6943C4.90213 18.3035 4.9026 17.6703 5.29342 17.2801L10.5827 11.9986L5.29565 6.70679C4.9053 6.31609 4.90559 5.68292 5.29629 5.29257C5.68699 4.90223 6.32016 4.90251 6.7105 5.29321L11.998 10.5855L17.2739 5.31734C17.6648 4.92711 18.2979 4.92758 18.6881 5.31839C19.0784 5.70921 19.0779 6.34237 18.6871 6.73261L13.4115 12.0003Z" fill="#92929D"/>
                </svg>

                </button>
            </div>
        </div>
        <div style="padding-bottom: 0">
            <div>
                <product-item v-for="product in products" :key="product.slug"
                :product="product"
                :isUpgrading="isUpgrading"
                :firstProductRendering="firstProductRendering"
                @remove-product="removeProduct"
                @commitment-changed-at-checkout="commitmentChanged"
                >
                </product-item>
            </div>
        </div>
        <div>
            <!-- SPECIAL PROMO -->
            <div class="summary-container"
            v-if="isErbPromo"
            style="margin-bottom: 5px;"
            >
                <div v-if="salesTaxPercent > 0">Total to pay today before tax</div>
                <div v-else>Total to pay today</div>
                <div style="text-decoration: ">{{ total }}</div>
            </div>
            <div class="summary-container"
            v-else
            >
                <div v-if="salesTaxPercent > 0">Total before Tax</div>
                <div v-else>Total</div>
                <div>{{ total }}</div>
            </div>
            <!-- SPECIAL PROMO ENDS -->
            <div 
            class="summary-container"
            v-if="salesTaxPercent > 0"
            >
                <div>UK Vat</div>
                <div>${{ vat }}</div>
            </div>
        </div>
        <div class="order-footer">
            <div class="tsandcs-box">

                <label class="tsandcs"><span>You agree to our <a href="https://financialsource.co/terms-conditions/" target="_blank">terms and conditions</a></span>
                    <input type="checkbox" v-model="termsAndConditions.val" @change="termsAndConditions.error = false">
                <span class="checkmark" :class="{'invalid-input': termsAndConditions.error}"></span>
                </label>
            </div>
            <div class="total-section">
                <span class="sum-total">
                    {{ totalWithVat }}
                </span>
                <base-button
                mode="o-green"
                :link="false"
                :disabled="makingRealPayment"
                :showSpinner="makingRealPayment"
                @click.native="checkAndMakePayment"
                >Make Payment
                </base-button>
            </div>
        </div>
    </div>
</template>

<script>
import SubscriptionMixin from "../../../mixins/SubscriptionMixin";
import { isErbPromo, precise } from "../../../utility/helper-functions";
import ProductItem from "./ProductItem.vue";
import Swal from 'sweetalert2';
// import { eventBus } from '../../../main';
import { createNamespacedHelpers } from 'vuex';
import _ from 'lodash';

const general = createNamespacedHelpers("general");

export default {
    name: "OrderSummary",
    mixins: [SubscriptionMixin],
    components: {
        ProductItem
    },
    props: {
        products: Array,
        removeHandler: Function,
        close: Function,
        makePayment: Function,
        isUpgrading: {
            type: Boolean,
            default: false
        }
    },
    // created() {
        
    // },
    data: function () {
        return {
            total: 0,
            vat: 0,
            totalWithVat: 0,
            selectedCommitment: {name: 'default'},
            termsAndConditions: {
                val: false,
                error: false
            },
            firstProductRendering: true,
            firstProductRenderingPrice: 0,
            commitmentSelectedOnce: true
        }
    },
    computed: {
        ...general.mapGetters(['user']),
        productsLength: function () {
            return this.products.length;
        },
        makingRealPayment() {
            return this.makingPayment && !document.querySelector('.terminal-modal');
        },
        isErbPromo() {
            return isErbPromo();
        }
    },
    watch: {
        user: {
            deep: true,
            handler: function () {
                this.updatePrice();
            },
        },
        // productsLength: function () {
        //     if(!this.products.length)
        //          eventBus.$emit("nav-change", {
        //             instanceID: this.$root.instanceID,
        //             comp: this.$store.getters["general/componentAfterRefresh"] || "home"
        //         });

        //     this.products.forEach(product => {
        //         if (product.product_type === "plan" && !product.trial_of_product_id) {
        //             this.calculateProductPrice(product).then(price => {
        //                 product.updatedPrice = price;
        //             })
        //         }
        //     })
        // },
        products: {
            deep: true,
            handler: function (val) {
                if(val && val.length) {
                    if(this.firstProductRendering && val[0]?.commitments?.length) {
                        const base = _.orderBy(val[0]?.commitments, el => el.pricing.USD, ["asc"])[0]
                        this.firstProductRenderingPrice = base.pricing.USD;
                        if(!this.isErbPromo)
                            this.commitmentSelectedOnce = false;
                    }
                }

                this.updatePrice();
            }
        }
    },
    methods: {
        updatePrice() {
            let total = 0;
            const from = this.firstProductRenderingPrice ? 'From ' : '';
            if(this.firstProductRenderingPrice) {
                total = this.firstProductRenderingPrice;
            }
            else {
                this.commitmentSelectedOnce = true;
                this.products.forEach(product => {
                    total += product.updatedPrice;
                });
            }
            this.total = from + '$' + precise(total / 100);
            let vat = 0;
            if(this.includesVAT)
                vat = this.calculateVat(total / 100);
            this.vat = vat;
            this.totalWithVat = from + '$' + precise(total / 100 + parseFloat(vat));
        },
        removeProduct(product) {
            product.selected = false;
            this.removeHandler(product);
        },
        clearError(field) {
            this.cardDetails[field].error = '';
            this.errorMessages = [];
        },
        checkAndMakePayment() {
            if(!this.commitmentSelectedOnce) {
                Swal.fire({
                    title: 'Select Commitment!',
                    type: 'warning',
                    text: 'You have to select a time commitment to continue!',
                });
            }
            else if(!this.termsAndConditions.val) {
                this.termsAndConditions.error = true;
                Swal.fire({
                    title: 'Payment Info!',
                    type: 'warning',
                    text: 'You have to accept the terms and conditions!',
                });
            }else{
                this.setMakingPayment(true);
                this.makePayment();
            }
        },
        commitmentChanged(val) {
            // console.log(val)
            this.firstProductRendering = false;
            this.firstProductRenderingPrice = 0;
            if(!val.comm) {
                this.firstProductRendering = true;
                this.firstProductRenderingPrice = 0;
            }
            
            this.$emit("commitment-changed-at-checkout", val)
        }
        
    },
}
</script>

<style lang="scss">
    .order-summary {

        background-color: $onboard-survey-card-background;
        border-radius: 20px;
        min-height: 70vh;
        display: flex;
        flex-direction: column;
        

        & > div {
            padding: 20px 20px 20px;

            &:first-child {
                border-bottom: 1px solid $separator-line-color;
            }
            &:nth-child(3) {
                margin-top: auto;
                border-top: 1px solid $separator-line-color;
                border-bottom: 1px solid $separator-line-color;
            }

        }
        

        & .order-summary-header {
            display: flex;
            justify-content: space-between;
            align-items: center;

            & > div:first-child {
                @include H7;
            }

            & .close-container button {
                background-color: transparent;
                border: none;
                color: $input-color-filter;
                cursor: pointer;
            }
        }

        & .product-icon {
            @include productIcon;
        }

        & .product-item {

            & .product-item-top {
                display: grid;
                gap: 20px;
                text-align: center;
                justify-content: center;
                // grid-template-columns: 55px 1fr auto 45px;

                @include respond(sm) {
                    justify-content: unset;
                    text-align: unset;
                    gap: 10px;
                    grid-template-columns: 55px 1fr auto 45px;
                }
                
                
               

                & h3 {
                    @include H3;

                }

                & .plan-description-price {
                    @include respond(sm) {
                        display: flex;
                        align-items: center;
                        justify-content: flex-end;
                    }
                    
                }

                & .plan-description-price-number {
                    @include H2;
                }

                & .plan-description-price-period {
                    margin-left: 5px;
                    font-size: 12px;
                    color: $input-color-filter;
                }
            }

        }

        & .summary-container {
            display: flex;
            justify-content: space-between;
        }

        & .sum-total {
            text-align: center;
            margin-bottom: 10px;
            @include H2;

            @include respond(md) {
                margin-bottom: unset;
                text-align: unset;
                margin-right: 8px;
            }
            
            
            
        }

        & .tsandcs-box {
            margin: 10px auto 26px auto;
            height: 1px;
            transform: translateX(-300%);

            @include respond(md) {
                transform: unset;
                margin: 0 auto 26px 0;
                height: 1px;
                display: flex;
                align-self: center;
            }
           
        }

    .order-footer {
        display: flex;
        flex-direction: column-reverse;
        align-items: center;

        @include respond(sm) {
            align-items: unset;
            flex-direction: unset;
            justify-content: space-between;
        }

        & .total-section {
            display: flex; 
            flex-direction: column;
            @include respond(md) {
                flex-direction: unset;
                align-items: center;
            }
            
        }
        

    }

    .tsandcs {
        display: block;
        position: relative;
        padding-left: 35px;
        margin-bottom: 12px;
        cursor: pointer;
        font-size: 22px;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
        font-size: 12px;
        font-weight: normal;
        color: $input-color;

        & span:first-child {
            position: absolute;
            top: 3px;
            width: 250px;

            & > a {
                font-weight: normal;
                color: $input-color;
                text-decoration: underline;
            }
        }

    }

        /* Hide the browser's default checkbox */
        .tsandcs input {
            position: absolute;
            opacity: 0;
            cursor: pointer;
            height: 0;
            width: 0;
        }

            /* Create a custom checkbox */
        .checkmark {
            position: absolute;
            top: 0;
            left: 0;
            height: 24px;
            width: 24px;
            background-color: transparent;
            border: 2px solid $input-icon-color;
            border-radius: 10px;

            &.invalid-input {
                border-color: $color-12;
            }
        }

            /* On mouse-over, add a grey background color */
        // .tsandcs:hover input ~ .checkmark {
        //     background-color: transparent;
        //     border: 2px solid $input-icon-color;
        //     border-radius: 10px;
        // }

            /* When the checkbox is checked, add a blue background */
        .tsandcs input:checked ~ .checkmark {
            background-color: transparent;
            border: 2px solid $input-icon-color;
            border-radius: 10px;
        }

            /* Create the checkmark/indicator (hidden when not checked) */
        .checkmark:after {
            content: "";
            position: absolute;
            display: none;
        }

            /* Show the checkmark when checked */
        .tsandcs input:checked ~ .checkmark:after {
            display: block;
        }

        .tsandcs input:checked ~ .checkmark {
            border: 2px solid $checkbox-active;
        }

            /* Style the checkmark/indicator */
        .checkmark:after {
            left: 6px;
            top: 2px;
            width: 5px;
            height: 10px;
            border: solid $checkbox-active;
            border-width: 0 3px 3px 0;
            -webkit-transform: rotate(45deg);
            -ms-transform: rotate(45deg);
            transform: rotate(45deg);
        }
    }
</style>
