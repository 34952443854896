import { render, staticRenderFns } from "./ClientProfiles.vue?vue&type=template&id=3758f7f8"
import script from "./ClientProfiles.vue?vue&type=script&lang=js"
export * from "./ClientProfiles.vue?vue&type=script&lang=js"
import style0 from "./ClientProfiles.vue?vue&type=style&index=0&id=3758f7f8&prod&lang=scss"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports