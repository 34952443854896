<template>
    <div
    :class="{ 'columns': thisQuestion.columns }"
    style="margin-top:43px;">
        <div class="steps-tracker">Step {{ thisQuestion.step }} of {{ allSteps }}</div>
        <h2 class="align-center u-f-weight-700">{{ thisQuestion.question }}</h2>
        <div class="form-text-div dimmed align-center subtitle" style="margin-top:-6px;">{{ thisQuestion.subtitle }}</div>
        <form @submit.prevent>
            <div class="plan-container" style="margin-top:17px;">
                <div class="plan-card1 survey-card"
                v-for="option in thisQuestion.options"
                :key="option.id"
                :class="{'highlighted': selectedOptionID === option.id, 'columns': thisQuestion.columns}"
                >
                    <label :for="option.name"
                    >
                        <survey-option
                        :option="option"
                        :selected="selectedOptionID"
                        :isColumn="thisQuestion.columns"
                        >
                        </survey-option>
                    </label>
                    <input 
                    type="radio" 
                    :id="option.name" 
                    :name="option.name" 
                    :value="option.id" 
                    v-model="selectedOptionID"
                    />
                </div>
                <div 
                v-if="thisQuestion.input"
                class="plan-card1 survey-card fixed"
                :class="{'highlighted': selectedOptionID === thisQuestion.options.length}"
                >
                    <div style="padding: 20px; box-sizing: border-box; height: 76px; display: flex;"
                    >
                        <survey-option
                        :option="thisQuestion.input"
                        :selected="null"
                        :isColumn="false"
                        :isInput="true"
                        @click.native="selectedOptionID = thisQuestion.options.length"
                        @input-keyup="val => this.feedbackText = val"
                        >
                        </survey-option>
                    </div>
                </div>
                <div v-if="error"
                class="error-message">{{ error }}</div>
            </div>
            <div class="container">
                <div class="button-div mt-10">
                    <base-button
                    mode="o-blue fullwidth"
                    :link="false"
                    @click.native="continuePressed"
                    >Continue</base-button>
                </div>
            </div>
        </form>
    </div>
</template>

<script>
import SurveyOption from './components/SurveyOption.vue';
import Questions from '../../../utility/survey-questions';
import { talkToServer } from '../../../utility/helper-functions';

export default {
    props: ["details", "promoID"],
    components: {
        SurveyOption
    },
    created() {
        this.question = this.details.step;

        let series = "default";

        this.questions = Questions[series];
    },
    computed: {
        thisQuestion() {

            return this.questions[this.question];
        },
        allSteps() {
            return Object.keys(this.questions).length;
        },
    },
    data() {
        return {
            selectedOptionID: 0,
            question: null,
            questions: null,
            feedbackText: '',
            error: ''
         
        }
    },
    methods: {
        continuePressed() {
            if(!this.verifyInput()) {
                // console.log("invalid inputs");
                return;
            }

            const question = this.thisQuestion.name;
            const option = this.thisQuestion.options.find(el => el.id === this.selectedOptionID);
            const answer = option ? option.name : 'custom-answer';
            let step = +this.question;  

            const feedback = this.feedbackText ? "?suggestion=" + encodeURIComponent(this.feedbackText) : '';

            const slug = encodeURIComponent(question + ":" + answer) + feedback;
            console.log(slug);

            talkToServer("/journey/" + slug, "GET", null, null, true);

            step =  +this.question + 1;

            
            let complete = step === 10 + this.allSteps ? true : false;
           
            if(complete) {
                step = 100;
                talkToServer("/journey/journey-completed", "GET", null, null, true);
            }

            step = step.toString();

            // this.feedbackText = '';

            this.$emit("update-step", {
                step,
                answer
            });
            this.selectedOptionID = 0;
            this.question = step;
        },
        verifyInput() {
            if(this.selectedOptionID === this.thisQuestion.options.length && this.feedbackText.length < 3) {
                this.error = "Minimum 3 characters";
                return false;

            }
            this.error = '';
            return true;

        }
    }
}
</script>



