<template>
    <div class="widget-config" style="padding-top: 20px; height: 100%;">
        <div class="shop">
            <div class="shop-tabs">
                <div class="tabs-body">
                    <div>
                        <item-instruction v-if="activeTab ==='instructions'"
                        :item="product"
                        mode="purchase"
                        :isCourse="isCourse"
                        :isLiveStream="isLiveStream"
                        @show-activation="activeTab = 'howactivation'"
                        ></item-instruction>
                    </div>
                    <div v-if="activeTab === 'howactivation'">
                        <how-activation-works
                        :isCourse="isCourse"
                        :isLiveStream="isLiveStream"
                        :product="product"
                        @back-to-instructions="activeTab = 'instructions'"
                        ></how-activation-works>
                    </div>
                </div>
            </div>
            <div class="shop-bottom">
                <!-- SPECIAL PROMO -->
                <div 
                v-if="isPromoProduct"
                class="col-2-lg-fs flex-center-lg"
                style="height:100%"
                >
                    
                    <base-button
                    v-if="showLearnMoreButton"
                    mode="o-blue fullwidth"
                    :link="false"
                    @click.native="learnMore"
                    >Learn More
                    </base-button>
                    
                    <base-button
                    mode="green fullwidth"
                    :link="false"
                    @click.native="purchaseClicked"
                    >Purchase now
                    </base-button>
                </div>
                <!-- SPECIAL PROMO ENDS -->
                <!-- XMAS PROMO -->
                <div 
                v-if="isMTACourse"
                class="col-2-lg-fs flex-center-lg"
                style="height:100%"
                >
                    
                    <!-- <base-button
                    mode="o-blue fullwidth"
                    :link="false"
                    @click.native="purchaseAccelerator"
                    >Purchase with Accelerator
                    </base-button> -->
                    
                    <base-button
                    mode="green fullwidth"
                    :link="false"
                    @click.native="purchaseClicked"
                    >Purchase now
                    </base-button>
                </div>
                <!-- XMAS PROMO ENDS -->
                <div 
                v-else
                class="col-2-lg-fs flex-center-lg"
                style="height:100%; gap: 0;"
                >       
                    <div></div>
                    <base-button
                    mode="green fullwidth"
                    :link="false"
                    @click.native="purchaseClicked"
                    >Purchase now
                    </base-button>
                    <div></div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import ItemInstruction from './ItemInstruction.vue';
import HowActivationWorks from './HowActivationWorks.vue';
import { eventBus } from '../../../main';
import { createNamespacedHelpers } from 'vuex';
import { getServerEnv, isValidPromo } from '../../../utility/helper-functions';
import Constants from '../../../utility/constants';

const payments = createNamespacedHelpers('payments');

export default {
    props: ["details"],
    components: {
        HowActivationWorks,
        ItemInstruction
    },
    // created() {
    //     console.log("product config", this.product);
    // },
    computed: {
        ...payments.mapGetters(['availableProducts']),

        product() {
            return Object.values(this.availableProducts).find(el => el.id === this.details.id);
        },
        isPromoProduct() {
            const env = getServerEnv();
            const promoID = this.product?.id
            return window.bootUp.products[env].includes(promoID) && !this.product?.slug?.indexOf('legacy') > -1;
           
        },
        showLearnMoreButton() {
            const env = getServerEnv();
            return this.product.id === Constants.MTA_PROMO[env] || this.product.id === Constants.ERB_PROMO[env];
        },
        //XMAS PROMO
        isMTACourse() {
            const env = getServerEnv();
            if(this.details.id === Constants.MTA_COURSE.prodID[env])
                return true;

            return false;
        }
        //XMAS PROMO ends
        ,
        isCourse() {
            return this.product.product_sub_type === 'addon' && 
            this.product.widgets.length === 1 &&
            this.product.widgets[0]?.code === 'TCVIDEO';
        },
        isLiveStream() {
            return this.product.product_sub_type === 'addon' && 
            this.product.widgets.length === 1 &&
            this.product.widgets[0]?.code === 'TCSTREAM';
        }
    },
    data() {
        return {
            activeTab: 'instructions'
        }
    },
    methods: {
        purchaseAccelerator() {
            const env = getServerEnv();
            const prod = Object.values(this.availableProducts).find(el => el.id === Constants.MTA_PROMO[env]);
            localStorage.setItem("promo", prod.id);

            const type = prod.product_type === 'plan' ? 'plan' : 'promotion';
            eventBus.$emit("nav-change", {
                instanceID: this.$root.instanceID,
                    comp: "checkout",
                    details: {
                        type: type,
                        selectedItem: prod
                    }
            });
          
            eventBus.$emit("close-modal");
        },
        purchaseClicked() {
            //SPECIAL PROMO
            if(isValidPromo(this.product.id))
                localStorage.setItem("promo", this.product.id);
            //SPECIAL PROMO ENDS
            const type = this.product.product_type === 'plan' ? 'plan' : 'promotion';
            eventBus.$emit("nav-change", {
                instanceID: this.$root.instanceID,
                    comp: "checkout",
                    details: {
                        type: type,
                        selectedItem: this.product
                    }
            });
            // console.log(this.product)
            // this.showModal(CheckoutOverview, {
            //     type: "promotion",
            //     selectedItem: this.details.product
            // }, {
            //     style: "width: 95% !important; height: 95% !important",
            //     resizable: true
            // })
            eventBus.$emit("close-modal");
            // console.log("purchase", this.details.product.id);
        },
        learnMore() {
            const env = getServerEnv();
            const promoID = this.product?.id;
            const erbPromo = Constants.ERB_PROMO[env] === promoID;
            const mtaPromo = Constants.MTA_PROMO[env] === promoID;

            if(erbPromo)
                window.open("https://go.financialsource.co/research-bundle/", "_blank").focus();
            else if(mtaPromo)
                window.open("https://go.financialsource.co/accelerator", "_blank").focus();
        }
    }
}
</script>
