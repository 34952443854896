<script>
    import { Line, mixins } from 'vue-chartjs'
    const { reactiveProp } = mixins

    export default {
        extends: Line,
        mixins: [reactiveProp],
        props: [
            "yLabelString",
            "maintainAspectRatio",
            "title",
            "chartData",
            "handler",
            "min",
            "max",
            "stepSize",
            "yTicks",
            "iteration",
            "datapointFormatter"
        ],
        data: function () {
            return {
                options : {
                    onClick: this.handler,
                    responsive: false,
                    maintainAspectRatio: this.maintainAspectRatio,
                    legend: {
                        display: true,
                    },
                    title: {
                        display: false,
                    },
                    scales: {
                        xAxes: [{
                            display: true,
                            gridLines: {
                                zeroLineColor: "#292C38",
                                drawBorder: true,
                                color: "#292C38",
                                display: true
                            },
                            ticks: {
                                beginAtZero: true,
                                fontSize: 11,
                                fontColor: "white",
                                maxRotation: 90,
                                minRotation: 33
                            },
                        }],
                        yAxes: [{
                            id: "yAxis",
                            gridLines: {
                                zeroLineColor: "#292C38",
                                drawBorder: true,
                                color: "#292C38",
                                display: true
                            },
                            scaleLabel: {
                                display: false,
                                labelString: ""
                            },
                            
                            ticks: {
                                beginAtZero: false,
                                fontSize: 11,
                                stepSize: 0.5,
                                callback: (value) => this.yTicks(value),
                            },
                            display: true,
                            position: 'right'
                        }],
                    },
                    tooltips: {
                        enabled: true,
                        mode: 'single',
                        intersect: true
                    },
                    events: ['click'],
                    plugins: {
                        datalabels: {
                            color: '#f0f8fa',
                            clamp: true,
                            anchor: 'end',
                            offset: 5,
                            // align: 'bottom',
                            align: function(context) {
                                if(context.dataset.alignment) {
                                    return context.dataset.alignment(context);
                                }
                                else
                                    return "bottom";
                            },
                            events: ['click'],
                            formatter: (value) => this.datapointFormatter(value)
                            // function(value) {
                                // let type = typeof value;
                                // if(type === "object") {
                                    // return value;
                                // } else {
                                //     return precise(value) + '%';
                                // }
                            // }
                        }
                    }
                }
            }
        },
        created() {
            this.setChartProps();

        },
        mounted () {
            this.renderChart(this.chartData, this.options)
        },
        updated() {
            this.setChartProps();
        },
        methods: {
            setChartProps() {
                if(this.min || this.min === 0)
                    this.options.scales.yAxes[0].ticks.min = this.min;
                if(this.max || this.max === 0)
                    this.options.scales.yAxes[0].ticks.max = this.max;
                if(this.stepSize)
                    this.options.scales.yAxes[0].ticks.stepSize = this.stepSize;
                if(this.yLabelString) {
                    this.options.scales.yAxes[0].scaleLabel.display = true;
                    this.options.scales.yAxes[0].scaleLabel.labelString = this.yLabelString;
                }

                    
            }
        },
        watch: {
            iteration() {
                // console.log("updated", this.iteration);
                this.setChartProps();
                 this.renderChart(this.chartData, this.options)
            }
        }
    }
</script>

<style scoped>

</style>