<template>
    <div 
    class="video-screen-particular"
    style="padding-bottom: 30px;"
    >
        <!-- <div> -->
            <div class="form-container">
                <div>
                    <div 
                    v-if="entityQnaToShow"
                    class="answer-card-icon yellow-empty">
                        <a href="javascript:void(0);" @click="isSlideDownOpen = true"><span style="display: flex; align.items: center;"><img style="height: 16px;" :src="questionAnswerSrc" /> <span>Ask a question</span></span>
                        </a>
                    </div>
                </div>
                <div>
                <form @submit.prevent
                class="video-search-box">
                    <base-input
                    elementType="input"
                
                    inputType="text"
                    v-model="searchTerms.keywords"
                    :placeholder="'Search for keywords...'"
                    forID="name"
                    inputClass="darker-input"
                    formControlClass=""
                    
                    ></base-input>

                    <base-button
                    mode="o-blue"
                    :link="false"
                    @click.native="search"
                    >Search
                    </base-button>
                    <base-button
                    v-if="searchMode"
                    mode="o-grey"
                    :link="false"
                    @click.native="back"
                    >Back
                    </base-button>
                </form>
                </div>
                <div></div>
                <!-- <form @submit.prevent
                class="video-search-box">
                    <base-input
                    elementType="input"
                    :placeholder="'Ask a question...'"
                    inputType="text"
                    v-model="question"
                    forID="name"
                    inputClass=""
                    formControlClass=""
                    
                    ></base-input>
                    
                    <base-button
                    mode="o-blue"
                    :link="false"
                    @click.native="submitQuestion"
                    >Submit
                    </base-button>
                </form> -->

            </div>
        <!-- </div> -->
        <vue-slide-up-down v-if="entityQnaToShow" :active="isSlideDownOpen" :duration="250" class="vue-slide-up-down darker-input">
            <video-question
            @close-slide-down="isSlideDownOpen = false"
            :forWidget="'vid-com'"
            :vidcomID="null"
            :widgetTitle="details.userSettings.widgetname"
            :implementation_id="details.implementation_id"
            ></video-question>
        </vue-slide-up-down>
        
        <fullpage-section
        v-for="item in Object.entries(sortedItems)"
        :key="item[0]"
        :name="'videotiles'"
        :title="''"
        :tiles="true"
        :moreLink="false"
        :slideDown="false"
        :scrollable="true"
        :elements="item[1]"
        :handler="loadMore"
        :height="height"
        :noMoreItems="noMoreItems"
        :details="details"
        :isLocked="isLocked"
        ></fullpage-section>
    </div>
</template>

<script>
import { eventBus } from '../../main';
import VideoCourseMixin from '../../mixins/videoCourseMixin';
// import { isMobileForDashboards } from '../../utility/helper-functions';
import FullpageSection from '../ui/FullpageSection.vue';
import VideoQuestion from './components/VideoQuestion.vue';
import { createNamespacedHelpers } from 'vuex';
import { frontFacingAssets } from '../../utility/helper-functions';

const general = createNamespacedHelpers('general');

export default {
    mixins: [VideoCourseMixin],
    props: ["details"],
    components: {
        FullpageSection,
        VideoQuestion
    },
    created() {
        eventBus.$on("widget-resize", this.handleWidgetResize);
        // eventBus.$on("window-resize", this.handleWindowResize);

        this.orderByProp = "created_at";
        this.orderByDirection = "desc";

        // console.log(this.details);
    },
    // mounted() {
    //     this.$nextTick(() => {
    //         this.calcHeight();
    //     });
    // }, 
    beforeDestroy() {
        eventBus.$off("widget-resize", this.handleWidgetResize);
    },
    computed: {
        ...general.mapState(["demoPageMode"]),
        ...general.mapGetters(["entityQnaToShow"]),

        questionAnswerSrc() {
            let src = frontFacingAssets() + 'question-answer.svg';
            return src;
        },
    },
    data() {
        return {
            height: 0,
            timeout: null,
            searchTerms: {
                keywords: ''
            },
            question: '',
            isSlideDownOpen: false
        }
    },
    methods: {
        calcHeight(h) {
            this.height = h * 2 - 75;
            // console.log("calci", document.querySelector('.widget-wrapper').clientHeight - adj);
            // let adj = 30;
            // if(isMobileForDashboards())
            //     adj = 55;

            // const num = document.querySelector('.widget-wrapper').clientHeight - adj;
            // if(num)
            //     this.height = num;
        },
        search() {
            this.bulkItems = [];
            this.sortedItems = {};
            this.noMoreItems = false;
            this.loadMore();
        },
        back() {
            this.bulkItems = [];
            this.sortedItems = {};
            this.noMoreItems = false;
            this.searchMode = false;
            this.searchTerms.keywords = ''; 
            this.fetchInitialItems();
        },
        handleWidgetResize(h) {
            if(this.timeout)
                return;

            this.timeout = setTimeout(() => {
                clearTimeout(this.timeout);
                this.timeout = null;

                this.calcHeight(h);
                console.log("window resized");

                eventBus.$emit("scroller-calc-vertical", {
                    instanceID: this.$root.instanceID
                });
            }, 1500);
 
        },
        // handleWindowResize() {
        //     this.handleWidgetResize((window.innerHeight - 190) / 2);
        // }
        // submitQuestion() {
        //     if(!this.question.length)
        //         return;

        //     let success, slug;
        //     let query = {};
           
        //     query.content_id = this.vidcomID ///?;
        //     query.question_text = this.question;
        //     query.module = this.details.implementation_id;
        //     query.source = this.details.userSettings.widgetname;

        //     slug = `/widgets/questions`;
        //     success = 'Your question was successfully submitted. Please check your emails in the next 24-48 hours for our response.';
        
        //     // console.log(query);
        //     talkToServer(slug, "POST", null, query, true).then(response => {
        //         if(response && response.data && (response.data.success || response.success)) {
        //             this.question = '';

        //             Swal.fire({
        //                 title: 'Success!',
        //                 type: 'info',
        //                 text: success,
        //                 showCancelButton: false
        //             });

        //             this.$emit("close-slide-down");
        //         }else{
        //             Swal.fire({
        //                 title: 'Error!',
        //                 type: 'warning',
        //                 text: "Could not submit question. Please try again later.",
        //                 showCancelButton: false
        //             });
        //         }
        //     }).catch(() => {
                
        //     })
            

        // }
    }
}
</script>

<style lang="scss">
    .video-screen-particular {
        & .form-container {
            width: 100%;
            margin: 20px 0;

            & form {
                
                &:first-child {
                    margin-bottom: 40px;
                }

                &:first-child {

                    @include respond(md) {
                        margin-bottom: 0;
                        margin-right: 10px;
                    }
                }
                &:nth-child(2) {
                    @include respond(md) {
                        margin-bottom: 0;
                        margin-left: 10px;
                    }
                }

                & .form-control-fs {
                    flex: 1 1 auto;
                }

                & input {
                    font-size: 16px;

                    @include respond(md) {
                        min-width: 235px;
                    }
                    @include respond(lg) {
                        min-width: 335px;
                    }
                }

            }

            & button {
                height: 48px;
            }

            @include respond(lg) {
                width: unset;
                display: grid;
                grid-template-columns: 1fr 1fr 1fr;
                align-items: center;
            }
            
        }
    }
    .video-search-box {
        display: flex;
        height: 48px;
        align-items: center;

        & form label {
            display: none;
            margin: 0;
        }

        

        & button {
            margin-left: 10px;
        }


    }
</style>