/* eslint-disable */
<template>
    <div class="update-card"
    :class="{paddingCorrection: !hideHeader}">
        <div>
            <h2 v-if="!hideHeader">
                <a href="javascript:void(0);"
                @click="$emit('card-modal-navigate-to', {
                    view: 'plan-mini-overview'
                });"
                >
                    <font-awesome-icon class="arrow" :icon="['fas', 'arrow-left']"/>
                </a> Update Card
            </h2>


            <div class="card-details-wrapper">
                <fieldset>
                    <div class="form-group featured required label-transition field-input">
                        <label for="name" class="focused"><span>Name on Card</span></label>
                        <div class="field-wrap">
                            <div class="wrapper">
                                <input id="name" type="text" placeholder="Name on Card *" required="required" class="form-control-fs" v-model="cardDetails.name.val" :class="{'invalid-input': cardDetails.name.error}" @keyup="clearError('name')">
                            </div>
                        </div>
                    </div>
                </fieldset>
                <!-- <base-input for-i-d="cardName" label="Name on Card" v-model="cardDetails.name"
                element-type="input" input-type="text"/> -->
                <div>
                    <fieldset>
                        <div class="card-details">
                            <div class="border" id="card-number-o" :class="{'invalid-input': cardErrorCode === 'incomplete_number'}">
                                 <label>Card Number</label>
                                <div class="" id="card-number"></div>
                            </div>
                            <div class="border" id="card-expiry-o" :class="{'invalid-input': cardErrorCode === 'incomplete_expiry'}">
                                 <label>Card Expiry</label>
                                <div class="" id="card-expiry"></div>
                            </div>
                            <div class="border" id="card-cvc-o" :class="{'invalid-input': cardErrorCode === 'incomplete_cvc'}">
                                 <label>CVC</label>
                                <div class="" id="card-cvc"></div>
                            </div>
                        </div>
                    </fieldset>

                </div>
            </div>



            <div>
                <div>
                    <!-- <place-autocomplete-field
                        v-model="cardDetails.line1.val"
                        label="Search Address"
                        name="field2"
                        api-key="AIzaSyCEFq_JCwQL-0UoaYPxEFiVPitVudW15lE"
                        placeholder="Enter an an address, zipcode, or location"
                        @autocomplete-select="autocompleteCountry"
                        v-address-autofill:street="cardDetails.line1.val"
                        v-address-autofill:city="cardDetails.city.val"
                        v-address-autofill:state="cardDetails.state.val"
                        v-address-autofill:zipcode="cardDetails.address.val">
                    </place-autocomplete-field> -->
                </div>
                <div style="margin-bottom: -15px;">
                    <div class="form-group featured required label-transition field-input">
                        <label for="street-address" class=""><span>Street address</span></label>
                        <div class="field-wrap">
                            <div class="wrapper">
                                <input for-i-d="line1" id="street-address" type="text" placeholder="Street address *" required="required" class="form-control-fs" v-model="cardDetails.line1.val" :class="{'invalid-input': cardDetails.line1.error}" @keyup="clearError('line1')">
                            </div>
                        </div>
                    </div>
                </div>
                <div class="lg-grid-2 lg-grid-gap-22">
                    <!-- <div class="form-group featured required label-transition field-input">
                        <label for="city" class=""><span>City</span></label>
                        <div class="field-wrap">
                            <div class="wrapper">
                                <input for-i-d="city" id="city" type="text" placeholder="City *" required="required" class="form-control-fs" v-model="cardDetails.city.val" :class="{'invalid-input': cardDetails.city.error}">
                            </div>
                        </div>
                    </div> -->
                    <div class="form-group featured required label-transition field-input">
                        <label for="country" class=""><span>Country</span></label>
                        <div class="field-wrap">
                            <div class="wrapper">
                                <select for-i-d="country" id="country" placeholder="Country *" required="required" class="form-control-fs" v-model="cardDetails.country.val"
                                :class="{'invalid-input': cardDetails.country.error}" @click="countryChanged"
                                >
                                    <option value="" disabled selected hidden class="select-prompt">Country *</option>
                                        <optgroup label="Main">
                                        <option
                                                v-for="country in mainCountries"
                                                :key="country.alpha3Code"
                                                :value="country.alpha3Code"
                                                :selected="cardDetails.country.val === country.alpha3Code"
                                        >{{ country.name }}
                                        </option>
                                    </optgroup>

                                    <optgroup label="Others">
                                        <option
                                                v-for="country in otherCountries"
                                                :key="country.alpha3Code"
                                                :selected="cardDetails.country.val === country.alpha3Code"
                                                :value="country.alpha3Code"
                                        >{{ country.name }}
                                        </option>
                                    </optgroup>

                                </select>
                            </div>
                        </div>
                    </div>
                    <div class="form-group featured required label-transition field-input">
                        <label for="zipcode" class=""><span>Zip Code</span></label>
                        <div class="field-wrap">
                            <div class="wrapper">
                                <input for-i-d="zipcode" id="zipcode" type="text" placeholder="Zip Code *" required="required" class="form-control-fs" v-model="cardDetails.address.val" :class="{'invalid-input': cardDetails.address.error}" @keyup="clearError('address')">
                            </div>
                        </div>
                    </div>
                </div>
                <div>
                    <!-- <div class="form-group featured required label-transition field-input">
                        <label for="state" class=""><span>State</span></label>
                        <div class="field-wrap">
                            <div class="wrapper">
                                <input for-i-d="state" id="state" type="text" placeholder="State *" required="required" class="form-control-fs" v-model="cardDetails.state.val" :class="{'invalid-input': cardDetails.state.error}">
                            </div>
                        </div>
                    </div> -->
                </div>

            </div>



            <div class="notice" v-if="!hideHeader">We will attempt to charge your card after it's updated.</div>



            <div class="tsandcs-box">

                <label class="tsandcs"><span>You agree to our <a href="https://financialsource.co/terms-conditions/" target="_blank">terms and conditions</a></span>
                    <input type="checkbox" v-model="cardDetails.termsAndConditions.val" @change="clearError('termsAndConditions')">
                <span class="checkmark" :class="{'invalid-input': cardDetails.termsAndConditions.error}"></span>
                </label>
                <!-- <input type="checkbox" name="tsandcs" id="tcandcs" /><label for="tsandcs">You agree to our <a href="javascript:void(0);">terms and conditions</a></label> -->
            </div>
            <br/>
            <br/>
        </div>

        <div id="card-error">
            <span v-for="error in errorMessages" :key="error">{{ error }}<br/></span>
        </div>

        <div class="modal-form-bottom-1">
            <base-button v-if="!showMakePayment"
            style="margin-top: -10px;"
            mode="o-blue fullwidth"
            :link="false"
            :disabled="makingPayment"
            :showSpinner="makingPayment"
            @click.native="saveCardIntent"
            >Save
            </base-button>
            <base-button v-if="showMakePayment"
            style="margin-top: -10px;"
            mode="o-blue fullwidth"
            :link="false"
            :disabled="makingPayment"
            :showSpinner="makingPayment"
            @click.native="makePayment"
            >Make payment
            </base-button>
        </div>
    </div>
</template>

<script>
import PaymentService from "@/services/payments/payment.service";
import Swal from "sweetalert2";
import Constants from "@/utility/constants"
// import {addSpinner, removeSpinner} from "../../../utility/helper-functions";
// import {store} from "../../../store/store";
import AccountService from "../../../services/account/account.service";
import _ from "lodash";
import {createNamespacedHelpers} from "vuex";
import AppService from "../../../services/app.service";
// import {AddressAutofill} from "../../../directives/PlaceAutofill";

import OnBoardMixin from '../../../mixins/onBoardMixin';
import { eventBus } from '../../../main';
import SubscriptionMixin from '../../../mixins/SubscriptionMixin';

const general = createNamespacedHelpers('general');

export default {
    name: "UpdateCard",
    mixins: [OnBoardMixin, SubscriptionMixin],
    // directives: {AddressAutofill},
    props: {
        hideHeader: Boolean,
        showMakePayment: Boolean,
        closeSpinner: {
            type: Boolean,
            default: true
        }
    },
    data() {
        return {
            field2: '',
            userContries: Constants["USER_COUNTRIES"],
            apiKey: Constants['STRIPE_API_KEY'],
            token: null,
            style: {
                base: {
                    color: 'white',
                    fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
                    fontSmoothing: 'antialiased',
                    fontSize: '14px',
                    '::placeholder': {
                        color: '#90a8af',
                    },
                    lineHeight: '39px',
                    padding: '0 0 0 5px',
                },
                invalid: {
                    color: '#fa755a',
                    iconColor: '#fa755a',
                },
            },
            card: {
                cardNumber: null,
                cardExpiry: null,
                cardCvc: null,
            },
            errorMessages: [],
            cardErrorCode : '',
            cardDetails: {
                termsAndConditions: {
                    val: false,
                    error: ''
                },
                name: {
                    val: '',
                    error: ''
                },
                country: {
                    val: '',
                    error: ''
                },
                // city: {
                //     val: '',
                //     error: ''
                // },
                // state: {
                //     val: '',
                //     error: ''
                // },
                address: {
                    val: '',
                    error: ''
                },
                line1: {
                    val: '',
                    error: ''
                },
                // phone: {
                //     val: '',
                //     error: ''
                // },
            },
            countries: [],
        }
    },
    computed: {
        ...general.mapState(['user']),
        stripeElements() {
            return this.$stripe.elements();
        },
        hasErrors() {
            return this.errorMessages.length > 0;
        },
        mainCountries: function () {
            return this.countries.filter(country => country.group === "main")
        },
        otherCountries: function () {
            return this.countries.filter(country => country.group !== "main")
        }
    },
    methods: {
        ...general.mapActions(['loadCurrentUserSettings']),
        initializeStripeElements: function (style) {
            this.card.cardNumber = this.stripeElements.create('cardNumber', {showIcon: true, style});
            this.card.cardNumber.mount('#card-number');
            this.card.cardExpiry = this.stripeElements.create('cardExpiry', {style});
            this.card.cardExpiry.mount('#card-expiry');
            this.card.cardCvc = this.stripeElements.create('cardCvc', {style});
            this.card.cardCvc.mount('#card-cvc');
        },
        destroyStripeElements: function () {
            this.card.cardNumber.destroy();
            this.card.cardExpiry.destroy();
            this.card.cardCvc.destroy();
        },
        initializeBillingAddress: function () {
            if(this.user?.address1) {
                this.cardDetails.address.val = this.user.postal_code;
                // this.cardDetails.state = this.user.region;
                // this.cardDetails.city = this.user.city;
                this.cardDetails.country.val = this.user.country_code;
                // this.cardDetails.phone.val = this.user.sms_notification_number;
                this.cardDetails.line1.val = this.user.address1;
            }
        },
        validateEmptyFields: function (attributes, length) {
            this.setMakingPayment(false);
            let isValid = true;
            if (Object.keys(attributes).length >= length) {
                Object.values(attributes).forEach(value => {
                    if (!value) {
                        isValid = false;
                    }
                })
            } else {
                isValid = false;
            }
            return isValid;
        },
        getCountrySystemCode: function () {
            return  this.cardDetails.country.val || '';
        },
        getCountryStripeCode: function (value) {
            return this.countries.find(country => country.alpha3Code === value)?.alpha2Code || '';
        },
        autocompleteCountry: function (place, geocoder) {
            let country = geocoder.address_components.find(address => address.types.includes('country'));
            if (country?.short_name) {
                this.cardDetails.country = country.short_name;
                console.log(country.short_name);
            }
        },
        listCountryCodesAndCurrentCountry: function () {
            AppService.listGeneralCountryCodes().then(result => {
                this.countries = result;
                this.initializeBillingAddress();
                this.cardDetails.name.val = this.user.name;
            });
        },
        saveCard: function (token) {
            let context = this;
            return new Promise(resolve => {
                // let acceptTerms = this.cardDetails.termsAndConditions;
                // if (!acceptTerms) {
                //     context.errorMessages = ["Please accept the terms and conditions!"];
                // }
                // let phoneLength = this.cardDetails.phone?.length;
                // if (phoneLength >= 12) {
                //     context.errorMessages = ["The phone number cannot be greater than 12 characters!"];
                // }

                let valid = true;
                Object.entries(this.cardDetails).forEach(([key, value]) => {
                    if(!value.val) {
                        console.log("invalid",key);
                        this.cardDetails[key].error = true;
                        valid = false;
                    }

                });

                this.formValid = valid;

                let isValid = this.validateEmptyFields(this.cardDetails, 5) && valid;

                if (isValid && context.errorMessages.length === 0) {
                    // addSpinner(store, "PAYMENT_CARD");
                    
                    this.$stripe.confirmCardSetup(token, {
                        payment_method: {
                            card: context.card.cardNumber,
                            billing_details: {
                                name: context.cardDetails.name.val,
                                // phone: context.cardDetails.phone,
                                address: {
                                    postal_code: context.cardDetails.address.val,
                                    // city: context.cardDetails.city,
                                    country: this.getCountryStripeCode(context.cardDetails.country.val),
                                    // state: context.cardDetails.state,
                                    line1: context.cardDetails.line1.val
                                }
                            }
                        }
                    }).then(function (result) {
                        if (result.error) {
                            context.setMakingPayment(false);
                            context.errorMessages.push(result.error.message);
                            context.cardErrorCode = result.error.code;
                            // removeSpinner(store, "PAYMENT_CARD");
                            // Swal.fire({
                            //     title: 'Payment Method failed',
                            //     html: result.error.message,
                            //     type: 'info',
                            //     confirmButtonColor: '##1d9deb',
                            //     confirmButtonText: 'OK'
                            // });
                        } else {
                            context.updateBillingAddress().then((countryChanged) => {
                                context.$emit("card-updated");
                                if (!context.showMakePayment) {
                                    Swal.fire({
                                        title: 'Payment Method Added',
                                        html: "Success! - Your new payment card will now be used for all purchases.",
                                        type: 'info',
                                        confirmButtonColor: '##1d9deb',
                                        confirmButtonText: 'OK'
                                    }).then(() => {
                                        eventBus.$emit("close-modal");

                                    })
                                    context.setMakingPayment(false);
                                }
                                // if(countryChanged) {
                                //     removeSpinner(store, "PAYMENT_CARD");
                                // }
                                resolve({success: true, changed: countryChanged});

                                // 
                            })
                        }
                    }).finally(() => {
                        // if (this.closeSpinner) {
                        //     removeSpinner(store, "PAYMENT_CARD");
                        // }
                    });
                } else {
                    context.setMakingPayment(false);
                    context.errorMessages.push("Please fill in all the information!");
                    // if (this.closeSpinner) {
                    //     removeSpinner(store, "PAYMENT_CARD");
                    // }
                    resolve({success: false});
                }
            })
        },
        clearError(field) {
            this.cardDetails[field].error = '';
            this.errorMessages = [];
        },
        saveCardIntent(callback) {
            this.setMakingPayment(true);
            this.errorMessages = [];
            PaymentService.getSetupIntent().then(result => {
                let clientSecret = result?.data?.data?.client_secret;
                if (clientSecret) {
                    this.saveCard(clientSecret).then((result) => {
                        if (result.success && (typeof callback) === 'function') {
                            callback(result.changed)
                        }
                    });
                }
            })
        },
        makePayment: function () {
            this.saveCardIntent((changed) => this.$emit("make-payment", changed));
        },
        updateBillingAddress: function () {
            let context = this;
            let selectedCountry = context.getCountrySystemCode();
            let isCountryChanged = this.user.country_code !== selectedCountry;
            console.log(this.cardDetails);
            return new Promise(resolve => {
                let currentUserData = _.cloneDeep(context.user);
                let userObj = {
                    ...currentUserData,
                    // sms_notification_number: context.cardDetails.phone,
                    postal_code: context.cardDetails.address.val,
                    // region: context.cardDetails.state.val,
                    // city: context.cardDetails.city.val,
                    country_code: selectedCountry,
                    address1: context.cardDetails.line1.val
                }
                AccountService.saveUserAccountSettings(userObj).then(result => {
                    if(result.data.success) {
                        context.loadCurrentUserSettings().then(() => {
                            let hasVatChanged = currentUserData.sales_tax_percent !== context.user.sales_tax_percent;
                            resolve(isCountryChanged && hasVatChanged);
                        });
                    } else {
                        resolve();
                    }
                })
            })
        },
        countryChanged() {
            if(!this.cardDetails.country.val)
                this.cardDetails.country.val = "GBR";
        }
    },
    created: function () {
        this.listCountryCodesAndCurrentCountry();
    },
    mounted() {
        this.initializeStripeElements(this.style);
    },
    beforeDestroy() {
        this.destroyStripeElements();
    }
}
</script>

<style lang="scss" scoped>
    .analyst-desk .update-card {
        // padding-left: 15px;
        padding-top: 0;

        & h2 {

            @include H2;

            & a {
                @include H2-back
            }
        }

        option {
            color: $input-placeholder-color;
        }

        & .label-transition label {
            background-color: transparent;
        }

        & .label-transition label.focused {
            top: 10px!important;
        }

        & .label-transition input,
        & .label-transition select {

            color: $input-color;
            border: none;
            background-color: $card-details-background;

            &:-webkit-autofill,
            &:-webkit-autofill:hover,
            &:-webkit-autofill:focus,
            &:-webkit-autofill:active{
                -webkit-box-shadow: 0 0 0 30px $card-details-background inset !important;
                -webkit-text-fill-color: $input-color !important;
                caret-color: white;
                background-clip: content-box;
            }
        }

        & .invalid-input {
                border: 1px solid $color-12!important;
            }

        &.paddingCorrection {
            padding-bottom: 15px;
            padding-right: 15px;
        }

        & .modal-form-bottom-1 {
            margin-top: 15px;
            padding-top: 10px;
            padding-bottom: 5px;
            text-align: right;
        }
    }
    .card-details {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 15px;

        @include respond(lg) {
            grid-template-columns: 2fr 1fr 1fr;
        }


        & label {
            position: absolute;
            top: -6px;
            left: 11px;
            font-size: 10px;
            font-weight: normal;
            letter-spacing: 0.3px;
            color: $input-focus-color;
            opacity: 1;
            background-color: $input-background-color;
            pointer-events: all;
            padding-left: 3px;
            padding-right: 3px;
            font-family: sans-serif;
        }
    }

    #card-number-o {
        grid-column: 1 / -1;

        & label {
            background-color: transparent;
        }

        @include respond(lg) {
            grid-column: span 1;
        }
    }

    #card-expiry-o,
    #card-cvc-o {
        grid-column: span 1;

        & label {
            background-color: transparent;
        }
    }

    #card-number,
    #card-expiry,
    #card-cvc {
        padding-top: 5px;
    }

    #card-number-o,
    #card-expiry-o,
    #card-cvc-o,
    .form-control-fs {
        position: relative;
        background-color: $card-details-background;
        border: none;
        border-radius: 10px;
        transition: all 0.3s ease;
        // font-family: Poppins;
        height: 48px;
        padding: 0 14px;

        &::placeholder {
            color: $input-color-filter;
        }

    }

    .notice {
        color: $input-color-filter;
    }

    .tsandcs-box {
        margin: 22px 0;
        height: 1px;
    }

    .tsandcs {
        display: block;
        position: relative;
        padding-left: 35px;
        margin-bottom: 12px;
        cursor: pointer;
        font-size: 22px;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
        font-size: 12px;
        font-weight: normal;
        color: $input-color;

        & span:first-child {
            position: absolute;
            top: 3px;

            & > a {
                font-weight: normal;
                color: $input-color;
                text-decoration: underline;
            }
        }

    }

        /* Hide the browser's default checkbox */
    .tsandcs input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
        height: 0;
        width: 0;
    }

        /* Create a custom checkbox */
    .checkmark {
        position: absolute;
        top: 0;
        left: 0;
        height: 24px;
        width: 24px;
        background-color: transparent;
        border: 2px solid $input-icon-color;
        border-radius: 10px;

        &.invalid-input {
            border-color: $color-12;
        }
    }

        /* On mouse-over, add a grey background color */
    // .tsandcs:hover input ~ .checkmark {
    //     background-color: transparent;
    //     border: 2px solid $input-icon-color;
    //     border-radius: 10px;
    // }

        /* When the checkbox is checked, add a blue background */
    .tsandcs input:checked ~ .checkmark {
        background-color: transparent;
        border: 2px solid $input-icon-color;
        border-radius: 10px;
    }

        /* Create the checkmark/indicator (hidden when not checked) */
    .checkmark:after {
        content: "";
        position: absolute;
        display: none;
    }

        /* Show the checkmark when checked */
    .tsandcs input:checked ~ .checkmark:after {
        display: block;
    }

    .tsandcs input:checked ~ .checkmark {
        border: 2px solid $checkbox-active;
    }

        /* Style the checkmark/indicator */
    .checkmark:after {
        left: 6px;
        top: 2px;
        width: 5px;
        height: 10px;
        border: solid $checkbox-active;
        border-width: 0 3px 3px 0;
        -webkit-transform: rotate(45deg);
        -ms-transform: rotate(45deg);
        transform: rotate(45deg);
    }

    #card-error {
        color: $color-12;
        font-size: 12px;
        margin: 10px 0;
        text-align: center;
    }

</style>



