<template>
<div  class="client-profiles team">
    <div style="padding-top: 40px;">
        Please enlarge your window or log in on a desktop device to manage clients.
    </div>
    <div class="client-profiles-tabs">
        <div class="client-profiles-tabs-left-tab">
            <div class="client-profiles-tabs-left-tab-menu">
                <nav>
                    <ul>
                        <li :class="{'active-team-menu-item': true}"><a :class="{'active-team-menu-item': true}" href="javascript:void(0);">Team</a></li>
                    </ul>
                </nav>
            </div>
        </div>
        <div class="client-profiles-main">
            <div>
                <tab-menu 
                type="clientprofiles"
                class="tab-menu-section"
                :active="activeList"
                :tabs="tabbedLists"
                @menu-item-clicked="obj => activeList = obj.id"
                ></tab-menu>
            </div>
            <div style="margin-top: 2px;">
                <team-profile-overview
                :activeList="activeList"
                >
                </team-profile-overview>
            </div>
        </div>
    </div>
</div>
</template>

<script>
import { eventBus } from '../../../main';
import TabMenu from '../TabMenu.vue';
import TeamProfileOverview from './components/TeamProfileOverview.vue';

export default {
    components: {
        TeamProfileOverview,
        TabMenu,
    },
    created() {
        eventBus.$on("client-switch-to", list => this.switchTab(list));
    },
    beforeDestroy() {
        eventBus.$off("client-switch-to");
    },
    data() {
        return {
            activeList: "approved",
            tabbedLists: [
                {
                    id: "approved",
                    name: "Team mates",
                    icon: "team-mates"
                },
                {
                    id: "requested",
                    name: "Pending invites",
                    icon: "access-request"
                }
                    
            ]
        }
    },
    methods: {
        switchTab(list) {
            this.activeList = list;
        }
    }
}
</script>

<style lang="scss">
    
</style>