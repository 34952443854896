import httpClient from "../../api/HttpClient";
import {createUrl, removeSpinner} from "../../utility/helper-functions";
import {store} from "../../store/store";
import { eventBus } from "../../main";

export default {
    listInvoiceHistory: function () {
        return httpClient.get(createUrl('/invoice'));
    },
    listPaymentAttemptsHistory: function () {
        return httpClient.get(createUrl('/payment/history'));
    },
    getInvoice: function (invoiceId) {
        return httpClient.get(createUrl('/invoice/pdf/' + invoiceId));
    },
    listAllAvailableProducts: function () {
        return httpClient.get(createUrl('/products'));
    },
    getPaymentMethodDetails: function () {
        return httpClient.get(createUrl('/payment/method', {spinner: true}));
    },
    removePaymentMethod: function () {
        return httpClient.delete(createUrl('/payment/method'), {generalError: true, spinner: true});
    },
    purchaseAddon(addon) {
        return this.generatePromise(httpClient.post(createUrl('/products/update?action=purchase&product_slug=' + addon.slug), {}, {
            generalError: true,
            spinner: true
        }));
    },
    purchasePlan(plan) {
        return this.generatePromise(httpClient.post(createUrl('/products/update?action=purchase&product_slug=' + plan.slug), {}, {
            generalError: true,
            spinner: true
        }));
    },
    cancelAddon(addon) {
        return httpClient.post(createUrl('/products/update?action=unsubscribe&product_slug=' + addon.slug), {}, {
            generalError: true,
            spinner: true
        });
    },
    cancelPlan(plan) {
        return httpClient.post(createUrl('/products/update?action=unsubscribe&product_slug=' + plan.slug), {}, {
            generalError: true,
            spinner: true
        });
    },
    getSetupIntent() {
        return httpClient.get(createUrl('/stripe/intent', {spinner: true}));
    },
    generatePromise: function (action) {
        return new Promise((resolve, reject) => {
            action.then(result => {
                let invoiceId = result.data?.data?.invoice_id;
                let success = result.data?.data?.success;
                if(!success) {
                    // console.log("reject result", result)
                    reject(result);
                    removeSpinner(store, result.config.url);
                    removeSpinner(store, "PAYMENT_CARD");
                }
                if (invoiceId && success) {
                    eventBus.$on("payment-processed-" + invoiceId, (announcement) => {
                        removeSpinner(store, result.config.url);
                        removeSpinner(store, "PAYMENT_CARD");
                        if (announcement.status === "SUCCESS") {
                            console.log("announcement" + JSON.stringify(announcement))
                            resolve(result)
                        } else {
                            reject();
                        }
                    });
                } else if(result.data.success){
                    resolve(result);
                    removeSpinner(store, result.config.url);
                    removeSpinner(store, "PAYMENT_CARD");
                } else {
                    removeSpinner(store, result.config.url);
                    removeSpinner(store, "PAYMENT_CARD");
                }
            }).catch((err) => reject(err));
        })
    },
    retryInvoicePayment: function (invoiceId) {
        return this.generatePromise(httpClient.get(createUrl('/payment/invoice/' + invoiceId + '/retry'), {
            generalError: true,
            spinner: true
        }));

    },
    retryInvoiceAndLoadProducts: function (invoiceId) {
        return new Promise((resolve, reject) => {
            this.retryInvoicePayment(invoiceId).then(() => store.dispatch("general/loadCurrentUserSettings", {}, {root:true}).then(result => resolve(result))).catch((err) => reject(err));
        })
    },
    processPaymentEntries: function (resultInvoices, resultPaymentAttempts, paymentHistory) {
        let invoices = resultInvoices.data?.data;
        invoices?.filter(entry => entry.gross > 0)?.forEach(entry => paymentHistory.push(entry))
        this.addProductCancellationEntries(invoices, resultPaymentAttempts);
    },
    addProductCancellationEntries: function (invoices, resultPaymentAttempts, paymentHistory) {
        resultPaymentAttempts?.data?.data?.filter(payment => payment.result !== "SUCCESS")?.forEach(entry => {
            let invoice = invoices.find(invoice => invoice.id === entry.invoice_id);
            if (invoice && paymentHistory) {
                paymentHistory.push(invoice);
            }
        });
    },
    calculateProductPrice: function (product) {
        return httpClient.get(createUrl("/products/" +product.slug+ "/upgrade-price"), {generalError: true});
    }
}
