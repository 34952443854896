<template>
	<div class="backdrop analyst-desk" :class="classes">
		<dialog open :class="mode">
      <header v-if="header">
        <div v-if="countdown && !permanentOffer"
        class="top-banner">Launch ends in {{ remainingTime }}</div>
        <slot name="header">
          <h2>{{ title }}</h2>
        </slot>
        <button
        v-if="close"
        :style="countdown ? 'top: 30px' : ''"
        @click="closeModal"
        ><img :src="closeButtonSrc" />
          

        </button>
      </header>
      <section>
        <slot>

        </slot>
      </section>

			<footer v-if="footer">
				<slot name="footer">

				</slot>
			</footer>
		</dialog>
	</div>

</template>

<script>
import Constants from '../../utility/constants';
import { calculateRemainingTime, dateTimedifference, usersTime } from '../../utility/datetime';
import { frontFacingAssets } from '../../utility/helper-functions';

export default {
    props: {
        title: {
            type: String,
            required: false
        },
      mode: {
        type: String,
        required: false,
        default: ''
      },
      classes: {
        type: String,
        required: false,
        default: ''
      },
      header: {
        type: Boolean,
        required: false,
        default: true
      },
      close: {
        type: Boolean,
        required: false,
        default: true
      },
      footer: {
        type: Boolean,
        required: false,
        default: true
      },
      countdown: {
        type: String,
        required: false,
        default: ''
      }
    },
    created() {

      if(this.countdown) {
        const now = usersTime(new Date(), true, true, true, { dateFormat : "y-m-d", timeFormat: "24hr" }, "UTC");
        let fullSeconds = dateTimedifference(now, this.countdown.replace("T", ' ').replace(".000000Z",""), "seconds") - 1;

        if(fullSeconds < Constants.PERMANENT_OFFER_DEADLINE) {
          this.permanentOffer = false;
           this.interval = setInterval(() => this.remainingTime = calculateRemainingTime(this.countdown), 1000);
        }
        
      }
    },
    computed: {
      closeButtonSrc() {
        let src = frontFacingAssets() + 'close-x.svg';
        return src;
      }
    },
    data() {
      return {
        interval: '',
        remainingTime: '',
        permanentOffer: true
      }
    },
    methods: {
        closeModal() {
            this.$emit('close-modal');
            this.$modal.hide(this.$parent.$parent.name);
        }
    },
    beforeDestroy() {
      clearInterval(this.interval);
    }
}
</script>

<style lang="scss">
.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100%;
  background-color: rgba($color-00, 0.84);
  z-index: 1000;
  overflow-y: auto;


  & dialog {
    position: absolute;
    top: 12vh;
    left: 10%;
    width: 80%;
    z-index: 100;
    border: none;
    padding: 0;
    margin: 0;
    overflow: hidden;
    border-radius: 10px;

     & .label-transition input {
   
        &:-webkit-autofill,
        &:-webkit-autofill:hover, 
        &:-webkit-autofill:focus, 
        &:-webkit-autofill:active{
            -webkit-box-shadow: 0 0 0 30px $onboard-survey-card-background inset !important;
            -webkit-text-fill-color: $input-color !important;
            caret-color: white;
            background-clip: content-box;
        }
    }


    @include respond(md) {
      left: calc(50% - 20rem);
      width: 40rem;
    }

    &.wide,
    &.large,
    &.fit-window,
    &.auto {

      top: 5vh;

      // @include respond(lg) {
      //   left: calc(50% - 30vw);
      //   width: 60vw;
      //   height: 90vh;
      //   max-height: 670px;
      // }

      @include respond(lg) {
        left: 50%;
        transform: translateX(-50%);

        width: auto;
      }

    }

    &.large {
      width: 95% !important;
    }

    &.auto {
      @include respond(lg) {
        width: fit-content;
      }
    }

    &.wide {



      @include respond(lg) {
        width: 720px;
        // height: 90vh;
        max-height: 690px;
      }

    }

    &.narrow {

      padding: 10px;

      @include respond(lg) {
        left: calc(50% - 215px);
        width: 430px;
        padding: 10px 25px;
      }

    }

    &.small {

      top: 15vh;
      margin-top: 50px;
      height: 300px;
      @include respond(md) {
        left: calc(50% - 20rem);
        width: 40rem;
      }

    }

  }

  & header {
    border-bottom: none;
    padding: 7px 30px;
    position: relative;
    display: block;
    display: flex;
    align-items: center;

    h2  {
      font-size: 24px;
      font-weight: 500;
      margin: 0;
      padding: 0;
      color: $input-color;
    }

    & button {
      top: 15px;
      right: 15px;
      position: absolute;
      color: $input-icon-color;
      z-index: 2;
    }

    & .top-banner {
      position: absolute;
      top: 0;
      background-color: $core-red;
      width: 100%;
      left: 0px;
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;
      padding: 4px 0;

      font-size: 10px;
      text-align: center;
      font-weight: 600;
      text-transform: uppercase;
      letter-spacing: 1px;

    }
  }

  & header button,
  & footer button {
    background-color: transparent;
    border: none;
    // color: $color-20;
    cursor: pointer;
  }

  & section {
    padding: 15px;
  }

  & menu {
    padding: 15px;
    display: flex;
    justify-content: flex-end;
    margin: 0;
  }

  &.terminal-modal {
    background-color: $modal-backdrop-2;

    & dialog {
      display: grid;
      grid-template-rows: 90px 1fr;
      background-color: $onboard-survey-background;
      box-shadow: 0 1px 20px rgba(#000000, 1);

    }
    .custom-dialog {
      grid-template-rows: 30px 1fr;
    }
    & header {
      padding-top: 20px;
      background-color: rgba(#ffffff, 0.05);
    }

    & section {
      background-color: rgba(#ffffff, 0.05);
    }

    // & footer {
    //   background-color: $modal-footer;

    // }

    &.simplified {

      section {
        padding: 0;
      }

      & dialog {
        grid-template-rows: auto 1fr;
      }

      & header {
        min-height: unset;
      }

      & h2 {
        font-size: 16px;
        color: $input-color;
      }
    }

    &.auto-height {

      & dialog.wide {
        height: auto;
        max-height: unset;
      }

    }


  }

  & .onboarding-modal {

    background-color: $onboard-modal-backdrop;

    dialog {
      background-color: $onboard-dialog-background;

    }

    header {
      justify-content: center;
    }

  }

}

.external-auto-fs {

  & .backdrop {

    & dialog {

      &.wide,
      &.large,
      &.fit-window,
      &.auto {
        top: 12vh;
      }
    }
  }
}

</style>
