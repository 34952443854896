<template>
    <div>
        <form class="change-password" @submit.prevent>
            <div>
                <fieldset>
                    <div class="form-group featured required label-transition field-input">
                        <span class="pw-reveal">
                            <a @click="togglePwVisibility('currentPassword')" v-if="!visibility.currentPassword"><font-awesome-icon :icon="['fa', 'eye']"/></a>
                            <a @click="togglePwVisibility('currentPassword')" v-else><font-awesome-icon :icon="['fa', 'eye-slash']"/></a>
                        </span>
                        <label for="name" class=""><span>Current Password</span></label>
                        <div class="field-wrap">
                            <div class="wrapper">
                                <input id="currentPassword" type="password" placeholder="Current Password *" required="required" class="form-control-fs" v-model="model.currentPassword" :class="{'invalid-input': error.currentPassword}" @keyup="clearError('currentPassword')">
                            </div>
                        </div>
                    </div>
                </fieldset>

                    <!-- <base-input
                    elementType="input"
                    :class="{invalid: error.currentPassword}"
                    inputType="password"
                    v-model="model.currentPassword"
                    forID="current-password"
                    inputClass=""
                    formControlClass=""
                    label="Current password"
                    @clear-validity="clearValidity"
                    ></base-input> -->
                    <!-- <b-form-group
                            id="currentPassword"
                            :invalid-feedback="errorKeys['currentPassword']"
                            :state="validateField(errorKeys['currentPassword'])"
                    >
                        <b-form-input placeholder="Current password" type="password" v-model="model.currentPassword"
                                      :state="validateField(errorKeys['currentPassword'])" trim></b-form-input>
                    </b-form-group> -->
                
            </div>
            
            <div>
                <fieldset>
                    <div class="form-group featured required label-transition field-input">
                        <span class="pw-reveal">
                            <a @click="togglePwVisibility('password')" v-if="!visibility.password"><font-awesome-icon :icon="['fa', 'eye']"/></a>
                            <a @click="togglePwVisibility('password')" v-else><font-awesome-icon :icon="['fa', 'eye-slash']"/></a>
                        </span>
                        <label for="name" class=""><span>New Password</span></label>
                        <div class="field-wrap">
                            <div class="wrapper">
                                <input id="password" type="password" placeholder="New Password *" required="required" class="form-control-fs" v-model="model.password" :class="{'invalid-input': error.password}" @keyup="clearError('password')">
                            </div>
                        </div>
                    </div>
                </fieldset>
                    <!-- <base-input
                    elementType="input"
                    :class="{invalid: error.password}"
                    inputType="password"
                    v-model="model.password"
                    forID="password"
                    inputClass=""
                    formControlClass=""
                    label="New password"
                    @clear-validity="clearValidity"
                    ></base-input> -->
                    <!-- <b-form-group
                            id="password"
                            :invalid-feedback="errorKeys['password']"
                            :state="validateField(errorKeys['password'])"
                    >
                        <b-form-input placeholder="New password" type="password" v-model="model.password"
                                      :state="validateField(errorKeys['password'])" trim></b-form-input>
                    </b-form-group> -->
                
            </div>
            
            <div>
                <fieldset>
                    <div class="form-group featured required label-transition field-input">
                        <span class="pw-reveal">
                            <a @click="togglePwVisibility('password2')" v-if="!visibility.password2"><font-awesome-icon :icon="['fa', 'eye']"/></a>
                            <a @click="togglePwVisibility('password2')" v-else><font-awesome-icon :icon="['fa', 'eye-slash']"/></a>
                        </span>
                        <label for="name" class=""><span>Confirm Password</span></label>
                        <div class="field-wrap">
                            <div class="wrapper">
                                <input id="password2" type="password" placeholder="Confirm Password *" required="required" class="form-control-fs" v-model="model.password2" :class="{'invalid-input': error.password2}" @keyup="clearError('password2')">
                            </div>
                        </div>
                    </div>
                </fieldset>
                    <!-- <base-input
                    elementType="input"
                    :class="{invalid: error.password2}"
                    inputType="password"
                    v-model="model.password2"
                    forID="password2"
                    inputClass=""
                    formControlClass=""
                    label="Confirm new password"
                    @clear-validity="clearValidity"
                    ></base-input> -->
                    <!-- <b-form-group
                            id="password2"
                            :invalid-feedback="errorKeys['password2']"
                            :state="validateField(errorKeys['password2'])"
                    >
                        <b-form-input placeholder="Confirm new password" type="password" v-model="model.password2"
                                      :state="validateField(errorKeys['password2'])" trim></b-form-input>
                    </b-form-group> -->
              
            </div>
           
            <div class="account-error">
                <div v-for="error in errorMessages" :key="error">
                    <span>{{ error }}<br/></span>
                </div>
            </div>
            <base-button
            :disabled="updating"
            mode="o-blue"
            :link="false"
            @click.native="changePassword"
            >Save
            </base-button>

        </form>
    </div>
</template>

<script>
import AccountService from "../../services/account/account.service";
import Swal from "sweetalert2";
import {createNamespacedHelpers} from "vuex";
import { eventBus } from '../../main';

import OnBoardMixin from '../../mixins/onBoardMixin';

const general = createNamespacedHelpers("general");

const CONDITIONS = [
    {test: (model) => model.password?.length > 0, message: 'Please fill password field.', keys: ['password']},
    {
        test: (model) => model.password2?.length > 0,
        message: 'Please fill confirm password field.',
        keys: ['password2']
    },
    {
        test: (model) => model.currentPassword?.length > 0,
        message: 'Please fill current password field.',
        keys: ['currentPassword']
    },
    {
        test: (model) => model.password === model.password2,
        message: 'Passwords do not match.',
        keys: ['password', 'password2']
    },
]
export default {
    name: "ChangePassword",
    mixins: [OnBoardMixin],
    data: function () {
        return {
            model: {
                currentPassword: '',
                password: '',
                password2: ''
            },
            errorMessages: [],
            error: {
                currentPassword: false,
                password: false,
                password2: false
            },
            visibility: {
                currentPassword: false,
                password: false,
                password2: false
            },
            updating: false
        }
    },
    computed: {
        hasErrors: function () {
            return this.errorMessages?.length > 0;
        },

    },
    methods: {
        ...general.mapActions(["loadCurrentUserSettings"]),
        validate: function () {
            this.errorMessages = [];

            CONDITIONS.forEach((condition) => {
                if (!condition.test(this.model)) {
                    this.errorMessages.push(condition.message);
                    console.log(condition);
                    condition.keys.forEach(k => this.error[k] = true);
                }
            })

            return Object.keys(this.errorMessages).length === 0;
        },
        clearError(field) {
            this.error[field] = '';
            this.errorMessages = [];
        },
        changePassword: function () {
            this.updating = true;
            let isValid = this.validate();
            if (isValid) {
                AccountService.changePassword({
                    password: this.model.password,
                    current_password: this.model.currentPassword
                })
                    .then(result => {
                        if (result.data.success) {
                            this.loadCurrentUserSettings().then(() => {
                            });
                            Swal.fire({
                                title: 'Success!',
                                type: 'info',
                                text: 'The password was successfully changed!',
                                showCancelButton: true,
                                cancelButtonColor: '#1d9deb'
                            });
                            eventBus.$emit("close-modal");
                        } else {
                            Object.values(result.data).forEach(message => this.errorMessages.push(message[0]));
                        }
                    })
                    .catch((error) => {
                        Object.values(error?.response?.data?.data || {}).forEach(message => this.errorMessages.push(message[0]));
                        this.updating = false
                    });
            }else{
                this.updating = false;
            }
        },
        togglePwVisibility(field) {
            const visibility = this.visibility[field];
            const type = visibility ? "password" : "text";
          
            document.getElementById(field).type = type;
            
            
            this.visibility[field] = !this.visibility[field]; 
        }
    },
    created: function () {
    }
}
</script>

<style lang="scss" scoped>

.change-password {
    padding: 25px;

    & input {
        color: $input-color;

        &::placeholder {
            color: $input-color-filter;
        }

        &:-webkit-autofill,
        &:-webkit-autofill:hover, 
        &:-webkit-autofill:focus, 
        &:-webkit-autofill:active{
            -webkit-box-shadow: 0 0 0 30px $onboard-survey-background inset !important;
        }
    }

    & .label-transition label {
        background-color: $onboard-survey-background;
        padding: 0;

        &.focused {
            top: 9px;
        }

        & span {
            background-color: $widget-faded-background;
            display: inline-block;
            padding: 1px 3px;
            width: 100%;
        }
    }
}

// fieldset {
  
//   border: none;
//   background-color: rgba($color-03, 0.6);

//   //Poppins
//   padding: 10px 20px;

//   //Roboto
//   // padding: 20px 25px;
// }


</style>
