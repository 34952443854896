<template>
    <div> 
        <div>
            <div class="form-header">
                <h2>Log in</h2>
                <p>Access your analyst-desk account settings here</p>
            </div>
            <form @submit.prevent>

                <base-input
                elementType="input"
                :class="{invalid: error.email}"
                inputType="email"
                :required="true"
                v-model="profile.email"
                forID="email"
                :placeholder="'sample@email.com'"
                inputClass="partner-input"
                formControlClass=""
                label="Email address"
                labelClass="medium-font"
                :invalid="error.email"
                :invalidMessage="error.email"
                @clear-validity="clearValidity"
                ></base-input>

                <base-input
                elementType="input"
                :class="{invalid: error.password} "
                inputType="password"
                :required="true"
                v-model="profile.password"
                forID="password"
                :placeholder="'Password'"
                inputClass="partner-input"
                formControlClass=""
                label="Password"
                labelClass="medium-font"
                :invalid="error.password"
                :invalidMessage="error.password"
                @clear-validity="clearValidity"
                >
                </base-input>
                <div class="form-text-div align-right"><a class="u-input-color-filter" href="javascript:void(0);" @click="redirectToReset">Forgotten Password</a></div>

                <div class="error">{{ serverError }}</div>

                <div class="button-bar" style="justify-content: flex-end;">

                    <!-- <base-button
                    mode="o-tranparent-grey o-width-125"
                    :link="false"
                    @click.native="back"
                    >Back
                    </base-button> -->

                    <base-button
                    mode="o-blue o-width-125"
                    :link="false"
                    @click.native="next"
                    :disabled="isProcessing"
                    :showSpinner="isProcessing"
                    >Continue
                    </base-button>
                    
                </div>
            </form>
        </div>
        <div v-if="entity.quantum_mode" class="admin-login-prompt" style="position: absolute; bottom: 20px; left: 50%; transform: translateX(-50%);">Are you a client? <a href="javascript:void(0)" @click="redirectToLogin">Log in here</a></div>
    </div>
</template>
<script>
import { createNamespacedHelpers } from 'vuex';
import bootUpMixin from '../../../mixins/bootUpMixin';
import { talkToServer } from '../../../utility/helper-functions';

const general = createNamespacedHelpers("general");

const CONDITIONS = [
    {test: (model) => model.email?.length > 5 && model.email?.length < 64 && model.email?.indexOf('@') > 0 && model.email?.indexOf('.') > 0, message: 'Please fill email field.', key: "email"},
    {test: (model) => model.password.length > 7 && model.password?.length < 33, message: 'Passwprd must be 8 - 32 characters long', key: "password"},
];

export default {
    mixins: [bootUpMixin],
    computed: {
        ...general.mapGetters(["entity"]),
    },
    data() {
        return {
            profile: {},
            error: {},
            isProcessing: false,
            serverError: ''
        }
    },
    methods: {
        // ...general.mapActions(["setComponentAfterRefresh"]),

        clearValidity(name) {
            delete this.error[name];
        },
        validateData() {
        this.error = {};
            CONDITIONS.forEach((condition) => {
                if (!condition.test(this.profile)) {
                    this.error[condition.key] = condition.message;
                }
            });
            // console.log(this.error)
        },
        next() {
            const formData = {
                email: this.profile.email,
                password: this.profile.password,
                refno: window.bootUp.refno,
            }

            this.isProcessing = true;
            talkToServer(`/login${window.bootUp.cacheParams}`, 'POST', null, formData).then(response => {
                // console.log('any response', response);
                this.isProcessing = false;
                if (!response.success && response.error) {
                  
                    this.serverError = "Username or password not correct.";
                }
                else if(!response.data.user.user.bm){
                        if(!this.entity.quantum_mode)
                            this.serverError = "The analyst desk is not active. You cannot log in now.";
                        else {
                            this.serverError = "This is not an admin account, you will be redirected to the home screen";
                            setTimeout(() => this.redirectToLogin(), 2500);
                        }
                    
                } else {
                    this.saveUserData(response);
                                
                    this.$emit('register-login-successful', {
                        data: response.data,
                        isLogin: true
                    });

                }

            })
            .catch(e => {
                // console.log(e);
                this.isProcessing = false;
                if (e.status === 401) {
                     this.serverError = "Username or password not correct."
                } 
            });
        },
        redirectToLogin() {
            this.$router.push({path: "home", query: {redirect: "login"}})
        },
        redirectToReset() {
            this.$router.push({path: "home", query: {redirect: "reset"}})
        }
    }
}

</script>
