export default {
    fetchInitialIntRates() {
        const queryObject = {
            name: "initial-intrate-fetch",
            objStore: "interest_rates",
            query: "/widgets/interest-rates"
        }


        return window.bootUp.iDB.getRecords(queryObject).then(response => {
            // console.log("these are the responses", response);
            response = response.filter(bank => !bank.deleted_at);
       
            return response;
        });
    }, 
    fetchIntRatesFromServer() {
        const queryObject = {

            name: "intrate-inital-fetch-server",
            objStore: "interest_rates",
            query: "/widgets/interest-rates",

        }

        return window.bootUp.iDB.getRecordsFromServer(queryObject).then(r => r);
    },
    fetchBank(code) {
        const queryObject = {
            name: "intrate-bank-fetch",
            objStore: "interest_rates",
            code: code
        }

        return window.bootUp.iDB.getRecords(queryObject).then(response => {
            // console.log("these are the responses", response);
            response = response.filter(bank => !bank.deleted_at);
       
            return response;
        });
    }, 
}