import { createNamespacedHelpers } from "vuex"

const general = createNamespacedHelpers("general");
export default {
    computed: {
        ...general.mapGetters(['entityQnaToShow']),
        externalPageContent() {
            return {
                "real-time-indicator-suite": {
                    isParentPage: true,
                    name: "real time indicator suite",
                    title: "Track market expectations as they change.",
                    subTitle: "Intuitive, real-time indicators, powered by proprietary economic models and premium news & data.",
                    heroIMG: "Real-time Indicator Suite.png",
                    howItWorksDesc: "<p>Our indicators are housed within our online analyst-desk software.</p><p>They help traders track market moving events in real-time.</p>",
                    howItWorksCards: [
                        {
                            id: 1,
                            text: "Each indicator is intuitive. Save time and effort in your analysis."
                        },
                        {
                            id: 2,
                            text: "They use real-time data from premium sources. Trade with conviction."
                        },
                        {
                            id: 3,
                            text: "They track changes in market data instantly. Manage trades confidently."
                        },
                    ],
                    includedDesc: "<p>Tune into baseline market expectations.</p><p>Know when expectations change. Optimise your trades.</p>",
                    includedWidgets: [
                        // {
                        //     id: 1,
                        //     img: "Market Scanner Frame.png",
                        //     title: "Sentiment Scanner",
                        //     text: "Baseline expectations curated from the worlds leading macro-fundamental trading experts.",
                        //     textBold: "",
                        //     route: "sentiment-scanner"
                        // },
                        {
                            id: 2,
                            img: "Interest Rate Probability Tracker.png",
                            title: "Interest Rate Tracker",
                            text: "Trackers STIR markets in real-time to display the probability of each central bank hike/cut.",
                            textBold: "",
                            route: "interest-rate-tracker"
                        },
                        {
                            id: 3,
                            img: "Professional Economic Calendar.png",
                            title: "Professional Calendar",
                            text: "Economic calendar designed for news traders. Includes high/low forecast ranges.",
                            textBold: "",
                            route: "professional-calendar"
                        },
                        {
                            id: 4,
                            img: "Breaking News Headlines.png",
                            title: "Breaking News Headlines",
                            text: "A streaming feed of market moving news as it breaks. Includes professionally curated research.",
                            textBold: "",
                            route: "breaking-news-headlines"
                        },
                        {
                            id: 5,
                            img: "Currency Strength Monitor.png",
                            title: "Currency Strength Monitor",
                            text: "Instantly view the strongest and weakest major currencies as they update in real-time.",
                            textBold: "",
                            centeredMD: true,
                            route: "currency-strength"
                        },
                    ],
                    startNowText: "Unlock our analyst desk<br/> today"
                },
                // "sentiment-scanner": {
                //     name: "sentiment scanner",
                //     title: "Keep your finger on the pulse of market sentiment 24/7",
                //     subTitle: "Professionally curated commentary and analysis from the world's leading macro trading experts.",
                //     heroIMG: "Market Scanner Frame.png",
                //     howItWorksDesc: "<p>Stay tuned into market expectations as they evolve in real-time. Professionally curated content from the world's leading trading experts.</p><br/><p>All content is automatically pulled in from Twitter. Every channel is professionally curated and displayed in a user-friendly manner.</p><br/><p>Every new tweet is instantly displayed without any clicking, scrolling or refreshing. The scanner operates 24/7.</p><br/><p>When a market moving tweet is published, you'll see it instantly.</p>",
                //     howItWorksCards: [
                //         {
                //             id: 1,
                //             text: "Trusted content professionally curated daily."
                //         },
                //         {
                //             id: 2,
                //             text: "Tune in effortlessly with automatic scrolling."
                //         },
                //         {
                //             id: 3,
                //             text: "Total coverage of changing market sentiment."
                //         },
                //     ],
                //     whoItsFor: "<p>Trades wanting to track the twittersphere with a professional tool they can trust.</p><br/><p>Day traders that want to tune into market sentiment as it changes in real-time.</p><br/><p>Swing traders that want to keep their finger on the market's pulse easily.</p>",
                //     whoItsForVideo: "824682274",
                //     startNowText: "Watch the sentiment scanner<br />in action"
                // },
                "breaking-news-headlines": {
                    name: "breaking news headlines",
                    title: "React first with market moving news as it breaks",
                    subTitle: "A streaming feed of breaking headlines and professional research around the clock.",
                    heroIMG: "Breaking News Headlines.png",
                    howItWorksDesc: "<p>Our software automatically generates and publishes breaking news as it’s released from the most respected public sources.</p><br/><p>Headlines cover all macro-fundamental market moving events. Hear critical information first everytime.</p><br/><p>Our interface has been designed with traders in mind. Effortlessly track all news without missing a beat.</p><br/><p>We only post news that has the potential to move financial markets.</p>",
                    howItWorksCards: [
                        {
                            id: 1,
                            text: "Track market moving central bank speeches in real time."
                        },
                        {
                            id: 2,
                            text: "Catch geopolitical and financial news as it breaks"
                        },
                        {
                            id: 3,
                            text: "Keep up with economic data and company earnings releases"
                        },
                    ],
                    whoItsFor: "<p>Day traders that need market moving news delivered quickly around the clock.</p><br/><p>Traders that want to effortlessly see what’s moving the markets without any fluff.</p>",
                    whoItsForVideo: "828108980",
                    startNowText: "Watch the breaking news headlines<br />in action"
                },
                "interest-rate-tracker": {
                    name: "interest rate tracker",
                    title: "Predict interest-rate-driven price moves in advance",
                    subTitle: "See hike/cut probability before each meeting and know which type of surprise will cause the biggest move.",
                    heroIMG: "Interest Rate Probability Tracker.png",
                    howItWorksDesc: "<p>Interest rate expectations drive FX markets. Our indicator shows you what hikes/cuts the markets are expecting at the next central bank meetings.</p><br/><p>It displays the probability of each hike/cut occuring and updates as pricing changes. We cover all major central banks and meetings.</p><br/><p>Know in advance exactly what central bank move will cause the largest price moves and react first if they happen.</p><br/><p>Stay one step ahead of the markets into every major central bank event.</p>",
                    howItWorksCards: [
                        {
                            id: 1,
                            text: "Calculated from data-driven expectations, not subjective opinions."
                        },
                        {
                            id: 2,
                            text: "Keep your finger on the pulse of real market expectations."
                        },
                        {
                            id: 3,
                            text: "Instantly react when unexpected rate adjustments are made."
                        },
                    ],
                    whoItsFor: "<p>News traders preparing for upcoming central bank rate statements.</p><br/><p>Day traders that want to add a high-probability strategy to their arsenal.</p>",
                    whoItsForVideo: "824682192",
                    startNowText: "Watch the interest rate probability tracker<br />in action"
                },
                "professional-calendar": {
                    name: "professional calendar",
                    title: "Predict economic-data driven price moves in advance",
                    subTitle: "View high/low forecasts for major events and instantly know when the actual figure will cause a move.",
                    heroIMG: "Professional Economic Calendar.png",
                    howItWorksDesc: "<p>Our professional economic calendar is built for day-traders. Its user-friendly layout makes tracking upcoming economic data easy.</p><br/><p>All important economic data from every major country is covered. You can easily filter the display based on your trading preferences.</p><br/><p>Our premium data sources ensure that the actual and updated prior figures are released instantly from each source.</p><br/><p>Trade with the only economic calendar specifically designed for risk-event traders.</p>",
                    howItWorksCards: [
                        {
                            id: 1,
                            text: "Trade risk events with an edge unavailable to most traders."
                        },
                        {
                            id: 2,
                            text: "Carefully designed UX with risk-event traders in mind."
                        },
                        {
                            id: 3,
                            text: "Never miss a move caused by scheduled risk events."
                        },
                    ],
                    whoItsFor: "<p>News and risk event traders looking for comprehensive forecast ranges.</p><br/><p>Traders that want to see upcoming risk events in an unrivalled user-experience.</p>",
                    whoItsForVideo: "824682490",
                    startNowText: "Watch the professional economic calendar<br />in action"
                },
                "currency-strength": {
                    name: "currency strength monitor",
                    title: "Always trade the strongest currency against the weakest",
                    subTitle: "A real-time indicator that displays the relative strength of major currencies across multiple timeframes.",
                    heroIMG: "Currency Strength Monitor.png",
                    howItWorksDesc: "<p>We combine streaming FX prices with our own proprietary models. This displays the strongest and weakest currencies in real-time.</p><br/><p>Using our currency strength monitor saves you time and provides data you can trust with your hard earned capital.</p><br/><p>When you have a trade idea for specific currency, the monitor instantly shows you the optimal currency to pair it against.</p><br/><p>Maximise your odds of success on every trade.</p>",
                    howItWorksCards: [
                        {
                            id: 1,
                            text: "Identify optimal pairs quickly and save time and effort"
                        },
                        {
                            id: 2,
                            text: "Trade in-line with the market moves confidently"
                        },
                        {
                            id: 3,
                            text: "Updates in real-time to help you trust its accuracy"
                        },
                    ],
                    whoItsFor: "<p>Day traders trying to identify the best currencies to buy or sell at any moment.</p><br/><p>Traders that need help understanding currency correlation.</p>",
                    whoItsForVideo: "824682102",
                    startNowText: "Watch the currency strength monitor<br />in action"
                },
                "analyst-chat": {
                    name: "professional analyst chat",
                    title: "Receive personalised guidance on your trade ideas as markets move",
                    subTitle: "Chat to our team of professional market analysts through the EU session about any trading question you have.",
                    heroIMG: "Analyst chat access.png",
                    howItWorksDesc: "<p>Access our analyst team via chat messenger from within the analyst-desk software. The chat is active for the entire EU session and the first-half of the US session.</p><br/><p>Our team can check institutional sources to make sure you haven't missed anything. They can also provide feedback and guidance on your trade ideas.</p><br/><p>The chat can also be used as an educational resource to find questions to your trading questions.</p><br/><p>Trade with a team of professional analysts at your fingertips.</p>",
                    howItWorksCards: [
                        {
                            id: 1,
                            text: "Quickly understand why specific assets are moving"
                        },
                        {
                            id: 2,
                            text: "Ask trading questions within the context of moving markets"
                        },
                        {
                            id: 3,
                            text: "Receive guidance on every idea and only take the best trades"
                        },
                    ],
                    whoItsFor: "<p>Experienced traders that need all available information before taking a trade.</p><br/><p>New traders that need help understanding markets and why they move in real-time.</p>",
                    whoItsForVideo: "824682024",
                    startNowText: "Watch the analyst chat<br />in action",
                    entityQnaToShow: true
                },
                "news-trading-livestreams": {
                    name: "news trading livestreams",
                    title: "Learn how to trade news events in real-time",
                    subTitle: this.entityQnaToShow ? "Livestream coverage of major economic calendar events. Get trade plan scenarios, ask questions and watch over our shoulder as we navigate the release." : "Livestream coverage of major economic calendar events. Get trade plan scenarios and watch over our shoulder as we navigate the release.",
                    heroIMG: "News-Trading livestreams.png",
                    howItWorksDesc: this.entityQnaToShow ? "<p>Learn how to trade data releases on the economic calendar. Follow live as our experts walk through every market moving event.</p><br/><p>Each livestream begins ahead of the release with a trade plan scenario. These explain which outcome will cause long/short moves in price.</p><br/><p>The livestream continues as the event unfolds and the markets react. Every move is explained and all questions are answered.</p><br/><p>Type your questions and receive answers live.</p>" : "<p>Learn how to trade data releases on the economic calendar. Follow live as our experts walk through every market moving event.</p><br/><p>Each livestream begins ahead of the release with a trade plan scenario. These explain which outcome will cause long/short moves in price.</p><br/><p>The livestream continues as the event unfolds and the markets react. Every move is explained.</p>",
                    howItWorksCards: [
                        {
                            id: 1,
                            text: "Follow economic news as the markets react"
                        },
                        {
                            id: 2,
                            text: "Know in advance which outcomes will generate price moves"
                        },
                        {
                            id: 3,
                            text: this.entityQnaToShow ? "Get answers to your questions as data is released" : "Follow in real-time as we navigate the reaction."
                        },
                    ],
                    whoItsFor: "<p>Part-time traders that want a strategy to use around their job or commitments.</p><br/><p>Macro traders that want a proven strategy to add to their arsenal.</p>",
                    whoItsForVideo: this.entityQnaToShow ? "826476771" : "834185440",
                    startNowText: "Watch the news-trading livestreams<br />in action"
                },
                "trading-library": {
                    name: "trading Q&A video library",
                    title: this.entityQnaToShow ? "Get your specific trading questions answered by experts" : "Learn niche trading concepts on-demand",
                    subTitle: this.entityQnaToShow ? "An ever expanding video library of trading Q&A. Search existing videos or submit your own question to receive an answer." : "An ever expanding video library of lessons based on real trader's questions.",
                    heroIMG: "Trading Q&A Video Library.png",
                    howItWorksDesc: this.entityQnaToShow ? "<p>Our team regularly creates video replies to traders' questions. These videos are archived for you to review on-demand.</p><br/><p>Search any trading term or concept to bring up every video on that topic. Submit your own questions in case it's not already been answered.</p><br/><p>Receive a reply to your questions via video or email within 24 hours. Ask unlimited questions to power your trading education.</p><br/><p>On-demand learning structured around your personal interests.</p>" : "<p>Our team regularly creates video replies to traders' questions. These videos are archived for you to review on-demand.</p><br/><p>Search any trading term or concept to bring up every video on that topic. On-demand learning structured around your personal interests.</p><br/><p>Our library of content is constantly expanding to help you stay educated as market moving catalysts change.</p>",
                    howItWorksCards: [
                        {
                            id: 1,
                            text: "Learn relevant trading concepts faster."
                        },
                        {
                            id: 2,
                            text: this.entityQnaToShow ? "Submit unlimited questions of your own." : "Quickly get context when market catalysts change."
                        },
                        {
                            id: 3,
                            text: "Focus your education on specific trading topics."
                        },
                    ],
                    whoItsFor: "<p>Beginner traders that want to jump-start their learning quickly on-demand.</p><br/><p>Experienced traders that want to understand specialist topics suddenly in the news.</p>",
                    whoItsForVideo: this.entityQnaToShow ? "826476706" : "834185379",
                    startNowText: "Watch the trading Q&A Video Library<br />in action"
                },
                "risk-event-trading-course": {
                    name: "News trading course",
                    title: "Learn a step by step strategy for trading economic news events",
                    subTitle: "A comprehensive video course that you can watch on-demand at your own pace an unlimited number of times.",
                    heroIMG: "News Trading Strategy Video Course.png",
                    howItWorksDesc: "<p>You can access this course from the training centre area of the analyst-desk software. It contains 26 videos.</p><br/><p>You'll learn a 3-step process for identifying high-probability trade ideas from scheduled economic data releases.</p><br/><p>The course covers economic data points and central bank events. It includes detailed trade examples of the strategy in action.</p>",
                    howItWorksCards: [
                        {
                            id: 1,
                            text: "Add a proven trading strategy to your arsenal."
                        },
                        {
                            id: 2,
                            text: "Short-cut your learning curve with real trade examples"
                        },
                        {
                            id: 3,
                            text: "Scheduled events that provide predictable opportunities"
                        },
                    ],
                    whoItsFor: "<p>Traders that want to understand how and why markets react to economic data.</p><br/><p>Part-time traders that want to trade a proven strategy around their current schedule.</p>",
                    whoItsForVideo: "826476741",
                    startNowText: "Watch the Risk Events Trading Course<br />in action"
                },
                "video-ideas": {
                    name: "real-time video trade ideas",
                    title: "Learn how to generate high-quality trade ideas as prices move",
                    subTitle: "Bite size videos that explain the markets and highlight top trading opportunities in real-time.",
                    heroIMG: "Real-time Video Trade Ideas.png",
                    howItWorksDesc: this.entityQnaToShow ? "<p>Our video player can be accessed within the analyst-desk software and loaded onto a customisable dashboard. 10-20 videos per day are published.</p><br/><p>We explain why prices move and how we anticipate the markets to react next. We also share specific ideas for how prices could move next.</p><br/><p>You can submit questions and receive a video reply directly to your player within the software and via email.</p><br/><p>Learn the professional trading approach by following every day.</p>" : "<p>Our video player can be accessed within the analyst-desk software and loaded onto a customisable dashboard. 10-20 videos per day are published.</p><br/><p>We explain why prices move and how we anticipate the markets to react next. We also share specific ideas for how prices could move next.</p><br/><p>Learn the professional trading approach by following every day.</p>",
                    howItWorksCards: [
                        {
                            id: 1,
                            text: "Understand price movements with real-time video explainers"
                        },
                        {
                            id: 2,
                            text: "Follow trade ideas as markets move and sentiment changes"
                        },
                        {
                            id: 3,
                            text: this.entityQnaToShow ? "Receive video replies to your specific trading questions": "Watch over our shoulder as we navigate changing sentiment"
                        },
                    ],
                    whoItsFor: "<p>Day-traders that want to understand why prices are moving and what could happen next.</p><br/><p>Part-time traders that want to easily catch up on all the main catalysts at the end of each day.</p>",
                    whoItsForVideo: this.entityQnaToShow ? "824682610" : "834185271",
                    startNowText: "Watch Real-time Video Trade Ideas<br />in action"
                },
                "macro-masterclass": {
                    name: "Trading Masterclass Course",
                    title: "Learn why price moves happen and how to predict them",
                    subTitle: "A comprehensive video course teaching you how to analyse fundamental market catalysts and generate high quality trade ideas.",
                    heroIMG: "Macro-Fundamental Masterclass Video Course.png",
                    howItWorksDesc: "<p>You can access this course from the training centre area of the analyst-desk software. It contains 93 videos.</p><br/><p>You'll learn a 3-step process for identifying high-probability, macro-fundamental trade ideas. You'll also learn how to navigate market sentiment.</p><br/><p>You'll learn how economics influences prices. It covers strategy for trading bonds, FX, equities and commodities.</p>",
                    howItWorksCards: [
                        {
                            id: 1,
                            text: "Understand how and why markets move"
                        },
                        {
                            id: 2,
                            text: "Simplify complex subjects like economic analysis and sentiment"
                        },
                        {
                            id: 3,
                            text: "Learn the professional trading approach"
                        },
                    ],
                    whoItsFor: "<p>Traders that want to understand markets and make better decisions.</p><br/><p>Traders that want to combine their favourite technical strategy with fundamental analysis.</p>",
                    whoItsForVideo: "828110440",
                    startNowText: "Watch the Macro-Fundamental Masterclass Course<br />in action"
                },
            }
        }
    }
}