<template>
    <div class="news-container" 
    ref="scrollable"
    :id="'headlines-' + layoutID"
    >
        <div
		
		class="scroll-up-badge yellow">
			<a href="javascript:void(0)"
            v-if="showBadge"
			v-scroll-to="scrollToOptions"
			><font-awesome-icon :icon="['fas', 'chevron-circle-up']" />&nbsp;New posts
			</a>
		</div>

        <div v-if="noItems && resultsReady">
            <div class="no-more-items"><font-awesome-icon :icon="['fas', 'exclamation-triangle']" /> No headlines found</div>
        </div>

        <div 
        ref="scroller" 
        :id="'headlines-inner-' + layoutID"
        style="position: relative;" >
            <news-perday
            v-for="(arr, date) in sortedItems"
            :key="date"
            :newsPerday="arr"
            :day="date"
            ></news-perday>

            <loading-spinner v-if="fetchMode"></loading-spinner>
            <div v-if="noMoreItems && !noItems" class="no-more-items"><font-awesome-icon :icon="['fas', 'exclamation-triangle']" /> No more headlines to load</div>

        </div>
    </div>
</template>

<script>

import NewsPerday from './NewsPerday';
import ScrollMixin from '../../../../mixins/scrollMixins';
import LoadingSpinner from '../../../ui/LoadingSpinner';
import { isEmptyObject } from '../../../../utility/helper-functions';

export default {
    props: ["sortedItems","resultsReady","currentSection", "noMoreItems","layoutID","newHeadline"],
    mixins: [ScrollMixin],
    components: {
        NewsPerday,
        LoadingSpinner
    },
    created() { 
        // console.log("cont", this.sortedItems);
        this.setupScrollListener();
    },
    computed: {
        showBadge() {
			return this.newHeadline && this.isScrolledDown;
		},
        noItems() {
            return isEmptyObject(this.sortedItems);
        }
    },
    data() {
        return {
            scrollToOptions: {
				el: "#headlines-inner-" + this.layoutID,
				container: "#headlines-" + this.layoutID,
				duration: 500,
				lazy: false,
				easing: 'linear',
				offset: 0,
				force: true,
				x: false,
				y: true
			},
        }
    },
    methods: {
        onScroll() {
            const scrollTop = this.$refs.scrollable.scrollTop;
            const height = this.$refs.scrollable.getBoundingClientRect().height;
            const scrollHeight = this.$refs.scrollable.scrollHeight;

            // console.log(this.$refs.scrollable.scrollTop, this.$refs.scrollable.getBoundingClientRect().height, this.$refs.scrollable.scrollHeight);
            
            if ((scrollTop + height >= scrollHeight - 200) &&
            !this.fetchMode && 
            !this.noMoreItems) {                 
                // console.log("scroll-down-enough");
                this.fetchMode = true;
                this.$emit("load-more");
            }                
            
            if(scrollTop > 100) {
                // console.log("scrolled down");
                this.isScrolledDown = true;
            }else{
                this.isScrolledDown = false;
                // console.log("scrolled up");
                this.$emit("new-headline-viewed");
            }
        },
    },
    watch: {
        resultsReady(val) {
            this.fetchMode = !val;
        },
        currentSection() {
            
            this.$nextTick(() => {
                this.$refs.scrollable.scrollTop = 0;
            });
        }
    }

}
</script>

<style lang="scss">
    
    .news-container {
        grid-column: 1 / -1;
        overflow-x: hidden;
        overflow-y: auto;

        @include scrollbarDark;

    }
    .news--item, .pinned-news--item {
        grid-column: 1 / -1;
        display: grid;
        @include news-item-cols;
        grid-template-rows: auto;
        overflow: visible;
        align-items: center;
        border-bottom: 1px solid white;
        transition: background-color 0.6s ease 0.6s;

        font-size: 1em;
        padding: 16px 0;

        &:last-child {
            margin-bottom: 0;
            border-bottom: none;
        }

        &-expandable {
            grid-column: 1 / -1;
            display: grid;
            @include news-item-cols;
            cursor: pointer;
        }
        
        &__time {
            grid-column: 1 / 2;
            padding: 0 5px;
            text-align: center;
            color: $color-20;

            &.expandable {
                cursor: pointer;
            }

            &.non-expandable {

                @include respond(mobile) {
                    cursor: pointer;
                }
            }

        }

        &__headline, &__news {
            text-align: left;
            color: $color-20;

            & a {
                color: $color-15!important;
            }
            
            // @include respond(mobile) {
            //     margin: 0 1rem;
            // }

        }

        &__headline a {
            text-decoration: none!important;
        }


        &.with-profile-div {

            & .news--item {

                &__pic {
                    grid-column: 4 / 5;
                    // position: relative;
                    text-align: center;


                    // @include respond(mobile) {
                    //     grid-column: 4 / 6;
                    // }

                    // @include respond(tablet) {
                    //     grid-column: 4 / 5;
                    // }

                    
                    
                }

                &__plus {
                    color: $color-15;
                }

                &__plus.green {
                    color: $color-131;
                }



                &__headline,
                &__news,
                &__analysis,
                &__reaction,
                &__analysis-details,
                &__reaction-details {

                    margin-left: 5px;

                    // @include respond(mobile) {
                    //     grid-column: 6 / -2;
                    // }

                    // @include respond(tablet) {
                    //     grid-column: 5 / -2;
                    // }

                }
                
            }
        }

        &__headline {
            grid-column: 2 / 3;
            letter-spacing: 0.4px;
            transition: 0.15s ease-out max-height;

        }


        &__news {
            grid-column: 1 / -1;
            padding: 0 20px;
            grid-row: 2;
            margin: 1.75em 0;
            letter-spacing: 0.1px;
            line-height: 25px;
            // text-align: justify;
            color: $input-color-filter;

            &.video-news-frame {
                max-width: 720px;
                position: relative;
                left: 50%;
                transform: translateX(-50%);
            }

            & h1 {
                margin-top: 1.75em!important;
            }
        
            & h1, 
            & h1 strong,
            & h1 span, 
            & h1 strong span {
                color: $core-yellow!important;
                font-weight: 100!important;
                font-size: 1.9em!important;
                line-height: 35px!important;
                text-align: left!important;
        
                // @include respond(xl) {
                //     font-size: 2.5em!important;
                //     line-height: 45px!important;
                // }
            } 
        
            & h2 {
                margin-top: 1.6em!important;
            }
        
            & h2, 
            & h2 strong,
            & h2 span, 
            & h2 strong span {
                color: $core-yellow!important;
                font-weight: 100!important;
                font-size: 2.45em!important;
                line-height: 30px!important;
                text-align: left!important;
        
            } 

            & p {
                color: $input-color-filter!important;
                margin: 1em 0!important;
                line-height: 1.6em;

            }
        
            & p strong,
            & p strong span {
                color: $core-yellow!important;
            }
        
            & p u,
            & p u span {
                color: $core-yellow!important;
            }
        
            & p b
            & p b span {
                color: $core-yellow!important;
            }
        
            & li strong,
            & li strong span {
                color: $core-yellow!important;
                line-height: 1.6em;
            }
            
            

            & span, 
            & ul, & ul li, & ul li span,
            & ol, & ol li, & ol li span {
                color: $input-color-filter !important;
            }

            & ul, & ul li, 
            & ol, & ol li {
                margin: 0.75em 0!important;
                line-height: 25px;
            }

            & img {
                max-width: 50%;
                height: auto;
                cursor: pointer;
            }

        }

        &__plus {
            grid-column: 3 / 4;
            color: $core-yellow;
            text-align: center;
            font-size: 1.2em;


            &.expandable {
                cursor: pointer;
            }


            & i {
                pointer-events: none;

            }
        }

        &__reaction, &__analysis {
            color: $core-yellow;
            grid-column: 2 / -1;
            cursor: pointer;
            margin-top: 10px;
            letter-spacing: 1px;
            font-size: 0.95em;
            text-align: left;

            // @include respond(mobile) {
            //     grid-column: 1 / -1;
            //     text-align: center;
            // }

            &-time {
                pointer-events: none;
            }
        }

        &__reaction-details, &__analysis-details {
            // grid-column-start: 3;
            grid-column: 1 / -1;
            padding: 0 20px;
            margin: 0.75em 0;
            color: $input-color-filter !important;

            & a {
                
                color: $color-15;
            }

            & ul li, p {
                margin: 1.1em 0;
                text-align: left;
            }

            // @include respond(mobile) {
            //     grid-column: 2 / -1;
                
            // }

        }

        & ul {
            padding-left: 20px;
        }

    }

    .news--item {
        background-color: $widget-faded-background;
        border-bottom: 3px solid $onboard-survey-card-background;
    }

    // .pinned {
    //     grid-column: 1 / -1;
    //     display: grid;
    //     grid-template-columns: repeat(12, 1fr);
    //     grid-template-rows: auto;
    //     border: 2px solid $color-20;
    //     background-color: $color-07;
    //     margin: 10px 0 0 0;
    //     display:none;

    //     @include respond(mobile) {
    //         margin: 20px 0 0 0;
    //     }

    //     &-header {
    //         grid-column: 1 / -1;
    //         grid-row: 1;
    //         background-color: $color-06;
    //         height: 40px;
    //         text-transform: capitalize;
    //         text-align: center;

    //         &--title {
    //             background-color: $color-20;
    //             grid-column: 1 / -1;
    //             grid-row: 1;
    //             padding-left: 20px;
    //             color: $color-06;
    //             font-size: 0.9em;
    //             line-height: 40px;
    //             text-transform: capitalize;
    //             text-align-last: left;
    //             letter-spacing: 1px;
                
    //         }
            

    //     }

        // &-news {
        //     grid-column: 1 / -1;
        //     display: grid;
        //     grid-template-columns: repeat(12, 1fr);
        //     grid-template-rows: auto;
        
        //     &--item {
        //         background-color: $color-07;

        //         &:first-child {
        //             margin-top:0;
        //         }
        
        //         &__end {
        //             grid-column: 1 / 3;
        //             padding: 0 5px;
        //             text-align: center;
        //             color: $color-20;

        //             &.expandable {
        //             cursor: pointer;
        //             }

        //             &.non-expandable {

        //                 @include respond(mobile) {
        //                     cursor: pointer;
        //                 }
        //             }
            
        
        //             @include respond(mobile) {
        //                 display: none;
        //             }
        //         }
        
        //         &__countdown {
        //             grid-column: 3 / 5;
        //             padding: 0 5px;
        //             text-align: center;
        //             color: $color-20;

        //             &.expandable {
        //                 cursor: pointer;
        //             }

        //             &.non-expandable {

        //                 @include respond(mobile) {
        //                     cursor: pointer;
        //                 }
        //             }

        //         }

        //         &__headline {

        //             grid-column: 5 / -4;

        //         }


        //         &.with-profile-div {

        //             & .pinned-news--item {
            
        //                 &__pic {
        //                     grid-column: 5 / 6;
        //                     // position: relative;
        //                     text-align: center;
            
        //                     // @include respond(mobile) {
        //                     //     grid-column: 5 / 7;
        //                     // }

        //                     // @include respond(tablet) {
        //                     //     grid-column: 4 / 5;
        //                     // }
            
                            
                            
        //                 }
            
        //                 &__plus {
        //                     color: $color-15;
        //                 }
            
        //                 &__plus.green {
        //                     color: $color-131;
        //                 }
            
        //                 &__headline,
        //                 &__news,
        //                 &__analysis,
        //                 &__reaction,
        //                 &__analysis-details,
        //                 &__reaction-details {
            
        //                     grid-column: 6 / -4;
            
        //                     margin-left: 5px;
            
        //                     // @include respond(mobile) {
        //                     //     grid-column: 7 / -2;
        //                     // }

        //                     // @include respond(tablet) {
        //                     //     grid-column: 5 / -2;
        //                     // }
            
        //                 }
                        
        //             }
        //         }
            
        //     }
        // }

    // }


    // .output-date {
    //     // @include respond(mobile) {
    //     //     display: none;
    //     // }
    // }

    .output-date, .output-time {
        pointer-events: none;
    }

    .news-profile-img {
        border: 2px solid $color-15;
        vertical-align: bottom;
        pointer-events: none;

        &.green {
            border: 3px solid #5DDC00;
        }

        // &.large-profile-img {
        //     @include respond(desktop) {
        //         height: 100px;
        //         border-radius: 10rem;
        //     }
        
        //     @include respond(xl) {
        //         height: 15rem;
        //         border-radius: 15rem;
            
        //     }
        
        //     @include respond(mobile) {
        //         height: 15rem;
        //         border-radius: 15rem;
            
        //     }
        // }

        // @include respond(desktop) {
        //     height: 2.5rem;
        //     border-radius: 2.5rem;
        // }

        // @include respond(xl) {
        //     height: 3rem;
        //     border-radius: 3rem;
        
        // }

        // @include respond(mobile) {
        //     height: 3rem;
        //     border-radius: 3rem;
        
        // }
        // position: absolute;
        // top: 50%;
        // left: 50%;
        // transform: translateX(-50%) translateY(-45%);
        
    }

    .timeslot {
        
        @include H7;
        color: $input-color-filter;
        padding: 12px 0 4px;
        text-align: left;
    }

    .no-more-items {
        text-align: center;
        padding: 15px;
        padding-bottom: 50px;
        color: $color-06;
    }

    .vue-slide-up-down {
        grid-column: 1/ -1;
    }

    figcaption.attachment__caption {
        display: none;
    }

    .size-sm .news--item__news {
        grid-column: 2 / 4;
        padding-left: 0;
    }

    .size-sm .news--item__reaction-details, 
    .size-sm .news--item__analysis-details{
        grid-column: 2 / 4;
        padding-left: 0;
    }

    // .size-md {
    //     & .news--item__headline,
    //     & .news--item__news,
    //     & .news--item__reaction, 
    //     & .news--item__analysis,
    //     & .news--item__reaction-details, 
    //     & .news--item__analysis-details {
    //         grid-column: 2 / -1;
    //         padding-left: 0;
    //         padding-right: 0;
    //     }

    // } 
</style>