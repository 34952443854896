import _ from "lodash";
export default {
    setEntity(state, payload) {
        state.entity = payload;
    },
    setCountries(state, payload) {
        state.countries = payload;
    },
    setLastShopVisitDate(state, [key, value]) {
        let lastShopVisit = _.cloneDeep(state.lastShopVisitDate);
        lastShopVisit[key] = value;
        state.lastShopVisitDate = lastShopVisit;
    },
    setLastShopVisitDateList(state, payload) {
        state.lastShopVisitDate = _.cloneDeep(payload) || {};
    },
    setCurrentUser(state, payload) {
        payload.lastUpdate = new Date();
        state.user = _.cloneDeep(payload);
    },
    addSpinner(state, payload) {
        state.spinnersList.push(payload);
    },
    removeSpinner(state, payload) {
        const index = state.spinnersList.findIndex(entry => entry === payload);
        if(index > -1) {
            state.spinnersList.splice(index, 1);
        }
    },
    removeAllSpinners(state) {
        state.spinnersList.length = 0;
    },
    toggleSpinner(state) {
        state.showSpinner = state.spinnersList.length > 0;
    },
    destroyUserStoreData(state) {
        state.user = {};
        state.lastShopVisitDate = {};
    },
    setVersionId(state, payload) {
        state.versionId = payload;
    },
    setRenewFails(state, payload) {
        state.renewFails = payload;
    },
    setPaymentBoundToFail(state, payload) {
        state.paymentBoundToFail = payload;
    },
    setActiveNavItem(state, payload) {
        state.activeNavItem = payload;
    },
    setComponentAfterRefresh(state, payload) {
        state.componentAfterRefresh = payload;
    },
    setDbReady(state, payload) {
        state.dbReady = payload;
    },
    setLastTrainingCentreOrderUpdated(state, payload) {
        state.lastTrainingCentreOrderUpdated = payload;
    },
    setWatchHistory(state, payload) {
        state.watchHistory = payload;
    },
    setEntityUpdateTimeout(state, payload) {
        state.entityUpdateTimeout = payload;
    },
    setDemoPageMode(state, payload) {
        state.demoPageMode = payload;
    },
    setTerminalMenu(state, payload) {
        state.terminalMenu = payload;
    },
    setEntityQna(state, payload) {
        state.entityQna = payload;
    },
    setUnlockedWidgets(state, payload) {
        state.unlockedWidgets = payload;
    },
    setWidgetsChoiceOnDemo(state, payload) {
        state.widgetsChoiceOnDemo = payload
    },
    setExternalUser(state, payload) {
        state.externalUser = payload;
    },
    setExternalUserAutoLogin(state, payload) {
        state.externalUserAutoLogin = payload;
    },
    setBrokerRegistrationData(state, payload) {
        state.brokerRegistrationData = {...state.brokerRegistrationData, ...payload};
    },
    setRuleSet(state, payload) {
        state.ruleSet = payload;
    },
    setCheckoutLoginMode(state, payload) {
        state.checkoutLoginMode = payload
    },
    setSingleWidgetMode(state, payload) {
        state.singleWidgetMode = payload;
    },
    setWarningDismissed(state) {
        state.warningDismissed = true;
    }
}