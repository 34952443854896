<template>
    <div class="padlock-overlay">
        <div class="padlock-overlay-centered">
            <div style="text-align: center;">
                <a href="javascript:void(0);" @click="activate" :class="{'cursor-pointer': isExternalClientMode}">
                    <svg width="22" height="28" viewBox="0 0 22 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M9.9024 0.0338188C6.14866 0.506721 3.14495 3.29904 2.37569 7.0307C2.26594 7.56305 2.25191 7.8198 2.23192 9.66664C2.21066 11.6316 2.21378 11.7116 2.31027 11.6818C2.64236 11.5795 5.67439 11.0457 5.92686 11.0451C5.97398 11.045 5.99694 10.6117 6.0131 9.41493C6.03113 8.08482 6.0525 7.72441 6.12948 7.45615C6.76807 5.23087 8.68055 3.75775 10.9308 3.75775C13.29 3.75775 15.3444 5.44548 15.7844 7.74501C15.8392 8.03124 15.862 8.57679 15.862 9.59783C15.862 10.6737 15.8786 11.045 15.9267 11.045C16.2012 11.045 19.3345 11.5959 19.5666 11.685C19.6431 11.7144 19.6496 11.5064 19.6297 9.66916C19.6049 7.37594 19.5603 7.00774 19.1706 5.88008C18.721 4.57907 18.0808 3.56505 17.0682 2.54977C15.4265 0.903847 13.4627 0.0590775 11.15 0.00390282C10.6829 -0.00721977 10.1215 0.00625884 9.9024 0.0338188ZM9.34188 12.3927C7.76308 12.5007 7.27615 12.5428 6.49275 12.6387C4.44148 12.8901 1.96486 13.3902 0.328747 13.8837L0 13.9828V20.1984V26.4139L0.958844 26.6752C3.14293 27.2706 5.36093 27.6615 7.91945 27.9017C9.16874 28.0191 12.5381 28.0351 13.6704 27.9292C15.6206 27.7467 17.1197 27.5316 18.6933 27.2085C19.5043 27.042 21.4594 26.5525 21.7405 26.4457L21.889 26.3892V20.1951V14.0009L21.7384 13.943C21.4668 13.8384 19.5228 13.3523 18.7112 13.186C17.0957 12.855 15.6616 12.6433 14.0265 12.4946C13.1545 12.4153 10.0112 12.3469 9.34188 12.3927ZM11.803 15.9842C12.5599 16.2443 13.1852 16.8742 13.4496 17.6427C13.6857 18.3289 13.641 18.9921 13.3124 19.6797C13.0772 20.1717 12.5208 20.7188 12.0621 20.9088L11.7527 21.0371V22.3968V23.7565H10.9308H10.109L10.108 22.4004L10.1071 21.0443L9.79512 20.9092C9.60389 20.8263 9.33838 20.6375 9.10908 20.4212C8.04312 19.4156 7.97813 17.7988 8.96048 16.724C9.69912 15.9158 10.7832 15.6336 11.803 15.9842ZM10.636 17.5214C10.354 17.5983 10.0216 17.9365 9.94371 18.2259C9.672 19.2349 10.9112 19.9536 11.6496 19.2153C12.3917 18.4731 11.656 17.2435 10.636 17.5214Z" fill="grey"/>
                    </svg>
                </a>
            </div>
            <div v-if="instructions">
                <h3>Content Locked</h3>
                <p v-if="displayPrompt">Please <a href="javascript:void(0);" @click="activate" :class="{'cursor-pointer': isExternalClientMode}">activate</a> to gain access</p>
            </div>
            
        </div>
    </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';
import ContentLockedMixin from '../mixins/contentLockedMixin';
import { isAutoPilotMode, isExternalClientMode } from '../utility/helper-functions';

const general = createNamespacedHelpers("general")

export default {
    props: {
        instructions: {
            type: Boolean,
        default: false
        },
        impID: Number
    },
    mixins: [ContentLockedMixin],
    computed: {
        ...general.mapState(["demoPageMode"]),
        ...general.mapGetters(["entity"]),
        isExternalClientMode() {
            return isExternalClientMode();
        },
        displayPrompt() {
            return this.isExternalClientMode;
        }
    },
    methods: {
        activate() {
            if(this.isExternalClientMode || isAutoPilotMode() || this.demoPageMode)
                this.showBrokerRequest(this.impID);
        }
    }
}
</script>

<style lang="scss">
    .padlock-overlay {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        // transform: translate(50%, 50%);
        background-color: $modal-backdrop;
        z-index: 1;
        text-align: center;
        color: $input-color-filter;
        &.blur {
            backdrop-filter: blur(6px);
        }

        &-centered {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);

            h3 {
                font-size: 18px;
            }

            p {
                font-size: 14px;
            }
            
        }
        &.sm-padlock .padlock-overlay-centered {
            transform: translate(-50%, -50%) scale(0.4);
        }

        & a:link,
        & a:hover,
        & a:active,
        & a:visited {
            color: $input-color-filter;
            cursor: unset;
        }

    }
    .broker-mode {
        & .padlock-overlay a {
            cursor: pointer;
        }
    }
</style>