import { createNamespacedHelpers } from 'vuex';
const general = createNamespacedHelpers("general");
import { cloneDeep } from "lodash";
import { eventBus } from "../main";
import TrackingService from '../services/tracking/tracking.service';
import * as workerTimers from 'worker-timers';
import { dontTrackOnLocalOrRemote } from '../utility/helper-functions';

export default {
    computed: {
        ...general.mapGetters(['watchHistory']),
    },
    data() {
        return {
            progress: 0,
            tracked: 0,
            watched: 0,
            //threshold must be divisible by steps
            threshold: {
                74: 600
            },
            step: {
                74: 60
            }
        }
    },
    methods: {
        trackVideoProgress(perc) {
            if(dontTrackOnLocalOrRemote())
                return;

            const watchHistory = cloneDeep(this.watchHistory)

            // console.log(this.code, this.video.id, perc);

            if(!watchHistory[this.code][this.video.id])
                watchHistory[this.code][this.video.id] = { id: this.video.id };
                
            let thisVideo = watchHistory[this.code][this.video.id];
            thisVideo.progress = perc;

            watchHistory[this.code][this.video.id] = thisVideo;

            this.setWatchHistory(watchHistory);

            eventBus.$emit("progress-update", {
                instanceID: this.$root.instanceID,
                code: this.code,
                video_id: this.video.id,
                progress: perc
            });

        },
        gTrackVideoProgress() {
            if(dontTrackOnLocalOrRemote())
                return;
            // console.log(this.threshold[this.impID] < this.watched, this.threshold, this.step, this.impID);
            if(this.threshold[this.impID] < this.watched || !this.threshold[this.impID] || !this.step[this.impID]) {
                this.clrInterval();
                // console.log("don't track");
                return;
            }
            setTimeout(() => {

            const watchHistory = cloneDeep(this.watchHistory);

            // console.log(this.code, this.video.id, perc);

            if(!watchHistory[this.code][this.video.id])
                watchHistory[this.code][this.video.id] = { id: this.video.id };
                
            let thisVideo = watchHistory[this.code][this.video.id];
            if(thisVideo.gtracked)
                return;

            // thisVideo = {...thisVideo, ...this.video};

            if(!thisVideo.watched)
                thisVideo.watched = 0;

            // console.log("before",thisVideo.watched);

            thisVideo.watched = this.watched;

            // console.log("after",thisVideo.watched);


            if(thisVideo.watched === this.threshold[this.impID]) {
                thisVideo.gtracked = true; // need this flag in case someone abandons the video before the next update is fired
                TrackingService.sendEvent("watched_livestream");
            }
            
            
            watchHistory[this.code][this.video.id] = thisVideo;

            this.setWatchHistory(watchHistory);
        
            }, 2000);

        },
        getGTrackVideoProgress() {
            let thisVideo = this.watchHistory[this.code][this.video.id];
            
            if(!thisVideo)
                return 0;
                
            return thisVideo.watched || 0;
           
        },
        clrInterval() {
            if(this.interval) {
                workerTimers.clearInterval(this.interval);
                this.interval = null;
            }
        }
    }
}