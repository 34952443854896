<template>
    <div class="rule-setup">
        <div class="rule-setup-header">
            <h2>{{ title }}</h2><span class="cntdown">Remaining time: {{ formattedTimeLeft }}</span>
        </div>
        <div class="rule-setup-body">
            <div class="rule-setup-body-subtitle">{{ subtitle }}</div>
            <div class="rule-setup-body-details-box">
                <div>
                    <div class="rule-setup-body-details-box-key">Client Name: </div>
                    <div class="rule-setup-body-details-box-value">{{ profile.name }}</div>
                </div>
                <div>
                    <div class="rule-setup-body-details-box-key">Client Email: </div>
                    <div class="rule-setup-body-details-box-value">{{ profile.email }}</div>
                </div>
                <div>
                    <div class="rule-setup-body-details-box-key">Agent: </div>
                    <div class="rule-setup-body-details-box-value">{{ bm.name }}</div>
                </div>
                <div v-if="currentActiveRules">
                    <div class="rule-setup-body-details-box-key">Current access: </div>
                    <div class="rule-setup-body-details-box-value">
                        <span v-for="curr in currentActiveRules"
                        :key="curr.id">{{ curr.rule.unlock_time_units }} {{ curr.rule.unlock_time_period }} access to {{ getWidgetNames(curr.widgets) }} {{ checkIfQnaShouldBeShown(curr) }} from {{ formatDate(curr.unlocked_at )}} until {{ formatDate(curr.locked_at)}}
                        <br /><br />
                        </span>
                    <!-- <span class="cancellation-warning">(This will be cancelled with the new access)</span> -->
                    </div>
                    <!-- SELECTOR CHANGES -->
                    <!-- <div class="rule-setup-body-details-box-value">{{ currentAccessNumberOfWidgets }}</div> -->
                    <!-- SELECTOR CHANGES ENDS -->
                </div>
            </div>

            <div class="rule-setup-body-divided" style="margin: -15px 0">
                <div class="narrow-div">
                    <base-input
                    elementType="select"
                    :class="{invalid: error.unlock_time_units}"
                    v-model="rule.unlock_time_units"
                    forID="unlock_time_units"
                    :inputClass="'small-font partner-input'"
                    formControlClass=""
                    label="Length"
                    labelClass="small-font u-input-color"
                    :invalid="error.unlock_time_units"
                    :invalidMessage="error.unlock_time_units"
                    @clear-validity="clearValidity"
                    @update="error.unlock_time_units = ''"
                    >
                        <option :value="0" selected disabled hidden>0</option>
                        <option v-for="unit in lenghtArray" 
                        :key="unit"
                        :value="unit">{{unit}}</option>
                    </base-input>
                </div>
                <div class="narrow-div-other" style="margin-left: 25px;">
                    <base-input
                    elementType="select"
                    :class="{invalid: error.unlock_time_period}"
                    v-model="rule.unlock_time_period"
                    forID="unlock_time_units"
                    :inputClass="'small-font partner-input'"
                    formControlClass=""
                    label="Days/weeks/months"
                    labelClass="small-font u-input-color"
                    :invalid="error.unlock_time_period"
                    :invalidMessage="error.unlock_time_period"
                    @clear-validity="clearValidity"
                    @change="updatePeriod"
                    >
                        <option :value="0" selected disabled hidden>Select...</option>
                        <option value="day">Day(s)</option>
                        <option value="week">Week(s)</option>
                        <option value="month">Month(s)</option>
                    </base-input>
                </div>
            </div>

            <div class="rule-setup-body-divided" style="margin: -15px 0;">
                <div class="narrow-div">
                    <base-input
                    elementType="select"
                    :class="{invalid: error.currency}"
                    v-model="rule.currency"
                    forID="unlock_time_units"
                    :inputClass="'small-font partner-input'"
                    formControlClass=""
                    label="Currency"
                    labelClass="small-font u-input-color"
                    :invalid="error.currency"
                    :invalidMessage="error.currency"
                    @clear-validity="clearValidity"
                    @update="error.currency = ''"
                    >
                        <option :value="0" selected disabled hidden>$/£/€</option>
                        <option v-for="unit in currencies" 
                        :key="unit"
                        :value="unit">{{unit}}</option>
                    </base-input>
                </div>
                <div class="narrow-div-other" style="margin-left: 25px;">
                    <base-input
                    elementType="input"
                    :class="{invalid: error.deposit_amount}"
                    inputType="number"
                    v-model="rule.deposit_amount"
                    forID="deposit_amount"
                    :placeholder="'To track average client deposit size'"
                    inputClass="partner-input"
                    formControlClass=""
                    label="Deposit Amount"
                    labelClass="small-font u-input-color"
                    :invalid="error.deposit_amount"
                    :invalidMessage="error.deposit_amount"
                    @clear-validity="clearValidity"
                    ></base-input>
                </div>
                <div class="narrow-div-other" style="margin-left: 25px;">
                    <base-input
                    elementType="input"
                    :class="{invalid: error.account_balance}"
                    inputType="number"
                    v-model="rule.account_balance"
                    forID="account_balance"
                    :placeholder="'To track average client account size'"
                    inputClass="partner-input"
                    formControlClass=""
                    label="Current trading account balance"
                    labelClass="small-font u-input-color"
                    :invalid="error.account_balance"
                    :invalidMessage="error.account_balance"
                    @clear-validity="clearValidity"
                    ></base-input>
                </div>
            </div>
            <label style="margin-top: 28px;" 
                :class="{'u-color-12': error.widget}"
                >Which features to enable?</label>
            <widget-selector
            @widget-selection-changed="impIDs => widgetSelectionChanged(impIDs)"
            @qna-on-changed="val => qna = val"
            :qnaActiveOnPrevRequest="qnaActiveOnPrevRequest"
            :qnaStartingValue="qna"
            :requestedWidgets="requestedWidgets"
            :explainerText="'These are the tools that your clients will have access to and engage with on the analyst-desk.<br />Upon approval, the client will have access to the specific widgets that you include here.'"
            ></widget-selector>

            <div style="margin-top: 20px;" class="error">{{ serverError }}</div>

            <div style="margin-top: 5px; margin-bottom: 15px;">
                <base-button
                mode="o-blue o-height-30"
                :link="false"
                :disabled="isProcessing"
                :showSpinner="isProcessing"
                @click.native="submit"
                >{{ isApproved ? "Update Access" : "Create Access" }}
                </base-button>
            </div>
        </div>
    </div>
</template>

<script>
import Swal from "sweetalert2";
import { createNamespacedHelpers } from 'vuex';
import { eventBus } from '../../../../main';
import _ from "lodash";
import { talkToServer } from '../../../../utility/helper-functions';
import lockUserMixin from '../../../../mixins/lockUserMixin';
import { usersTime } from '../../../../utility/datetime';
import WidgetSelector from './WidgetSelector.vue';
import Constants from '../../../../utility/constants';

const payments = createNamespacedHelpers('payments');
const rules = createNamespacedHelpers('rules');
const general = createNamespacedHelpers('general');

const CONDITIONS = [
    {test: (model) => model.unlock_time_units > 0, message: 'Set lenght', key: "unlock_time_units"},
    {test: (model) => model.unlock_time_period, message: 'Set days/weeks/ months', key: "unlock_time_period"},
    {test: (model) => model.currency, message: 'Set unit', key: "currency"},
    {test: (model) => model.deposit_amount > -1, message: 'Deposit must be 0 or over', key: "deposit_amount"},
    {test: (model) => model.account_balance > -1, message: 'Balance must be 0 or over', key: "account_balance"},
    
];

export default {
    props: ["modal"],
    mixins: [lockUserMixin],
    components: {
        WidgetSelector,
    },
    created() {
        // this.suggestedWidgets = [...this.widgetsToSelectFromForHomeScreen];
        if(this.modal.id) {
            const rule = _.cloneDeep(this.getUserByRuleID(this.modal.id));
            this.profile = rule.user;
            this.rule = rule.rule;
            this.rule.unlock_time_period = 0;
            this.rule.unlock_time_units = 0;
            this.rule.currency = 0;
            this.requestedWidgets = rule.widgets;
            
            if(rule.user.qanda_requested)
                this.qna = true;
            //TEMP
            // this.addWidget('selected', rule.widgets);
            //UNDO AFTER TEMP
            // this.addWidget('selected', this.widgetsToSelectFromForHomeScreen.map(el => el.implementation_id));

        }

        if(!this.profile.account_manager_id)
            this.profile.account_manager_id = this.bms.find(el => el.id === this.profile.account_manager_id)?.id || window.loggedInUserId;

        this.timeLeft = 600;

        const currentActiveRules = this.getAllIndRuleUsers.filter(el => el.rule.rule_status === 'approved' && el.user.id === this.profile.id);

        if(currentActiveRules.length) 
            this.currentActiveRules = currentActiveRules;

        // console.log(currentActiveRules)

        const alreadyActiveQna = currentActiveRules.find(el => el.qnaOn);
        
        if(alreadyActiveQna)
            this.qnaActiveOnPrevRequest = "previous active";
        else if(currentActiveRules.length && !alreadyActiveQna)
            this.qnaActiveOnPrevRequest = "previous inactive";
        else if(!currentActiveRules.length)
            this.qnaActiveOnPrevRequest = "no previous";



    },
    computed: {
        ...payments.mapGetters(["widgetsToSelectFromForHomeScreen"]),
        ...rules.mapGetters(['bms', 'getUserByRuleID', 'getAllIndRuleUsers']),
        ...general.mapGetters(['user', 'entityQnaCustom']),
        lengthType() {
            return this.rule.unlock_time_period;
        },
        lenghtArray() {
            if(this.lengthType === 'day')
                return 30;
            else if(this.lengthType === 'week')
                return 4;
            else
                return 12;
        },
        isApproved() {
            return this.rule.rule_status === 'approved';
        },
        isRequested() {
            return this.rule.rule_status === 'requested';
        },
        isArchived() {
            return this.rule.rule_status === 'archived';
        },
        title() {
            if(this.isApproved)
                return "Edit existing access";
            else if(this.isRequested)
                return "Create client access";
            else if(this.isArchived)
                return "Edit archived request";
            else 
                return "Create new access";
        },
        subtitle() {
            if(this.isApproved)
                return "Client access will restart by updating access details. To maintain original timing, adjust access length";
            else if(this.isRequested)
                return "Client access will begin immediately and they will receive log in details at the email address below."
            else 
                return "";
        },
        bm() {
            return this.bms.find(el => el.id === this.profile.account_manager_id || window.loggegIdUserId) || { name: "N/A"};
        },
        listUserForLockUserEditing() {
            return this.profile;
        },
        currentAccessNumberOfWidgets() {
            if(!this.currentActiveRules)
                return "";

        const qnaProp = "qanda_enabled";
        const qnaValue = this.entityQnaCustom ? this.currentActiveRules.user[qnaProp] : false;
        const qnaText = qnaValue ? " + Q&A" : ""
        const ruleWidgetsLength = this.currentActiveRules.widgets.length;
        if(ruleWidgetsLength === this.widgetsToSelectFromForHomeScreen.length)
            return "all widgets" + qnaText;

        return ruleWidgetsLength + " Widget" + (ruleWidgetsLength > 1 ? "s" : "") + qnaText;

        //SELECTOR CHANGES
        // const qnaProp = "qanda_enabled";
        // const qnaValue = this.entityQnaCustom ? this.currentActiveRules.find(el => el.qnaOn)?.qnaOn : false;
        // const qnaText = qnaValue ? " + Q&A" : ""
        // let ruleWidgets = [];
        // this.currentActiveRules.forEach(el => {
            
        //     ruleWidgets.push(...el.widgets);
        // });
        // // if(ruleWidgetsLength === this.widgetsToSelectFromForHomeScreen.length)
        // //     return "all widgets" + qnaText;
        // console.log(ruleWidgets)
        // return ruleWidgets.length + " Widget" + (ruleWidgets.length > 1 ? "s" : "") + qnaText;
        //--SELECTOR CHANGES ENDS
 
        },

    },
    data() {
        return {
            profile: {
            },
            rule: {},
            error: {},
            currencies: ["USD", "GBP", "EUR", "CHF", "JPY", "CAD", "AUD", "NZD"],
            selectedWidgets: [],
            serverError: "",
            isProcessing: false,
            timeLeft: 0,
            qna: false,
            currentActiveRules: null,
            requestedWidgets: [],
            qnaActiveOnPrevRequest: false
        }
    },
    methods: {
        ...rules.mapActions(["updateUser"]),

        clearValidity(name) {
            delete this.error[name];
        },
        validateData() {
        this.error = {};
        this.serverError = ""
            CONDITIONS.forEach((condition) => {
                if (!condition.test(this.rule)) {
                    this.error[condition.key] = condition.message;
                }
            });
            if(!this.selectedWidgets.length)
                this.error.widget = true;

                // console.log(this.error)
        },
        async submit() {
            this.validateData();

            if(!_.isEmpty(this.error))
                return;

            // if(this.currentActiveRules) {
            //     const rule = _.cloneDeep(this.currentActiveRules);
            //     rule.rule.rule_status = "cancelled";

            //     try {
            //         const response = talkToServer("/b/rules/" + rule.id, "PUT", null, rule.rule, true);

            //         if(!response.success && response.error) {
            //             this.serverError = "Something went wrong, please try again later";
            //         }
                
            //     }
            //     catch(e) {
            //         console.log(e);
            //         this.serverError = "Something went wrong, please try again later"
            //     }
            // }
            
            if(this.serverError)
                return;

            this.isProcessing = true;

            const rule = {
                ...this.rule,
                unlock_time_units: +this.rule.unlock_time_units,
                start_from: "login",
                rule_status: "approved",
                deposit_amount: +this.rule.deposit_amount,
                account_balance: +this.rule.account_balance,
                qanda_enabled: this.qna,
                application_mode: "quantum"
            }

            this.profile = {
                ...this.profile,
                request_status: "approved",
                qanda_enabled: this.qna
            }

            try {
                const ruleCall = talkToServer("/b/rules/" + this.rule.id, "PUT", null, rule, true);
                const ruleWidgetCall = talkToServer("/b/rules-widget/" + this.rule.id, "PUT", null, {
                    widget_implementation_ids: this.selectedWidgets
                }, true);
                const userUpdate = talkToServer("/b/user/" + this.profile.id, "PUT", null, this.profile, true);
               
                return Promise.allSettled([ruleCall, ruleWidgetCall, userUpdate]).then(resp => {
                    const fail = resp.map(el => el.status).find(el => el === 'rejected');
                    this.isProcessing = false;
                    if(!fail) {
                        // const ruleWidgets = this.selectedWidgetImpIDs.map(el => {
                        //     return {
                        //         rule_id: rule.id, 
                        //         widget_implementation_id: el
                        //     }
                        // });

                        // this.updateRuleSet({
                        //     rule: rule,
                        //     ruleWidgets: ruleWidgets
                        // });

                        this.updateUser(this.profile);

                        Swal.fire({
                        title: 'Success',
                        type: 'info',
                        text: 'Client access is now active and we have sent their log in information to ' + this.profile.email,
                        allowOutsideClick: false,
                        showCancelButton: false,
                        confirmButtonColor: '#3B9C83',
                        confirmButtonText: 'OK'
                    }).then(() => {
                        eventBus.$emit("close-modal");
                    });

                    }else{
                        Swal.fire({
                        title: 'Error',
                        type: 'warning',
                        text: 'Some of the settings were not saved. Please refresh the terminal to check the user again',
                        allowOutsideClick: false,
                        showCancelButton: false,
                        confirmButtonColor: '#3B9C83',
                        confirmButtonText: 'OK'
                    }).then(() => {
                    });
                    }
                });
                
            }
            catch(e) {
                console.log(e);
                this.serverError = e.statusText;
            }

            

        },
        
        updatePeriod(event) {
            this.error.unlock_time_period = '';
            const value = event.target.value;
            const units = this.rule.unlock_time_units
            if(value === 'week' && units > 4)
                this.rule.unlock_time_units = 0;
            if(value === 'month' && units > 12)
                this.rule.unlock_time_units = 0;

        },
        formatDate(date) {
            return usersTime(date, true, false, false, {
                dateFormat: "d M Y",
            }, false, this.user.timezone);
        },
        widgetSelectionChanged(impIDs) {
            if(impIDs.length)
                this.error.widget = false;
            this.selectedWidgets = [...impIDs];
            // console.log("selected and saved", impIDs);
        },
        getWidgetNames(widgets) {
            return this.widgetsToSelectFromForHomeScreen.filter(el => widgets.includes(el.implementation_id)).map(el => el.name).join(', ');
        },
        checkIfQnaShouldBeShown(curr) {
            const selectedQnaWidgets = this.widgetsToSelectFromForHomeScreen.filter(el => Constants.QNA_WIDGETS.includes(el.fs_version_of_widget_for_brokers) && curr.widgets.includes(el.implementation_id));
            if(!selectedQnaWidgets.length || !this.entityQnaCustom)
                return '';

            return ' + Q&A';

        }

    },
}
</script>

<style lang="scss">
    .rule-setup {
        letter-spacing: 0.1px;
        color: $input-color;

        & .rule-setup-header {
            display: flex;
            align-items: center;
            margin-top: 25px;
            & h2 {
                font-size: 20px;
                font-weight: 500;
                
                margin: 0;
            }
            & .cntdown {
                font-size: 12px;
                margin-left: 20px;
                color: $core-lightred;
            }
            color: $input-color;
        }

        & .cancellation-warning {
             color: $core-lightred;
        }

        & .rule-setup-body-details-box {
            margin-bottom: 20px;
            font-size: 12px;
            & > div {
                display: flex;
            }

            & .rule-setup-body-details-box-key {
                
                color: $partner-dimmed-text-1;
                text-transform: uppercase;
                flex-basis: 130px;
                min-width: 130px;
                letter-spacing: 0.5px;
            }
            & .rule-setup-body-details-box-value {
                
            }
        }

        & .rule-setup-body {
            position: relative;

            & .rule-setup-body-subtitle {
                color: $partner-dimmed-text-1;
                font-size: 12px;
                margin: 15px 0;
            }

            & .rule-setup-body-divided {
                display: flex;

                & .narrow-div {
                    flex-basis: 100px;
                    min-width: 100px;
                }
                & .narrow-div-other {
                    flex-basis: 100%;
                }
            }

            & .rule-setup-body-rule-selector {
                display: flex;
                align-items: center;

                & span {
                    font-size: 13px;
                    color: $input-color;
                    padding: 0 3px;

                    &:first-child {
                        padding-left: 0;
                    }
                    &:last-child {
                        padding-right: 0;
                    }
                }

                & select, 
                & input {
                    height: 25px;
                    border: 1px solid $partner-border-2;
                    background-color: $partner-background-2;
                    font-size: 13px;
                    border-radius: 4px;
                    max-width: 100px;
                    color: $checkbox-active;
                    padding: 2px 0px;

                    &.invalid {
                        color: $partner-red;
                    }
                }
                & input {
                    padding-left: 4px;
                }
            }

            & .rule-setup-body-user-selector,
            & .rule-setup-body-item-selector {
                border: 1px solid $partner-border-3;
                border-radius: 20px;
                padding: 10px;
                color: $input-color;
                background-color: $onboard-survey-background;
            }

            & .rule-setup-body-user-selector {
                position: absolute;
                left: 20px;
                top: 35px;
                z-index: 1000;

                &.name {
                    top: -100px;
                }

                &.email {
                    top: -14px
                }
                & .rule-setup-body-user-selector-search-results {
                    height: 250px;
                    width: 300px;
                    overflow-y: auto;

                    @include scrollbarDark;

                    & > div a {
                        height: 28px;
                        width: 100%;
                        display: inline-block;
                        font-size: 12px;
                        padding: 5px 10px;
                        text-decoration: none;
                        color: $input-color;

                        &:hover {
                            background-color: $partner-background-4;
                        }
                    }
                }
            }

            & .rule-setup-body-item-selector {
                margin-top: 20px;
                font-size: 12px;
                color: $partner-dimmed-text-1;

                & h4 {
                    font-size: 14px;
                    margin-bottom: 0;
                    margin-top: 0;
                    font-weight: 400;
                }

                & h3 {
                    margin-top: 5px;
                    font-weight: 500;
                    color: $input-color;
                }

                & p {
                    font-weight: 400;
                    
                }

                & .rule-setup-body-item-selector-action {
                    padding: 10px 15px;
                    // background-color: $partner-background-3;
                    border-radius: 4px;
                    height: 35vh;
                    overflow-y: auto;

                    @include scrollbarDark;

                    & .selected-widgets,
                    & .suggested-widgets {
                        background-color: #252525;

                    }
                    & .selected-widgets .widget-tags {
                        background-color: $partner-background-6;
                    }

                }

                & .selected-widgets,
                    & .suggested-widgets {
                        min-height: 48px;
                        padding: 8px;

                        & .widget-tags {
                            color: $input-color;
                            background-color: $partner-background-4;
                            padding: 2px 10px;
                            border-radius: 2px;
                            margin-left: 8px;
                            margin-bottom: 8px;
                            display: inline-block;
                            text-decoration: none;
                        }
                    }

            }
        }
    }
</style>