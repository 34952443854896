<template>
    <div class="embedded-component"
    v-show="noToken && !autoLogin && !isExternalClientMode"
    >
        <h1 class="align-center">{{ title }}</h1>
        <div v-if="promoID && !checkoutLoginMode" class="form-text-div dimmed align-center subtitle">{{ subText }}</div>
        <div v-if="checkoutLoginMode" class="form-text-div align-center subtitle">{{ subText }}</div>
        <span id="pw-reveal" class="pw-reveal">
            <a @click="togglePwVisibility" v-if="schema.fields[1].inputType === 'password'"><font-awesome-icon :icon="['fa', 'eye']"/></a>
            <a @click="togglePwVisibility" v-else><font-awesome-icon :icon="['fa', 'eye-slash']"/></a>
        </span>
        <form @submit.prevent class="container">
            <vue-form-generator ref="form"
            :schema="schema"
            :model="model"
            :options="formOptions"
            @model-updated="modelUpdated"
            ></vue-form-generator>
            <div class="form-text-div align-right"><router-link to="reset" class="onboard-link dimmed">Forgotten Password</router-link></div>
            <div class="button-div">
                <base-button
                mode="o-blue fullwidth"
                :link="false"
                :showSpinner="showSpinner"
                @click.native="submitForm"
                >Login</base-button>
            </div>
            <!--<div class="form-text-div">{{ noAccount }} <router-link :to="registerLink" class="onboard-link">Register Now</router-link></div>-->
        </form>
    </div>
</template>

<script>
import VueFormGenerator from "vue-form-generator";
import { createNamespacedHelpers } from 'vuex';
import { eventBus } from '../../../main';
import bootUpMixin from '../../../mixins/bootUpMixin';
import OnBoardMixin from '../../../mixins/onBoardMixin';
import TrackingService from '../../../services/tracking/tracking.service';
import { getParameterByName, isBrokerManagementMode, isExternalClientMode, isFreeMTARoute, talkToServer } from '../../../utility/helper-functions';
const general = createNamespacedHelpers("general");

export default {
    props: ["cachedEmail", "promoID", "specialLink"],
    mixins: [OnBoardMixin, bootUpMixin],
    created() {
        this.autoLogin = this.externalUserAutoLogin || window.bootUp.remoteAccess;
        if(isBrokerManagementMode())
            this.$router.push("partner-register");
        if(isExternalClientMode() && !this.autoLogin) {
            this.$router.push("/home");
        }
            
        
        let user = {}
        if (this.autoLogin && !window.bootUp.remoteAccess) {
             user = {
                ...window.bootUp.user,
                name: window.bootUp.user.firstName,
                refno: window.bootUp.refno
             };
            //  {
            //     refno: window.bootUp.refno,
            //     userref: window.bootUp.user.userref
            // }
            // if (window.bootUp.user.name)
            //     user.name = window.bootUp.user.name;
            // if (window.bootUp.user.email)
            //     user.email = window.bootUp.user.email;
            // if (window.bootUp.user.timezone)
            //     user.timezone = window.bootUp.user.timezone;
        }

        if(this.cachedEmail)
            this.model.email = this.cachedEmail;

        if(this.autoLogin)
            this.login(user);

        

    },
    mounted() {
        document.querySelector('.label-transition.password').appendChild(document.getElementById('pw-reveal'));
    },
    computed: {
        ...general.mapState(["checkoutLoginMode"]),
        ...general.mapGetters(["externalUserAutoLogin", 'singleWidgetMode']),
        isExternalClientMode() {
            return isExternalClientMode();
        },
        title() {
            const promoIsPlan = window.bootUp.promo?.product_type === 'plan';
            const pretag = 'Log in';
            const posttag = promoIsPlan ? ' subscription' : '';
            const prename = promoIsPlan ? ' your ' : ' the ';

            if(isFreeMTARoute(this.promoID)) {
                return pretag + ' to get' + prename + window.bootUp.promo?.name;
            }

            if(this.promoID) {
                if(this.checkoutLoginMode)
                    return pretag;
                else
                    return pretag + ' to purchase' + prename + window.bootUp.promo?.name + posttag;
            }
            else if(this.specialLink === '/showcaselogin') {
                return pretag + ' to view our showcase'
            }
            return pretag;
        },
        subText() {
            const promoIsPlan = window.bootUp.promo?.product_type === 'plan';
            const pretag = 'Log in';
            const posttag = promoIsPlan ? ' subscription' : '';
            const prename = promoIsPlan ? ' your ' : ' the ';

            if(this.checkoutLoginMode)
                return pretag + ' to purchase' + prename + window.bootUp.promo?.name + posttag;
            else if(isFreeMTARoute(this.promoID)) {
                return "So that we can send your free training";
            }
            else if(this.promoID) {
                return 'When you click login below, you\'ll land on the checkout page';
            }
            return '';
        },
        noAccount() {
            if(this.promoID) {
                return 'Don\'t have an account?';
            }
            return 'New to Financial Source?';
        },
        registerLink() {
            if(this.checkoutLoginMode)
                return "/check-out";
                
            return "/start-here";
        }
    },
    data() {
        return {
            autoLogin: false,
            registrationAllowed: window.bootUp.canRegisterUsersViaApi,
            noToken: !localStorage.getItem("token"),
            model: {
                email: '',
                password: ''
            },
            schema: {
                fields: [
                    {
                        type: "input",
                        inputType: "email",
                        label: "Email",
                        model: "email",
                        readonly: false,
                        featured: true,
                        required: true,
                        disabled: false,
                        placeholder: "Email *",
                        styleClasses: "label-transition",
                        validator: VueFormGenerator.validators.required
                    },
                    {
                            
                        type: "input",
                        inputType: "password",
                        label: "Password",
                        model: "password",
                        min: 6,
                        featured: true,
                        required: true,
                        placeholder: "Password *",
                        styleClasses: "label-transition password",
                        validator: VueFormGenerator.validators.string
                
                    }
                ],
            },
            formOptions: {
                validateAfterLoad: false,
                validateAfterChanged: true,
                validateAsync: false
            },
            showSpinner: false
        }
    },
    methods: {
        ...general.mapActions(['setComponentAfterRefresh']),
        modelUpdated() {
            return;
        },
        submitForm() {

            const formData = {
                email: this.model.email,
                password: this.model.password,
                refno: window.bootUp.refno,
            }

            if(this.model.email && this.model.password)
                this.login(formData);
        },
        login(data) {
            let path;
            let loginData = null;
            let singleWidgetCacheParam = '';

            if(this.autoLogin && window.bootUp.remoteAccess) {
                localStorage.setItem("remoteAccess", 1);
                path = "token";
                loginData = {
                    ara: getParameterByName('ara'),
                    t: getParameterByName('t'),
                    refno: window.bootUp.refno
                };

                this.$router.replace({'query': null});

                //double token call bug fix
                if(!loginData.ara)
                    return;
            }
            else if(this.autoLogin) {
                path = "token";
                loginData = data;
                if(this.singleWidgetMode)
                    singleWidgetCacheParam = '&widget_implementation_id=' + this.$root.widgetId;
            }
            else {
                path = "login";
                loginData = data;
            }

            // console.log("ready to post to /" + path);
            this.showSpinner = true;
            talkToServer(`/${path + window.bootUp.cacheParams + singleWidgetCacheParam}`, 'POST', null, loginData).then(response => {
                // console.log('any response', response);
                this.showSpinner = false;
                if (!response.success && response.error) {
                    this.$emit('emit-error', {
                        error: response.error,
                        email: this.model.email
                    });
                } else {
                    // console.log("should send the event next");
                    TrackingService.sendEvent("login", { method: "login-form" });
                    // console.log("save user data", response);
                    this.setComponentAfterRefresh("home");
                    this.saveUserData(response);
                    if(this.autoLogin) {
                        eventBus.$emit('register-login-successful', {
                            instanceID: this.$root.instanceID,
                            data: response.data,
                            isLogin: true
                        });
                        return;
                    }
                        
                    this.$emit('register-login-successful', {
                        data: response.data,
                        isLogin: true
                    });
                    // this.openTourGuide();
                }

            })
            .catch(e => {
                // console.log(e);
                this.showSpinner = false;
                if (e.status === 401) {
                    this.$emit('emit-error', {
                        error: "Username or password not correct",
                        email: this.model.email
                    });
                } 
            })
        },
        togglePwVisibility() {
            let type = this.schema.fields[1].inputType;
            if(type === 'password')
                type = 'text';
            else if(type === 'text')
                type = 'password';

            this.schema.fields[1].inputType = type;
        }
    },
    beforeRouteLeave(to, from, next) {
        this.$emit('emit-error', {
            error: "",
            // email: this.model.email
        });
        next();
    }
}
</script>