<template>
    <div class="video-preview"
    :class="origin"
    >
        <div class="video-preview-label multi-item">
            <div>
                <tab-menu 
                v-if="tabs && tabs.length"
                style="padding: 0 15px;"
                type="playlist"
                :active="activeTab"
                :tabs="tabs"
                @menu-item-clicked="tabChanged"
                ></tab-menu>
                <div v-else
                style="padding: 0 0 3px; margin-top: -5px;"
                >Playlist</div>
            </div>
        </div>
        <div 
        class="video-preview-main"
        :style="listStyle"
        ref="scrollable"
        :id="'videos-' + randomNum + '-' + layoutID"
        >
            <div v-if="noItems && resultsReady">
                <div class="no-more-items"><font-awesome-icon :icon="['fas', 'exclamation-triangle']" /> {{ noItemMessage }}</div>
            </div>

            <div 
            v-else
            class="video-preview-main__list scrollable"
            ref="scroller" 
            :id="'videos-inner-' + randomNum + '-' + layoutID"
            style="position: relative;" >

                <div v-show="activeTab !== 'questions' && activeTab !== 'resources' && activeTab !== 'downloads'">
                    <videos-perday
                    v-for="(arr, objKey, index) in sortedItems"
                    :firstTemp="firstTemp"
                    :key="objKey"
                    :index="index"
                    :objKey="objKey"
                    :videosPerday="arr"
                    :widgetCode="widgetCode"
                    :topLevelCategory="topLevelCategory"
                    :impID="impID"
                    :isLocked="isLocked"
                    :activeTab="activeTab"
                    @render-video="renderVideo"
                    ></videos-perday>

                    <loading-spinner v-if="fetchMode" style="height:100px;"></loading-spinner>
                    <div v-if="noMoreItems" class="no-more-items"><font-awesome-icon :icon="['fas', 'exclamation-triangle']" /> No more items</div>
                </div>
                <div v-show="activeTab === 'questions'"
                >
                    <live-question
                    v-for="(question, i) in questions"
                    :key="question.id"
                    :question="question"
                    :isLastSection="i === questions.filter(el => el.type === 'section').length - 1"
                    :layoutID="layoutID"
                    ></live-question>
                </div>
                <div v-show="activeTab === 'resources'"
                class="video-resources"
                >
                    <vidlib-resource
                    v-if="isThereResource"
                    :resource="resource"
                    >
                    </vidlib-resource>
                    <!-- <div v-if="resource && resource.resource"
                    >
                        <img v-if="resourceType === 'image' " :src="resourceLink" alt="Video Image">
                        <a v-else-if="resourceType === 'pdf' " href="javascript:void(0);" @click="openPDF"><font-awesome-icon :icon="['fas', 'file-pdf']" />Open PDF</a>
                   </div> -->
                </div>
                <div v-show="activeTab === 'downloads'"
                class="video-resources"
                >
                    <vidlib-resource
                    v-if="true"
                    :resource="courseRootText"
                    >
                    </vidlib-resource>
                </div>
                
                
            </div>

        </div>
    </div>
</template>

<script>

import VideosPerday from './VideosPerday.vue';
import ScrollMixin from '../../../../mixins/scrollMixins';
import LoadingSpinner from '../../../ui/LoadingSpinner';
import TabMenu from '../../../ui/TabMenu.vue';
import LiveQuestion from './LiveQuestion.vue';
import { isEmptyObject, randomizeBetween } from '../../../../utility/helper-functions';
import VidlibResource from './VidlibResource.vue';
import { createNamespacedHelpers } from 'vuex';

const widgets = createNamespacedHelpers("widget");



export default {
    props: ["tabs", "defaultActiveTab", "sortedItems","resultsReady", "noMoreItems","layoutID","newVideo", "noItemMessage", "listStyle", "questions", "resource", "widgetCode", "topLevelCategory","impID", "courseRootText", "firstTemp", "origin", "isLocked"],
    mixins: [ScrollMixin],
    components: {
        LiveQuestion,
        LoadingSpinner,
        TabMenu,
        VideosPerday,
        VidlibResource
    },
    created() {
        this.activeTab = this.defaultActiveTab;
        this.tabChanged({id: this.defaultActiveTab});
    },
    mounted() { 
        this.setupScrollListener();
    },
    beforeDestroy() {
        this.removeScrollListener();
    },
    computed: {
        ...widgets.mapGetters(['getWidgetByImplementationId']),
        showBadge() {
			return this.newHeadline && this.isScrolledDown;
		},
        randomNum() {
            return randomizeBetween(1000,9999);
        },
        noItems() {
            return isEmptyObject(this.sortedItems);
        },
        renderedLive() {
            return this.renderedObj.status === 'live';
        },
        isThereResource() {
            return this.resource && this.resource.text;
        },
        
    },
    data() {
        return {
            activeTab: '',
            scrollToOptions: {
				el: 'videos-inner-' + this.randomNum + '-' + this.layoutID,
				container: 'videos-' + this.randomNum + '-' + this.layoutID,
				duration: 500,
				lazy: false,
				easing: 'linear',
				offset: 0,
				force: true,
				x: false,
				y: true
			},
            fetchMode: true,
            renderedObj: false,
            question: '',
        }
    },
    methods: {
        onScroll() {
            const style = this.$store.getters['widget/getWidgetByImplementationId'](this.impID)?.training_centre_widget_style;
            if(this.widgetCode === 'TCVIDEO' && style !== 'Q&A')
                return;
                
            const scrollTop = this.$refs.scrollable.scrollTop;
            const height = this.$refs.scrollable.getBoundingClientRect().height;
            const scrollHeight = this.$refs.scrollable.scrollHeight;

            // console.log(this.$refs.scrollable.scrollTop, this.$refs.scrollable.getBoundingClientRect().height, this.$refs.scrollable.scrollHeight);
            
            if ((scrollTop + height >= scrollHeight - 200) &&
            !this.fetchMode && 
            !this.noMoreItems) {                 
                // console.log("scroll-down-enough");
                this.fetchMode = true;
                this.$emit("load-more", this.activeTab);
            }                
            
            if(scrollTop > 100) {
                // console.log("scrolled down");
                this.isScrolledDown = true;
            }else{
                this.isScrolledDown = false;
                // console.log("scrolled up");
                this.$emit("new-video-viewed");
            }
        },
        renderVideo(obj) {
            this.renderedObj = obj;

            this.$emit("render-video", obj);
        },
        tabChanged(obj) {
            const fromTab = this.activeTab;
            this.activeTab = obj.id;
            if(obj.id === 'questions' || fromTab === 'questions' && obj.id === "archiveVideos")
                return;

            this.$emit("playlist-changed", obj.id);
        },
       
    },
    watch: {
        resultsReady(val) {
            this.fetchMode = !val;
        },
        defaultActiveTab(val) {
            this.activeTab = val;
            this.tabChanged({id: val});
        },
        // resource: {
        //     handler(nVal) {
        //         console.log(nVal);
        //         if(!_.isEmpty(nVal)) {
        //             this.resouce
        //         }
        //     },
        //     deep: true
        // }
    }

}
</script>