
import { usersTime } from '../utility/datetime';
import { frontFacingAssets } from '../utility/helper-functions';

export default {
    created() {

    },
    computed: {
        flagSrc() {
            let src = frontFacingAssets() + this.bank.central_bank_code + '.png';
            return src;
        },
        formattedRate() {
            const rate = this.bank.current_rate;
            return rate || rate === 0 ? parseFloat(rate).toFixed(3) +'%' : 'Unset';
        },
        formattedProbability() {
            if(this.checkProbAndChangeBy()) {
                const id = this.showBiggerBasedOnProbability(this.bank.sc1_probability, this.bank.sc2_probability);
                const prop = 'sc' + id + '_probability';
                return this.bank[prop].toFixed(0) + '%';
            }
                
            return !isNaN(this.bank.probability) && this.bank.probability ? (+this.bank.probability).toFixed(0) + '%' : 'Unset';
        },
        formattedChangeBy() {
            if(this.checkProbAndChangeBy()) {
                const id = this.showBiggerBasedOnProbability(this.bank.sc1_probability, this.bank.sc2_probability);
                const prop = 'sc' + id + '_change_by';
                return (this.bank[prop] - this.bank.current_rate).toFixed(3) + '%';
            }
            
            return !isNaN(this.bank.change_by) && this.bank.change_by ? parseFloat(this.bank.change_by).toFixed(3) + '%' : '-';
        },
        redOrGreen() {
            // console.log(this.bank.next_expected_move)
            const action = this.bank.next_expected_move;
            if(action === "Hike")
                return "u-color-13";
            else if(action === "Cut")
                return "u-color-12";
            else
                return "";
        },
        nextMeetingDay() {
            const date = usersTime(this.bank.next_meeting_date, true, false, false, {
                dateFormat: "d M Y",
            }, "UTC");
            return {
                dm: date.substring(0,6),
                y: date.substring(7,11)
            }
        },
        action() {
            if(this.bank.next_expected_move.toLowerCase() === 'no change')
                return "Hold";

            return this.bank.next_expected_move;
        },
        isThereDailyData() {
            return this.bank?.irpt_daily?.dates?.length && this.bank?.irpt_daily?.rates?.length;
        },
        isThereStandardData() {
            return this.bank?.irpt_standard?.current?.dates?.length && this.bank?.irpt_standard?.current?.rates?.length &&
            this.bank?.irpt_standard?.week_ago?.dates?.length && this.bank?.irpt_standard?.week_ago?.rates?.length;
        },
        isThereProbabilityData() {
            return this.bank?.sc1_change_by || this.bank?.sc2_change_by ||
            this.bank?.sc1_probability || this.bank?.sc2_probability;
        }
    },
    data() {
        return {
            dailyDates: [],
            dailyRates: [],
            standardData: {
                current: {
                    dates:[],
                    rates: []
                },
                week_ago: {
                    dates:[],
                    rates: []
                },
            }
        }
    },
    methods: {
        isValidValue(val) {
            if(val || val === 0)
                return true;

            return false;
        },
        checkProbAndChangeBy() {
            if(this.isValidValue(this.bank.sc1_change_by) &&
            this.isValidValue(this.bank.sc1_probability) &&
            this.isValidValue(this.bank.sc2_change_by) &&
            this.isValidValue(this.bank.sc2_probability))
                return true;

            return false;
        },
        showBiggerBasedOnProbability(sc1_probability, sc2_probability) {
            if(sc1_probability >= sc2_probability)
                return 1;

            return 2;
        },
    }
}