<template>
    <div :class="fontsize">
        <div 
        class="main-video-grid course"
        :style="innerElementHeight"
        >
            <div v-if="resultsReady && bulkItems.videos && !bulkItems.videos.length" class="no-more-items"><font-awesome-icon :icon="['fas', 'exclamation-triangle']" /> No videos available</div>
            <div v-else class="main-video-grid-center">
                <div v-for="video in videoInPlayer"
                :key="video.src">
                    <component 
                    :is="'video-player'" 
                    :video="video" 
                    :key="video.src"
                    :code="details.widgetCode"
                    :videoAutoPlay="videoAutoPlay"
                    :impID="impID"
                    @allow-player-render="() => {}"
                    :isLocked="isLocked"
                    ></component>
                </div>

                <div class="video-selection">
                
                    <video-list
                    :origin="details.widgetCode"
                    :tabs="tabs"
                    :defaultActiveTab="'videos'"
                    :resource="currentResourcesForVideo"
                    :courseRootText="courseRootText"
                    :listStyle="playlistHeight"
                    :noItemMessage="'No recordings found'"
                    :sortedItems="sortedItems"
                    :resultsReady="resultsReady"
                    :withUpcoming="false"
                    :widgetCode="details.widgetCode"
                    :topLevelCategory="this.topLevelCategories[0]"
                    :layoutID="details.layoutID"
                    :impID="details.implementation_id"
                    :isLocked="isLocked"
                    @render-video="renderVideo"
                    ></video-list>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import VideoCourseMixin from '../../mixins/videoCourseMixin';
import VideoList from './components/VideoPlayers/VideoList.vue';
import VideoPlayer from './components/VideoPlayers/VideoPlayer.vue';
export default {
    mixins: [VideoCourseMixin],
    components: {
        VideoList,
        VideoPlayer
    },
    computed: {
        tabs() { 
            return [
                {
                    id: "videos",
                    name: "Videos"
                },
                {
                    id: "resources",
                    name: "Resources",
                    canDisable: true,
                    disabled: !this.currentResourcesForVideo
                },
                {
                    id: "downloads",
                    name: "Downloads",
                    canDisable: true,
                    disabled: !this.courseRootText
                },
            ];
        },
        
    }
}
</script>