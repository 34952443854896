<template>
    <div class="setup-screen">
        <div class="setup-screen-container">
            <div class="setup-screen-header">
                <h2 style="color:white;">Follow the simple steps to get set-up</h2>
            </div>
            <div class="setup-screen-main">
                <div class="setup-screen-main-cards">

                    <setup-card
                    v-for="card in setupCards"
                    :key="card.id"
                    :card="card"></setup-card>
                </div>
            </div>
        </div>
    </div>
    
</template>

<script>
import SetupCard from './components/SetupCard.vue';

export default {
    components: {
        SetupCard
    },
    data() {
        return {
            setupCards: [
                {
                    step: 1,
                    title: "Activate analyst-desk software",
                    description: "To start improving conversions we need to install our Analyst-Desk code wherever your clients log in to manage their account with you (usually the client portal).",
                    buttonText: "Install software",
                    dynamicComponent: "partner-details",
                    details: {
                        data: [],
                        noDataMessage: "Click the Install Software button to activate Analyst Desk"
                    },
                    completed: false,
                    current: false
                },
                {
                    step: 2,
                    title: "Create access rules",
                    description: "Decide which clients can use the analyst desk in your client portal. Test different rules to optimise for client conversion or regulatory compliance.",
                    buttonText: "Create a rule",
                    dynamicComponent: "partner-details",
                    details: {
                        data: [],
                        noDataMessage: "Click on the Create A Rule to add new rules"
                    },
                    modal: {
                        component: "rule-setup",
                        icon: 'scroll',
                        title: 'Create a rule'
                    },
                    completed: false,
                    current: true
                },
                {
                    step: 3,
                    title: "Activate free trial",
                    description: "Calculate actual client usage, generate insights and see results before purchasing your subscription.",
                    buttonText: "Activate trial",
                    completed: false,
                    current: false
                },
                {
                    step: 4,
                    title: "Launch conversion campaign",
                    description: "'Start converting more clients. Our team of conversion experts will help you step by step.",
                    buttonText: "Launch campaign",
                    completed: false,
                    current: false
                }
            ]
        }
    }
}
</script>

<style lang="scss">
    .setup-screen {

        background-color: $onboard-survey-background;
        padding-bottom: 40px;
        @include respond(lg) {
            height: calc(100vh - 100px);
            padding-bottom: 0;
        }

        & .setup-screen-container {
            margin-left: 20px;
            margin-right: 20px;
            color: $input-color;

            @include respond(lg) {
                max-width: 992px;
                margin: 0 auto;
            } 
            @include respond(xl) {
                max-width: 1200px;
                margin: 0 auto;
            } 

            & .setup-screen-header {
                text-align: center;
                letter-spacing: 0.1px;
                margin: 40px 0;

                h2 {
                    font-weight: 500;
                    font-size: 24px;
                    margin: 10px 0;
                }
            }

            & .setup-screen-main {

                &-cards {
                    display: grid;
                    gap: 20px;
                    grid-template-columns: 1fr;
                    @include respond(lg) {
                    grid-template-columns: 1fr 1fr;

                }

            } 
            }

        }

        & .setup-card {
            background-color: $onboard-survey-card-background;
            border: 1px solid $partner-border-2;
            border-radius: 20px;
            position: relative;

            & .setup-card-inner {
                padding: 15px 20px;
            }

            & .setup-card-overlay {
                position: absolute;
                width: 100%;
                height: 100%;
                background-color: rgba($partner-background-1, 0.65);
                border-radius: 20px;
            }

            & .setup-card-header-top {
                display: flex;
                justify-content: space-between;
                margin-bottom: 10px;
            }

            & .setup-card-header-top-left-step {
                font-size: 14px;
                font-weight: 500;
                letter-spacing: 1px;
                text-transform: uppercase;
                color: $partner-red;

                &.completed {
                    color: $onboard-green;
                }
            }

            & .setup-card-header-top-left-title {
                letter-spacing: 0.1px;
            }

            & .setup-card-header-bottom {
                font-size: 14px;
                letter-spacing: 0.1px;
                color: $partner-dimmed-text-1;

                @include respond(xl) {
                    height: 63px;
                    margin: 0 auto;
                } 
            }

            & .setup-card-main {
                margin-top: 15px;
            }

            & .inner-card {
                padding: 12px;
                border: 1px solid $partner-border-2;
                border-radius: 10px;

                &-prompt {

                    & a {
                        display: flex;
                        justify-content: space-between;
                        color: $input-color;
                        text-decoration: none;
                        font-size: 14px;
                    }
                }

                &-note {
                    font-size: 12px;
                    color: $partner-dimmed-text-1;
                    
                    & a {
                        color: $checkbox-active;
                    }
                }

                & .partner-card {
                    
                    &-input {
                        margin: 12px 0;
                        display: flex;
                        align-items: center;

                        &-dynamic {
                            background-color: $partner-background-3;
                            border-radius: 10px;
                            padding: 8px;
                            height: 37px;
                            font-size: 14px;
                            color: $partner-dimmed-text-1;
                            display: inline-block;
                            overflow: hidden;
                            white-space: nowrap;
                            text-overflow: ellipsis;

                            &.url-set-up {
                                max-width: 150px;
                                color: $input-color;

                                @include respond(sm) {
                                    max-width: 300px;
                                }

                                @include respond(md) {
                                    max-width: 420px;
                                }

                                @include respond(lg) {
                                    max-width: 280px;
                                }

                                @include respond(xl) {
                                    max-width: 380px;
                                }
                            }
                        }

                        &-separator {
                            border-bottom: 1px solid $partner-border-2;
                        }
                    }

                    &-confirmation {
                        background-color: $partner-background-4;
                        color: $onboard-green;
                        font-size: 14px;
                        margin-left: 30px;
                        border-radius: 2px;
                        padding: 3px 5px;
                        font-weight: 500;
                    }
                }
            }

        }
    }
</style>