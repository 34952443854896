<template>
    <div class="partner-funnel">
        <div class="partner-funnel-main">
            <transition
            mode="out-in"
            enter-active-class="animate__animated animate__fadeIn"
            leave-active-class="animate__animated animate__fadeOut">
                <div>
                    <component
                    :is="currentComponent"
                    @register-login-successful="registerLoginSuccessful"
                    >
                    </component>
                    <!-- <div class="partner-redirect">Haven't got an account? <router-link to="/partner-register">Register</router-link></div> -->
                </div>
            </transition>
        </div>
    </div>
</template>

<script>
import PartnerSignin from './PartnerSignin.vue';

export default {
    components: {
        PartnerSignin
    },
    data() {
        return {
            currentComponent: 'PartnerSignin',
        }
    },
    methods: {
        registerLoginSuccessful(val) {
            // console.log(val);
            this.$emit("register-login-successful", val);
        }
    }
}
</script>