<template>
    <div class="client-profile-overview">
        <div class="client-profile-overview-header">
            
            <div class="client-profile-overview-header-title">{{ title }}</div>
            <!-- <div class="client-profile-overview-header-subtitle">{{ subtitle }}</div> -->
            <div class="client-profile-overview-header-controls">
                <div class="client-profile-overview-header-controls-search">
                    
                </div>
                <div class="client-profile-overview-header-controls-sort">
                </div>
                <div class="client-profile-overview-header-controls-action">
                    <div>
                        <base-button
                        mode="green o-height-40"
                        :link="false"
                        @click.native="addTeamMate"
                        ><font-awesome-icon :icon="['fas', 'plus']"/> Add Team Mate
                        </base-button>
                    </div>
                    
                </div>
            </div>

        </div>
        <div>
            <team-profile-table
            :rules="sortedData"
            :sortStatus="sortStatus"
            >
            </team-profile-table>
        </div>
    </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';
import TeamProfileTable from './TeamProfileTable.vue';
import _ from "lodash";
import { eventBus } from '../../../../main';

const general = createNamespacedHelpers("general");
const rules = createNamespacedHelpers("rules");

export default {
    props: ["activeList"],
    components: {
        TeamProfileTable,
    },
    created() {
        this.sortStatus = this.activeList;
        this.sortEntries();
    },
    computed: {
        ...general.mapGetters(['user']),
        ...rules.mapGetters(['rules', 'userList', 'bms', "getAllIndRuleUsers"]),
        timezone() {
            return this.user.timezone;
        },
        title() {
            if(this.sortStatus === 'approved')
                return 'Approved Team Mates';
            else                 
                return 'Pending Invites';
          
        },
        // subtitle() {
        //     if(this.sortStatus === 'approved')
        //         return 'A list of every access request that has been granted by your team';
        //     else if(this.sortStatus === 'requested')
        //         return 'The clients below have requested access to the analyst desk.'
        //     else
        //         return '';
        // }
    },
    data() {
        return {
            sortedData: [],
            sortStatus: "",
            searchMode: false
        }
    }, 
    methods: {
        sortEntries() {
            let rules = _.cloneDeep(this.getAllIndRuleUsers).filter(el => el.user.bm);

            if(this.sortStatus === 'approved')
                rules = _.orderBy(rules.filter(el => el.user.first_logged_in_at, el => el.created_at, "desc"));
            else
                rules = _.orderBy(rules.filter(el => !el.user.first_logged_in_at, el => el.created_at, "desc"));

            this.sortedData = rules;
        },
        addTeamMate() {

            const modal = {
                component: "create-access",
                bm: true,
                title: 'Profile Manager',
                icon: 'scroll',
                user_id: null
            }

            eventBus.$emit('open-side-modal', modal);

        },
    },
    watch: {
        activeList(val) {
            this.sortStatus = val;
        },
        sortStatus() {
            this.sortEntries();
        },
        userList: {
            handler: function() {
                this.sortEntries();
            },
            deep: true
        },
        rules: {
            handler: function() {
                this.sortEntries();
            },
            deep: true
        }
    }
}
</script>

<style lang="scss">
    
</style>