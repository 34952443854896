<template>
    <div 
    class="dark-section">

        <div class="e-client-container">
            <external-client-nav></external-client-nav>
            <img :src="unpluggedLogo" alt="unplugged logo" style="width: 100%; margin-top: 60px;">
            <div class="unplugged-service-message">
                <div class="oops-div">Oops!</div>
                <div class="inactive-message">The analyst-desk system is not active at the moment. Please contact the support team to reactivate it.</div>
            </div>
            
        </div>
    </div>
</template>

<script>
import { frontFacingAssets } from '../../../utility/helper-functions';
import ExternalClientNav from './ExternalClientNav.vue';
export default {
    components: {
        ExternalClientNav
    },
    computed: {
        unpluggedLogo() {
            return frontFacingAssets() + 'unplugged' + '.svg';
        }
    }
}
</script>