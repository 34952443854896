<template>
    <div class="fullpage-section"
    :style="elements.length ? 'margin-top: 0' : ''">
        <div class="fullpage-section-header" v-if="elements.length || forceShowHeader">
            <div class="fullpage-section-header--title">{{ title }}
                <VueCustomTooltip 
                v-if="tooltipText"
                :label="tooltipText" 
                :multiline="true"
                position="is-bottom"
                class="left-tick"
                >
                    <font-awesome-icon :icon="['fas', 'question-circle']" />
                </VueCustomTooltip>
            </div>
            <div class="fullpage-section-header--more">
                <!-- <a href="javascript:void(0);">View all {{ numOfDashboards }} dashboards <font-awesome-icon :icon="['fas', 'arrow-right']" /></a> -->
                </div>

        </div>

        <!-- Dashboards -->

        <div class="fullpage-section-body"
        v-if="name === 'dashboards'">
            <div 
            class="fullpage-section-body--top-row"
            :class="cardContainerClasses"
            >
                <section-card
                v-for="item in topRow"
                :key="item.id"
                :cardType="name"
                :item="item"
                ></section-card>
                <section-card
                :key="newDashboardItem.id"
                :cardType="name"
                :item="newDashboardItem"
                ></section-card>
            </div>
            <div
            v-if="hiddenRow.length"
            class="fullpage-section-body--slidedown"
            >
                <vue-slide-up-down :active="isSlideDownOpen" :duration="250" class="vue-slide-up-down" :class="slideDownClasses">
                    <section-card
                    v-for="item in hiddenRow"
                    :key="item.id"
                    :cardType="name"
                    :item="item"
                    ></section-card>
              
                </vue-slide-up-down>
                <div class="show-more">
                    <span class="show-more-line"><span class="show-more-line-2"></span></span>
                    <span class="show-more-prompt"><a href="javascript:void(0)" @click="showMore">Show {{ isSlideDownOpen ? 'less' : 'more'}} {{ name }} <font-awesome-icon :icon="['fa', arrowUpOrDown]" /></a></span>
                    <span class="show-more-line"><span class="show-more-line-2"></span></span>
                </div>
            </div>
        </div>

        <!-- Products -->

        <horizontal-scrollable
        v-if="name === 'products'">
            <section-card
            v-for="item in elements"
            :key="item.code === 'TCVIDEO' || item.code === 'TCSTREAM' ? item.implementation_id : item.id"
            :cardType="name"
            :item="item"
            ></section-card>
        </horizontal-scrollable>
                                    
        <!-- Widgets -->

        <div class="fullpage-section-body"
        v-if="name === 'widgets'"
        >
            <div 
            class="fullpage-section-body--top-row"
            :class="cardContainerClasses"
            v-if="elements.length"
            >
                <section-card
                v-for="item in elements"
                :key="item.implementation_id"
                :cardType="name"
                :item="item"
                :noActions="false"
                :widgetDirectAddition="false"
                ></section-card>
            </div>
            <div v-else>
                You have no {{ showcaseMode ? 'showcases' : 'indicators'}} at the moment.
            </div>
        </div>

        <!-- Live Streams, Vid Libs -->

        <div v-if="name === 'videotiles'"
        >
            <horizontal-scrollable
            :isHorizontal="false"
            :height="height"
            :handler="handler"
            >
                <div class="fullpage-section-body--top-row tiles big">
                    <section-card
                    v-for="item in elements"
                    :key="item.implementation_id"
                    :details="details"
                    :cardType="name"
                    :item="item"
                    ></section-card>
                </div>
                <div v-if="noMoreItems" class="no-more-items" style="font-size: 14px;"><font-awesome-icon :icon="['fas', 'exclamation-triangle']" /> {{ elements.length ? 'No more items' : 'No matches'}}</div>
            </horizontal-scrollable>
        </div>

        <horizontal-scrollable
        v-else-if="!tiles && tcType">
            <section-card
            v-for="item in elements"
            :key="item.implementation_id"
            :cardType="name"
            :item="item"
            ></section-card>
        </horizontal-scrollable>

        <div v-else-if="tiles && tcType" 
        class="fullpage-section-body--top-row tiles small-gap"
        >
            <section-card
            v-for="item in elements"
            :key="item.implementation_id"
            :cardType="name"
            :item="item"
            ></section-card>
        </div>


    </div>
</template>

<script>
import SectionCard from './SectionCard.vue';
import HorizontalScrollable from '../ui/HorizontalScrollable.vue';
import { createNamespacedHelpers } from 'vuex';
const general = createNamespacedHelpers("general");

export default {
    props: {
        name: {
            type: String,
            required: false,
            default: ''
        },
        title: {
            type: String,
            required: false,
            default: ''
        },
        // arrangement: {
        //     type: String,
        //     required: false,
        //     default: 'cards'
        // },
        moreLink: {
            type: Boolean,
            required: false,
            default: false,
        },
        slideDown: {
            type: Boolean,
            required: false,
            default: false,
        },
        scrollable: {
            type: Boolean,
            required: false,
            default: false,
        },
        elements: {
            type: Array,
            required: true,
            default: () => []
        },
        tiles: {
            type: Boolean,
            required: false,
            default: false
        },
        handler: {
            type: Function,
            default: () => {}
        },
        forceShowHeader: {
            type: Boolean,
            default: false
        },
        height: {
            type: Number,
            default: 300,
            required: false
        },
        noMoreItems: {
            type: Boolean,
            required: false,
            default: false
        },
        details: {
            type: Object,
            required: false,
            default: () => {}
        },
        showcaseMode: {
            type: Boolean,
            required: false,
            default: false
        }



    },
    components: {
        HorizontalScrollable,
        SectionCard
    },
    // created() {
    //     console.log(this.elements, this.name, this.details);
    // },
    computed: {
        ...general.mapState(['demoPageMode', 'externalUser']),
        tooltipText() {
            if(this.name === 'dashboards')
                return 'Dashboards are where you can use and view our analysis tools (indicators). Click an existing board to open it or create a new one below.';

            else if(this.name === 'products')
                if(this.externalUser || this.demoPageMode)
                    return 'View trainings that we\'re currently offering.';
                else
                    return 'View extra products and showcased events that we\'re currently offering.';

            else if(this.name === 'widgets' && this.showcaseMode)
                return 'Click the indicator below to start using it for free. It will automatically disappear at the end of the showcase. You can continue using it through our pro plan subscription.';

            else if(this.name === 'widgets') {
                if(this.demoPageMode || this.externalUser)
                    return 'Each indicator contains a specific news or analysis tool. To use an indicator simply click to add it to a dashboard.';
                    
                return 'Each indicator contains a specific news or analysis tool. To use an indicator simply click to add it to a dashboard. To activate an indicator you can purchase and add it to your existing plan or upgrade to a plan that includes it.';
            }

            return '';
        },
        slideDownClasses() {
            let classes = [this.name];

            if(this.isSlideDownOpen)
                classes.push('mt-20');
            
            return classes.join(' ');
        },
        arrowUpOrDown() {
            if(this.isSlideDownOpen)
                return "long-arrow-alt-up";
            else
                return "long-arrow-alt-down";
        },
        topRow() {
            return this.elements.slice(0,3);
        },
        hiddenRow() {
            return this.elements.slice(3);
        },
        cardContainerClasses() {
            const classes = [this.name];
            if(this.scrollable)
                classes.push("scrollable");
            // if(this.arrangement === 'card-list')
            //     classes.push("card-list");

            return classes.join(' ');
        },
        tcType() {
            return (this.name === 'livestreams' || 
            this.name === 'withupcoming' || 
            this.name === 'noupcoming' || 
            this.name === 'vidlibs')
        }
        // numOfDashboards() {
        //     return this.topRow.length + this.hiddenRow.length;
        // }
    },
    data() {
        return {
            isSlideDownOpen: false,
            newDashboardItem: {
                id: "new-dashboard-card",

            },
        }
    },
    methods: {
        showMore() {
            this.isSlideDownOpen = !this.isSlideDownOpen;
        }
    },
    // beforeDestroy() {
    //     this.removeScrollListener();
    // }
}
</script>

<style lang="scss" scoped>
    .fullpage-section {
        margin-top: 20px;
        color: $input-color;

    }
    .fullpage-section:first-child {
        margin-top: 0;
    }

    .fullpage-section-header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin: 20px 0;
    }

    .fullpage-section-header--title {
        font-size: 22px;
        letter-spacing: 0.2px;
        font-weight: 300;

        & svg {
            font-size: 20px;
            color: $input-color-filter;
        }
    }

    .fullpage-section-header--more {
        font-size: 12px;
        text-transform: uppercase;
        letter-spacing: 1px;

        & a {
            color: inherit;
            text-decoration: none;
        }
    }

    .fullpage-section-body--top-row {

        &.dashboards {
            display: grid;
            grid-template-columns: 1fr;
            gap: 20px;

            @include respond(md) {
                grid-template-columns: 1fr 1fr 1fr 1fr;
            }
        }

        &.tiles {
            display: grid;
            grid-template-columns: 1fr;
            gap: 20px;

            &.small-gap {
                gap: 15px;
            }

            @include respond(sm) {
                grid-template-columns: 1fr 1fr;
            }
            @include respond(lg) {
                grid-template-columns: 1fr 1fr 1fr;
            }
            @include respond(xl) {
                grid-template-columns: 1fr 1fr 1fr 1fr;
            }
        }

        &.tiles.big {
            display: grid;
            grid-template-columns: 1fr;
            gap: 20px;

            @include respond(lg) {
                grid-template-columns: 1fr 1fr;
            }
            @include respond(xl) {
                grid-template-columns: 1fr 1fr 1fr;
            }
        }

        // &.products {
    
        // }

        &.widgets {
            display: grid;
            grid-template-columns: 1fr;
            gap: 20px;

            @include respond(md) {
                grid-template-columns: 1fr 1fr;
            }
        }

        &.scrollable {
            
            overflow-x: auto;
            overflow-y: hidden;

            & > .scroller {
                display: flex;

                & > div {
                    margin-right: 20px;
                }
            }

            &::-webkit-scrollbar {
                display: none;
            }
            

        }

    }

    .fullpage-section-body--slidedown {


        & > .dashboards {
            @include respond(md) {
                display: grid;
                grid-template-columns: 1fr 1fr 1fr 1fr;
                gap: 20px;
            }
        }
    }

    .show-more {
        display: flex;
        margin: 25px 0;
        color: $input-color-filter;
    }
    .show-more-line {
        flex-grow: 1;
    }
    .show-more-line-2 {
        border-top: 2px solid $separator-line-color;
        display: block;
        margin-top: 10px;
    }
    .show-more-prompt {
        margin: 0 10px;
        font-size: 14px;

        & a {
            color: inherit;
            text-decoration: none;
        }
    }
</style>
