<template>
    <div class="slick-scrollable shadows"
    :class="slickScrollableClasses"
    ref="horiz"
    v-resize @resize="onResize"
    >
        <span
        v-if="!noBefore"
        @click="scrollIt(beforeClass)"
        class="shadows-arrow"
        :class="beforeClass"><a href="javascript:void(0);"
        ><font-awesome-icon :icon="['fas', 'arrow-' + beforeClass]"/></a></span>
        <div
        class="slick-scrollable-row scrollable"
        ref="scrollable"
        :style="containerStyles"
        >
            <div
            class="slick-scrollable-row--scroller scroller"
            ref="scroller">

                <slot>


                </slot>

            </div>

        </div>
        <span
        v-if="!noAfter"
        @click="scrollIt(afterClass)"
        class="shadows-arrow"
        :class="afterClass"><a href="javascript:void(0);"><font-awesome-icon :icon="['fas', 'arrow-' + afterClass]"/></a></span>
    </div>
</template>

<script>
import { eventBus } from '../../main';
export default {
    props: {
        isHorizontal: {
            type: Boolean,
            default: true,
            required: false
        },
        height: {
            type: Number,
            default: 0,
            required: false
        },
        handler: {
            type: Function,
            default: () => {}
        }
    },
    created() {
        eventBus.$on("scroller-calc-vertical", obj => {
            if(this.$root.instanceID === obj.instanceID)
                setTimeout(() => this.calcVertical(), 500);
        })
    },
    mounted() {
        this.$nextTick(() => {
            setTimeout(() => {
                if(this.$refs?.horiz) {
                    this.width = this.$refs.horiz.offsetWidth;
                    this.setupScrollListener();
                }
            },1000);

        });
    },
    computed: {
        containerStyles() {
            const arr = [];
            if(this.height) {
                const style = 'height: ' + this.height + 'px;';
                arr.push(style);
            }
            if(this.width) {
                const style = 'width: ' + this.width + 'px;';
                arr.push(style);
            }

            return arr.join('');
                

        },
        beforeClass() {
            if(this.isHorizontal) {
                return 'left';
            }
            return 'up';
        },
        afterClass() {
            if(this.isHorizontal) {
                return 'right';
            }
            return 'down';
        },
        beforeAfterClasses() {
            const arr = [];
            if(this.noBefore)
                arr.push('no-before');
            if(this.noAfter)
                arr.push('no-after');

            return arr.join(' ');
        },
        slickScrollableClasses() {
            const arr = [this.beforeAfterClasses];
            if(!this.isHorizontal)
                arr.push("vertical");
            
            return arr;
        }
    },
    data() {
        return {
            width: 300,
            noBefore: true,
            noAfter: true,
        }
    },
    methods: {
        setupScrollListener() {
            this.$refs.scrollable.addEventListener('scroll', this.onScroll);
            this.onScroll(); // needed for initial loading on page

		},
		removeScrollListener() {
			this.$refs.scrollable.removeEventListener('scroll', this.onScroll);
		},
        onScroll() {
            if(this.isHorizontal) {
                this.calcHoriz();
            }else{
                this.calcVertical();
            }
            


            // if ((scrollTop + height >= scrollHeight - 200) &&
            // !this.fetchMode &&
            // !this.noMoreItems) {
            //     // console.log("scroll-down-enough");
            //     this.fetchMode = true;
            //     window.bootUp.iDB.getMoreRecords({
            //         name: "tweets-load-more",
            //         channelID: this.column.id,
            //         offset: this.tweets.length,
            //         limit: this.limit,
            //         oldestTweetTime: this.tweets[this.tweets.length - 1].created_at
            //     }).then(response => {
            //         // console.log("to render in col",response);
            //         let allTweets = mergeArraysByProp(this.tweets, response, "uid");
            //         sortDataBy(allTweets, "created_at", "timeDESC");
            //         this.tweets = allTweets;
            //         this.fetchMode = false;
            //     })
            // }

            // if(scrollTop > 100) {
            //     // console.log("scrolled down");
            //     this.isScrolledDown = true;
            // }else{
            //     this.isScrolledDown = false;
            //     // console.log("scrolled up");
            //     this.newTweet = false;
            // }
        },
        scrollIt(direction) {
            let pixels = 450;
            if(direction === 'left' || direction === 'up')
                pixels = pixels * -1;

            this.scrollTo(this.$refs.scrollable, pixels, 400);
        },
        scrollTo(element, scrollPixels, duration) {
            const scrollRef = this.isHorizontal ? "scrollLeft" : "scrollTop";
            const clientDim = this.isHorizontal ? "clientWidth" : "clientHeight";
            const scrollDim = this.isHorizontal ? "scrollWidth" : "scrollHeight";
            const scrollPos = element[scrollRef];
            if (!((scrollPos === 0 || scrollPixels > 0) && (element[clientDim] + scrollPos === element[scrollDim] || scrollPixels < 0))) {
                const startTime =
                    "now" in window.performance
                        ? performance.now()
                        : new Date().getTime();

                window.requestAnimationFrame((timestamp) => this.scroll(element, scrollPos, scrollPixels, startTime, timestamp, duration));
            }
        },
        scroll: function (element, scrollPos, scrollPixels, startTime, timestamp, duration) {
            const scrollRef = this.isHorizontal ? "scrollLeft" : "scrollTop";
            const timeElapsed = timestamp - startTime;
            const progress = Math.min(timeElapsed / duration, 1);
            element[scrollRef] = scrollPos + scrollPixels * progress;
            if (timeElapsed < duration) {
                window.requestAnimationFrame((ts) => this.scroll(element, scrollPos, scrollPixels, startTime, ts, duration));
            }
        },
        calcHoriz() {
            const scrollLeft = this.$refs.scrollable.scrollLeft;
            const width = Math.ceil(this.$refs.scrollable.getBoundingClientRect().width);
            const scrollWidth = Math.ceil(this.$refs.scrollable.scrollWidth);

            // console.log(width, scrollWidth);

            if(width < scrollWidth) {
               
                if(scrollLeft <= 1) {
                    this.noBefore = true;
                    this.noAfter = false;
                }else if(width + scrollLeft > scrollWidth -3) {
                    this.noBefore = false;
                    this.noAfter = true;
                }else if(scrollLeft >= 1 && width + scrollLeft < scrollWidth) {
                    this.noBefore = this.noAfter = false;
                }
            }else {
                
                this.noBefore = true;
                this.noAfter = true;
            }
                // console.log(width < scrollWidth,this.noBefore, this.noAfter);
        },
        calcVertical() {
            if(!this.$refs.scrollable)
                return;
                
            const scrollTop = this.$refs.scrollable.scrollTop;
            const height = this.height;
            const scrollHeight = Math.ceil(this.$refs.scrollable.scrollHeight);

            if(height < scrollHeight)
                if(scrollTop < 1) {
                    this.noBefore = true;
                    this.noAfter = false;
                }else if(height + scrollTop > scrollHeight -3) {
                    this.noBefore = false;
                    this.noAfter = true;
                    this.handler();
                }else if(scrollTop >= 1 && height + scrollTop < scrollHeight) {
                    this.noBefore = this.noAfter = false;
                }
            else {
                this.noBefore = true;
                this.noAfter = true;
            }
        },
        onResize() {
            // if(this.isHorizontal) {
                
                setTimeout(() => {
                    this.width = this.$refs.horiz.offsetWidth;
                    if(this.isHorizontal)
                        this.calcHoriz();
                    else 
                        this.calcVertical();
                }, 150);
            // }
            
        },
    }
}
</script>

<style lang="scss" scoped>
    .slick-scrollable {

        &.shadows {
            position: relative;

            & .shadows-arrow {
                position: absolute;
                z-index: 2;

                top: 50%;
                transform: translateY(-50%);

                width: 32px;
                height: 32px;
                border-radius: 32px;
                background-color: $scroll-arrow-color;
                color: $input-color;
                display: flex;


                & a {
                    color: inherit;
                    font-size: 13px;
                    height: 100%;
                    width: 100%;
                    padding-top: 6px;
                    padding-left: 10px;
                }


                &.left {
                    left: 5px;
                }

                &.right {
                    right: 5px;
                }

            }

            &:before {
                content: "";
                position: absolute;
                width: 100%;
                margin: 0 auto;
                height: 100%;
                border: 0;
                left: 0;
                box-shadow: inset 20px 0 12px -12px rgba(0,0,0,0.5);
                pointer-events: none;
                z-index: 1;
            }
            &:after {
                content: "";
                position: absolute;
                width: 100%;
                margin: 0 auto;
                height: 100%;
                border: 0;
                left: 0;
                bottom: 0;
                transform: rotateY(180deg) translateX(-2px);
                box-shadow: inset 20px 0 12px -12px rgba(0,0,0,0.5);
                pointer-events: none;
                z-index: 1;
            }

            &.no-before {
                &:before {
                    display: none;
                }
    }

            &.no-after {
                &:after {
                    display: none;
                }
            }
        }

        &.vertical {






                 &.shadows {
           

                    & .shadows-arrow {

                        left: 50%;
                        transform: translateX(-50%);

                        & a {
                            padding-top: 6px;
                            padding-left: 10px;
                        }

                        &.up {
                            top: 5px;
                        }

                        &.down {
                            top: unset;
                            bottom: 35px;
                        }
                    }

                    &:before {
                        content: "";
                        position: absolute;
                        width: 100%;
                        margin: 0 auto;
                        height: 100%;
                        border: 0;
                        left: 0;
                        box-shadow: inset 0px 20px 12px -12px rgba(0,0,0,0.5);
                        pointer-events: none;
                        z-index: 1;
                    }
                    &:after {
                        content: "";
                        position: absolute;
                        width: 100%;
                        margin: 0 auto;
                        height: 100%;
                        border: 0;
                        top: 0;
                        bottom: 0;
                        transform: rotateX(180deg) translateX(-2px);
                        box-shadow: inset 0px 20px 12px -12px rgba(0,0,0,0.5);
                        pointer-events: none;
                        z-index: 1;
                    }

                    &.no-before {
                        &:before {
                            display: none;
                        }
            }

                    &.no-after {
                        &:after {
                            display: none;
                        }
                    }
                }








        }
    }

    .slick-scrollable-row {

        &.scrollable {

            overflow-x: auto;
            overflow-y: hidden;

            & > .scroller {
                display: flex;

                & > div {

                    flex-shrink: 0;
                }
            }

            &::-webkit-scrollbar {
                display: none;
            }

            scrollbar-width: none;


        }
    }

    .vertical .slick-scrollable-row {

        &.scrollable {

            overflow-x: hidden;
            overflow-y: auto;

            & > .scroller {
                display: block;
            }
        }
    }
</style>
