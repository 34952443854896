<template>
    <div class="e-client">
        <div class="e-client-container">
            <img :src="unpluggedLogo" alt="unplugged logo" style="width: 100%; margin-top: 60px;">
            <div class="unplugged-service-message">
                <!-- <div class="oops-div">Oops!</div> -->
                <div class="inactive-message">{{ inactiveMessage }}</div>
            </div>
        </div>
    </div>
</template>

<script>
import { frontFacingAssets } from '../../../../utility/helper-functions';
export default {
    props: ["inactiveMessage"],
    computed: {
        unpluggedLogo() {
            return frontFacingAssets() + 'unplugged' + '.svg';
        }
    }
}
</script>