<template>
    <a
    class="dashboard-mini-menu widget"
    href="javascript:void(0);"
    v-click-outside="closeMenu"
    @click.stop="openDashboardMenu"
    ><font-awesome-icon :icon="['fas', 'ellipsis-v']" />
        <div
        v-if="isDashboardMenuOpen" 
        class="dashboard-mini-menu--menu widget"
        >
            <nav>
                <ul>
                    <li><a href="javascript:void(0);" @click="editWidget">Edit</a></li>
                    <li><a href="javascript:void(0);" @click="removeWidget">Remove</a></li>
                </ul>
            </nav>
        </div>
    </a>
</template>

<script>
import { eventBus } from '../../../main';
import Swal from 'sweetalert2';
// import { createNamespacedHelpers } from 'vuex';

// const dashboards = createNamespacedHelpers('dashboard');

export default {
    props: ["implementation_id", "layout_id"],
    // computed: {
    //     ...dashboards.mapGetters(['layouts', 'defaultDashboards', 'getPoppedoutDashboardByID', 'activeDashboardByInstanceID', 'tabbedDashboards']),

     
    // },
    data() {
        return {
            isDashboardMenuOpen: false,
        }
    },
    methods: {
        // ...dashboards.mapActions(['setSystemDashboards', 'deleteDashboardById', 'setActiveDashboardAndTab', 'removeDashboard', 'updateDashboardById']),

        openDashboardMenu() {
            this.isDashboardMenuOpen = !this.isDashboardMenuOpen;
        },
        closeMenu() {
            this.isDashboardMenuOpen = false;
        },
        editWidget() {
            eventBus.$emit('open-modal', {
                instanceID: this.$root.instanceID,
                componentInModal: "widget-shop",
                classes: "terminal-modal simplified",
                panel: {
                    view: "widget-config",
                    id: this.implementation_id,
                    layout_id: this.layout_id,
                    showBackButton: true,
                    mode: "edit"
                },
                mode: "wide"
            });
        },
        removeWidget() {
            Swal.fire({
                title: 'Are you sure?',
                type: 'warning',
                text: 'You can always add it back to a dashboard later',
                showCancelButton: true,
                cancelButtonColor: '#1d9deb',
                confirmButtonColor: '#ff5656',
                confirmButtonText: 'Yes, delete it!'
            }).then((result) => {
                if (result.value) {
                    // console.log("this details", this.details);
                    eventBus.$emit("close-widget", {
                        instanceID: this.$root.instanceID,
                        layoutID: this.layout_id,
                    });
                }
            });
        }
    }
}
</script>

<style lang="scss">

    .dashboard-mini-menu.widget {
        padding: 0 3px;

        display: flex;

        & svg {
            font-size: 14px;
        }
    }
    
</style>