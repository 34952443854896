<template>
    <LineTest 
    id="intrate-chart"
    :chartData="chartData"
    :min="min"
    :max="max"
    :stepSize="0.2"
    :chartHeight="350"
    :chartWidth="chartWidth"
    yLabelString="Implied Interest Rate"
    :yTicks="(value) => '   ' + value.toFixed(2) + '%'"
    :iteration="iteration"
    :datapointFormatter="value => formatDataPoint(value)"
    title="Implied Interest Rate" />
</template>

<script>
import { createNamespacedHelpers } from 'vuex';
import { usersTime } from '../../../../utility/datetime';
import LineTest from '../../../charts/LineChartWrapper.vue';

const intrateNS = createNamespacedHelpers('interestRates');

export default {
    props: ["bank", "standardData", "chartWidth"],
    components: {
        LineTest
    },
    created() {
        this.processData();
    },
    computed: {
        ...intrateNS.mapGetters(['lastIntRateUpdate']),
        min()  {
            return Math.floor((Math.min(...this.standardData.week_ago.rates, ...this.standardData.current.rates.slice(1))) * 10) / 10 - 0.2;
        },
        max()  {
            return Math.ceil(Math.max(...this.standardData.week_ago.rates, ...this.standardData.current.rates) * 10) / 10 + 0.2;
        }
    },
    data() {
        return {
            chartData: {},
            chart1Defaults: {
                label: "Current",
                pointBackgroundColor: "#16CEB9",
                borderWidth: 1,
                fill: true,
                pointBorderColor: "#16CEB9",
                backgroundColor: "rgba(22, 206, 185, 0.1)",
                borderColor: "#16CEB9",
            },
            chart2Defaults: {
                label: "A Week Ago",
                borderColor: "#81c784",
                pointBackgroundColor: "#81c784",
                borderWidth: 1,
                fill: true,
                pointBorderColor: "#81c784",
                backgroundColor: "rgba(129, 199, 132, 0.1)",
            },
            line1Data: [],
            line2Data: [],
            labels: [],
            iteration: 0,
        }
    },
    methods: {
        processData() {
            const weekAgoDates = this.standardData.week_ago.dates;
            const currentDates = this.standardData.current.dates;
            const jointDates = [...new Set([...weekAgoDates ,...currentDates])];
            const line1Data = [];
            const line2Data = [];

            const weekAgoObj = {};
            weekAgoDates.forEach((el, i) => {
                weekAgoObj[el] = this.standardData.week_ago.rates[i];
            });
            const currentObj = {};
            currentDates.forEach((el, i) => {
                currentObj[el] = this.standardData.current.rates[i];
            });

            jointDates.forEach(el => {
                if(Object.prototype.hasOwnProperty.call(currentObj, el)) 
                    line1Data.push(currentObj[el]);
                else
                    line1Data.push(null);
                
                if(Object.prototype.hasOwnProperty.call(weekAgoObj, el)) 
                    line2Data.push(weekAgoObj[el]);
                else
                    line2Data.push(null);
            });

            let labels = [];
            // console.log(weekAgoObj, currentObj);
            if(currentDates[0] !== weekAgoDates[0]) {
                line1Data.splice(0,1);
                line2Data.splice(0,1);
                // console.log(this.line2Data)
                labels = currentDates;
            }else{
                labels = jointDates;
            }

            const fixedLabels = [];

            labels.forEach((el) => {
                const year = el.substr(0,4);
                const foundYear = fixedLabels.find(label => label.includes("-" + year.substr(2,2)));
                if(foundYear)
                    fixedLabels.push(usersTime(el, true, false, false, { dateFormat: "d-m", timeFormat: "24hr"}, "UTC"));
                else
                    fixedLabels.push(usersTime(el, true, false, false, { dateFormat: "d-m-y", timeFormat: "24hr"}, "UTC"));
            });

            this.labels = fixedLabels;
            this.line1Data = line1Data;
            this.line2Data = line2Data;

            this.chartData = {
                datasets: [
                    {
                        ...this.chart1Defaults,
                        data: this.line1Data,
                        
                    },
                    {
                        ...this.chart2Defaults,
                        data: this.line2Data,
                        
                    },
                ],
                labels: this.labels
            }

        },
        formatDataPoint(value) { 
            if(this.chartWidth > 500)
                return value.toFixed(2) + '%';
            
            return '';
        }
    },
    watch: {
        bank: {
           handler: function() {
            this.processData();
            this.iteration++;
           },
           deep: true

        },
        // standardData: {
        //      handler: function() {
        //         this.processData();
        //         this.iteration++;
        //    },
        //    deep: true
        // }
    }
}
</script>