import Vue from 'vue';
import VueRouter from 'vue-router';
// import ForgotPassword from "../components/ui/ForgotPassword";
import AppMain from "../AppMain";
// import ResetPassword from "../components/ui/ResetPassword";
import PasswordReset from "../components/ui/onboard/PasswordReset.vue";
import LoginComponent from "../components/ui/onboard/LoginComponent.vue";
import PartnerLogin from "../components/ui/broker/PartnerLogin.vue";
import PartnerRegister from "../components/ui/broker/PartnerRegister.vue";
import StartHere from "../components/ui/onboard/StartHere.vue";
import NewPassword from "../components/ui/onboard/NewPassword.vue";
import ExternalClient from "../components/ui/ExternalClient.vue"
import IndicatorPage from "../components/ui/external/IndicatorPage.vue"
import SentimentPage from "../components/ui/external/SentimentPage.vue"
import NewsPage from "../components/ui/external/NewsPage.vue"
import IntRatePage from "../components/ui/external/IntRatePage.vue"
import CalendarPage from "../components/ui/external/CalendarPage.vue"
import CurrencyPage from "../components/ui/external/CurrencyPage.vue"
import AnalystPage from "../components/ui/external/AnalystPage.vue"
import NewsStreamPage from "../components/ui/external/NewsStreamPage.vue"
import LibraryPage from "../components/ui/external/LibraryPage.vue"
import RiskCoursePage from "../components/ui/external/RiskCoursePage.vue"
import VidComPage from "../components/ui/external/VidComPage.vue"
import MasterClassPage from "../components/ui/external/MasterClassPage.vue"
import Constants from '../utility/constants';
import { isAutoPilotMode, isBrokerManagementMode, isExternalClientMode } from '../utility/helper-functions';
import { store } from '../store/store';
// import DashboardComponent from "../components/DashboardComponent.vue";
// import HomeComponent from "../components/HomeComponent.vue";

Vue.use(VueRouter);

export default new VueRouter({
    mode: 'history',
    routes: [
        // {path: '/forgot-password', component: ForgotPassword},
        // {
        //     path: '/password-reset', component: ResetPassword, props: (route) => ({
        //         token: route.query.nonce,
        //         email: route.query.email
        //     }),
        //     beforeEnter: (to, from, next) => {
        //         console.log(to.query?.email?.length)
        //         if (to.query?.email?.length > 0 && to.query?.nonce?.length > 0) {
        //             next();
        //         } else {
        //             next(false);
        //         }
        //     }
        // },
        {
            path: '/',
            component: AppMain,
            children: [
                {
                    name: 'login',
                    path: '/login',
                    component: LoginComponent,
                    props: true,
                    beforeEnter: (to, from, next) => {
                        if(!window.bootUp.promo) {
                            store.dispatch("general/setCheckoutLoginMode", false);
                        }
                        next();
                    }
                },
                {
                    name: 'check-out',
                    path: '/check-out',
                    component: StartHere,
                    props: true,
                    beforeEnter: (to, from, next) => {
                        store.dispatch("general/setCheckoutLoginMode", true);
                        next();
                    }
                },
                {
                    name: 'start-here',
                    path: '/start-here',
                    component: StartHere,
                    props: true
                },
                {
                    name: 'reset',
                    path: '/reset',
                    component: PasswordReset,
                    props: true
                },
                {
                    path: '/password-reset',
                    component: NewPassword,
                    props: (route) => ({
                        token: route.query.nonce,
                        email: route.query.email
                    }),
                    beforeEnter: (to, from, next) => {
                        console.log(to.query?.email?.length)
                        if (to.query?.email?.length > 0 && to.query?.nonce?.length > 0) {
                            next();
                        } else {
                            next(false);
                        }
                    }
                },
                {
                    name: 'admin-login',
                    path: '/admin-login',
                    component: PartnerLogin,
                    props: true
                },
                {
                    name: 'partner-register',
                    path: '/partner-register',
                    component: PartnerRegister,
                    props: true
                },
                {
                    name: 'home',
                    path: '/home',
                    component: ExternalClient,
                    props: (route) => ({
                        token: route.query.nonce,
                        email: route.query.email,
                        redirect: route.query.redirect,
                        initial: route.query.initial
                    }),
                },
                {
                    name: 'real-time-indicator-suite',
                    path: '/real-time-indicator-suite',
                    component: IndicatorPage,
                    props: true
                },
                {
                    name: 'sentiment-scanner',
                    path: '/sentiment-scanner',
                    component: SentimentPage,
                    props: true
                },
                {
                    name: 'breaking-news-headlines',
                    path: '/breaking-news-headlines',
                    component: NewsPage,
                    props: true
                },
                {
                    name: 'interest-rate-tracker',
                    path: '/interest-rate-tracker',
                    component: IntRatePage,
                    props: true
                },
                {
                    name: 'professional-calendar',
                    path: '/professional-calendar',
                    component: CalendarPage,
                    props: true
                },
                {
                    name: 'currency-strength',
                    path: '/currency-strength',
                    component: CurrencyPage,
                    props: true
                },
                {
                    name: 'analyst-chat',
                    path: '/analyst-chat',
                    component: AnalystPage,
                    props: true
                },
                {
                    name: 'news-trading-livestreams',
                    path: '/news-trading-livestreams',
                    component: NewsStreamPage,
                    props: true
                },
                {
                    name: 'trading-library',
                    path: '/trading-library',
                    component: LibraryPage,
                    props: true
                },
                {
                    name: 'risk-event-trading-course',
                    path: '/risk-event-trading-course',
                    component: RiskCoursePage,
                    props: true
                },
                {
                    name: 'video-ideas',
                    path: '/video-ideas',
                    component: VidComPage,
                    props: true
                },
                {
                    name: 'macro-masterclass',
                    path: '/macro-masterclass',
                    component: MasterClassPage,
                    props: true
                },
                // {
                //     name: 'dashboard',
                //     path: '/dashboard',
                //     component: DashboardComponent,
                //     props:  true,
                // },
                // {
                //     name: 'account',
                //     path: '/account',
                //     component: AccountOverview,
                //     props:  true,
                // },
                // {
                //     path: '/home',
                //     component: HomeComponent,
                //     props: true
                // },
                {
                    name: 'root',
                    path: '/:catchAll(.*)',
                    component: AppMain,
                    props: true,
                    beforeEnter: (to, from, next) => {
                        const autoLogin = window.bootUp.externalUserAutoLogin && isAutoPilotMode() || window.bootUp.remoteAccess;
                        // console.log("autoLogin", autoLogin);
                        if(autoLogin) {
                            next();
                            return;
                        } 
                        if (localStorage.getItem("token")) {
                            next();
                        }
                        else {
                            if(+localStorage.getItem("promo") === Constants.MTA_FREE_ROUTE)
                                next("start-here");
                            else{
                                if(isBrokerManagementMode())
                                    next("admin-login");
                                else if(isExternalClientMode()) {
                                    next("/home")
                                }
                                else if(isAutoPilotMode())
                                    next();
                                else    
                                    next("login");
                                    
                            }
                        }
                    }
                }
            ],
            
            // beforeEnter: (to, from, next) => {
            //     const autoLogin = window.bootUp.externalUserAutoLogin || window.bootUp.remoteAccess;
            //     if(autoLogin) {
            //         next();
            //     } 
            //     else if (localStorage.getItem("token")) {
            //         next();
            //     }
            //     else {
            //         console.log("redirect");
            //         next("login");
            //     }
            // }
        },
        // {
        //     name: 'root',
        //     path: '/:catchAll(.*)',
        //     component: AppMain,
        //     props: true,
        //     beforeEnter: (to, from, next) => {
        //         if (localStorage.getItem("token")) {
        //             next();
        //         } else {
        //             next("login");
        //         }
        //     }
        // }
    ]
});

