import {isAutoPilotMode, isEmptyObject, isExternalClientMode, randomizeBetween, sortDataBy, talkToServer} from "../utility/helper-functions";
import _ from "lodash";
import {createNamespacedHelpers} from "vuex";
import Swal from 'sweetalert2';
import { addDays, usersTime } from "../utility/datetime";
import HeadlineService from "../services/headlines/headline.service";
import { eventBus } from "../main";

const announcements = createNamespacedHelpers('announcements');
const general = createNamespacedHelpers('general');
const currency = createNamespacedHelpers('currency');
const payments = createNamespacedHelpers('payments');
const headlines = createNamespacedHelpers('headlines');

let entityTimeout = null;
let bmTimeout = null;
let nonAuthEntityTimeout = null;

export default {
    data() {
        return {
            partialTweetArray: [],
            partialTweetCheck: {}
        }
    },
    computed: {
        ...currency.mapState(["currencyChannels"]),
        ...headlines.mapGetters(["rareHeadlinesFetched"]),
        ...general.mapGetters(["user"]),
    },
    methods: {
        ...announcements.mapActions(['addAnnouncements']),
        ...general.mapActions(['loadCurrentUserSettings']),
        ...headlines.mapActions(['setRareHeadlinesFetched']),
        ...payments.mapActions(['loadAvailableProducts', 'setMakingPayment']),
        ...currency.mapActions(['setPairs', 'setLeaderBoard', 'setLastPush']),
        setupPusherChannels(channel) {

            const bID = 5;//window.bootUp.entity.id;

            // console.log("channel",channel);

            if (channel === "tweetdeck") {

                window.Echo.private('tweetdeck.' + bID)
                    .listen('.TweetBoard', response => {

                        const data = response.data ? response.data : [];

                        // console.log('board_push', response);

                        if (data && data.length > 0) {
                            data.forEach(element => {

                                let dataArray = [element];

                                const processingObject = {
                                    objStore: "tweet_boards",
                                    data: dataArray
                                }

                                if (element.deleted_at) {
                                    window.bootUp.iDB.deleteRecordByID("tweet_boards", element.id).then(() => {
                                        this.$store.dispatch("tweetdeck/lastBoardUpdate", element);
                                    });
                                } else {
                                    window.bootUp.iDB.storeRecords(processingObject).then(() => {
                                        this.$store.dispatch("tweetdeck/lastBoardUpdate", element);
                                    });
                                }


                            });

                        }

                    });

                window.Echo.private('tweetdeck.' + bID)
                    .listen('.TweetChannel', response => {

                        const data = response.data ? response.data : [];

                        // console.log('channel_push', response);

                        if (data && data.length > 0) {
                            data.forEach(element => {

                                let dataArray = [element];

                                const processingObject = {
                                    objStore: "tweet_channels",
                                    data: dataArray,
                                }

                                if (element.deleted_at) {
                                    window.bootUp.iDB.deleteRecordByID("tweet_channels", element.id).then(() => {
                                        this.$store.dispatch("tweetdeck/lastChannelUpdate", element);
                                    });
                                } else {
                                    window.bootUp.iDB.storeRecords(processingObject).then(() => {
                                        this.$store.dispatch("tweetdeck/lastChannelUpdate", element);
                                    });
                                }

                            });

                        }

                    });

                window.Echo.private('tweetdeck.' + bID)
                    .listen('.TweetChannelHandle', response => {

                        const data = response.data ? response.data : [];

                        // console.log('channel_handle_push', response);

                        if (data && data.length > 0) {
                            data.forEach(element => {


                                if (element.deleted_at) {
                                    window.bootUp.iDB.deleteTweetsFromChannelByHandle(element.channel_id, element.handle);
                                    this.$store.dispatch("tweetdeck/lastChannelHandleUpdate", element);
                                }

                            });

                        }

                    });

                window.Echo.private('tweetdeck.' + bID)
                    .listen('.TweetHandle', response => {

                        const data = response.data ? response.data : [];

                        // console.log('handle_push', response);

                        if (data && data.length > 0) {
                            data.forEach(element => {

                                if (element.deleted_at) {
                                    window.bootUp.iDB.deleteTweetsByHandle(element.handle.toLowerCase());
                                    this.$store.dispatch("tweetdeck/lastHandleUpdate", element);
                                }

                            });

                        }

                    });

                window.Echo.private('tweetdeck.' + bID)
                    .listen('.TweetTweet', response => {

                        const data = response.data ? response.data : [];

                        // console.log('tweet_push', response);

                        const processPush = data => {

                            window.bootUp.iDB.createUniqueIndex(data);

                            data.forEach(element => {

                                // if (element.deleted_at) {

                                //     mainObject.deleteRecordByID(mainObject.db.tweet_tweets, element.uid, true);
                                // }
                                // else {
                                let dataArray = [element];

                                const processingObject = {
                                    objStore: "tweet_tweets",
                                    data: dataArray
                                }

                                window.bootUp.iDB.storeRecords(processingObject);

                                this.$store.dispatch("tweetdeck/lastTweetUpdate", element);

                            });
                        }

                        if (data && data.length > 0) {
                            processPush(data);

                        } else if (response.tweet_id) {

                            this.partialTweetArray.push(response);

                            const totalChunks = response.total_chunks;
                            const currentChunks = this.partialTweetArray.filter(partial => partial.tweet_id === response.tweet_id);

                            if (currentChunks.length === totalChunks) {

                                sortDataBy(currentChunks, 'part_number', 'orderbyASC');
                                let data = currentChunks.map(element => element.partial_chunk).join('');
                                data = JSON.parse(data);

                                processPush([data]);

                                this.partialTweetArray = this.partialTweetArray.filter(partialTweet => partialTweet.tweet_id !== response.tweet_id);
                            }

                            clearTimeout(this.partialTweetCheck);

                            this.partialTweetCheck = setTimeout(() => {
                                let ids = [];
                                this.partialTweetArray.forEach(element => {
                                    if (!ids.includes(element.tweet_id)) {
                                        ids.push(element.tweet_id);

                                        //TODO (replace with axios based methods)
                                        talkToServer(`/widgets/tweetdeck/tweets/${element.tweet_id}/${element.channel_id}`, "GET", "", null,
                                            function (response) {
                                                if (response && !isEmptyObject(response)) {

                                                    processPush([response]);
                                                    this.partialTweetArray = this.partialTweetArray.filter(partialTweet => partialTweet.tweet_id !== response.tweet_id);

                                                }
                                            },
                                            function (error) {
                                                console.log(error);
                                            });

                                    }
                                });

                            }, randomizeBetween(2000, 3000));
                        }

                    });
            }
            if (channel === "headlines") {

                window.Echo.private('headlines.' + bID)
                    .listen('.Headline', data => {
                        // console.log("headline", data);
                        let news;
                        if (data && data.headline) {
                            news = data.headline;
                            news.published_at = news.published_at.replace(".000000Z", '');
                            news.published_at = news.published_at.replace("T", ' ');
                        }


                        if (data && (!data.partialFeed || (data.partialFeed && data.partialFeed === false))) {
                            this.processNews([news]);
                        }
                        // at the moment if partialFeed is true it was a big headline so lets just get it via ajax in a few seconds time
                        // else if(data && data.partialFeed && data.partialFeed === true) {
                        //     // console.log('obj.data, part feed');
                        //     // console.log(data.headline);
                        //     news.content = '';
                        //     news.reaction_details = '';
                        //     news.analysis_details = '';
                        //     news.reaction_updated_at = null;
                        //     news.analysis_updated_at = null;

                        //     const ID = news.id;

                        //     obj.data=[news];

                        //     const controllerObject = {
                        //         data: obj.data,
                        //         searchresults: false,
                        //         modal: false,
                        //         target: 'sentiment',
                        //         addloadmore: false,
                        //         isPushOrPull: true
                        //     }

                        //     // console.log('controllerObject',controllerObject);

                        //     mainObject.sendDataToController(controllerObject);

                        //     const processingObject = {
                        //         objStore: mainObject.db.headlines,
                        //         // module: 'sentiment',
                        //         data: obj.data,
                        //         isPushOrPull: true,
                        //         filter: 1,
                        //     }

                        //     mainObject.storeRecords(processingObject);

                        //     app.talkToServer(`/headlines/en/get/id/${ID}`, "GET", "", null, function(response) {
                        //         if(response) {


                        //             // mainObject.isPushOrPull = true;
                        //             response.data[0].updated_at = moment().add(1, 'seconds').utc().format("YYYY-MM-DD HH:mm:ss");

                        //             const controllerObject = {
                        //                 data: response.data,
                        //                 searchresults: false,
                        //                 modal: false,
                        //                 target: 'sentiment',
                        //                 addloadmore: false,
                        //                 isPushOrPull: true
                        //             }

                        //             mainObject.sendDataToController(controllerObject);

                        //             const processingObject = {
                        //                 objStore: mainObject.db.headlines,
                        //                 // module: 'sentiment',
                        //                 data: response.data,
                        //                 isPushOrPull: true,
                        //                 filter: 1,
                        //             }

                        //             mainObject.storeRecords(processingObject);

                        //         }

                        //     }, window.idDB.printStoreHeadlineError,false);
                        // }

                    });

                //Fetch rare headlines once

                if(!this.rareHeadlinesFetched) {
                    const fromTo = {
                        from: addDays(-30),
                        to: addDays(0)
                    };

                    [1,3].forEach(el => {
                        HeadlineService.fetchCategoryHeadlines({
                            sources: [1,2],
                            categories: null,
                            prop: el,
                            offset: 0,
                            limit: 50,
                            fromTo: fromTo.from + '/' + fromTo.to,
                            isSearchMode: false,
                            keywords: "*"
                        }).then(() => this.setRareHeadlinesFetched(true));
                    });
                }
                    
            }
            // if (channel === "livestream") {
            //     window.Echo.private('live-stream')
            //         .listen('.LiveStreamUpdate', data => {
            //             console.log("live stream update", data);

            //             if (data && data.liveStream && data.deleted) {
            //                 window.bootUp.iDB.deleteRecordByID("livestreams", data.liveStream.id);

            //                 this.$store.dispatch("webinars/lastLiveStreamDelete", data.liveStream.id);

            //                 return;
            //             }

            //             if (data && data.liveStream) {
            //                 const stream = data.liveStream;
            //                 stream.created_at = stream.created_at.replace(".000000Z", '');
            //                 stream.created_at = stream.created_at.replace("T", ' ');
            //                 stream.starts_at = stream.starts_at.replace(".000000Z", '');
            //                 stream.starts_at = stream.starts_at.replace("T", ' ');

            //                 let dataArray = [stream];

            //                 const processingObject = {
            //                     objStore: "livestreams",
            //                     data: dataArray
            //                 }

            //                 window.bootUp.iDB.storeRecords(processingObject);

            //                 const streamToRender = JSON.parse(JSON.stringify(stream));
            //                 streamToRender.new = true;

            //                 this.$store.dispatch("webinars/lastLiveStreamUpdate", streamToRender);

            //                 if(!window.bootUp.externalUser && stream.status === 'live') {
            //                     this.liveStreamStarted(stream);
            //                 }

            //             }

            //         });
            // }
            if (channel === "ecocalendar") {
                window.Echo.private('calendar')
                    .listen('.CalendarEventUpdate', data => {
                        // console.log("ecocal update", data, nowUTC());

                        if (data && data.calendarEvent && (data.deleted || data.calendarEvent.deleted_at)) {

                            window.bootUp.iDB.deleteRecordByID("ecocalendar", data.calendarEvent.id);

                            this.$store.dispatch("calendar/lastEcoCalendarDelete", data.calendarEvent.id);

                            return;
                        }

                        if (data && data.calendarEvent) {

                            // if (data.calendarEvent.previous_version_id)
                            //     window.bootUp.iDB.deleteRecordByID("ecocalendar", data.calendarEvent.previous_version_id);

                            const calEvent = data.calendarEvent;
                            calEvent.timestamp = calEvent.timestamp.replace(".000000Z", '');
                            calEvent.timestamp = calEvent.timestamp.replace("T", ' ');

                            let dataArray = [calEvent];

                            const processingObject = {
                                objStore: "ecocalendar",
                                data: dataArray
                            }

                            window.bootUp.iDB.storeRecords(processingObject);

                            const calEventToRender = JSON.parse(JSON.stringify(calEvent));
                            calEventToRender.new = true;

                            this.$store.dispatch("calendar/setLastEcoCalendarUpdate", calEventToRender);
                        }

                    })
                    .on("pusher:subscription_succeeded", () => { 
                        // console.log("success ecocal",success);
                        if(!this.singleWidgetMode)
                            eventBus.$emit("pusher-available")
                    })
                    .on("pusher:subscription_error", error => { 
                        console.log("error ecocal",error);
                        if(!this.singleWidgetMode)
                            eventBus.$emit("pusher-unavailable");
                    });
            }
            // if (channel === "videolibrary") {
            //     window.Echo.private('vidlib.' + bID)
            //         .listen('.VideoLibrary ', data => {
            //             // console.log("vidlib update", data);

            //             if (data && data.videoLibrary && data.deleted) {
            //                 window.bootUp.iDB.deleteRecordByID("video_library_videos", data.videoLibrary.id);

            //                 this.$store.dispatch("video/lastVideoDelete", data.videoLibrary.id);

            //                 return;
            //             }

            //             if (data && data.videoLibrary) {
            //                 const video = data.videoLibrary;

            //                 let dataArray = [video];

            //                 const processingObject = {
            //                     objStore: "video_library_videos",
            //                     data: dataArray
            //                 }

            //                 window.bootUp.iDB.storeRecords(processingObject);

            //                 const videoToRender = JSON.parse(JSON.stringify(video));
            //                 // streamToRender.new = true;

            //                 this.$store.dispatch("video/lastVideoUpdate", videoToRender);
            //             }

            //         });
            // }
            if (channel === "irpt") {
                window.Echo.private('interest-rates')
                .listen('.InterestRateUpdate', data => {
                        // console.log("irpt update", data);

                        if (data && data.interestRate && data.deleted) {
                            window.bootUp.iDB.deleteRecordByID("interest_rates", data.interestRate.id);

                            this.$store.dispatch("interestRates/lastIntRateDelete", data.interestRate.id);

                            return;
                        }

                        if (data && data.interestRate) {
                            const bank = data.interestRate;

                            let dataArray = [bank];

                            const processingObject = {
                                objStore: "interest_rates",
                                data: dataArray
                            }

                            window.bootUp.iDB.storeRecords(processingObject);

                            const bankToRender = JSON.parse(JSON.stringify(dataArray));

                            this.$store.dispatch("interestRates/lastIntRateUpdate", bankToRender);
                        }

                    });
            }
            if (channel === "curr-strength") {
                this.setupCurrencyChannels();
            }
        },
        leavePusherChannels(channel) {
            window.Echo.leave(channel);
        },
        addCurrencyDetails: function (key) {
            window.Echo.private('curr-strength')
                .listen('.' + key, data => {
                    // if(data.length > 0) {
                        // console.log("Data for: " + key);
                    // } 
                    // else {
                    //     console.log("There is no data for: " + key);
                    // }
                    // if(key === 'LiveDataUpdateDailyPerf')
                        // console.log("ccy",data);
                    // let date = JSON.parse(data[1]).substr(13, 16) + ":00-04:00";
                    if (this.currencyChannels.includes(key) && data && data.length > 0 && data[3]) {
                        this.setPairs(_.cloneDeep({key: key, value: data[5]}));
                        this.setLeaderBoard(_.cloneDeep({key: key, value: data[3]}));
                        this.setLastPush(usersTime(new Date(), true, true, true, {
                            dateFormat: "y-m-d",
                            timeFormat: "24hr"
                        }, false, "Europe/London"));
                    }
            });   
        },
        setupCurrencyChannels: function () {
            this.addCurrencyDetails('LiveDataUpdateDailyPerf');
            // this.addCurrencyDetails('LiveDataUpdateWeekToDate');
            this.addCurrencyDetails('LiveDataUpdateRollingYearly');
            this.addCurrencyDetails('LiveDataUpdateRollingWeekly');
            this.addCurrencyDetails('LiveDataUpdateRollingQuarterly');
            this.addCurrencyDetails('LiveDataUpdateRollingMonthly');
            this.addCurrencyDetails('LiveDataUpdateRolling24');
            // this.addCurrencyDetails('LiveDataUpdateMonthToDate');
        },
        setupDefaultPusherChannels: function (/*callback*/) {
            // console.log("!!! setting up default pusher channels");
            const uID = window.loggedInUserId;
            const bID = window.bootUp.entity.id;
            let context = this;
            if(!this.singleWidgetMode && !isAutoPilotMode() && !isExternalClientMode()) {
                window.Echo.private('announcements.' + uID)
                .listen(".NewAnnouncement", (response) => {
                    console.log("announcements" + JSON.stringify(response));
                    if (response) {
                        this.createAnnouncement(response.announcement);
                    }
                })
            window.Echo.private('payment-processing.' + uID)
                .listen(".PaymentProcessed", (response) => {
                    console.log("PaymentProcessed" + JSON.stringify(response));
                    if (response) {
                        this.createPaymentLog(response);
                        if (response.status !== "SUCCESS") {
                            // callback(response);
                            console.log("failed", response)
                            this.setFailedPayment(response);

                            Swal.fire({
                                title: 'Payment Failed',
                                html: "Please try again or contact your bank to get help with your payment.",
                                type: 'info',
                                confirmButtonColor: '##1d9deb',
                                confirmButtonText: 'OK'
                                // timer: 1500
                            });
                        }
                        this.setMakingPayment(false);
                    }
                })
            window.Echo.private('payment-processing.' + uID)
                .listen(".RefreshProducts", (response) => {
                    console.log("RefreshProducts" + response);
                    context.loadCurrentUserSettings({ withCache: true });
                })
            }
            if(!this.singleWidgetMode) {
                const tcrChannel = window.Echo.private('training-centre');

                tcrChannel.listen('.TrainingCentreStream', data => {
                    
                    // console.log("tcstream", data);

                    if (data && data.liveStream && data.deleted) {
                        window.bootUp.iDB.deleteRecordByID("livestreams", data.liveStream.id);

                        this.$store.dispatch("webinars/lastLiveStreamDelete", data.liveStream.id);

                        return;
                    }

                    if (data && data.liveStream) {
                        const stream = data.liveStream;
                        stream.created_at = stream.created_at.replace(".000000Z", '');
                        stream.created_at = stream.created_at.replace("T", ' ');
                        stream.starts_at = stream.starts_at.replace(".000000Z", '');
                        stream.starts_at = stream.starts_at.replace("T", ' ');

                        let dataArray = [stream];

                        const processingObject = {
                            objStore: "livestreams",
                            data: dataArray
                        }

                        window.bootUp.iDB.storeRecords(processingObject);

                        const streamToRender = JSON.parse(JSON.stringify(stream));
                        streamToRender.new = true;

                        this.$store.dispatch("webinars/lastLiveStreamUpdate", streamToRender);

                        if(stream.status === 'live') {
                            this.liveStreamStarted(stream);
                        }

                    }
                });
                        
                tcrChannel.listen('.TrainingCentreVideo', data => {
                    
                    // console.log("tcvideo update", data);


                    if (data && data.vidLib) {
                        const video = data.vidLib;

                        let dataArray = [video];

                        if (data.deleted) {
                            window.bootUp.iDB.deleteRecordByID("video_library_videos", data.vidLib.id);
                        }else{
                            const processingObject = {
                                objStore: "video_library_videos",
                                data: dataArray
                            }
        
        
                            window.bootUp.iDB.storeRecords(processingObject);
                        }

                        const videoToRender = JSON.parse(JSON.stringify(video));
                        // streamToRender.new = true;

                        this.$store.dispatch("library/lastVideoUpdate", videoToRender);
                    }
            
                    
                });

                tcrChannel.listen('.TrainingCentreOrderUpdated', data => {
                    // console.log("TrainingCentreOrderUpdated", data);
                    if(bID === data.entity_id) {
                        setTimeout(() => {
                            this.$store.dispatch("general/setLastTrainingCentreOrderUpdated", data);
                        }, 2000);
                        
                    }
                });
            }
            if(isExternalClientMode()) {
                const bmChannel = window.Echo.private('bm.' + bID);
                // console.log("setupDefaultPusherChannels", bID);
                bmChannel.listen(".BMUpdate", () => {
                    // console.log("BMUpdate push", r);
                    clearTimeout(bmTimeout);
                    bmTimeout = setTimeout(() => {
                        talkToServer(`/entity/broker/auth`, 'GET', null, null, true)
                        .then(response => {
                            // console.log("BMUpdate fetch", response);
                            if(response?.data?.entity)
                                this.$store.dispatch("general/setEntity", response.data.entity);
                            if(response?.data?.userList)
                                this.$store.dispatch("rules/setUserList", response.data.userList);
                        })
                        .catch(e => {
                            {
                                console.log(e);
                            }
                        }) ;
                    }, 1500);
                    
        
                });

                bmChannel.listen(".UserLocked", response => {
                    // console.log("UserLocked", response);
                    if(response?.data?.user)
                        this.$store.dispatch("rules/updateUserListUser", response.data.user);
                });
            }

            const entityChannel = window.Echo.private('entity-sync.' + bID);
            
            entityChannel.listen(".EntityProductsUpdated", () => {
                // console.log("EntityProductsUpdated" + JSON.stringify(response));
                const random = randomizeBetween(500,120000);
                clearTimeout(entityTimeout);
                console.log(random);
                entityTimeout = setTimeout(() => {
                    context.loadAvailableProducts().then(() => context.loadCurrentUserSettings({ withCache: true }).then(() => this.$store.dispatch("general/setEntityUpdateTimeout", random)))
                }, random);
            });

            entityChannel.listen(".EntityRulesUpdated", (response) => {
                console.log("EntityRulesUpdated", response);
            });

            window.Echo.private('user.' + window.loggedInUserId)
            .listen(".UserRulesUpdated", response => {
                console.log("UserRulesUpdated", response);
                if(response?.data?.ruleSet || response?.data?.ruleSet === null)
                    this.$store.dispatch("general/setRuleSet", response.data.ruleSet);
                if(response?.data?.user && window.loggedInUserId === response.data.user.id) {
                    this.$store.dispatch("general/setCurrentUser", response.data.user);
                    eventBus.$emit("ANALCHAT_CHECK");
                }
            });


        },
        setupNonAuthPusherChannels() {
            // console.log("setupNonAuthPusherChannels");

            window.Echo.channel('entity.' + this.$store.getters["general/entity"].id)
            .listen(".EntityUpdated", () => {
                clearTimeout(nonAuthEntityTimeout);
                nonAuthEntityTimeout = setTimeout(() => {
                    talkToServer(`/entity/${window.bootUp.entity.uuid}`, 'GET', null, null)
                    .then(response => { 
                        // console.log("EntityUpdated fetch", response);
                        const oldQuantumMode = this.$store.getters["general/entity"].quantum_mode;
                        const newQuantumMode = response.data.entity.quantum_mode;
                        const oldAutoPilotMode = this.$store.getters["general/entity"].autopilot_mode;
                        const newAutoPilotMode = response.data.entity.autopilot_mode;
                        
                        const forceLogout = oldQuantumMode === 1 && newQuantumMode === 0 && !this.user.bm;
                        // console.log("forceLogout", forceLogout)
                        this.$store.dispatch("general/setEntity", response.data.entity);
                        if((response.data.entity.ksa || forceLogout) && !isAutoPilotMode())
                            this.logout();
                        if(isAutoPilotMode() && oldAutoPilotMode !== newAutoPilotMode)
                            location.reload();

                        if(localStorage.getItem("token")) {
                            eventBus.$emit("products-ready");
                            eventBus.$emit("ANALCHAT_CHECK");
                        }
                    })
                    .catch(e => {
                        {
                            console.log(e);
                        }
                    }) ;
                }, 1000);

                
            });
        },
        leaveDefaultPusherChannels() {
            const channels = this.$store.state.widget.defaultPusherChannels;
            channels.forEach(ch => this.leavePusherChannels(ch));
        },
        createAnnouncement: function (data) {
            this.addAnnouncements(_.cloneDeep(data));
        },
        createPaymentLog: function (data) {
            let log = {
                invoiceId: data.invoice_id,
                id: Math.random(),
                product: {},
                date: new Date(),
                title: data.status,
                status: data.status,
                description: data.reason + " " + data.amount
            };
            eventBus.$emit("payment-processed-" + log.invoiceId, log)

        },
        processNews(newsArray) {

            const processingObject = {
                objStore: "headlines",
                data: newsArray
            }

            window.bootUp.iDB.storeRecords(processingObject);

            const newsToRender = _.cloneDeep(newsArray);

            this.$store.dispatch("headlines/lastHeadlineUpdate", newsToRender);
        }
    }
}