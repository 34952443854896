<template>
    <div class="home-screen">
        <fullpage-section
        class="dashboard-row"
        name="dashboards"
        title="Open or create dashboard"
        :forceShowHeader="true"
        :moreLink="true"
        :slideDown="true"
        :scrollable="false"
        :elements="dashboardsForDesktopDisplay"
        ></fullpage-section>
        
        <fullpage-section
        v-if="renderedProducts.length && !excludeUsers"
        name="products"
        :title=" externalUser || demoPageMode ? 'Training you might like' : 'Products you might like'"
        :moreLink="false"
        :slideDown="false"
        :scrollable="true"
        :elements="renderedProducts"
        ></fullpage-section>

        <fullpage-section
        name="widgets"
        :title="widgetsTitle"
        :moreLink="false"
        :slideDown="false"
        :scrollable="false"
        :elements="widgetsToShow"
        :showcaseMode="specialLink === '/showcaselogin'"
        ></fullpage-section>
    </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';
import { eventBus } from '../main';
import FullpageSection from './ui/FullpageSection.vue';
import _ from 'lodash';
import TrackingService from '../services/tracking/tracking.service';
import LivestreamService from '../services/livestream/livestream.service';
import { addDays, usersTime } from '../utility/datetime';
import Constants from '../utility/constants';
import { getServerEnv } from '../utility/helper-functions';
import * as workerTimers from 'worker-timers';

const dashboards = createNamespacedHelpers('dashboard');
const payments = createNamespacedHelpers('payments');
const general = createNamespacedHelpers('general');
const livestreamNS = createNamespacedHelpers("webinars");

export default {
    props:["specialLink"],
    components: {
        FullpageSection
    }, 
    created() {

        eventBus.$on("remove-promo", this.removePromo);
        if(this.dbReady)
            this.handlePromoSection();
        // console.log("active", this.widgetsOnTheHomeScreen);
        
    }, 
    mounted() {
        TrackingService.sendEvent("visit_home_page", {});

        TrackingService.trackHeartBeat({
            lookingAt: "home"
        });
        
        this.heartbeatInterval = workerTimers.setInterval(() => {
            TrackingService.trackHeartBeat({
                    lookingAt: "home"
                });
            // console.log("heartbeat", "home");
        }, Constants.TRACK_INTERVAL);
    },
    beforeDestroy() {
        workerTimers.clearInterval(this.heartbeatInterval);
        this.heartbeatInterval = null;
        eventBus.$off("remove-promo");
    },
    computed: {
        ...general.mapState(["user", "externalUser"]),
        ...dashboards.mapGetters(['defaultDashboards', 'dashboards', 'layouts', 'customDashboards', 'systemDashboards', 'getDashboardById', 'isThereLayoutByInstanceID']),
        ...payments.mapGetters(['availableProducts', 'activePlan', 'availablePlans', 'promotionalProducts', 'videoWidgetsForShop', 'mergedPlans', 'currentAddons', 'availableWidgets', 'widgetsOnTheHomeScreen']),
        ...general.mapGetters(['dbReady','lastTrainingCentreOrderUpdated', 'entityUpdateTimeout', 'demoPageMode']),
        ...livestreamNS.mapGetters(['lastLiveStreamUpdate', 'lastLiveStreamDelete']),

        excludeUsers() {
            return this.user.is_analyst;
        },
        isThereLayout() {
            return this.isThereLayoutByInstanceID(this.$root.instanceID);
        },
        groupedDashboards: function () {
            return {
                defaultDashboards: this.defaultDashboards,
                customDashboards: this.customDashboards,
                systemDashboards: this.systemDashboards.filter(el => !el.mobile && !el.unique_id && !el.vidpage),
                showcaseDashboards: this.systemDashboards.filter(el => !el.mobile && el.unique_id && !el.vidpage),
                mobileDashboards: _.orderBy(this.systemDashboards.filter(el => el.mobile), dashboard => dashboard.orderBy, ["desc"]),
                vidpageDashboards: this.systemDashboards.filter(el => el.vidpage)

            }
        },
        dashboardsForDesktopDisplay() {
            let dashboards = [
                ...this.groupedDashboards.showcaseDashboards,
                ...this.groupedDashboards.defaultDashboards,
                ...this.groupedDashboards.systemDashboards,
            ];

            return dashboards;
        },
        vidpageDashboards() {
            return this.systemDashboards.filter(el => el.vidpage);
        },
        widgetsToShow() {

            if(this.specialLink === '/showcaselogin')
                return this.widgetsOnTheHomeScreen.filter(el => el.planDetails?.planWidgetDetails?.showcase);

                return this.widgetsOnTheHomeScreen;
            
        },
        widgetsTitle() {
            const items = this.specialLink === '/showcaselogin' ? 'Showcases' : 'Indicators';
            return  items + ' (' + this.widgetsToShow.length + ')'
        },
        availPromos() {
            return [...this.promotionalProducts, ...this.availablePlans.filter(el => el.is_featured)];
        }
    },
    data() {
        return {
            renderedProducts: [],
            heartbeatInterval: null
        }
    },
    methods: {
        ...dashboards.mapActions(['updateDashboardById']),
        removePromo(obj) {
            if(obj.instanceID === this.$root.instanceID)
                this.handlePromoSection();
        },
        handlePromoSection() {
            const env = getServerEnv();
            let renderedProducts = _.cloneDeep(this.availPromos);
            
            const foundPlans = renderedProducts.filter(el => this.mergedPlans.find(e => e.id === el.id)).map(el => el.id);
            if(foundPlans.length) {
                foundPlans.forEach(el => {
                    const index = renderedProducts.findIndex(e => el === e.id);
                    if(index > -1)
                        renderedProducts.splice(index, 1, _.cloneDeep(this.mergedPlans.find(e => e.id === el)));
                });
            }
            let onPayingPlan = false;
            if(+this.activePlan.price) {
                onPayingPlan = true;
                let prod = this.activePlan.product
                if(this.activePlan.product.parent_id && !this.activePlan.product.trial_of_product_id)
                    prod = Object.values(this.availableProducts).find(el => el.id === this.activePlan.product.parent_id);

                const planImpIDs = prod.widgets.map(el => el.implementation_id || el.pivot.widget_implementation_id);
                // let found = false;
                const prodIDs = [];
                renderedProducts.forEach(el => {
                    if(el.product_type === Constants.ADDON_TYPE && el.product_sub_type === 'addon') {
                        if(el.widgets.find(el => planImpIDs.includes(el.implementation_id)))
                            prodIDs.push(el.id)
                    }
                });
                renderedProducts = renderedProducts.filter(el => !prodIDs.includes(el.id))
               
            }

            this.currentAddons.forEach(addon => {
                
                if(addon.product.product_type === Constants.ADDON_TYPE ) { //&& addon.product.product_sub_type === 'bundle'

                    let prod = addon.product
                    if(addon.product.parent_id && !addon.product.trial_of_product_id)
                        prod = Object.values(this.availableProducts).find(el => el.id === addon.product.parent_id);


                    const planImpIDs = prod?.widgets.map(el => el.implementation_id || el.pivot.widget_implementation_id);
                    // let found = false;
                    const prodIDs = [];
                    renderedProducts.forEach(el => {
                        if(el.product_type === Constants.ADDON_TYPE && el.product_sub_type === 'addon') {
                            if(el.widgets.find(el => planImpIDs.includes(el.implementation_id)))
                                prodIDs.push(el.id)
                        }
                    });
                    renderedProducts = renderedProducts.filter(el => !prodIDs.includes(el.id))
                    }
                    
            });


            //MTA
            const now = usersTime(new Date(), true, true, true, {
                dateFormat: "y-m-d",
                timeFormat: "24hr"
            }, "UTC");
            
            let mtas = this.currentAddons.filter(el => el.product.parent_id === Constants.MTA_PROMO[env] || 
            el.product.id === Constants.MTA_PROMO[env] || 
            el.product.parent_id === Constants.MTA_OFFER[env] || 
            el.product.id === Constants.MTA_OFFER[env]
            );
            if(mtas.length) {
                mtas = _.orderBy(mtas, el => el.ended_at, ['desc']);
                const end = mtas[0]?.ended_at?.replace('T', ' ').replace('.000000Z', '');
                const hideUntil = addDays(-7,end) + ' 00:00:00';
                
                if(now < hideUntil) {
                    let index = renderedProducts.findIndex(el => el.id === Constants.MTA_PROMO[env]);
                    if(index > -1) {
                        renderedProducts.splice(index, 1);
                    }
                    index = renderedProducts.findIndex(el => el.id === Constants.MTA_COURSE.prodID[env]);
                    if(index > -1) {
                        renderedProducts.splice(index, 1);
                    }
                    index = renderedProducts.findIndex(el => el.id === Constants.MACRO_PLAN[env].prodID);
                    if(index > -1) {
                        renderedProducts.splice(index, 1);
                    }
                }

                renderedProducts = renderedProducts.filter(el => el.id !== Constants.MTA_WEBINAR.prodID[env]);
                renderedProducts = renderedProducts.filter(el => el.id !== Constants.VIDCOM_PROMO[env]);

                // console.log("renderedProducts", renderedProducts, this.currentAddons, mta);
            }
            let mtcs = this.currentAddons.filter(el => el.product.parent_id === Constants.MTA_COURSE.prodID[env] || el.product.id === Constants.MTA_COURSE.prodID[env]);
            if(mtcs.length) {
                const index = renderedProducts.findIndex(el => el.id === Constants.MTA_COURSE.prodID[env]);
                if(index > -1) {
                    renderedProducts.splice(index, 1);
                }
            }

            let blackFriday = this.currentAddons.find(el => el.product.parent_id === Constants.BLACK_FRIDAY_2023[env]);
            if(blackFriday) {
                const index = renderedProducts.findIndex(el => el.id === Constants.BLACK_FRIDAY_2023[env]);
                if(index > -1) {
                    renderedProducts.splice(index, 1);
                }
            }
           
            const marcoPlanAnnual = this.currentAddons.find(el => el.product.id === Constants.MACRO_PLAN[env].annualProdID);
            if(marcoPlanAnnual) {
                const index = renderedProducts.findIndex(el => el.id === Constants.MACRO_PLAN[env].prodID);
                if(index > -1) {
                    renderedProducts.splice(index, 1);
                }
            }

            // if(!Constants.MACRO_PLAN[env].users.includes(this.user.email.toLowerCase())) {
            //     renderedProducts = renderedProducts.filter(el => el.id !== Constants.MACRO_PLAN[env].prodID)
            // }

            let onPayingBundles = this.currentAddons.filter(el => el.product.id === Constants.MACRO_PLAN[env].prodID || el.product.id === Constants.MACRO_PLAN[env].annualProdID);

            if(onPayingPlan || mtas.length || onPayingBundles.length) {
                const index = renderedProducts.findIndex(el => el.id === Constants.ULTIMATE_NEWS_TRADING_TOOL[env]);
                if(index > -1) {
                    renderedProducts.splice(index, 1);
                }
            }

            let renderedShowcases = _.cloneDeep(this.videoWidgetsForShop.filter(el => el.planDetails?.planWidgetDetails.showcase));
            const Ids = renderedShowcases.map(el => el.implementation_id);
            
            LivestreamService.fetchNextUpcomingByImpIDs(Ids).then(r => {
                if(!_.isEmpty(r)) {
                    renderedShowcases.forEach(el => {
                        if(r[el.implementation_id]) {
                            el.next_upcoming = r[el.implementation_id];
                            el.forceShowcase = true;
                            el.id = el.implementation_id,
                            el.showcase_created_at = el.training_centre_order_at;
                        }
                    });
                }

                renderedShowcases.filter(el => el.code === 'TCVIDEO').forEach(el => {
                    el.id = el.implementation_id;
                    el.forceShowcase = true;
                    el.showcase_created_at = el.planDetails?.planWidgetDetails.showcase_created_at.replace('T', ' ').replace('.000000Z', '');
                });
                
                renderedShowcases = renderedShowcases.filter(el => el.forceShowcase);

                renderedShowcases = _.orderBy(renderedShowcases, el => el.showcase_created_at, "asc");


            });


            let renderedTrainings = [];
            if(this.externalUser || this.demoPageMode) {
                renderedShowcases = [];
                renderedProducts = [];
                renderedTrainings = this.videoWidgetsForShop.filter(el => el);
                renderedTrainings = _.orderBy(renderedTrainings, el => el.code, "asc");
            }

            // sorting
            const arr = [];
            Constants.PYML_PROD_ORDER[env].forEach(PYMLOrderID => {
                
                const found = renderedProducts.find(prod => prod.id === PYMLOrderID);
                if(found)
                    arr.push(found);

            });
            renderedProducts = arr;

            this.renderedProducts = _.cloneDeep([...renderedShowcases, ...renderedProducts, ...renderedTrainings]);

        }   
    },
    watch: {
        availPromos: {
            deep: true,
            handler: function () {
               this.handlePromoSection();
            }
        },
        dbReady(val) {
            if(val) {
                this.handlePromoSection();
            }
                
        },
        videoWidgetsForShop: {
            deep: true,
            handler: function (nVal, oVal) {
                if(nVal &&
                oVal &&
                !_.isEqual(nVal, oVal)) {
                    this.handlePromoSection();
                }
            }
        },
        lastTrainingCentreOrderUpdated: {
            deep: true,
            handler: function(nVal,oVal) {
                if(!_.isEqual(nVal,oVal)) {
                    // console.log("on home", nVal,oVal, this.vidpageDashboards);
                    const layouts = _.cloneDeep(this.layouts);
                    const changed = layouts["vidpage_" + nVal.widget_implementation_id];

                    if(changed) {
                        changed.widget.training_centre_order_at = nVal.training_centre_order_at.replace("T",' ').substring(0,19);
                        
                        // console.log(changed);
                        this.updateDashboardById(changed);
                        this.handlePromoSection();
                    }
                }
            }
        },
        lastLiveStreamUpdate() {
            this.handlePromoSection();
        },
        lastLiveStreamDelete() {
            this.handlePromoSection();
        },
        // entityUpdateTimeout(val) {
        //     if(val)
        //         this.handlePromoSection();
        // }
    },
    
    
}
</script>

<style lang="scss" scoped>
    .home-screen {
        @include standardContainer;

        & .dashboard-row {
            display: none;

            @include respond(lg) {
                display: unset;
            }
        }
        
    }
</style>