<template>
	<div class="star-rating">
		<button type="link" class="star-rating__link" @click="submitRating"><font-awesome-icon :icon="['fas', 'check']"/><span v-if="showTextTile">&nbsp;Rate this {{ widgetType }}</span></button>
	</div> 
</template>

<script>
import { createNamespacedHelpers } from 'vuex';
import { eventBus } from '../../../main'

const widgets = createNamespacedHelpers("widget");

export default {
    props: ["implementation_id", "widgetTitle", "showTextTile"],
    created() {
        
    },
    computed: {
        ...widgets.mapGetters(['getCodeByImplementationId']),
        widgetType() {
            if(this.getCodeByImplementationId(this.implementation_id) === 'TCSTREAM')
                return "live stream series";
            if(this.getCodeByImplementationId(this.implementation_id) === 'TCVIDEO')
                return "video course";

            return 'indicator';
        }
    },
    // data() {
    //     return {
    //     }
    // },
	methods: {
		submitRating() {
			

			const modal = {
                instanceID: this.$root.instanceID,
                componentInModal: "submit-rating",
                classes: "terminal-modal simplified auto-height",
				mode: 'wide',
				panel: {
					implementation_id: this.implementation_id,
                    widgetTitle: this.widgetTitle
				}
			}
            // console.log("emitting");

			eventBus.$emit('open-modal', modal);
		}
	}
}
</script>

<style lang="scss" scoped>
    .star-rating {

        display: flex;
        height: 100%;

        &__link {
            background-color: transparent;
            background-repeat:no-repeat;
            border: none;
            cursor: pointer;
            overflow: hidden;
            outline:none;
            color: $input-color;
            font-size: 13px;
        }
    }
</style>