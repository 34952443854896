<template>
<div>
    <div v-if="entityLocked">
        <dynamic-logo></dynamic-logo>
    </div>
    <div v-else class="e-client-nav">
        <div class="nav-left-right">
            <router-link to="/home" style=" text-decoration: none;">
            <dynamic-logo></dynamic-logo>
            </router-link>
        </div>

        <div style="position: relative; display: flex; align-items: center;" v-click-outside="closeMenu">
            <div><a style="color: #FFFFFF; font-size: 24px;" href="javascript:void(0);" @click="toggleMenu"><font-awesome-icon v-if="!menuIsOpen" :icon="['fas', 'bars']"/><font-awesome-icon v-else :icon="['fas', 'times']"/></a></div>
            <div v-if="menuIsOpen"
            >
                <div
                style="top: 40px; left: -100px; width: 220px;"
            class="dashboard-mini-menu--menu"
            >
                <nav>
                    <ul>
                        <li
                        >
                            <router-link 
                            :class="{'active-item': currentPage === 'home'}"
                            :to="'home'" 
                            target="_blank"
                            style="text-transform: capitalize;">Home</router-link>
                        </li>
                        <li v-for="item in menuItems"
                        :key="item.slug"
                        >
                            <router-link 
                            :class="{'active-item': currentPage === item.slug}"
                            :to="item.slug" 
                            target="_blank"
                            style="text-transform: capitalize;"
                            >{{ item.name }}</router-link>
                        </li>
                        
                    </ul>
                </nav>
            </div>

        </div>


        </div>


        <div class="nav-left-right">
            <base-button
            mode="o-tranparent-grey o-white-border o-white-text o-border-radius-0"
            :link="false"
            style="width: 100px; display: block; margin-left: auto;"
            @click.native="openLogin"
            >Login
            </base-button>
        </div>
    </div>
</div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';
import externalPageContentMixin from '../../../mixins/externalPageContentMixin';
import DynamicLogo from './DynamicLogo.vue';

const general = createNamespacedHelpers("general");

export default {
    components: {
        DynamicLogo
    },
    mixins: [externalPageContentMixin],
    created() {
        this.currentPage = window.location.pathname.replace("/", "");
    },
    computed: {
        ...general.mapGetters(["entity", "entityLocked"]),
        menuItems() {
            
            return Object.entries(this.externalPageContent).filter(el => !Object.prototype.hasOwnProperty.call(el[1], "entityQnaToShow") || el[1].entityQnaToShow === this.entityQnaToShow).map(value => { return {slug: value[0], name: value[1].name} });
        }
    },
    data() {
        return {
            currentPage: '',
            menuIsOpen: false
        }
    },
    methods: {
        toggleMenu() {
            this.menuIsOpen = !this.menuIsOpen;
        },
        closeMenu() {
            if(this.menuIsOpen)
                this.menuIsOpen = false;
        },
        openLogin() {
            if(this.entity.quantum_mode)
                this.$emit('open-login');
            else
                this.$router.push("admin-login");
        }
    }
}
</script>

<style lang="scss" scoped>
    .active-item {
        color: $checkbox-active!important;
    }
</style>