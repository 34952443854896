<template>
    <div class="rule-setup">
        <div class="rule-setup-header">
            <h2>Create a new rule</h2>
        </div>
        <div class="rule-setup-body">
            <div class="rule-setup-body-rule-selector">
                <span>Unlock for</span>
                <select v-model="targetUsers">
                    <option value="all">[all clients]</option>
                    <option value="individual">[individual]</option>
                </select>
                <span>for</span>
                <select>
                    <option value="0">[forever]</option>
                    <option value="1">[1 day]</option>
                    <option value="7">[1 week]</option>
                    <option value="30">[1 month]</option>
                </select>
                <span>with</span>
                <select>
                    <option value="0">[balance]</option>
                    <option value="1">[deposit]</option>
                </select>
                <span>of</span>
                <input :class="{'invalid': num === 'invalid'}" placeholder="[$50000+]" @blur="makeHumanReadable" @focus="makeNumber" v-bind:value="num">
            </div>
            <div 
            v-if="targetUsers === 'individual'"
            class="rule-setup-body-user-selector">
                <div class="rule-setup-body-user-selector-search">
                    <base-input
                    elementType="input"
                    inputType="email"
                    v-model="email"
                    forID="email"
                    :placeholder="'Begin typing user email...'"
                    inputClass="partner-input darker-input no-border"
                    formControlClass=""
                    labelClass="medium-font"
                    data-lpignore="true"
                    ></base-input>
                </div>
                <div class="rule-setup-body-user-selector-search-results">
                    <div
                    v-for="email in filteredEmails"
                    :key="email.email"
                    ><a href="javascript:void(0);">{{ email.email }}</a></div>
                </div>
            </div>


            <div 
            class="rule-setup-body-item-selector">
                <h3>Included widgets</h3>
                <p v-if="!hasSelectedWidgets">Your rule doesn't include any widgets.<br />
                These are the tools that your clients will have access to and engage with on the analyst-desk.<br />
                When this rule applies, the client will have access to the specific widgets that you include here.</p>
                <div 
                v-if="hasSelectedWidgets && !widgetSelectorOpen"
                class="selected-widgets">
                    <a 
                    v-for="widget in selectedWidgets"
                    :key="widget.implementation_id"
                    class="widget-tags"
                    href="javascript:void(0);"
                    @click="addWidget('suggested', widget.implementation_id)">{{ widget.name }}</a>
                </div>
                <vue-slide-up-down :active="widgetSelectorOpen" :duration="250" class="vue-slide-up-down rule-setup-body-item-selector-action">
                    <div>
                        <h4>Widgets</h4>
                        <p style="margin: 0 0 7px;">Selected widgets</p>
                        <div class="selected-widgets">
                            <a 
                            v-for="widget in selectedWidgets"
                            :key="widget.implementation_id"
                            class="widget-tags"
                            href="javascript:void(0);"
                            @click="addWidget('suggested', widget.implementation_id)">{{ widget.name }} <font-awesome-icon
                        :icon="['fas', 'minus']" style="margin-left=5px;"/></a>
                        </div>
                        <h4 style="font-size: 12px; margin: 7px 0;">Suggested widgets</h4>
                        <div class="suggested-widgets">
                            <a 
                            v-for="widget in suggestedWidgets"
                            :key="widget.implementation_id"
                            class="widget-tags"
                            href="javascript:void(0);"
                            @click="addWidget('selected', widget.implementation_id)">{{ widget.name }} <font-awesome-icon
                        :icon="['fas', 'plus']" style="margin-left=5px;"/></a>
                        </div>
                    </div>
                </vue-slide-up-down>
                <div v-if="widgetSelectorOpen" 
                class="widget-buttons" style="margin-top:15px;">
                    <base-button
                    mode="o-tranparent-grey o-white-text o-semibolt-text o-height-30"
                    :link="false"
                    @click.native="cancelWidgetSelection"
                    >Cancel
                    </base-button>
                    <base-button
                    style="margin-left: 15px;"
                    mode="o-tranparent-grey o-white-text o-semibolt-text o-height-30"
                    :link="false"
                    @click.native="saveWidgetSelection"
                    >Save
                    </base-button>
                </div>
                <div v-else
                class="widget-buttons" style="margin-top:15px;">
                        <base-button
                        mode="o-tranparent-grey o-white-text o-semibolt-text o-height-30"
                        :link="false"
                        @click.native="addAllWidgets"
                        >Add all
                        </base-button>
                        <base-button
                        style="margin-left: 15px;"
                        mode="o-tranparent-grey o-white-text o-semibolt-text o-height-30"
                        :link="false"
                        @click.native="widgetSelectorOpen = true"
                        >Add individually
                        </base-button>
                        <base-button
                        v-if="hasSelectedWidgets"
                        style="margin-left: 15px;"
                        mode="o-tranparent-red o-red-text o-semibolt-text o-height-30"
                        :link="false"
                        @click.native="removeAllWidgets"
                        >Remove All
                        </base-button>
                    </div>
            </div>

            <div 
            class="rule-setup-body-item-selector">
                <h3>Included countries</h3>
                <p v-if="!hasSelectedCountries">Your rule doesn't include any countries.<br />
                These are the tools that your clients will have access to and engage with on the analyst-desk.<br />
                The current rule will only include clients from these countries.</p>
                <div 
                v-if="hasSelectedCountries && !countrySelectorOpen"
                class="selected-widgets">
                    <a 
                    v-for="country in selectedCountries"
                    :key="country.alpha3Code"
                    class="widget-tags"
                    :class="{'uppercase-fs letter-spacing-fs-05': country.group === 'main'}"
                    href="javascript:void(0);"
                    @click="addCountry('suggested', country.alpha3Code)">{{ country.name }}</a>
                </div>
                <vue-slide-up-down :active="countrySelectorOpen" :duration="250" class="vue-slide-up-down rule-setup-body-item-selector-action">
                    <div>
                        <h4>Countries</h4>
                        <p style="margin: 0 0 7px;">Selected countries</p>
                        <div class="selected-widgets">
                            <a 
                            v-for="country in selectedCountries"
                            :key="country.code"
                            class="widget-tags"
                            :class="{'uppercase-fs letter-spacing-fs-05': country.group === 'main'}"
                            href="javascript:void(0);"
                            @click="addCountry('suggested', country.alpha3Code)">{{ country.name }} <font-awesome-icon
                        :icon="['fas', 'minus']" style="margin-left=5px;"/></a>
                        </div>
                        <h4 style="font-size: 12px; margin: 7px 0;">Suggested countries</h4>
                        <div class="suggested-widgets">
                            <a 
                            v-for="country in suggestedCountries"
                            :key="country.code"
                            class="widget-tags"
                            :class="{'uppercase-fs letter-spacing-fs-05': country.group === 'main'}"
                            href="javascript:void(0);"
                            @click="addCountry('selected', country.alpha3Code)">{{ country.name }} <font-awesome-icon
                        :icon="['fas', 'plus']" style="margin-left=5px;"/></a>
                        </div>
                    </div>
                </vue-slide-up-down>
                <div v-if="countrySelectorOpen" 
                class="widget-buttons" style="margin-top:15px;">
                    <base-button
                    mode="o-tranparent-grey o-white-text o-semibolt-text o-height-30"
                    :link="false"
                    @click.native="cancelCountrySelection"
                    >Cancel
                    </base-button>
                    <base-button
                    style="margin-left: 15px;"
                    mode="o-tranparent-grey o-white-text o-semibolt-text o-height-30"
                    :link="false"
                    @click.native="saveCountrySelection"
                    >Save
                    </base-button>
                </div>
                <div v-else
                class="widget-buttons" style="margin-top:15px;">
                        <base-button
                        mode="o-tranparent-grey o-white-text o-semibolt-text o-height-30"
                        :link="false"
                        @click.native="addAllCountries"
                        >Add all
                        </base-button>
                        <base-button
                        style="margin-left: 15px;"
                        mode="o-tranparent-grey o-white-text o-semibolt-text o-height-30"
                        :link="false"
                        @click.native="countrySelectorOpen = true"
                        >Add individually
                        </base-button>
                        <base-button
                        v-if="hasSelectedCountries"
                        style="margin-left: 15px;"
                        mode="o-tranparent-red o-red-text o-semibolt-text o-height-30"
                        :link="false"
                        @click.native="removeAllCountries"
                        >Remove All
                        </base-button>
                    </div>
            </div>


            <div style="margin-top: 20px;">
                <base-button
                mode="o-blue"
                :link="false"
                >Save Rule
                </base-button>
            </div>
        </div>
    </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';
import Constants from '../../../../utility/constants';
const allCountries = Constants.USER_COUNTRIES;
const payments = createNamespacedHelpers('payments');
export default {
    created() {
        this.suggestedWidgets = [...this.widgetsToSelectFromForHomeScreen];
        this.oldSuggestedWidgets = [...this.suggestedWidgets];

        this.suggestedCountries = [...allCountries];
        this.oldSuggestedCountries = [...this.suggestedCountries];
    },
    computed: {
        ...payments.mapGetters(["widgetsToSelectFromForHomeScreen"]),

        filteredEmails() {
            return this.userEmails.filter(el => el.email.indexOf(this.email) > -1);
        },
        selectedWidgetImpIDs() {
            return this.selectedWidgets.map(el => el.implementation_id);
        },

    },
    data() {
        return {
            num: null,
            email: '',
            userEmails: [
                {
                    email: 'sample@email1.com'
                },
                {
                    email: 'sample@email2.com'
                },
                {
                    email: 'sample@email3.com'
                },
                {
                    email: 'sample@email4.com'
                },
                {
                    email: 'sample@email5.com'
                },
                {
                    email: 'sample@email6.com'
                },
                {
                    email: 'sample@email7.com'
                },
                {
                    email: 'sample@email8.com'
                },
                {
                    email: 'sample@email9.com'
                },
                {
                    email: 'sample@email10.com'
                },
                {
                    email: 'sample@email11.com'
                },
                {
                    email: 'sample@email12.com'
                },
                {
                    email: 'sample@email13.com'
                },
            ],
            targetUsers: 'all',

            selectedWidgets: [],
            suggestedWidgets: [],
            widgetSelectorOpen: false,
            oldSuggestedWidgets: [],
            oldSelectedWidgets: [],
            hasSelectedWidgets: false,

            selectedCountries: [],
            suggestedCountries: [],
            countrySelectorOpen: false,
            oldSuggestedCountries: [],
            oldSelectedCountries: [],
            hasSelectedCountries: false,



        }
    },
    methods: {
        makeHumanReadable(u) {
            if(isNaN(u.target.value)) {
                this.num = 'invalid';
                return;
            }

                const num = +u.target.value;
                this.num = '[$' + num.toFixed(2) + '+]';

        },
        makeNumber() {
            if(!this.num || this.num === '[$0+]') {
                this.num = ''
                return;
            }
            if(this.num === 'invalid' || this.num === 0)
                this.num = '';
            else
                this.num = +this.num.replace('$','').replace('[','').replace(']','').replace('+','');
        },


        addWidget(group, impID) {
            const toAdd = this.widgetsToSelectFromForHomeScreen.find(el => el.implementation_id === impID)

            if(!toAdd)
                return;

            if(group === 'selected') {
                this.selectedWidgets.push(toAdd);
                this.suggestedWidgets = this.suggestedWidgets.filter(el => el.implementation_id != impID);
            }
            else{
                this.suggestedWidgets.push(toAdd);
                this.selectedWidgets = this.selectedWidgets.filter(el => el.implementation_id != impID)
            }

        },
        addAllWidgets() {
            this.widgetSelectorOpen = true;
            setTimeout(() => {
                this.suggestedWidgets = [];
                this.selectedWidgets = [...this.widgetsToSelectFromForHomeScreen];
            }, 750);
        },
        removeAllWidgets() {
            this.widgetSelectorOpen = true;
            setTimeout(() => {
                this.selectedWidgets = [];
                this.suggestedWidgets = [...this.widgetsToSelectFromForHomeScreen];
            }, 750);
        },
        cancelWidgetSelection() {
            this.selectedWidgets = [...this.oldSelectedWidgets];
            this.suggestedWidgets = [...this.oldSuggestedWidgets];
            setTimeout(() => {
                this.widgetSelectorOpen = false;
            }, 500);
        },
        saveWidgetSelection() {
            this.widgetSelectorOpen = false;
            this.oldSelectedWidgets = [...this.selectedWidgets];
            this.oldSuggestedWidgets = [...this.suggestedWidgets];
         
        },

        addCountry(group, code) {
            const toAdd = allCountries.find(el => el.alpha3Code === code);

            if(!toAdd)
                return;

            if(group === 'selected') {
                this.selectedCountries.push(toAdd);
                this.suggestedCountries = this.suggestedCountries.filter(el => el.alpha3Code != code);
            }
            else{
                this.suggestedCountries.push(toAdd);
                this.selectedCountries = this.selectedCountries.filter(el => el.alpha3Code != code)
            }

        },
        addAllCountries() {
            this.countrySelectorOpen = true;
            setTimeout(() => {
                this.suggestedCountries = [];
                this.selectedCountries = [...allCountries];
            }, 750);
        },
        removeAllCountries() {
            this.countrySelectorOpen = true;
            setTimeout(() => {
                this.selectedCountries = [];
                this.suggestedCountries = [...allCountries];
            }, 750);
        },
        cancelCountrySelection() {
            this.selectedCountries = [...this.oldSelectedCountries];
            this.suggestedCountries = [...this.oldSuggestedCountries];
            setTimeout(() => {
                this.countrySelectorOpen = false;
            }, 500);
        },
        saveCountrySelection() {
            this.countrySelectorOpen = false;
            this.oldSelectedCountries = [...this.selectedCountries];
            this.oldSuggestedCountries = [...this.suggestedCountries];
         
        }
    },
    watch: {
        widgetSelectorOpen() {
            if(this.selectedWidgets.length) {
                setTimeout(() =>  this.hasSelectedWidgets = true, 500);
            }
            else
                this.hasSelectedWidgets = false;
        },
        countrySelectorOpen() {
            if(this.selectedCountries.length) {
                setTimeout(() =>  this.hasSelectedCountries = true, 500);
            }
            else
                this.hasSelectedCountries = false;
        }
    }
}
</script>

<style lang="scss">
    .rule-setup {
        // letter-spacing: 0.1px;
        // color: $input-color;

        // & .rule-setup-header {
        //     & h2 {
        //         font-size: 20px;
        //         font-weight: 500;
        //         line-height: 44px;
        //         margin-top: 15px;
        //         margin-bottom: 0;
        //     }
        //     color: $input-color;
        // }
        // & .rule-setup-body {
        //     position: relative;

        //     & .rule-setup-body-subtitle {
        //         color: $partner-dimmed-text-1;
        //         font-size: 12px;
        //         margin-bottom: 15px;
        //     }

            // & .rule-setup-body-details-box {
            //     margin-bottom: 20px;
            //     font-size: 12px;
            //     & > div {
            //         display: flex;
            //     }

            //     & .rule-setup-body-details-box-key {
                    
            //         color: $partner-dimmed-text-1;
            //         text-transform: uppercase;
            //         flex-basis: 110px;
            //         letter-spacing: 0.5px;
            //     }
            //     & .rule-setup-body-details-box-value {
                    
            //     }
            // }

            // & .rule-setup-body-divided {
            //     display: flex;

            //     & .narrow-div {
            //         flex-basis: 100px;
            //     }
            //     & .narrow-div-other {
            //         flex-basis: 100%;
            //     }
            // }

            // & .rule-setup-body-rule-selector {
            //     // display: flex;
            //     // align-items: center;

            //     // & span {
            //     //     font-size: 13px;
            //     //     color: $input-color;
            //     //     padding: 0 3px;

            //     //     &:first-child {
            //     //         padding-left: 0;
            //     //     }
            //     //     &:last-child {
            //     //         padding-right: 0;
            //     //     }
            //     // }

            //     & select, 
            //     & input {
            //         height: 25px;
            //         border: 1px solid $partner-border-2;
            //         background-color: $partner-background-2;
            //         font-size: 13px;
            //         border-radius: 4px;
            //         max-width: 100px;
            //         color: $checkbox-active;
            //         padding: 2px 0px;

            //         &.invalid {
            //             color: $partner-red;
            //         }
            //     }
            //     & input {
            //         padding-left: 4px;
            //     }
            // }

            & .rule-setup-body-user-selector,
            & .rule-setup-body-item-selector {
                // border: 1px solid $partner-border-3;
                // border-radius: 20px;
                // padding: 10px;
                // color: $input-color;
                // background-color: $onboard-survey-background;
            }

            & .rule-setup-body-user-selector {
                // position: absolute;
                // left: 20px;
                top: -15px;
                // z-index: 1000;

                // &.name {
                //     top: -100px;
                // }

                // &.email {
                //     top: -14px
                // }

                & .rule-setup-body-user-selector-search-results {
                    // height: 250px;
                    width: 400px;
                    // overflow-y: auto;

                    // @include scrollbarDark;

                    & > div a {
                        // height: 28px;
                        // width: 100%;
                        // display: inline-block;
                        // font-size: 12px;
                        // padding: 5px 10px;
                        // text-decoration: none;
                        // color: $input-color-filter;

                        // &:hover {
                        //     background-color: $partner-background-4;
                        // }
                    }
                }
            }

            // & .rule-setup-body-item-selector {
            //     margin-top: 20px;
            //     font-size: 12px;
            //     color: $partner-dimmed-text-1;

            //     & h4 {
            //         font-size: 14px;
            //         margin-bottom: 0;
            //         margin-top: 0;
            //         font-weight: 400;
            //     }

            //     & h3 {
            //         margin-top: 5px;
            //         font-weight: 500;
            //         color: $input-color;
            //     }

            //     & p {
            //         font-weight: 400;
                    
            //     }

            //     & .rule-setup-body-item-selector-action {
            //         padding: 10px 15px;
            //         background-color: $partner-background-3;
            //         border-radius: 4px;
            //         height: 35vh;
            //         overflow-y: auto;

            //         @include scrollbarDark;

            //         & .selected-widgets,
            //         & .suggested-widgets {
            //             background-color: #252525;

            //         }
            //         & .selected-widgets .widget-tags {
            //             background-color: $partner-background-6;
            //         }

            //     }

            //     & .selected-widgets,
            //         & .suggested-widgets {
            //             min-height: 48px;
            //             padding: 8px;

            //             & .widget-tags {
            //                 color: $input-color;
            //                 background-color: $partner-background-4;
            //                 padding: 2px 10px;
            //                 border-radius: 2px;
            //                 margin-left: 8px;
            //                 margin-bottom: 8px;
            //                 display: inline-block;
            //                 text-decoration: none;
            //             }
            //         }

            // }
        // }
    }
</style>