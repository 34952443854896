<template>
    <div>
        <div class="align-center">
            <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M42 16H6V22C6 23.1046 6.89543 24 8 24H40C41.1046 24 42 23.1046 42 22V16ZM14 12C14 8.68629 16.6863 6 20 6H28C31.3137 6 34 8.68629 34 12H42C44.2091 12 46 13.7909 46 16V36C46 40.4183 42.4183 44 38 44H10C5.58172 44 2 40.4183 2 36V16C2 13.7909 3.79086 12 6 12H14ZM18 12H30C30 10.8954 29.1046 10 28 10H20C18.8954 10 18 10.8954 18 12ZM18 28.0001H8C7.29873 28.0001 6.62556 27.8797 6 27.6586V36.0001C6 38.2092 7.79086 40.0001 10 40.0001H38C40.2091 40.0001 42 38.2092 42 36.0001V27.6586C41.3744 27.8797 40.7013 28.0001 40 28.0001H30V31.2001C30 32.7464 28.7464 34.0001 27.2 34.0001H20.8C19.2536 34.0001 18 32.7464 18 31.2001V28.0001ZM22 30H26V28H22V30Z" fill="white"/>
            </svg>
        </div>
        <div class="steps-tracker">Step 2 of 3</div>
        <h2 class="align-center">{{ title }}</h2>
        <div class="form-text-div dimmed align-center subtitle">{{ subText }}</div>
        <form @submit.prevent class="container">
            <div class="button-div mt-10">
                <base-button
                mode="o-blue fullwidth"
                :link="false"
                @click.native="continuePressed"
                >{{ buttonText }}</base-button>
            </div>
        </form>
    </div>
</template>

<script>
import onBoardMixin from '../../../mixins/onBoardMixin';
import { isFreeMTARoute } from '../../../utility/helper-functions';

export default {
    props: ["promoID"],
    mixins: [onBoardMixin],
    computed: {
        title() {
            if(isFreeMTARoute(this.promoID))
                return "Choose your training";

            return "Choose your plan";
        },
        subText() {
            if(isFreeMTARoute(this.promoID))
                return "We have free or premium training. Decide which is best for you.";
                
            return "We have free or premium plans. Decide which is best for you.";

            // return "Don't worry, you can downgrade or upgrade your plan at any time.";

            
        },
        buttonText() {
            if(isFreeMTARoute(this.promoID))
                return "See The Options";

            return "See The Plans";

            
        }
    },
    methods: {
        continuePressed() {
            this.$emit("update-step", {
                step: "4"
            });
            
        }
    }
}
</script>