<template>
    <div>
        <div class="align-center">
            <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M44 24C44 35.0457 35.0457 44 24 44C12.9543 44 4 35.0457 4 24C4 12.9543 12.9543 4 24 4C35.0457 4 44 12.9543 44 24ZM8 24C8 32.8366 15.1634 40 24 40C32.8366 40 40 32.8366 40 24C40 15.1634 32.8366 8 24 8C15.1634 8 8 15.1634 8 24ZM22 16V26C22 27.1046 22.8954 28 24 28C25.1046 28 26 27.1046 26 26V16C26 14.8954 25.1046 14 24 14C22.8954 14 22 14.8954 22 16ZM26 32C26 33.1046 25.1046 34 24 34C22.8954 34 22 33.1046 22 32C22 30.8954 22.8954 30 24 30C25.1046 30 26 30.8954 26 32Z" fill="#FC5A5A"/>
            </svg>
        </div>
        <div class="steps-tracker">Step 3 of 3</div>
        <h2 class="align-center">Your Payment Failed!</h2>
        <div class="form-text-div dimmed align-center subtitle">Your product has not been activated. Select an option  below to proceed.</div>
        <form @submit.prevent class="container">
            <div class="button-div mt-10">
                <base-button
                mode="o-blue fullwidth"
                :link="false"
                @click.native="updatePayment"
                >Update Payment Details</base-button>
            </div>
            <div class="button-div mt-10">
                <base-button
                mode="o-grey fullwidth"
                :link="false"
                @click.native="continuePressed"
                >Continue on Free Plan instead</base-button>
            </div>
        </form>
    </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';
import { talkToServer } from '../../../utility/helper-functions';

const payments = createNamespacedHelpers("payments");

export default {
    props: ["details"],
    created() {
        talkToServer("/journey/failed", "GET", null, null, true);
    },
    methods: {
        ...payments.mapActions(['setFailedPayment']),
        continuePressed() {
            this.$emit("update-step", {
                step: "8"
            });
        },
        updatePayment() {
            this.setFailedPayment({});
            this.$emit("update-step", {
                step: "5",
                plan: this.details.plan
            });
        }
    }
}
</script>