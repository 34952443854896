<template>
    <div>
        <div v-if="aa" style="color: white; line-height: 12px;">

            <div style="font-size: 6px; font-weight: 300; letter-spacing: 1.5px; margin-left: 0%;">THE</div>
            <div style="font-size: 16px;">AA Financial Research</div>
            <div style="font-size: 6px; font-weight: 300; letter-spacing: 1.5px; margin: 0 auto; text-align: center;">ANALYST DESK</div>
        </div>

        <img v-else class="site-logo" :src="siteLogo" alt="Site logo" srcset="" />
    </div>
</template>

<script>
import { frontFacingAssets } from '../../../utility/helper-functions';
export default {
    
    computed: {
        aa() {
            return window.bootUp.entity.name.indexOf('aafinancialr') > -1;
        },
        siteLogo() {
            return frontFacingAssets() + window.bootUp.entity.name + '.png';
        },
    },
    
}
</script>