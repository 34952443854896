import { addDays } from "../../utility/datetime";
import { sortDataBy } from "../../utility/helper-functions";

export default {
    fetchInitialHeadlines(obj) {
        const queryObject = {
            name: "initial-headline-fetch",
            objStore: "headlines",
            sources: obj.sources,
            categories: obj.categories,
            props: obj.props
        }

        // console.log("q",queryObject);

        return window.bootUp.iDB.getRecords(queryObject).then(response => {
            // console.log("these are the responses", response);
            response = response.filter(headline => !headline.deleted_at);
            sortDataBy(response, "published_at", "timeDESC");
            return response.slice(0,25);
        });
    },
    loadMoreHeadlines(slug, sources, categories, props, offset, limit, isSearchMode, fromTo, keywords) {
        const queryObject = {
            slug: slug,
            name: "headlines-load-more",
            objStore: "headlines",
            sources: sources,
            categories: categories,
            props: props,
            offset: offset,
            limit: limit,
            fromTo: fromTo,
            isSearchMode: isSearchMode,
            keywords: keywords
        } 


        return window.bootUp.iDB.getMoreRecords(queryObject).then(response => {
            response = response.filter(headline => !headline.deleted_at);
            sortDataBy(response, "published_at", "timeDESC");

            return response;
        });
    },
    fetchHeadlinesFromServer(obj) {
        const startDate = addDays(-7);
        const queryObject = {

            name: "headlines-inital-fetch-server",
            objStore: "headlines",
            query: "/widgets/headlines/en/since/" + startDate,
            categories: obj.categories

        }

        return window.bootUp.iDB.getRecordsFromServer(queryObject).then(r => r);
    },
    fetchCategoryHeadlines({sources, categories, prop, offset, limit, isSearchMode=false, fromTo="*/*"}) {
        const queryObject = {
            query: "/widgets/headlines/en/search",
            name: "category-headlines",
            objStore: "headlines",
            sources: sources,
            categories: categories,
            prop: prop,
            offset: offset,
            limit: limit,
            fromTo: fromTo,
            isSearchMode: isSearchMode,
            keywords: "*"
        } 

        return window.bootUp.iDB.getRecordsFromServer(queryObject).then(r => {
           
            return r;
        });
    }
}

