import { usersTime } from "../../utility/datetime";
import { cloneDeep } from "lodash";

export default {
    setWidgets(state, payload) {
        state.widgets = payload;
    },
    setStoreStartIndex(state, payload) {
        state.storeStartIndex = payload;
    },
    setStoreSearchMode(state, payload) {
        state.storeSearchMode = payload;
    },
    setSearchStartIndex(state, payload) {
        state.searchStartIndex = payload;
    },
    setStoreEndIndex(state, payload) {
        state.storeEndIndex = payload;
    },
    setSearchEndIndex(state, payload) {
        state.searchEndIndex = payload;
    },

    addPusherChannel(state, payload) {
        state.pusherChannels.push(payload);
    },
    removePusherChannel(state, payload) {
        const index = state.pusherChannels.findIndex(el => el === payload);
        if(index > -1)
            state.pusherChannels.splice(index, 1);
    },
    clearPusherChannels(state) {
        state.pusherChannels = [];
    },

    addLookupCategories(state, payload) {
        const widgets = cloneDeep(state.widgets);
        const currentWidget = widgets.find(wdg => wdg.implementation_id === payload.implementation_id);
        if(currentWidget && currentWidget.settings) {
            currentWidget.settings.categories = {
                area: "Options",
                editableByUser: true,
                formJSON: payload.obj
            };

            const index = widgets.findIndex(wdg => wdg.implementation_id === payload.implementation_id);
            widgets.splice(index, 1, currentWidget); 
            state.widgets = widgets;
        }
    },

    setRatings(state, payload) {
        state.ratings = payload;
    },

    setSingleRating(state, payload) {
        state.ratings[payload] = usersTime(new Date(), true, true, true, {dateFormat: "y-m-d", timeFormat: "24hr"}, "UTC");
    }
};