<template>
    <div class="partner-funnel">
        <div class="partner-funnel-header">
            <div class="partner-progress">
                <div class="partner-progress-text">
                    <span>Progress </span>
                    <span class="circle"></span>
                    <span> {{ (step - 1) / 2 * 100 }}%</span>

                </div>
                <div>
                    <div class="partner-progress-graph">
                        <span class="partner-progress-graph-line" :style="{'width': (step - 1) / 2 * 100 + '%'}"></span>
                    </div>
                </div>
            </div>
        </div>
        <div class="partner-funnel-main">
            <transition
            mode="out-in"
            enter-active-class="animate__animated animate__fadeIn"
            leave-active-class="animate__animated animate__fadeOut">
                <div>
                    <component
                    :is="currentComponent"
                    @partner-new-step="next"
                    @register-login-successful="registerLoginSuccessful"
                    >
                    </component>
                    <!-- <div 
                    v-if="currentComponent !== 'PartnerBuilding'"
                    class="partner-redirect">Already registered? <router-link to="/admin-login">Sign in</router-link></div> -->
                </div>
            </transition>
        </div>
    </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';
import Constants from '../../../utility/constants';
import BusinessDetails from './BusinessDetails.vue';
import PartnerBuilding from './PartnerBuilding.vue';
import PartnerSignup from './PartnerSignup.vue';

const general = createNamespacedHelpers("general");

export default {
    components: {
        BusinessDetails,
        PartnerBuilding,
        PartnerSignup
    },
    created() {
        this.renderComponent();
    },
    computed: {
        ...general.mapGetters(["brokerRegistrationData"]),
    },
    data() {
        return {
            currentComponent: '',
            step: 1
        }
    },
    methods: {
        ...general.mapActions(["setBrokerRegistrationData"]),

        next(obj) {
            this.setBrokerRegistrationData(obj);
            if(obj)
                this.step++;
            else
                this.step--;

            this.renderComponent();
        },
        renderComponent() {
            this.currentComponent = Constants.PARTNER_STEPS[this.step];
        },
        registerLoginSuccessful(val) {
            this.$emit("register-login-successful", val);
        }
    }
}
</script>