import { eventBus } from '../main';
import VideoService from '../services/video/video.service';
import _ from 'lodash';
import ContentLockedMixin from '../mixins/contentLockedMixin';
import ScrollMixin from './scrollMixins';

import { createNamespacedHelpers } from 'vuex';
import { mergeArraysByProp } from '../utility/helper-functions';
// import { arrayIntersect, sortDataBy } from '../../utility/helper-functions';

const libraryNS = createNamespacedHelpers('library');
const headlinesNS = createNamespacedHelpers('headlines');
const widgets = createNamespacedHelpers('widget');

export default {
    props: ["details"],
    mixins: [ScrollMixin, ContentLockedMixin],
    async created() {
        eventBus.$on("widget-resize", obj => {
            if(obj.id === this.details.layoutID) {
                
                if(this.details.widgetCode === 'TCVIDEO') {
                    // console.log(obj.height)
                    const innerHeight = obj.height;
                    this.playlistHeight = {height: innerHeight - 50 + 'px'};
                    
                    this.innerElementHeight = {height: innerHeight + 'px', 'overflow-y': 'auto', 'overflow-x': 'hidden'};

                    // setTimeout(() => {
                    //     const vidHeight = document.querySelector(".video-player__iframe")?.offsetHeight;
                    //     const titleHeight = document.querySelector(".video-player__description--title")?.offsetHeight;
                       
                    //     this.descriptionHeight = {height: innerHeight - vidHeight - titleHeight - 60 + 'px', 'overflow-y': 'auto', 'overflow-x': 'hidden'}
                    // }, 300);
                    
                }

            }
            
        });

        if(this.topLevelCategories.length === 1) {
            this.currentCategories = this.topLevelCategories;
        }
        await this.getCats();
        
        // if(this.readyForVideoProcessing)
            this.fetchInitialItems();



        // const obj = {
        //     menu: {
        //         // isSearchEnabled: false,
        //         // isGlossaryEnabled: false,
        //         isVideoQuestionEnabled: true,
        //     }
            
        // }
        // this.$emit('set-frame', obj);

        // if(this.readyForHeadlineProcessing) {
        //     this.fetchInitialHeadlines(this.categories);
        // }
    },
    provide() {
        return {
            $activeVideo: () => this.activeVideo
        }
    },
    computed: {
        ...libraryNS.mapGetters(['lastVideoUpdate', 'lastVideoDelete']),
        ...headlinesNS.mapGetters(['readyForHeadlineProcessing']),
        ...widgets.mapGetters(['getWidgetByImplementationId']),

        fontsize() {
            return 'medium-font';
            // if(this.details.userSettings)
            //     return this.details.userSettings.fontsize;
            
            // return '';
        },
        impID() {   
            return this.details.implementation_id;
        },
        courseRootText() {
            
            const html = this.getWidgetByImplementationId(this.details.implementation_id).associated_text;
            
            if(html.length > 10)
                return {text: html};
            
            return '';
        }
    },
    data() {
        return {
            topLevelCategories: (this.$store.getters['widget/getWidgetByImplementationId'](this.details.implementation_id)).categories, //[38],//[56, 57],//
            currentCategories: -1,
            innerElementHeight: '',
            // descriptionHeight: '',
            playlistHeight: '',
            resultsReady: true,
            sortedItems: {},
            itemsToFetch: null,
            bulkItems: [],
            serverForceFetchDone: false,
            activeVideo: null,
            videoInPlayer: [],
            firstRender: true,
            fetchTimeout: null,
            searchMode: false,
            noMoreItems: false,
            videoAutoPlay: false,
            currentResourcesForVideo: null,
            orderByProp: "order_by",
            orderByDirection: "asc"

        }
    },
    methods: {
        renderVideo(obj) {
            this.videoInPlayer = [obj];
            this.activeVideo = obj.id;

            VideoService.fetchResourcesForVideo(obj.id).then(res => {
                const vid = res[0];
                if(vid.associated_text?.length > 15 || vid.resource) {
                    this.currentResourcesForVideo = {
                        text: vid.associated_text.length > 15 ? vid.associated_text : "",
                        resource: vid.resource
                    }
                }else{
                    this.currentResourcesForVideo = null
                }
                // console.log(this.currentResourcesForVideo);
            });
            
            this.$nextTick(() => eventBus.$emit("widget-resize", {
                id: this.details.layoutID,
                height: this.details.height,
                
            }));

            setTimeout(() => {
                this.videoAutoPlay = true;
            }, 500);
            
        },
        async fetchInitialItems() {
            this.resultsReady = false;
            
            const obj = {
                categories: this.itemsToFetch,
                type: this.orderByProp === "order_by" ? "course" : ""
            };

            this.bulkItems = await VideoService.fetchInitialItems(obj);
            if(this.bulkItems.categories.length || this.bulkItems.videos.length) {
                this.resultsReady = true;
            }
            // console.log("bb",this.bulkItems);
            // in case videos have not been loaded on the cache
            // else if(!this.serverForceFetchDone) {
            //     this.fetchItemsFromServer();
            // }else if(this.serverForceFetchDone && !this.bulkItems.length)
            //     this.resultsReady = true;

            this.serverForceFetchDone = true;
            // console.log("itemsToFetch", this.itemsToFetch);
            if(this.itemsToFetch.length === 1)
                this.currentCategories = _.cloneDeep(this.itemsToFetch);
            else
                this.currentCategories = -1;

            // console.log("curr Cats", this.currentCategories);
            this.sortDataByCategories();
            this.focusFirstVideoIfNeeded();
            
        },
        sortDataByCategories() {
            
            let cats = _.orderBy(this.bulkItems.categories, el => el.order_by, "asc");
            const sortedItems = {};

            if(!cats.length)
                cats = [{ id: 98 }];
            cats.forEach(el => {
                const id = el.id;
                if(!sortedItems[id]);
                    sortedItems[id] = [];
                
                let vids = [];
                vids = _.orderBy(this.bulkItems.videos.filter(el => el.categories.includes(id)), el => el[this.orderByProp], this.orderByDirection);
                    
                sortedItems[id] = vids;

            });
            this.sortedItems = _.cloneDeep(sortedItems);

        },
        async loadMore () {
            if(this.noMoreItems)
                return;

            const id= this.topLevelCategories[0];
            const bulkItems = _.cloneDeep(this.bulkItems);
            // console.log(this.bulkItems.videos, id)
            this.resultsReady = false;
            const limit = 12;
            let offset = this.sortedItems[id]?.length;
            let from = "";
            let to = ""
            let keywords ="*";
            let searchMode = false;
            let type = this.orderByProp === "order_by" ? "course" : ""

            if(this.searchTerms.keywords) {
                // from = this.searchTerms.from;
                // to = this.searchTerms.to;
                keywords = this.searchTerms.keywords;
                offset = this.bulkItems.length ? this.bulkItems.length : 0;
                searchMode = true;
                this.searchMode = true;
                
            }

            let response = await VideoService.loadMoreItems(this.itemsToFetch, offset, limit, searchMode, from, to, keywords, type);

            let bulkItemVideos = mergeArraysByProp(bulkItems.videos || [], response, "id");
            if(response.length)
                bulkItemVideos = _.orderBy(bulkItemVideos, el => el[this.orderByProp], this.orderByDirection);

            bulkItems.videos = bulkItemVideos;
            this.bulkItems = bulkItems;

            // console.log(this.bulkItems);
            this.sortDataByCategories();

            if(!response.length || this.searchMode) {
                this.noMoreItems = true;
            }
            this.resultsReady = true;

            eventBus.$emit("scroller-calc-vertical", {
                instanceID: this.$root.instanceID
            });

        },
        focusFirstVideoIfNeeded() {
            
            const firstVideo = this.sortedItems[this.topLevelCategories[0]][0];

            if(firstVideo && this.firstRender) {
                
                const src = "https://player.vimeo.com/video/" + firstVideo.video_id;

                const obj = {
                    id: firstVideo.id,
                    src: src,
                    title: firstVideo.video_name,
                    time: firstVideo.created_at
                };

                this.renderVideo(obj);

                this.firstRender = false;
            }
                
        },
        async getCats() {
            const subcats = await VideoService.getSubCategoriesForCourse(...this.topLevelCategories);
            // console.log("subcats ready", this.details);
            this.itemsToFetch = [...this.topLevelCategories, ...subcats.map(el => el.id)];
            return;
        }
    },
    watch: {
        lastVideoUpdate(val) {
            if(val.widget_implementation_id !== this.impID)
                return;

            clearTimeout(this.fetchTimeout);
            this.fetchTimeout = setTimeout(() => {
                    ////////

                    const bulkItems = _.cloneDeep(this.bulkItems);

                    const video = val;

                    if(video.deleted_at) {
                        const index = bulkItems.videos.findIndex(element => element.id === video.id);
    
                        if(index > -1)
                            bulkItems.videos.splice(index,1);
    
                    }else{
                        
                        if(!this.searchMode) {
    
                            const ID = video.id;
                            const index = bulkItems.videos.findIndex(element => element.id === ID);
    
                            if(index > -1) {
                                bulkItems.videos.splice(index, 1, video);
                            }else{
                                bulkItems.videos.push(video);
                            }

                        }
                    }

                    this.bulkItems = bulkItems;
        
                    this.sortDataByCategories();
        
                    ////////
            }, 1000);

        },
        // lastVideoDelete(val) {
        //     if(val.widget_implementation_id !== this.impID)
        //         return;

        //     clearTimeout(this.fetchTimeout);
        //     this.fetchTimeout = setTimeout(() => this.fetchInitialItems(), 1000);
        // },
        async readyForHeadlineProcessing(val) {
            if(val) {
                await this.getCats();
                this.fetchInitialItems();
            }
        }
    }
}
