import { createNamespacedHelpers } from "vuex";
import { eventBus } from "../main";
import IntRateService from '../services/intrates/intrate.service';
import { cloneDeep, orderBy } from 'lodash';
import { humanizedTimeDifference, usersTime } from "../utility/datetime";

const intrateNS = createNamespacedHelpers('interestRates');

export default {
    created() {
        eventBus.$on("widget-resize", obj => {
            
            if(obj.id === this.details.layoutID) {
                this.height = obj.height;
            }

        });

        // this.maintenance = this.checkMaintenance();

        // this.maintenaceInterval = setInterval(() => {
        //     this.maintenance = this.checkMaintenance();
        // },300000);
        
        this.sorting = {
            label: 'central_bank',
            orderBy: 'asc'
        }

        if(this.details.userSettings.orderby)
            this.sorting = this.details.userSettings.orderby;

        this.fetchInitialIntRates();

        
        this.lastUpdateInterval = setInterval(() => {
            this.calcHumanizedUpdatedAt();
            
        }, 30000);


    },
    beforeDestroy() {
        clearInterval(this.lastUpdateInterval);
        clearInterval(this.maintenaceInterval);
    },
    computed:{
        ...intrateNS.mapGetters(['lastIntRateUpdate', 'lastIntRateDelete', 'humanizedUpdatedAt']),
        verticalHeight() {
            let adj = 70;
            return {height: this.height - adj + 'px'};
           
        },
        styleData() {
            return {
                height: this.height,
                bankCount: this.banks.length
            }
        },
    },
    data() {
        return {
            banks: [],
            fetchMode: true,
            resultsReady: true,
            serverForceFetchDone: false,
            updatedAt: '',
            sorting: {},
            height: 0,
            lastUpdateInterval: null,
            panelForEmbed: {}
            // maintenance: false,
            // maintenaceInterval: null
        }

    },
    methods: {
        ...intrateNS.mapActions(['setHumanizedUpdatedAt']),
        async fetchInitialIntRates() {
            this.resultsReady = false;

            this.banks = await IntRateService.fetchInitialIntRates();
            if(this.banks.length) {
                if(window.bootUp.user?.irpt === 'freesite') {
                    this.banks = this.banks.filter(el => el.central_bank_code === 'BOE' ||
                    el.central_bank_code ==='FED');
                }
                this.orderBankRows();
                
                this.resultsReady = true;
                
                // this.panelForEmbed = {
                //     bank: this.banks[0],
                //     isLocked: this.isLocked
                // }
            }
            //in case int rates have not been loaded on the cache
            else if(!this.serverForceFetchDone) {
                this.fetchIntRatesFromServer();
            }else if(this.serverForceFetchDone && !this.banks.length)
                this.resultsReady = true;


            this.serverForceFetchDone = true;
        },
        async fetchIntRatesFromServer() {
            await IntRateService.fetchIntRatesFromServer();
            this.fetchInitialIntRates();
        },
        sortData(label) {
            let orderBy = 'asc';
            if(this.sorting.label === label)
                if(this.sorting.orderBy === 'asc')
                    orderBy = 'desc';
                else
                    orderBy = 'asc'

            this.sorting = {
                label: label,
                orderBy: orderBy
            }
            this.orderBankRows();
            this.$emit("update-user-settings", {key : 'orderby', val: this.sorting});
        },
        orderBankRows() {
            // console.log(this.sorting);

            const banks = orderBy(this.banks, bank => bank[this.sorting.label], [this.sorting.orderBy]);
            this.banks = banks;
            this.updatedAt = orderBy(this.banks, bank => bank.updated_at, "desc")[0].updated_at;

            this.calcHumanizedUpdatedAt();
            
            // const banks = [];

            // this.centralBankOrder.forEach(cbCode => {
            //     bankArr.some(dataSet => {
            //         if(cbCode === dataSet.central_bank_code) {
            //             banks.push(dataSet);
            //             if(!this.updatedAt || this.updatedAt < dataSet.updated_at)
            //                 this.updatedAt = dataSet.updated_at
                        
            //         }
            //     });
            // });

            // return banks;
        }, 
        calcHumanizedUpdatedAt() {
            const now = usersTime(new Date(), true, true, true, {
                dateFormat: "y-m-d",
                timeFormat: "24hr"
            }, "UTC");
            
            this.setHumanizedUpdatedAt(humanizedTimeDifference(this.updatedAt, now));
        },
        // checkMaintenance() {
        //     const now = usersTime(new Date(), true, true, true, {
        //         dateFormat: "y-m-d",
        //         timeFormat: "24hr"
        //     }, "UTC");

        //     if(now > "2023-02-26 23:54:00")
        //         return true;

        //     return false;
        // }
    },
    watch: {
        lastIntRateUpdate(val) {

            let banks = cloneDeep(this.banks);

            val.forEach(bank => {

                const ID = bank.id;
                const index = banks.findIndex(element => element.id === ID);

                if(index > -1) {
                    banks.splice(index, 1, bank);
                }else{
                    banks.push(bank);
                }
                
            });

            if(window.bootUp.user?.irpt === 'freesite') {
                banks = this.banks.filter(el => el.central_bank_code === 'BOE' ||
                el.central_bank_code ==='FED');
            }

            this.banks = banks;

            this.orderBankRows();


        },
        lastIntRateDelete(val) {
            const index = this.banks.findIndex(bank => bank.id === val);
            
            this.banks.splice(index, 1);
        }

    }
}