import mutations from './mutations';
import actions from './actions';
import getters from './getters';

export default {
    namespaced: true,
    state() {
        return {
            widgets: [],
            storeStartIndex: 0,
            storeEndIndex: 0,
            storeSearchMode: false,
            searchStartIndex: 0,
            searchEndIndex: 0,
            pusherChannels: [],
            defaultPusherChannels: ["announcements", "payment-processing", "entity-sync", "training-centre", "user", "bm"],
            ratings: null
        }
    },
    mutations,
    actions,
    getters
}