export default {
    widgets(state) {
        return state.widgets;
    },
    getWidgetByImplementationId: (state) => (id) => {
        return state.widgets.find(element => element.implementation_id === id);
    },
    getWidgetImplementationIdBy: (state) => (key, val) => {
        return (state.widgets.find(element => element[key] === val)).implementation_id;
    }, 
    getCodeByImplementationId: (state) => (id) => {
        return (state.widgets.find(element => element.implementation_id === id))?.code;
    },
    getWidgetByCode: state => code => {
        return state.widgets.find(element => element.code === code);
    },
    getWidgetSettings: (_, getters) => (id) => {
        const widget = getters.getWidgetByImplementationId(id);
        return widget.settings;
    },
    getStoreStartIndex(state) {
        return state.storeStartIndex;
    },
    getStoreSearchMode(state) {
        return state.storeSearchMode;
    },
    getSearchStartIndex(state) {
        return state.searchStartIndex;
    },
    getStoreEndIndex(state) {
        return state.storeEndIndex;
    },
    getSearchEndIndex(state) {
        return state.searchEndIndex;
    },
    pusherChannels(state) {
        return state.pusherChannels;
    },

    ratings(state) {
        return state.ratings;
    },
    hasTCWidgets(_, getters, _3, rootGetters) {
        const available = rootGetters["payments/availableWidgets"];

        if(!available) {
            return false;
        }

        let found;
        
        available.forEach(el => {
            const widget = getters.getWidgetByImplementationId(+el);
            
            if(widget && !found) {
                found = widget.code === 'TCSTREAM' || widget.code === 'TCVIDEO';
            }

        });

        return found;
    },
    getWidgetImpIDByCode: (getters) => (code) => {
        const widget = getters.widgets.find(el => el.code === code);
        return widget?.implementation_id;
        
    }
};