// import { getParameterByName } from '../utility/helper-functions';
import {createNamespacedHelpers} from 'vuex';
import {talkToServer} from "../utility/helper-functions";
const dashboards = createNamespacedHelpers('dashboard');

export default {
    computed: {
        ...dashboards.mapGetters(['layouts']),
    },
    methods: {
        ...dashboards.mapActions(['setAnalChatActiveOn']),
        startIntercom () {
            if (window.startedIntercom) //|| getParameterByName("popout")
                return;

            window.intercomSettings = {
                app_id: "tplvkcfy",
                email: window.user.email,
                name: window.user.name,
                show: true
            };

            window.Intercom('boot', window.intercomSettings);
            // window.Intercom('showNewMessage');
            window.startedIntercom = true;
        },

        shutDownIntercom() {
            window.Intercom('shutdown');
            window.startedIntercom = false;
        },

        removeAnalChatWidget() {
            const obj = {
                dashboardID: null
            };

            talkToServer("/setting/user/analyst-chat", "POST", null, obj, true).then(() => {
                this.shutDownIntercom();
                this.setAnalChatActiveOn(null);
            })
        }
    }
}
