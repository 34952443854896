<template>
    <div class="rule-setup">
        <div class="rule-setup-header">
            <h2>{{ title }} </h2>
        </div>
        <div class="rule-setup-body">
            <div class="rule-setup-body-subtitle">{{ subtitle }}</div>
            <div class="rule-setup-body-details-box">
                <div v-if="currentActiveRules">
                    <div class="rule-setup-body-details-box-key">Current access: </div>
                    <div class="rule-setup-body-details-box-value"><span v-for="curr in currentActiveRules"
                        :key="curr.id">{{ curr.rule.unlock_time_units }} {{ curr.rule.unlock_time_period }} access to {{ getWidgetNames(curr.widgets) }} {{ checkIfQnaShouldBeShown(curr) }} from {{ formatDate(curr.unlocked_at )}} until {{ formatDate(curr.locked_at) }}
                        <br /><br />
                        </span></div>
                </div>
            </div>

            <base-input
            style="display:none;"
            elementType="select"
            :class="{invalid: error.account_manager_id}"
            v-model="profile.account_manager_id"
            forID="agent_name"
            :inputClass="'small-font partner-input'"
            formControlClass=""
            label="Agent"
            labelClass="small-font u-input-color"
            :disabled="editingExisting"
            :invalid="error.account_manager_id"
            :invalidMessage="error.account_manager_id"
            @clear-validity="clearValidity"
            @update="error.agent_name = ''"
            >
                <option :value="null" selected>{{ profile.bm ? 'N/A' : 'Select...'}}</option>
                <option v-for="bm in bms"
                :key="bm.id"
                :value="+bm.id">{{ bm.name }}</option>
            </base-input>

            <base-input
                elementType="input"
                :class="{invalid: error.name}"
                inputType="text"
                :required="true"
                v-model="profile.name"
                forID="name"
                :placeholder="'So we can communicate with them about their product'"
                inputClass="partner-input"
                formControlClass=""
                label="Name"
                labelClass="small-font u-input-color"
                :disabled="editingExisting"
                :invalid="error.name"
                :invalidMessage="error.name"
                @clear-validity="clearValidity"
                @keyup="lookUpUser"
                ></base-input>

                <base-input
                elementType="input"
                :class="{invalid: error.email}"
                inputType="email"
                :required="true"
                v-model="profile.email"
                forID="email"
                :placeholder="'So we can send the client their log in details'"
                inputClass="partner-input"
                formControlClass=""
                label="Email"
                labelClass="small-font u-input-color"
                :disabled="editingExisting"
                :invalid="error.email"
                :invalidMessage="error.email"
                @clear-validity="clearValidity"
                @keyup="lookUpUser"
                ></base-input>
                <div 
                v-click-outside="outsideClick"
                style="position: relative;">
                    <div 
                    v-if="lookUpData.length"
                    class="rule-setup-body-user-selector"
                    :class="activeInput">
                        <div class="rule-setup-body-user-selector-search">
                        </div>
                        <div class="rule-setup-body-user-selector-search-results">
                            <div
                            v-for="usr in lookUpData"
                            :key="usr.id"
                            >
                                <a href="javascript:void(0);" @click="selectUser(usr.id)">
                                    <span style="display: flex; justify-content: space-between;">
                                        <span style="flex-basis: 250px; text-overflow: ellipsis; overflow-x: hidden;white-space: nowrap;">{{ usr.name }} {{ usr.email }}</span> 
                                        <span><font-awesome-icon :icon="['fas', 'plus']" /></span>
                                    </span>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
                <vue-phone-number-input 
                style="margin-top: 35px;"
                no-example  
                size="sm"
                :disabled="editingExisting"
                :default-phone-number="selectedNumber.number"
                :default-country-code="selectedNumber.countryCode"
                :dark="true"
                :darkColor="'#1B1B1B'"
                :class="{'invalid-input-phone': error.phone_number}"
                v-model="selectedNumber.number"   
                @update="updatePhoneNumber"
                >
                </vue-phone-number-input>
                <div class="error" v-if="error.phone_number">{{ error.phone_number }}</div>


        <div v-if="!isBmMode" class="rule-setup-body-divided" style="margin-top: 10px;">
                <div class="narrow-div">
                    <base-input
                    elementType="select"
                    :class="{invalid: error.unlock_time_units}"
                    v-model="rule.unlock_time_units"
                    forID="unlock_time_units"
                    :inputClass="'small-font partner-input'"
                    formControlClass=""
                    label="Length"
                    labelClass="small-font u-input-color"
                    :invalid="error.unlock_time_units"
                    :invalidMessage="error.unlock_time_units"
                    @clear-validity="clearValidity"
                    @update="error.unlock_time_units = ''"
                    >
                        <option :value="0" selected disabled hidden>0</option>
                        <option v-for="unit in lenghtArray" 
                        :key="unit"
                        :value="unit">{{unit}}</option>
                    </base-input>
                </div>
                <div class="narrow-div-other" style="margin-left: 25px;">
                    <base-input
                    elementType="select"
                    :class="{invalid: error.unlock_time_period}"
                    v-model="rule.unlock_time_period"
                    forID="unlock_time_period"
                    :inputClass="'small-font partner-input'"
                    formControlClass=""
                    label="Days/weeks/months"
                    labelClass="small-font u-input-color"
                    :invalid="error.unlock_time_period"
                    :invalidMessage="error.unlock_time_period"
                    @clear-validity="clearValidity"
                    @change="updatePeriod"
                    >
                        <option :value="0" selected disabled hidden>Select...</option>
                        <option value="day">Day(s)</option>
                        <option value="week">Week(s)</option>
                        <option value="month">Month(s)</option>
                    </base-input>
                </div>
            </div>

            <div v-if="!isBmMode" class="rule-setup-body-divided" style="margin: -15px 0;">
                <div class="narrow-div">
                    <base-input
                    elementType="select"
                    :class="{invalid: error.currency}"
                    v-model="rule.currency"
                    forID="currency"
                    :inputClass="'small-font partner-input'"
                    formControlClass=""
                    label="Currency"
                    labelClass="small-font u-input-color"
                    :invalid="error.currency"
                    :invalidMessage="error.currency"
                    @clear-validity="clearValidity"
                    @update="error.currency = ''"
                    >
                        <option :value="0" selected disabled hidden>$/£/€</option>
                        <option v-for="unit in currencies" 
                        :key="unit"
                        :value="unit">{{unit}}</option>
                    </base-input>
                </div>
                <div class="narrow-div-other" style="margin-left: 25px;">
                    <base-input
                    elementType="input"
                    :class="{invalid: error.deposit_amount}"
                    inputType="number"
                    v-model="rule.deposit_amount"
                    forID="deposit_amount"
                    :placeholder="'To track average client deposit size'"
                    inputClass="partner-input"
                    formControlClass=""
                    label="Deposit Amount"
                    labelClass="small-font u-input-color"
                    :invalid="error.deposit_amount"
                    :invalidMessage="error.deposit_amount"
                    @clear-validity="clearValidity"
                    ></base-input>
                </div>
                <div class="narrow-div-other" style="margin-left: 25px;">
                    <base-input
                    elementType="input"
                    :class="{invalid: error.account_balance}"
                    inputType="number"
                    v-model="rule.account_balance"
                    forID="account_balance"
                    :placeholder="'To track average client account size'"
                    inputClass="partner-input"
                    formControlClass=""
                    label="Current trading account balance"
                    labelClass="small-font u-input-color"
                    :invalid="error.account_balance"
                    :invalidMessage="error.account_balance"
                    @clear-validity="clearValidity"
                    ></base-input>
                </div>

            </div>

            <label style="margin-top: 28px;" 
                :class="{'u-color-12': error.widget}"
                >Which features to enable?</label>
            <div v-for="i in selectorIteration"
            :key="i"
            >
                <widget-selector
                @widget-selection-changed="impIDs => widgetSelectionChanged(impIDs)"
                @qna-on-changed="val => qna = val"
                :qnaActiveOnPrevRequest="qnaActiveOnPrevRequest"
                :qnaStartingValue="qna"
                :explainerText="'These are the tools that your clients will have access to and engage with on the analyst-desk.<br />Upon approval, the client will have access to the specific widgets that you include here.'"
                ></widget-selector>
            </div>
            <div style="margin-top: 20px;" class="error">{{ serverError }}</div>

            <div style="margin-top: 5px; margin-bottom: 15px;">
                <base-button
                mode="o-blue o-height-30"
                :link="false"
                :disabled="isProcessing"
                :showSpinner="isProcessing"
                @click.native="submit"
                >{{ isApproved ? "Update Access" : "Create Access" }}
                </base-button>
                 <base-button
                v-if="editingExisting"
                style="margin-left: 15px;"
                mode="o-tranparent-red o-red-text o-semibolt-text o-height-30"
                :link="false"
                @click.native="clearUserSelection"
                >Clear User Selection
                </base-button>
            </div>



        </div>
    </div>
</template>

<script>
import Swal from "sweetalert2";
import { createNamespacedHelpers } from 'vuex';
import { eventBus } from '../../../../main';
import _ from "lodash";
import { talkToServer } from '../../../../utility/helper-functions';
import countries from '../../../../assets/countries.json';
import { usersTime } from '../../../../utility/datetime';
import WidgetSelector from './WidgetSelector.vue';
import Constants from '../../../../utility/constants';

const general = createNamespacedHelpers('general');
const payments = createNamespacedHelpers('payments');
const rules = createNamespacedHelpers('rules');

export default {
    props: ["modal"],
    components: {
        WidgetSelector,
    },
    created() {
        

        if(!this.profile.account_manager_id)
            this.profile.account_manager_id = this.bms.find(el => el.id === this.profile.account_manager_id)?.id || window.loggedInUserId;

        if(this.isBmMode) {
            this.rule.unlock_time_units = 10;
            this.rule.unlock_time_period = "year";
            this.rule.currency = "USD";
            this.rule.deposit_amount = 0;
            this.rule.account_balance = 0;

            this.profile.bm = true;
        }

        this.updatePhoneNumber({isValid: true});

        if(this.entityQnaCustom)
            this.qna = true;

        

    },
    computed: {
        ...payments.mapGetters(["widgetsToSelectFromForHomeScreen"]),
        ...rules.mapGetters(['userList', 'bms', 'getUserByRuleID', 'getAllIndRuleUsers']),
        ...general.mapGetters(['entityQnaCustom']),
        isBmMode() {
            return this.modal.bm;
        },
        selectedWidgetImpIDs() {
            return this.selectedWidgets.map(el => el.implementation_id);
        },
        lengthType() {
            return this.rule.unlock_time_period;
        },
        lenghtArray() {
            if(this.lengthType === 'day')
                return 30;
            else if(this.lengthType === 'week')
                return 4;
            else
                return 12;
        },
        isApproved() {
            return this.rule.rule_status === 'approved';
        },
        isRequested() {
            return this.rule.rule_status === 'requested';
        },
        isArchived() {
            return this.rule.rule_status === 'archived';
        },
        title() {
            if(this.isApproved)
                return "Edit existing access";
            else if(this.isRequested)
                return "Create client access";
            else if(this.isArchived)
                return "Edit archived request";
            else 
                return this.isBmMode ? "Invite new team mate" : "Create new access";
        },
        // userTitle() {
        //     return this.isBmMode ? "team mate" : "client";
        // },
        subtitle() {
            if(this.isApproved)
                return "Client access will restart by updating access details. To maintain original timing, adjust access length";
            else if(this.isRequested)
                return "Client access will begin immediately and they will receive log in details at the email address below."
            else 
                return "";
        },
        bm() {
            return this.bms.find(el => el.id === this.profile.account_manager_id || window.loggegIdUserId) || { name: "N/A"};
        },
        currentAccessNumberOfWidgets() {
            if(!this.currentActiveRule)
                return "";

        const qnaProp = "qanda_enabled";
        const qnaValue = this.entityQnaCustom ? this.currentActiveRule.user[qnaProp] : false;
        const qnaText = qnaValue ? " + Q&A" : ""
        const ruleWidgetsLength = this.currentActiveRule.widgets.length;
        if(ruleWidgetsLength === this.widgetsToSelectFromForHomeScreen.length)
            return "all widgets" + qnaText;

        return ruleWidgetsLength + " Widget" + (ruleWidgetsLength > 1 ? "s" : "") + qnaText;
 
        },

    },
    data() {
        return {
            profile: {
            },
            rule: {
                unlock_time_units: 0,
                unlock_time_period: 0,
                currency: 0,

            },
            error: {},
            currencies: ["USD", "GBP", "EUR", "CHF", "JPY", "CAD", "AUD", "NZD"],
            selectedWidgets: [],
            selectedNumber: {},
            validPhoneNumber: true,
            serverError: "",
            isProcessing: false,
            lookUpData: [],
            editingExisting:false,
            activeInput: "",
            qna: false,
            currentActiveRules: null,
            qnaActiveOnPrevRequest: false,
            selectorIteration: [0],

        }
    },
    methods: {
        ...general.mapActions(["setEntity"]),
        ...rules.mapActions(["updateUser"]),

        clearValidity(name) {
            delete this.error[name];
        },
        validateData() {
            this.error = {};
            this.serverError = ""

            const REQ_CONDITIONS = [
                {test: (model) => model.unlock_time_units > 0, message: 'Set lenght', key: "unlock_time_units"},
                {test: (model) => model.unlock_time_period, message: 'Set days/weeks/ months', key: "unlock_time_period"},
                {test: (model) => model.currency, message: 'Set unit', key: "currency"},
                {test: (model) => this.isBmMode || model.deposit_amount > -1, message: 'Deposit must be 0 or over', key: "deposit_amount"},
                {test: (model) => this.isBmMode || model.account_balance > -1, message: 'Balance must be 0 or over', key: "account_balance"},
                
            ];

            REQ_CONDITIONS.forEach((condition) => {
                if (!condition.test(this.rule)) {
                    this.error[condition.key] = condition.message;
                }
            });
            if(!this.selectedWidgets.length)
                this.error.widget = true;

            const PROF_CONDITIONS = [
                {test: (model) => model.bm || model.account_manager_id && this.userList.find(el => el.id === +model.account_manager_id)?.bm, message: 'No (known) manager', key: "account_manager_id"},
                {test: (model) => model.name?.length > 2 && model.name?.length < 31, message: 'Name must be 3 - 30 characters long', key: "name"},
                {test: (model) => model.email?.length > 5 && model.email?.length < 64 && model.email?.indexOf('@') > 0 && model.email?.indexOf('.') > 0, message: 'Please fill email field.', key: "email"},
                
            ];
            PROF_CONDITIONS.forEach((condition) => {
                if (!condition.test(this.profile)) {
                    this.error[condition.key] = condition.message;
                }
            });

            const bmFound = this.bms.find(el => el.email === this.profile.email);

            if(bmFound)
                if(this.isBmMode)
                    this.error.email = 'Admin has already been invited or added'
                else
                    this.error.email = 'You cannot give client access to an admin';



            if(!this.validPhoneNumber)
                this.error.phone_number = this.isBmMode ? "Please enter a valid phone number or leave it blank" : "Please enter a valid phone number";

            // console.log(this.error)
        },
        updatePhoneNumber(result) {
            delete this.error.phone_number;
            // console.log(result);
            if(result.isValid) {
                this.validPhoneNumber = true;
                this.profile.sms_notification_country = result.countryCallingCode;
                this.profile.sms_notification_number = result.phoneNumber;
                this.profile.phone_number = result.e164;
            }else if(!result.phoneNumber && this.isBmMode) {
                this.validPhoneNumber = true;
                this.profile.sms_notification_country = "";
                this.profile.sms_notification_number = "";
                this.profile.phone_number = "";
            }
            else
                this.validPhoneNumber = false;

        },
        async submit() {
            this.validateData();

            if(!_.isEmpty(this.error))
                return;

            this.isProcessing = true;

            // if(this.currentActiveRule) {
            //     const rule = _.cloneDeep(this.currentActiveRule);
            //     rule.rule.rule_status = "cancelled";

            //     try {
            //         const response = talkToServer("/b/rules/" + rule.id, "PUT", null, rule.rule, true);

            //         if(!response.success && response.error) {
            //             this.serverError = "Something went wrong, please try again later";
            //         }
                
            //     }
            //     catch(e) {
            //         console.log(e);
            //         this.serverError = "Something went wrong, please try again later"
            //     }
            // }

            const profileData = {
                ...this.profile,
                name: this.profile.name,
                email: this.profile.email,
                refno: window.bootUp.refno,
                phone_number: this.profile.phone_number,
                sms_notification_country: this.profile.sms_notification_country,
                sms_notification_number: this.profile.sms_notification_number,
                widgets: this.selectedWidgets,
                qanda_requested: this.qna,
                application_mode: "quantum"
            }

            let rule = {
                ...this.rule,
                unlock_time_units: +this.rule.unlock_time_units,
                start_from: "login",
                rule_status: "approved",
                deposit_amount: +this.rule.deposit_amount,
                account_balance: +this.rule.account_balance,
                qanda_enabled: this.qna,
                application_mode: "quantum"
            }

            try {
                const response = await talkToServer(`/register`, 'POST', null, profileData);
                    
                // console.log('any response', response);
                if (!response.success && response.error) {
                    this.serverError = "Something went wrong, please try again later"
                }
                else {
                    this.profile = {
                        ...response.data.user.user
                    };
                    this.updateUser(this.profile);

                }    
            } catch (e) {
                console.log(e);
                this.isProcessing = false;
                this.serverError = "Something went wrong, please try again later"
            }
        
            if(!this.serverError)
            try {
                const response = await talkToServer(`/entity/broker/auth`, 'GET', null, null, true);
                if (response.success) {
                    this.setEntity(response.data.entity);
                    // this.$store.dispatch("rules/addBulkRecords")

                    const defaultRule = this.getAllIndRuleUsers.find(el => el.user.id === this.profile.id && el.rule.rule_status === 'requested');
                    // console.log(defaultRule, this.profile.id);
                    rule = {
                        ...defaultRule.rule,
                        ...rule
                    }
                }
            } catch (e) {
                console.log(e);
                this.isProcessing = false;
                this.serverError = "Something went wrong, please try again later"
            }
            if(!this.serverError)
            try {
                await talkToServer("/b/rules-widget/" + rule.id, "PUT", null, {
                    widget_implementation_ids: this.selectedWidgets
                }, true);
            } catch(e) {
                console.log(e);
                this.isProcessing = false;
                this.serverError = "Something went wrong, please try again later"
            }


            if(!this.serverError)
            try {
                const ruleCall = talkToServer("/b/rules/" + rule.id, "PUT", null, rule, true);
                this.profile = {
                    ...this.profile,
                    request_status: 'approved',
                    qanda_enabled: this.qna,
                    qanda_requested: this.qna
                };
                // console.log("profile", this.profile);
                const userUpdate = talkToServer("/b/user/" + this.profile.id, "PUT", null, this.profile, true);
                // console.log({
                //     widget_implementation_ids: this.selectedWidgetImpIDs
                // });
                return Promise.allSettled([ruleCall, userUpdate]).then(resp => {
                    const fail = resp.map(el => el.status).find(el => el === 'rejected');
                    this.isProcessing = false;
                    if(!fail) {
                        // const ruleWidgets = this.selectedWidgetImpIDs.map(el => {
                        //     return {
                        //         rule_id: rule.id, 
                        //         widget_implementation_id: el
                        //     }
                        // });

                        
                        this.updateUser(this.profile);

                        // this.updateRuleSet({
                        //     rule: rule,
                        //     ruleWidgets: ruleWidgets
                        // });

                        // console.log("user updated", this.getAllIndRuleUsers);

                        let preText = this.isBmMode ? 'Your team mate\'s access' : 'Client access ';

                        Swal.fire({
                            title: 'Success',
                            type: 'info',
                            text: preText + ' is now active and we have sent their login information to ' + this.profile.email,
                            allowOutsideClick: false,
                            showCancelButton: false,
                            confirmButtonColor: '#3B9C83',
                            confirmButtonText: 'OK'
                        }).then(() => {
                            eventBus.$emit("close-modal");
                        });
                    } else {
                        Swal.fire({
                            title: 'Error',
                            type: 'warning',
                            text: 'Some of the settings were not saved. Please refresh the terminal to check the user again',
                            allowOutsideClick: false,
                            showCancelButton: false,
                            confirmButtonColor: '#3B9C83',
                            confirmButtonText: 'OK'
                        }).then(() => {});
                    }
                });
            } catch (e) {
                console.log(e);
                this.isProcessing = false;
                this.serverError = "Something went wrong, please try again later"
            }

            

        },
        widgetSelectionChanged(impIDs) {
            if(impIDs.length)
                this.error.widget = false;
            this.selectedWidgets = [...impIDs];
            // console.log("selected and saved", impIDs);
        },
        lookUpUser(ev) {
            if(this.isBmMode) {
                return;
            }
            // console.log(ev.target.id)
            this.lookUpData = this.userList.filter(usr => usr[ev.target.id].toLowerCase().indexOf(ev.target.value.toLowerCase()) > -1)
            ?.map(el => {return {id: el.id, name: el.name, email: el.email}});
            this.activeInput = ev.target.id;
        },
        selectUser(id) {
            this.profile = this.userList.find(usr => usr.id === id);
            this.updatePhoneNumber({
                isValid: true,
                countryCallingCode: this.profile.sms_notification_country,
                phoneNumber: this.profile.sms_notification_number
            })
            this.selectedNumber.number = this.profile.sms_notification_number;
            this.selectedNumber.countryCode = countries.find(el => el.callingCodes?.[0] === "+" + this.smsNotificationCountry)?.cca2;
            if(!this.profile.account_manager_id && !this.profile.bm)
                this.profile.account_manager_id = this.bms.find(el => el.id === this.profile.account_manager_id)?.id || window.loggedInUserId;
            this.lookUpData = [];
            this.editingExisting = true;
            this.activeInput = ""

            // const currentActiveRule = this.getAllIndRuleUsers.find(el => el.rule.rule_status === 'approved' && el.user.id === this.profile.id);
            // if(currentActiveRule)
            //     this.currentActiveRule = currentActiveRule;
            const currentActiveRules = this.getAllIndRuleUsers.filter(el => el.rule.rule_status === 'approved' && el.user.id === this.profile.id);
            // console.log(currentActiveRules);
            if(currentActiveRules.length) 
                this.currentActiveRules = currentActiveRules;

            // console.log("curr",currentActiveRules, this.profile.qanda_requested, this.qna)

            const alreadyActiveQna = currentActiveRules.find(el => el.qnaOn);
            
            if(alreadyActiveQna)
                this.qnaActiveOnPrevRequest = "previous active";
            else if(currentActiveRules.length && !alreadyActiveQna)
                this.qnaActiveOnPrevRequest = "previous inactive";
            else if(!currentActiveRules.length)
                this.qnaActiveOnPrevRequest = "no previous";

            // console.log("already",this.qnaActiveOnPrevRequest);
            this.selectorIteration = [this.selectorIteration[0] + 1];
            // console.log(this.selectorIteration);

        // console.log(this.currentActiveRule)
            // console.log(this.profile)
        },
        clearUserSelection() {
            this.editingExisting = false;
            this.error = {};
            this.profile = {};
            this.selectedNumber.number = '';
            this.selectedNumber.countryCode = 'GB';
            if(!this.profile.account_manager_id)
                this.profile.account_manager_id = this.bms.find(el => el.id === this.profile.account_manager_id)?.id || window.loggedInUserId;
            this.qnaActiveOnPrevRequest = 'no previous';
            this.currentActiveRules = [];
            this.selectorIteration = [this.selectorIteration[0] + 1];
        },
        outsideClick() {
            this.lookUpData = [];
            this.activeInput = "";
        },
        updatePeriod(event) {
            this.error.unlock_time_period = '';
            const value = event.target.value;
            const units = this.rule.unlock_time_units
            if(value === 'week' && units > 4)
                this.rule.unlock_time_units = 0;
            if(value === 'month' && units > 12)
                this.rule.unlock_time_units = 0;

        },
        formatDate(date) {
            return usersTime(date, true, false, false, {
                dateFormat: "d M Y",
            }, false, this.user.timezone);
        },
        getWidgetNames(widgets) {
            return this.widgetsToSelectFromForHomeScreen.filter(el => widgets.includes(el.implementation_id)).map(el => el.name).join(', ');
        },
        checkIfQnaShouldBeShown(curr) {
            const selectedQnaWidgets = this.widgetsToSelectFromForHomeScreen.filter(el => Constants.QNA_WIDGETS.includes(el.fs_version_of_widget_for_brokers) && curr.widgets.includes(el.implementation_id));
            if(!selectedQnaWidgets.length || !this.entityQnaCustom)
                return '';

            return ' + Q&A';

        }

    },
   
}
</script>