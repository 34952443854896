import { orderBy } from "lodash";

export default {
    rules(_, _1, _2, rootGetters) {
        return rootGetters["general/entity"].rules;
    },
    ruleDetails(_, _1, _2, rootGetters) {
        return rootGetters["general/entity"].ruleDetails;
    },
    ruleWidgets(_, _1, _2, rootGetters) {
        return rootGetters["general/entity"].ruleWidgets;
    },
    ruleAccess(_, _1, _2, rootGetters) {
        return rootGetters["general/entity"].ruleAccess;
    },
    userList(state) {
        return state.userList;
    },
    bms(_, getters) {
        return getters.userList.filter(el => el.bm)
    },
    indTypeRules(_, getters) {
        // console.log(getters.rules)
        const indTypeRules = []
        getters.ruleDetails.forEach(ruleDetail => {
            const found = getters.rules.find(rule => rule.id === ruleDetail.rule_id && rule.rule_type === 'individual' && rule.application_mode === 'quantum');
            if(found)
                indTypeRules.push(found);
        });
        return indTypeRules;
    },
    indTypeRuleDetails(_, getters) {
        const indTypeRuleDetails = [];
        getters.indTypeRules.forEach(rule => {
            const found = getters.ruleDetails.find(rd => rd.rule_id === rule.id)
            if(found)
            indTypeRuleDetails.push(found);
        });

        return indTypeRuleDetails;
    },
    getLockDateForUser(_, getters, _1, rootGetters) {
        let dates = getters.indTypeRuleDetails.filter(el => el.user_id === rootGetters["general/user"]?.id)?.map(el => el.rule_id)?.map(el => getters.ruleAccess?.find(r => r.rule_id === el))?.map(r => r?.locked_at);
    
        if(dates.length) {
            dates = orderBy(dates.filter(el => !!el), el => el, "desc");
            if(dates.length)
                return dates[0];

            return null
        }
        return null;
     
    },

    // getUserRuleSets: (_, getters) => id => {
    //     let ruleIDs = getters.ruleDetails.filter(el => el.user_id === id);
    //     ruleIDs = ruleIDs.map(el => el.rule_id);
    //     const rules = {};
    //     ruleIDs.forEach(rID => {
    //         const ruleWidgets = getters.ruleWidgets.filter(el => el.rule_id == rID);
    //         if(!rules[rID])
    //             rules[rID] = {};
                
    //             rules[rID].widgets = ruleWidgets.map(el => el.widget_implementation_id);
    //             rules[rID].rule = getters.rules.find(el => el.id === rID);
    //     });

    //     return rules; 
    // },
    getAllIndRuleUsers: (_1, getters) => {
        const getAllIndRuleUsers = [];
        getters.indTypeRuleDetails.forEach(rd => {
            const userID = rd.user_id;
            let user = getters.userList.find(el => el.id === userID);
            const rule = getters.rules.find(el => el.id === rd.rule_id);
            const widgets = getters.ruleWidgets.filter(el => el.rule_id === rd.rule_id)?.map(el => el.widget_implementation_id);
            
            const unlockedAt = getters.ruleAccess.find(el => el.rule_id === rd.rule_id)?.unlocked_at;
            const lockedAt = getters.ruleAccess.find(el => el.rule_id === rd.rule_id)?.locked_at;
            const qnaOn = getters.ruleAccess.find(el => el.rule_id === rd.rule_id)?.qanda_enabled;

            if(user && rule && widgets)
                getAllIndRuleUsers.push({
                    id: rd.rule_id,
                    unlocked_at: unlockedAt,
                    locked_at: lockedAt,
                    qnaOn: qnaOn,
                    user,
                    rule,
                    widgets
                });
            
        });
        return getAllIndRuleUsers;
    },
    getUserByRuleID: (_1, getters) => id => {
        return getters.getAllIndRuleUsers.find(el => el.id === id);
    },
    // getUserByUserID: (_1, getters) => id => {
    //     getters.getAllIndRuleUsers.filter(el => el.user.id === id);
    // },
    // getUserNextLockDate: (_1, getters) => id => {
    //     const rule= getters.getUserByUserID(id);
    //     let dates = rule.map(el => el.locked_at.replace("T", " ").substr(0,19));
    //     dates = orderBy(dates, el => el, "desc");
    //     console.log(dates, id);
        
    // }
    // getIndRuleUserData: (_1, getters) => id => {
    //     const userID = +getters.indTypeRuleDetails.find(el => el.rule_id === id)?.user_id;
    //     let user = getters.userList.find(el => el.id === userID);
    //     if(user) {
    //         user = _.cloneDeep(user);
    //     }
    //     const rule = getters.rules.find(el => el.id === id);
    //     const widgets = getters.ruleWidgets.filter(el => el.rule_id === id)?.map(el => el.widget_implementation_id);
    //     return {
    //         id,
    //         user,
    //         rule,
    //         widgets
    //     };
    // }
    // getUnlockedWidgetsByImpID: (state) => (id) => {
    //     return state.widgets.find(element => element.implementation_id === id);
    // },
}