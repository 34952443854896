// import { processIntRateDatesAndRates } from "../../utility/helper-functions";

export default {
    addBulkRecords(_, payload) {

        // payload.forEach(bank => {
        //     const { dates, rates } = processIntRateDatesAndRates(bank.dates, bank.rates);

        //     bank.dates = dates;
        //     bank.rates = rates;
        // });
        
        const processingObject = {
            objStore: `interest_rates`, 
            data: payload
            
        };

        window.bootUp.iDB.storeRecords(processingObject).then(() => {});
        
    },

    lastIntRateUpdate(context, payload) {
        context.commit("lastIntRateUpdate", payload);
    },

    lastIntRateDelete(context, payload) {
        context.commit("lastIntRateDelete", payload);
    },
    setHumanizedUpdatedAt({commit}, payload) {
        commit("setHumanizedUpdatedAt", payload);
    },

}