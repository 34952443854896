<template>
    <div v-if="!isExternalClientMode" class="embedded-component" :class="modifierClasses">
        <component 
        :is="currentComponent"
        @update-step="updateStep"
        @register-login-successful="registerSuccessful"
        @emit-error="emitError"
        :promoID="promoID"
        :specialLink="specialLink"
        :details="details"></component>
        <div 
        v-if="showSkip"
        class="skip-setup">
            <a v-if="skipSetupMode" href="javascript:void(0);" @click="skipSetup">Skip setup guide</a>
            <a class="white-link" v-else-if="skipSurveyMode" href="javascript:void(0);" @click="skipSurvey">Skip</a>
        </div>
    </div>
</template>

<script>
import { scroller } from 'vue-scrollto/src/scrollTo';
import RegisterComponent from './RegisterComponent.vue';
import FinishCreating from './FinishCreating.vue';
import CreatePassword from './CreatePassword.vue';
import Constants from '../../../utility/constants';
import ChoosePlan from './ChoosePlan.vue';
import PlanSelector from './PlanSelector.vue';
import EnterPayment from './EnterPayment.vue';
import PaymentSuccess from './PaymentSuccess.vue';
import PaymentFailure from './PaymentFailure.vue';
import FreeSuccess from './FreeSuccess.vue';
import SurveyWelcome from './SurveyWelcome.vue';
import SurveySection from './SurveySection.vue';
import BuildingTerminal from './BuildingTerminal.vue';
import onBoardMixin from '../../../mixins/onBoardMixin';
import { isExternalClientMode, talkToServer } from '../../../utility/helper-functions';
import TrackingService from '../../../services/tracking/tracking.service';
import { createNamespacedHelpers } from 'vuex';

const general = createNamespacedHelpers("general");
const payments = createNamespacedHelpers("payments");

export default {
    props: ["continueOnboardingState", "promoID", "specialLink"],
    mixins: [onBoardMixin],
    components: {
        RegisterComponent,
        FinishCreating,
        CreatePassword,
        ChoosePlan,
        PlanSelector,
        EnterPayment,
        PaymentSuccess,
        PaymentFailure,
        FreeSuccess,
        SurveyWelcome,
        SurveySection,
        BuildingTerminal
    },
    created() {
        if(isExternalClientMode())
            this.$router.push("/home");
            
        if(this.continueOnboardingState.step)
            this.details = this.continueOnboardingState;

        if(this.continueOnboardingState.step >= 9)
            this.$emit('switch-onboard-mode', 'survey');
            

    },
    computed: {
        ...general.mapState(["checkoutLoginMode"]),
        ...payments.mapState(["availableProducts"]),
        isExternalClientMode() {
            return isExternalClientMode();
        },
        currentComponent() {
            let step = this.details.step;
            if(step === 3)
                step = 8;
            
            if(step > 10 && step <= 13) ///!!!
                step = 10;
                
            // console.log(step, Constants.ONBOARDING_STEPS[step])
            return Constants.ONBOARDING_STEPS[step];
        },
        showSkip() {
            return +this.details.step >= 4 && +this.details.step < 6 && this.details.skippable || +this.details.step >= 9;
        },
        skipSetupMode() {
            return +this.details.step < 9;
        },
        skipSurveyMode() {
            return +this.details.step >= 9 && +this.details.step < 14 ///!!! ;
        },
        modifierClasses() {
            if(+this.details.step === 4 || +this.details.step === 5)
                return "wide";
                
            return 0;
        }

    },
    data() {
        return {
            details: {
                step: "0",
                email: '',
                name: ''
            },
            promoMode: false
            // tags: {
            //     "start-journey": 5572,
            //     "registration-done": 5574,

            //     "Explorer": 5576,
            //     "Pro Trial": 5578,
            //     "Pro Plan": 5580,

            //     "Success": 5584,
            //     "Fail": 5586,

            //     "Free entry": 5582

            // }

        }
    },
    methods: {
        ...general.mapActions(['setComponentAfterRefresh', 'setCheckoutLoginMode']),
        updateStep(val) {
            this.details.step = val.step;
            this.scrollToTop();
            let step = parseInt(val.step);

            if(this.promoID && this.promoID !== Constants.MTA_FREE_ROUTE && step === 3) {
                if(this.checkoutLoginMode)
                    this.setCheckoutLoginMode(false);
                    
                this.trackEvent();
                this.updateStep({ step: "-1" });
                return;
            }

            


            if(step === -1)  
                this.$emit('onboard-skipped');
            
            if(step >= 9)
                this.$emit('switch-onboard-mode', 'survey');

            this.details = { ...this.details, ...val };

            this.trackEvent();
                

            // if(val.email) {
            //     this.details.email = val.email;
            // }
            // if(val.name) {
            //     this.details.name = val.name;
            // }
            // if(val.plan) {
            //     this.details.plan = val.plan;
            // }
            // if(val.country) {
            //     this.details.country = val.country;
            // }
            // console.log("val",this.details);

            if(step >= 3 && step <= 6 || step >= 8)
                this.updateStepProgress(this.details);
            // console.log("details sent", this.details);
                
        },
        registerSuccessful(val) {
            this.$emit("register-login-successful", val);
        },
        emitError(val) {
            this.$emit('emit-error', val);
        },
        skipSetup() {
            this.updateStep({ step: "8" });
        },
        skipSurvey() {
            let slug = "skipped-after-starting-ques"
            if(this.details.step === "9") {
                slug = "skipped-before-starting-ques"
            }

            talkToServer("/journey/" + slug, "GET", null, null, true);

            this.updateStep({ step: "100" });
        },
        scrollToTop() {
            const scrollTo = scroller();

            scrollTo('#scrollable', 0, {
                container: '#scroll-container',
                lazy: false,
                easing: 'linear',
                offset: 0,
                force: true,
                x: false,
                y: true
            });
        },
        trackEvent() {
            const step = parseInt(this.details.step);
            const answer = this.details.answer;
            let eventName;
            
            const events = {
                1: "get_started",
                2: "finish_creating_account",
                3: "user_registration",
                4: "see_plans",
                6: "*pro_plan*", //to be processed
                8: "*explorer_plan*", //to be processed
                10: "survey_started",
                11: "trader_type",
                12: "asset_type",
                13: "improve_suggestions",
                14: "discovered-on",
            }

            // console.log("step",step);
            
            if(step === -1)
                return;

            if(events[step]) {
                let eventObj = {};
                eventName = events[step];

                if(step === 6) {
                    const plan = Object.values(this.availableProducts).find(el => el.id === this.details.commitment);

                    if(plan)
                        TrackingService.sendTransaction(plan, "purchased_onboard", true);

                    return;
                }
                if(step >= 11 && step <= 14) { ///!!!
                    if(!answer)
                        return;

                    eventName = eventName + "_" + answer;
                }

                // if(step === 3) {
                //     eventObj = { method: "onboarding"}
                //     TrackingService.sendEvent("sign_up", eventObj);
                // }
                if(step !== 8)
                    TrackingService.sendEvent(eventName, eventObj);
                    

                // console.log(eventName, eventObj);
            }
        } 
    },
    beforeRouteLeave(to, from, next) {
        
        if(this.details && this.details.step > 2) {
            return false;
        }
      
        next();

    }
}
</script>