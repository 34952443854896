import _ from "lodash"
export default {
    setActiveDashboard(state, payload) {
        state.activeDashboard = payload;
    },
    setFullScreenId(state, payload) {
        state.fullscreenId = _.cloneDeep(payload);
    },
    setDashboards(state, payload) {
        state.poppedoutDashboards = payload;
    },
    addDashboard(state, payload) {
        state.poppedoutDashboards = payload;
    },
    removeDashboard(state, payload) {
        state.poppedoutDashboards = payload;
    },
    setSystemDashboards(state, payload) {
        let layouts = {layouts: {}}
        payload
            .filter(dashboard => dashboard.id !== undefined && dashboard.id !== null)
            .forEach(dashboard => {
                layouts.layouts[dashboard.id] = dashboard
            });

        state.layouts = _.cloneDeep(layouts);
    },
    setSystemDashboardsFromLayout(state, payload) {
        const { layout } = payload;

        // let currentActiveDashboard = layout.layouts[state.activeDashboard[instanceID]];

        // // console.log("currently active", instanceID, currentActiveDashboard);

        // if(state.activeDashboard[instanceID] < 0 || !currentActiveDashboard || window.bootUp.demoPageMode) {
        //     const active = Object.keys(layout.layouts)
        //         .map(key => layout.layouts[key])
        //         .find(dashboard => {
        //             if(dashboard.default)
        //                 return dashboard
        //             else if(dashboard.broker && instanceID === dashboard.id)
        //                 return dashboard
        //             else
        //                 return null;
        //         });
        //     state.activeDashboard[instanceID] = active ? active.id : -1;
        // }

        // // console.log("active",layout.layouts[state.activeDashboard[instanceID]]);

        // if(state.activeDashboard[instanceID] >= 0 && !state.poppedoutDashboards.includes(state.activeDashboard[instanceID])) {
        //     layout.layouts[state.activeDashboard[instanceID]].tab = true;
        // }
        state.layouts = _.cloneDeep(layout);

    },
    addUserGuideDashboard(state) {
        let layouts = _.cloneDeep(state.layouts.layouts);
        layouts[0] = state.userGuideDashboard;
        state.layouts.layouts = layouts;
    },
    generateReadonlyDashboards(state, payload) {
        state.layouts.layouts = _.cloneDeep(payload);
    },
    closeAllPopouts(state) {
        let layouts = _.cloneDeep(state.layouts);
        Object.keys(layouts.layouts).map(key => layouts.layouts[key]).forEach(dashboard => dashboard.popout = false);
        state.layouts = layouts;
    },
    updateDashboardById(state, payload) {
        if(payload.id) {
            let layouts = _.cloneDeep(state.layouts);
            layouts.layouts[payload.id] = payload;
            state.layouts = layouts;
        }
    },
    updateWidgetLayout(state, {elements, dashboardId}) {
        let layouts = _.cloneDeep(state.layouts);
        if(dashboardId) {
            let dashboard = layouts.layouts[dashboardId];
            // console.log("mutation", dashboard, elements);
            let updatedValuesIds = elements.map(element => element.i);
            elements.forEach(element => dashboard.elements[element.i].layout = element);
            Object.keys(dashboard.elements).forEach(id => {
                if(!updatedValuesIds.includes(parseInt(id))) {
                    delete dashboard.elements[id];
                }
            });
            state.layouts = layouts;
        }
    },
    deleteDashboardById(state, payload) {
        let layouts = _.cloneDeep(state.layouts);
        delete layouts.layouts[payload];
        state.layouts = layouts;

    },

    setAnalChatActiveOn(state, payload) {
        state.analChatActiveOn = payload
    }
    // setDashboards(state, payload) {
    //     state.poppedoutDashboards = payload;
    // }


    // setLayout(state, payload) {
    //     let dashboards = JSON.parse(JSON.stringify(state.dashboards));
    //     let active = dashboards.find(element => element.id === state.activeDashboard);
    //     active.layout = payload;

    //     state.dashboards = dashboards;
    // },
    // removeItem(state, payload) {
    //     let dashboards = JSON.parse(JSON.stringify(state.dashboards));
    //     let active = dashboards.find(element => element.id === state.activeDashboard);

    //     active.layout.splice(payload, 1);

    //     state.dashboards = dashboards;

    // }
}
