<template>
    <div class="video-list">
        <video-item
        v-for="item in sortedItems[currentCat]"
        :key="item.id"
        :video="item"
        ></video-item>
    </div>
</template>

<script>
import VideoService from '../../../services/video/video.service';
import VideoItem from '../../widgets/components/VideoPlayers/VideoItem.vue';
import _ from 'lodash';

export default {
    props: ["currentCat", "item"],
    components: {
        VideoItem
    },
    async created() {
        // console.log("item", this.item)
        this.topLevelCategories = this.item.widgets[0].categories[0];
        const topcat = await VideoService.getCourseCat(this.topLevelCategories);
        const subcats = await VideoService.getSubCategoriesForCourse(this.topLevelCategories)
        this.categories = [...topcat, ...subcats];
    
        const obj = {
            categories: [this.topLevelCategories, ...subcats.map(el => el.id)],
                type: "course"
            }
        
        this.bulkItems = await VideoService.fetchInitialItems(obj);
        this.sortItems();
        this.$emit("cats-ready", this.categories);
        
    },
    provide() {
        return {
            $activeVideo: () => {}
        }
    },
    data() {
        return {
            categories: [],
            bulkItems: {},
            sortedItems: {},
            topLevelCategories: null,
        }
    },
     methods: {
        sortItems() {
            const cats = _.orderBy(this.bulkItems.categories, el => el.order_by, "asc");
            const sortedItems = {};
            
            cats.forEach(el => {

                const id = el.id;
                if(!sortedItems[id]);
                    sortedItems[id] = [];

                const vids = _.orderBy(this.bulkItems.videos.filter(el => el.categories.includes(id)), el => el.order_by, "asc");
                sortedItems[id] = vids;

            });
            this.sortedItems = _.cloneDeep(sortedItems);
    
    
        }
    },
}
</script>

<style lang="scss" scoped>
    .video-list {
        overflow: auto;
        @include scrollbarDark;

    }
</style>