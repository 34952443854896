export default {
    // addBulkRecords({dispatch}) {
    //     dispatch("setRules", window.bootUp.entity.rules);
    //     dispatch("setRuleDetails", window.bootUp.entity.ruleDetails);
    //     dispatch("setRuleWidgets", window.bootUp.entity.ruleWidgets);
    //     dispatch("setRuleAccess", window.bootUp.entity.ruleAccess);

    // },
    // setRules({commit}, payload) {
    //     commit("setRules", payload);
    // },
    // setRuleDetails({commit}, payload) {
    //     commit("setRuleDetails", payload);
    // },
    // setRuleWidgets({commit}, payload) {
    //     commit("setRuleWidgets", payload);
    // },
    // setRuleAccess({commit}, payload) {
    //     commit("setRuleAccess", payload);
    // },
    setUserList({commit}, payload) {
        commit("setUserList", payload);
    },
    updateUserListUser({commit}, payload) {
        commit("updateUserListUser", payload);
    },
    deleteUserListUser({commit}, payload) {
        commit("deleteUserListUser", payload);
    },
    updateRuleSet({dispatch}, payload) {
        if(payload.rule)
            dispatch("updateRule", payload.rule);
        if(payload.ruleDetails)
            dispatch("updateRuleDetails", payload.ruleDetails);
        if(payload.ruleWidgets)
            dispatch("updateRuleWidgets", payload.ruleWidgets);
        if(payload.ruleAccess)
            dispatch("updateRuleAccess", payload.ruleAccess);
    },
    updateRule({commit}, payload) {
        commit("updateRule", payload);
    },
    updateRuleDetails({commit}, payload) {
        commit("updateRuleDetails", payload);
    },
    updateRuleWidgets({commit}, payload) {
        commit("updateRuleWidgets", payload);
    },
    updateRuleAccess({commit}, payload) {
        commit("updateRuleAccess", payload);
    },
    updateUser({commit}, payload) {
        commit("updateUser", payload);
    },

}