export default {
    entity(state) {
        return state.entity;
    },
    getLastShopVisitById: function (state) {
        return (key) => state.lastShopVisitDate[key];
    },
    user(state) {
        return state.user;
    },
    isUserBm(state) {
        return state.user?.bm;
    },
    renewFails(state) {
        return state.renewFails;
    },
    hasRenewFails(state) {
        return !!state.renewFails.length;
    },
    paymentBoundToFail(state) {
        return state.paymentBoundToFail;
    },
    activeNavItem(state) {
        return state.activeNavItem;
    },
    componentAfterRefresh(state) {
        return state.componentAfterRefresh;
    },
    dbReady(state) {
        return state.dbReady;
    },
    lastTrainingCentreOrderUpdated(state) {
        return state.lastTrainingCentreOrderUpdated;
    },
    watchHistory(state) {
        return state.watchHistory;
    },
    entityUpdateTimeout(state) {
        return state.entityUpdateTimeout;
    },
    demoPageMode(state) {
        return state.demoPageMode;
    },
    terminalMenu(state) {
        return state.terminalMenu;
    },
    entityQna(_, getters) {
        return getters.entity.qanda_enabled;
    },
    entityQnaOn(_, getters) {
        return getters.entityQna === 'on';
    },
    entityQnaCustom(_, getters) {
        return getters.entityQna === 'custom';
    },
    entityQnaOff(_, getters) {
        return getters.entityQna === 'off';
    },
    entityQnaToShow(_, getters) {
        return getters.entityQnaOn || getters.entityQnaCustom
    },
    userQnaOn(_, getters) {
        return getters.entityQnaOn || (getters.entityQnaCustom && getters.user?.qanda_enabled)
    },
    unlockedWidgets(_, getters, _1, rootGetters) {
        const rules = getters.ruleSet;
        // console.log(getters.ruleSet)
        const widgets = rootGetters["payments/availableWidgets"]?.map(el => +el);
        // TEMP FOR LIVE
        // if(!rules || rules)
        //     return widgets;
        // FOR OTHER BUILDS
        if(getters.demoPageMode)
            return widgets.filter(el => rules.includes(el));
        else if(window.bootUp.entity.id === 5 || window.bootUp.entity.id === 12)
            return widgets;
        else if(!rules) {
            return [];
        }
        else {
            // console.log("rules", rules);
            return widgets.filter(el => rules.includes(el));
    
        }
    },
    widgetsChoiceOnDemo(state) {
        return state.widgetsChoiceOnDemo;
    },
    brokerRegistrationData(state) {
        return state.brokerRegistrationData;
    },
    ruleSet(state) {
        return state.ruleSet;
    },
    entityLocked(state) {
        return state.entity.ksa;
    },
    externalUserAutoLogin(state) {
        return state.externalUserAutoLogin;
    },
    singleWidgetMode(state) {
        return state.singleWidgetMode;
    },
    warningDismissed(state) {
        return state.warningDismissed;
    }
}