export default {

    lastIntRateUpdate(state, payload) {
        state.lastIntRateUpdate = payload;
    },

    lastIntRateDelete(state, payload) {
        state.lastIntRateDelete = payload;
    },
    setHumanizedUpdatedAt(state, payload) {
        state.humanizedUpdatedAt = payload;
    },

}