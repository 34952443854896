<template>
    <div 
    :style="backgroundIMG"
    class="login-overlay">
        <div class="e-login-form">
            <div>
                <div class="login-header">
                    Set up a New Password
                </div>
                <div>
                    <form @submit.prevent>

                    <base-input
                    elementType="input"
                    :class="{invalid: error.password} "
                    inputType="password"
                    :required="true"
                    v-model="profile.password"
                    forID="password"
                    :placeholder="'Password'"
                    inputClass="partner-input"
                    formControlClass=""
                    label="Password"
                    labelClass="medium-font"
                    :invalid="error.password"
                    :invalidMessage="error.password"
                    @clear-validity="clearValidity"
                    >
                    </base-input>

                    <base-input
                    elementType="input"
                    :class="{invalid: error.password2} "
                    inputType="password"
                    :required="true"
                    v-model="profile.password2"
                    forID="password2"
                    :placeholder="'Confirm Password'"
                    inputClass="partner-input"
                    formControlClass=""
                    label="Confirm Password"
                    labelClass="medium-font"
                    :invalid="error.password2"
                    :invalidMessage="error.password2"
                    @clear-validity="clearValidity"
                    >
                    </base-input>

                    <div class="error">{{ serverError }}</div>

                    <div class="button-bar" style="display: flex; justify-content: space-between; margin-top: 40px;">

                        <base-button
                        mode="o-tranparent-grey o-border-radius-0 o-width-125"
                        :link="false"
                        @click.native="$emit('back-to-home')"
                        >Back
                        </base-button>

                        <base-button
                        mode="o-blue o-width-125 o-border-radius-0"
                        :link="false"
                        @click.native="resetPassword"
                        :disabled="isProcessing"
                        :showSpinner="isProcessing"
                        >Confirm
                        </base-button>
                        
                    </div>
                </form>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';
import _ from "lodash";
import { frontFacingAssets, talkToServer } from '../../../utility/helper-functions';
import AccountService from '../../../services/account/account.service';
import Swal from "sweetalert2";
import bootUpMixin from '../../../mixins/bootUpMixin';

const general = createNamespacedHelpers("general");

const CONDITIONS = [
    {
        test: (model) => model.password?.length >= 8 && model.password?.length <= 32, 
        message: 'Password must be between 8 and 32 characters.',
        key: "password"
    },
    {
        test: (model) => model.password2?.length !== 0, 
        message: 'Please confirm your password.',
        key: "password2"
    },
    
]

export default {
    props: ["email", "token", "initial"],
    mixins: [bootUpMixin],
    computed: {
        backgroundIMG() {
            // return "background-color: red;"
            return "background: black url('" + frontFacingAssets() + "Login BG Image.png') no-repeat center -890px; ";
        },
    },
    data() {
        return {
            profile: {},
            error: {},
            isProcessing: false,
            serverError: '',
        }
    },
    methods: {
        ...general.mapActions(["setComponentAfterRefresh"]),
        clearValidity(name) {
            delete this.error[name];
        },
        validateData() {
            this.error = {};
            this.serverError = "";
            CONDITIONS.forEach((condition) => {
                if (!condition.test(this.profile)) {
                    this.error[condition.key] = condition.message;
                }
            })
            if(this.profile.password && this.profile.password2 && this.profile.password !== this.profile.password2) {
                this.error.nomatch = true;
                this.serverError = "Passwords do not match";
            }
            // console.log(this.error, this.profile.password2)
        },
        resetPassword() {
            this.validateData();
            if(!_.isEmpty(this.error))
                return;

            this.isProcessing = true;
            AccountService.resetPassword({
                password: this.profile.password,
                password_confirmation: this.profile.password2,
                nonce: this.token,
                email: this.email,
                initial: this.initial
            })
                .then(result => {
                    this.isProcessing= false;
                    if (result.data.success) {
                        const html = `Your password was updated successfully. You will now be logged in.`
                        Swal.fire({
                            title: 'Success',
                            html: html,
                            type: 'info',
                            confirmButtonColor: '##1d9deb',
                            confirmButtonText: 'OK',
                            timer: 3000
                        });
                        // setTimeout(() => this.$emit('back-to-home'), 2000);

                        const formData = {
                            email: this.email,
                            password: this.profile.password,
                            refno: window.bootUp.refno,
                        }
                        talkToServer(`/login${window.bootUp.cacheParams}`, 'POST', null, formData).then(response => {
                            console.log('any response', response);
                            if (!response.success && response.error) {
                                this.serverError = "Something went wrong, please try again later"
                            } else {
                                const comp = response.data.user.user.bm ? "clients" : "home"
                                this.setComponentAfterRefresh(comp);
                                this.saveUserData(response);
                                            
                                this.$emit('register-login-successful', {
                                    data: response.data,
                                    isLogin: true
                                });

                            }

                        })
                        .catch(e => {
                            console.log(e);
                            this.isProcessing = false;
                            if (e.status === 401) {
                            this.serverError = "Username or password not correct"
                            }
                            else  {
                                this.serverError = "Something went wrong, please try again later"
                            } 
                        });


                    } else {
                        this.serverError = Object.values(result.data)?.[0]?.[0];
                        
                    }
                })
                .catch((error) => {
                    this.isProcessing= false;
                    this.serverError = Object.values(error?.response?.data?.data || {})?.[0]?.[0];
                    if(!this.serverError)
                        this.serverError = "Something went wrong, please try again later";
                })
            
        },
    }
}

</script>
