<template>
    <div class="embedded-component" v-if="!isExternalClientMode">
        <div v-if="!success">
        <h2 class="align-center">Forgot Your Password?</h2>
        <div
        class="form-text-div dimmed align-center subtitle">Enter the email address you normally use to access the 
        terminal. We'll email you password re-set instructions</div>
        <form 
        
        @submit.prevent class="container"
        >
            <vue-form-generator ref="form"
            :schema="schema"
            :model="model"
            :options="formOptions"
            @model-updated="modelUpdated"
            ></vue-form-generator>
            <fieldset style="margin-top: 10px;">
                <base-button
                mode="o-blue fullwidth"
                :link="false"
                @click.native="resetPassword"
                >Send Password Re-set</base-button>
            </fieldset>
            <div class="form-text-div align-center">Remembered your password? <router-link :to="loginLink" class="onboard-link">Log in</router-link></div>
        </form>
        
        </div>
        <div v-else>
            <div class="align-center">
                <svg aria-hidden="true" focusable="false" data-prefix="far" data-icon="paper-plane" class="svg-inline--fa fa-paper-plane fa-w-16" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" style="height: 48px; width:48px;">
                    <path fill="currentColor" d="M440 6.5L24 246.4c-34.4 19.9-31.1 70.8 5.7 85.9L144 379.6V464c0 46.4 59.2 65.5 86.6 28.6l43.8-59.1 111.9 46.2c5.9 2.4 12.1 3.6 18.3 3.6 8.2 0 16.3-2.1 23.6-6.2 12.8-7.2 21.6-20 23.9-34.5l59.4-387.2c6.1-40.1-36.9-68.8-71.5-48.9zM192 464v-64.6l36.6 15.1L192 464zm212.6-28.7l-153.8-63.5L391 169.5c10.7-15.5-9.5-33.5-23.7-21.2L155.8 332.6 48 288 464 48l-59.4 387.3z">
                    </path>
                </svg>
            </div>
            <h2 class="align-center">Email Sent</h2>
            <div
            class="form-text-div dimmed align-center subtitle">{{ success }}</div>
        </div>
    </div>
</template>

<script>
import VueFormGenerator from "vue-form-generator";
import { createNamespacedHelpers } from 'vuex';
import OnBoardMixin from '../../../mixins/onBoardMixin';
import AppService from '../../../services/app.service';
import { isExternalClientMode } from '../../../utility/helper-functions';
const general = createNamespacedHelpers("general");

export default {
    mixins: [OnBoardMixin],
    props: ["cachedEmail"],
    created() {
        if(this.cachedEmail)
            this.model.email = this.cachedEmail;

        if(isExternalClientMode())
            this.$router.push("/home");
    },
    computed: {
        ...general.mapState(["checkoutLoginMode"]),
        isExternalClientMode() {
            return isExternalClientMode();
        },
        loginLink() {
            // if(this.checkoutLoginMode)
            //     return "/check-out";

            return "/login";
        }
    },
    data() {
        return {
            submitDisabled: false,
            success: "",
            model: {
                email: '',
            },
            schema: {
                fields: [
                    {
                        type: "input",
                        inputType: "email",
                        label: "Enter your email",
                        model: "email",
                        readonly: false,
                        featured: true,
                        required: true,
                        disabled: false,
                        placeholder: "Enter your email *",
                        styleClasses: "label-transition",
                        validator: VueFormGenerator.validators.required
                    },
                ],
            },
            formOptions: {
                validateAfterLoad: false,
                validateAfterChanged: true,
                validateAsync: false
            }
        }
    },
    methods: {
        modelUpdated() {
            console.log(this.model);
        },
        resetPassword() {
            
            if(this.submitDisabled)
                return;

            this.submitDisabled = true;

            if (this.model.email.length > 0) {
                AppService.resetPassword({
                    refno: window.bootUp.refno,
                    email: this.model.email
                }).then(() => {
                    this.success = 'Please check your email address and follow the necessary steps to reset your password!';
                }).catch(() => {
                    this.submitDisabled = false;
                    this.$emit("emit-error", {
                        error: 'There was an issue with the email you provided. Please try again later!'
                    });
                });
            }
        }
    }
}
</script>