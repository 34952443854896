<template>
    <div>
        <div v-if="!checkoutLoginMode" class="steps-tracker">Step 1 of 3</div>
        <h2 class="align-center">{{ title }}</h2>
        <div class="form-text-div dimmed align-center subtitle">{{ subText }}</div>
        <span id="pw-reveal" class="pw-reveal">
            <a @click="togglePwVisibility" v-if="schema.fields[2].inputType === 'password'"><font-awesome-icon :icon="['fa', 'eye']"/></a>
            <a @click="togglePwVisibility" v-else><font-awesome-icon :icon="['fa', 'eye-slash']"/></a>
        </span>
        <form @submit.prevent class="container">
            <vue-form-generator ref="form"
            :schema="schema"
            :model="model"
            :options="formOptions"
            @model-updated="modelUpdated"
            ></vue-form-generator>
            <fieldset :style="fieldsetStyle">
                <base-button
                mode="o-blue fullwidth"
                :link="false"
                :showSpinner="isProcessing"
                @click.native="submitForm"
                >Continue</base-button>
            </fieldset>
        </form>
    </div>
</template>

<script>
import VueFormGenerator from "vue-form-generator";
import OnBoardMixin from '../../../mixins/onBoardMixin';
import { isFreeMTARoute, talkToServer, validateEmail, validateName } from '../../../utility/helper-functions';
import bootUpMixin from '../../../mixins/bootUpMixin';
import TrackingService from '../../../services/tracking/tracking.service';
import { createNamespacedHelpers } from 'vuex';
const general = createNamespacedHelpers("general");

export default {
    props: ["details", "promoID"],
    mixins: [OnBoardMixin, bootUpMixin],
    mounted() {
        document.querySelector('.label-transition.password').appendChild(document.getElementById('pw-reveal'));
    },
    computed: {
        ...general.mapState(["checkoutLoginMode"]),
        title() {
            if(this.checkoutLoginMode)
                return 'Create a password';
            else if(this.promoID) {
                return 'Create a password for your free account';
            }
            return 'Create a password to enter the terminal';
        },
        subText() {
            if(this.checkoutLoginMode)
                return 'Create a password to proceed';
            else if(isFreeMTARoute(this.promoID)) {
                return 'So you can watch the free macro-masterclass videos inside the terminal immediately.';
            }
            if(this.promoID) {
                return 'This will allow you to log in and purchase the ' + window.bootUp.promo.name;
            }
            return 'Don\'t worry, you can change or update your password anytime.';
        },
        fieldsetStyle() {
            if(this.checkoutLoginMode)
                return "margin-top: 20px;"

            return "margin-top: 8px;"
        }
    },
    data() {
        return {
            nextStep: "3",
            isProcessing: false,
            model: {
                name: this.details.name,
                email: this.details.email,
                password: ''
            },
            schema: {
                fields: [
                    {
                        type: "input",
                        inputType: "text",
                        label: "Name",
                        model: "name",
                        readonly: false,
                        required: true,
                        featured: true,
                        disabled: false,
                        styleClasses: "label-transition",
                        placeholder: "Name *"
                    },
                    {
                        type: "input",
                        inputType: "email",
                        label: "Email",
                        model: "email",
                        readonly: false,
                        featured: true,
                        required: true,
                        disabled: false,
                        placeholder: "Email *",
                        styleClasses: "label-transition",
                        validator: VueFormGenerator.validators.required
                    },
                    {
                            
                        type: "input",
                        inputType: "password",
                        label: "Password",
                        model: "password",
                        min: 6,
                        featured: true,
                        required: true,
                        placeholder: "Password *",
                        styleClasses: "label-transition password",
                        validator: VueFormGenerator.validators.string
                
                    }
                ],
            },
            formOptions: {
                validateAfterLoad: false,
                validateAfterChanged: true,
                validateAsync: false
            }
        }
    },
    methods: {
        modelUpdated() {
            return;
        },
        validate() {
            let errorMessages = [];

            const isValidName = validateName(this.model.name);
            const isValidEmail = validateEmail(this.model.email);
            const isValidPassword = this.model.password.length >= 8 && this.model.password.length <= 32;

            if(!isValidName.value)
                errorMessages.push(isValidName.error);
            if(!isValidEmail.value)
                errorMessages.push(isValidEmail.error);
            if(!isValidPassword)
                errorMessages.push("Password must be between 8 and 32 characters.");
                

            if(!isValidName.value || !isValidEmail.value || !isValidPassword) {
                this.$emit("emit-error", {
                    error: errorMessages.join(' ')
                });

                return false;
            }
            
            return true;
        },
        submitForm() {

            const isValid = this.validate();
            if(!isValid)
                return;

            this.isProcessing = true;

            const formData = {
                name: this.model.name,
                email: this.model.email,
                password: this.model.password,
                refno: window.bootUp.refno,
                country_code: ''
            }

            // console.log(formData);

            formData.password_confirmation = this.model.password;
            formData.affil_code = 1234;

            let slug = "";

            if(isFreeMTARoute(this.promoID))
                slug = "&slug=free-entry-mta";


            talkToServer(`/register${window.bootUp.cacheParams}${slug}`, 'POST', null, formData).then(response => {
                console.log(response);
                this.isProcessing = false;
                if (!response.success && response.error) {
                    this.$emit('emit-error', {
                        error: response.error,
                        email: this.model.email,
                        name: this.model.name
                    });
                } else {
                    this.saveUserData(response);
                    this.$emit("update-step", {
                        step: this.nextStep
                    });
                    let plan = response.data.user.associated_data.product_access[0];
                    const id = plan.product.id
                    if(isFreeMTARoute(this.promoID))
                        plan = {
                            product : {
                                    name: "Accelerator Taster Videos",
                                    slug: "accelerator-taster-videos",
                                    id: id,
                                    price: 0
                                    
                                }
                            };
                        

                    TrackingService.sendTransaction(plan, "purchased_onboard");
                    this.$emit('register-login-successful', {
                        data: response.data,
                        isOnboard: true
                    });
                    
                    
                }

            }).catch(err => {
                console.log(err);
                this.isProcessing = false;
                let errorText = err.statusText;
                if(!errorText)
                    errorText = "Something went wrong. Please try again later."
                this.$emit('emit-error', {
                    error: errorText,
                    email: this.model.email,
                    name: this.model.name
                });
            });


        },
        togglePwVisibility() {
            let type = this.schema.fields[2].inputType;
            if(type === 'password')
                type = 'text';
            else if(type === 'text')
                type = 'password';

            this.schema.fields[2].inputType = type;
        }
    }
}
</script>