<template>
    <div class="video-screen"
    :class="{rendered: !!impID, 'bm': impID && isUserBm}">
        <div class="video-screen-header" v-if="impID">
            <div class="video-screen-header-left">
                <h1>
                    <button href="javascript:void(0);" @click="backToOverview"><font-awesome-icon class="arrow" :icon="['fas', 'arrow-left']"/> back to training centre</button>
                    <span style="height: 26px;"></span></h1>
                    <h1>
                        <span>{{ pageTitle }}</span>
                    </h1>
            </div>
            <div class="video-screen-header-right">
                <div v-if="showRating">
                    <star-rating :implementation_id="impID"
                    :showTextTile="true"
                    :widgetTitle="pageTitle"></star-rating>
                </div>
                <!--<form @submit.prevent id="training-centre-search">
                    <input type="text" placeholder="Search...">
                    <button><font-awesome-icon :icon="['fas', 'search']"/></button>
                </form> -->
            </div>
        </div>
        <div v-if="!impID"
        class="video-screen-body">
            <div class="video-screen-sidebar">
                <div class="video-screen-sidebar-block">
                    <h2>Content</h2>
                    <ul>
                        <li><a href="javascript:void(0);" :class="{active : activeNav === 'all'}" @click="renderMain('all')"><span>All</span><span>{{ vidlibs.length + livestreams.length }}</span></a></li>
                        <li v-if="livestreams.length"><a href="javascript:void(0);" :class="{active : activeNav === 'livestreams'}" @click="renderMain('livestreams')"><span>Livestream Series</span><span>{{ livestreams.length }}</span></a></li>
                        <li><a href="javascript:void(0);" :class="{active : activeNav === 'vidlibs'}" @click="renderMain('vidlibs')"><span>Courses</span><span>{{ vidlibs.length }}</span></a></li>
                        
                    </ul>
                </div>
            </div>
            <div class="video-screen-main">
                
            
                <div v-if="activeNav === 'all'" class="video-screen-summary">
                    <fullpage-section
                    v-for="item in Object.entries(allObj).filter(el => el[0] !== 'withupcoming' && el[0] !== 'noupcoming')"
                    :key="item[0]"
                    :name="item[0]"
                    :title="item[1].title"
                    :tiles="false"
                    :moreLink="false"
                    :slideDown="false"
                    :scrollable="true"
                    :elements="item[1].data"
                    ></fullpage-section>
                </div>
                <div v-else-if="activeNav === 'vidlibs'" class="video-screen-particular">
                    <fullpage-section
                    v-if="allObj[activeNav].data.length"
                    :name="activeNav"
                    :title="allObj[activeNav].title"
                    :tiles="true"
                    :moreLink="false"
                    :slideDown="false"
                    :scrollable="true"
                    :elements="allObj[activeNav].data"
                    ></fullpage-section>
                </div>
                <div v-else-if="activeNav === 'livestreams'" class="video-screen-particular">
                    <fullpage-section
                    v-if="allObj.withupcoming.data.length"
                    :name="'withupcoming'"
                    :title="allObj.withupcoming.title"
                    :tiles="true"
                    :moreLink="false"
                    :slideDown="false"
                    :scrollable="true"
                    :elements="allObj.withupcoming.data"
                    ></fullpage-section>
                    <fullpage-section
                    v-if="allObj.noupcoming.data.length"
                    :name="'noupcoming'"
                    :title="allObj.noupcoming.title"
                    :tiles="true"
                    :moreLink="false"
                    :slideDown="false"
                    :scrollable="true"
                    :elements="allObj.noupcoming.data"
                    ></fullpage-section>
                </div>
            </div>
        </div>
        <div v-else>
            <dragndrop-grid :active="'vidpage_'+impID" :panelLayoutID="1" :pageType="'videopage'"></dragndrop-grid>
        </div>
    </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';
import { eventBus } from '../main';
import FullpageSection from './ui/FullpageSection.vue';
import DragndropGrid from './ui/DragndropGrid';
import _ from 'lodash';
import LivestreamService from '../services/livestream/livestream.service';
import VideoService from '../services/video/video.service';
import { getServerEnv, mergeArraysByProp } from '../utility/helper-functions';
import StarRating from './widgets/components/StarRating.vue';
import { addTime, usersTime } from '../utility/datetime';
import TrackingService from '../services/tracking/tracking.service';
import Constants from '../utility/constants';
import * as workerTimers from 'worker-timers';

const pageName = "Training Centre";
const orderBy = "asc" //desc

const dashboards = createNamespacedHelpers('dashboard');
const general = createNamespacedHelpers('general');
const livestreamNS = createNamespacedHelpers("webinars");
const libraryNS = createNamespacedHelpers("library");
const payments = createNamespacedHelpers("payments");

// const payments = createNamespacedHelpers("payments");
export default {
    components: {
        StarRating,
        DragndropGrid,
        FullpageSection
    },
    created() {
        eventBus.$on("render-video-library-component", obj => { 
            if(obj.instanceID === this.$root.instanceID) {
                this.impID = obj.impID
            }
        });

        eventBus.$on("back-to-training-centre", () => this.backToOverview());

        this.getAndSortContent();

        // this.vidpageDashboards = this.systemDashboards.filter(el => el.vidpage);

        if(this.activeDashboard && this.activeDashboard !== -1)
            this.impID = +this.activeDashboard.replace('vidpage_','');

    },
    mounted() {
        TrackingService.sendEvent("viewed_training_centre", {});
        this.setupHeartBeat();
    },
    beforeDestroy() {
        workerTimers.clearInterval(this.heartbeatInterval);
        this.heartbeatInterval = null;
        eventBus.$off("back-to-training-centre");
    },
    computed: {
        ...livestreamNS.mapGetters(['lastLiveStreamUpdate', 'lastLiveStreamDelete']),
        ...libraryNS.mapGetters(['lastVideoUpdate', 'lastVideoDelete']),
        ...dashboards.mapGetters(['systemDashboards', 'activeDashboardByInstanceID', 'layouts']),
        ...general.mapGetters(['lastTrainingCentreOrderUpdated', 'isUserBm']),
        ...payments.mapState(['currentProducts', 'widgetsOnTheHomeScreen']),
        ...payments.mapGetters(['qnaStyleWidgets', 'videoWidgetsForShop']),

        activeDashboard() {
            return this.activeDashboardByInstanceID(this.$root.instanceID, "videopage");
        },
        
        vidpageDashboards() {
            return this.systemDashboards.filter(el => el.vidpage);
        },
        notAccessible() {
            return this.vidpageDashboards.filter(el => {
                const wid = el.widget;
                
                if(wid.planDetails?.includedPlan.length)
                    return false;
                else if(!wid.planDetails?.included && wid.planDetails?.planProducts.filter(el => el.is_visible_front_end).length)
                    return true;
                else if(!wid.planDetails?.planProducts.filter(el => el.is_visible_front_end).length)
                    return true;

                else 
                    return false;
            });
        },
        accessible() {
            const notAccessibleIDs = this.notAccessible.map(el => el.impID);
            return this.vidpageDashboards.filter(el => !notAccessibleIDs.includes(el.impID));

        },
        livestreams() {

            return _.orderBy(this.vidpageDashboards.filter(el => this.filterTCItemsByAccess(el, "TCSTREAM"), el => el.orderBy, orderBy));

            // return [
                // ..._.orderBy(this.accessible.filter(el => el.code === "TCSTREAM"), el => el.widget.training_centre_order_at, orderBy), 
                // ..._.orderBy(this.notAccessible.filter(el => el.code === "TCSTREAM"), el => el.widget.training_centre_order_at, orderBy), 
            // ];
        },
        vidlibs() {


            return _.orderBy(this.vidpageDashboards.filter(el => this.filterTCItemsByAccess(el, "TCVIDEO")), el => el.orderBy, orderBy);

            // return [
            //     ..._.orderBy(this.accessible.filter(el => el.code === "TCVIDEO"), el => el.widget.training_centre_order_at, orderBy), 
            //     ..._.orderBy(this.notAccessible.filter(el => el.code === "TCVIDEO"), el => el.widget.training_centre_order_at, orderBy), 
            // ];
        },
        pageTitle() {
            const found = this.vidpageDashboards.find(el => el.impID === this.impID);
            if(found)
                return found.name;
            else 
                return pageName;
        },
        showRating() {
            let longAgo = true;

            const past = addTime(-3, "months");

            const ratings = this.$store.getters["widget/ratings"];

            let refDate = ratings?.[this.impID];
            if (refDate) {
                refDate = usersTime(ratings?.[this.impID], true, true, true, {
                    dateFormat: "y-m-d",
                    timeFormat: "24hr"
                });

                if (refDate) {

                    if (past < refDate)
                        longAgo = false;
                }
            }


            return longAgo;
        },
    },
    data() {
        return {
            impID: null,
            activeNav: 'all',
            allObj: {
                livestreams: {
                    title: "Livestream series",
                    name: "livestreams",
                    data: this.livestreams
                },
                withupcoming: {
                    title: "Livestream series",
                    name: "withupcoming",
                    data: []
                },
                noupcoming: {
                    title: "Livestream Archives",
                    name: "noupcoming",
                    data: []
                },
                vidlibs: {
                    title: "Video Courses",
                    name: "vidlibs",
                    data: this.vidlibs
                }
            },
            itemsToFetch: [],
            bulkItems: [],
            sortedItems: {},
            resultsReady: true,
            searchTerms: null,
            heartbeatInterval: null
            // vidpageDashboards: []
        }
    },
    methods: {
        ...dashboards.mapActions(['setActiveDashboard', 'updateDashboardById']),
        renderMain(section) {
            this.activeNav = section;
        },
        backToOverview() {
            this.impID = null
            this.setActiveDashboard({
                target: 'videopage',
                id: -1,
                instanceID: this.$root.instanceID
            });
            this.setupHeartBeat();
        },
        getAndSortContent() {
            let withupcoming = _.cloneDeep(this.livestreams);
            let noupcoming = _.cloneDeep(this.livestreams);
            // const now = usersTime(new Date(), true, true, false, { dateFormat : "d M Y", timeFormat: "24hr" }, false, "UTC");

            const Ids = this.livestreams.map(el => el.impID);

            LivestreamService.fetchNextUpcomingByImpIDs(Ids).then(r => {
                if(!_.isEmpty(r)) {
                    
                    let keys = Object.keys(r);
                    keys = keys.map(el => +el);
                    withupcoming = withupcoming.filter(el => keys.includes(el.impID));
                    noupcoming = noupcoming.filter(el => !keys.includes(el.impID))
                }
                else {
                    withupcoming = [];
                    noupcoming = [...this.livestreams];
                   
                }

                const allObj = _.cloneDeep(this.allObj);
                // allObj.livestreams.data = this.livestreams;
                allObj.livestreams.data = [...withupcoming, ...noupcoming];
                allObj.withupcoming.data = withupcoming;
                allObj.noupcoming.data = noupcoming;
                allObj.vidlibs.data = this.vidlibs;

                this.allObj = allObj;
                // console.log(this.allObj);

            });

            // if(this.qnaStyleWidgets.length) {
                // this.itemsToFetch = [98]//this.qnaStyleWidgets.map(el => el.implementation_id)
                // console.log("we have QA style widget", this.qnaStyleWidgets);
                // this.fetchInitialItems();

            // }
        },
        async fetchInitialItems() {
            this.resultsReady = false;
            
            const obj = {
                categories: this.itemsToFetch,
                type: ""
            };

            this.bulkItems = await VideoService.fetchInitialItems(obj);
            if(this.bulkItems.categories.length || this.bulkItems.videos.length) {
                this.resultsReady = true;
            }
            // in case videos have not been loaded on the cache
            // else if(!this.serverForceFetchDone) {
            //     this.fetchItemsFromServer();
            // }else if(this.serverForceFetchDone && !this.bulkItems.length)
            //     this.resultsReady = true;

            this.serverForceFetchDone = true;
            // console.log("itemsToFetch", this.itemsToFetch);
            if(this.itemsToFetch.length === 1)
                this.currentCategories = _.cloneDeep(this.itemsToFetch);
            else
                this.currentCategories = -1;

            // console.log("curr Cats", this.currentCategories);
            this.sortDataByCategories();
            // this.focusFirstVideoIfNeeded();
            
        },
        sortDataByCategories() {
            
            const cats = _.orderBy(this.bulkItems.categories, el => el.order_by, "asc");
            const sortedItems = {};
            
            cats.forEach(el => {

                const id = el.id;
                // const itemName = this.qnaStyleWidgets.find(elm => elm.categories.includes(id));
                if(!sortedItems[id]);
                    sortedItems[id] = [];

                const vids = _.orderBy(this.bulkItems.videos.filter(el => el.categories.includes(id)), el => el.order_by, "asc");
                sortedItems[id] = vids;

            });
            this.sortedItems = _.cloneDeep(sortedItems);
            // console.log(this.sortedItems);

        },
        async loadMore () {
            const id= this.itemsToFetch[0];
            const bulkItems = _.cloneDeep(this.bulkItems);
            // console.log(this.bulkItems.videos, id)
            this.resultsReady = false;
            const limit = 12;
            let offset = this.sortedItems[id].length;
            let from = "";
            let to = ""
            let keywords ="*";
            let searchMode = false;

            // if(this.searchTerms) {
            //     from = this.searchTerms.from;
            //     to = this.searchTerms.to;
            //     keywords = this.searchTerms.keywords;
            //     offset = this.bulkItems.length ? this.bulkItems.length : 0;
            //     searchMode = true;
            // }

            // console.log(this.currentCategories);

            const response = await VideoService.loadMoreItems(this.itemsToFetch, offset, limit, searchMode, from, to, keywords);

            // console.log("load more", response);

            let bulkItemVideos = mergeArraysByProp(bulkItems.videos, response, "id");
            if(response.length)
                bulkItemVideos = _.orderBy(bulkItemVideos, el => el.order_by, "asc");

            bulkItems.videos = bulkItemVideos;
            this.bulkItems = bulkItems;

            // console.log(this.bulkItems);
            this.sortDataByCategories();

            if(!response.length) {
                this.noMoreItems = true;
            }
            this.resultsReady = true;


        },
        filterTCItemsByAccess(el, type) {
          
            const env = getServerEnv();
            
            const tcVid = el.code === type;
            
            let access = true;
            const thisTCVid = this.videoWidgetsForShop.find(e => e.implementation_id === el.impID);
            if(!thisTCVid.planDetails.included && Constants.SHOW_ONLY_IF_PURCHASED_TCS[env].includes(el.impID))
                access = false;
            
            return tcVid && access; 
           
        },
        setupHeartBeat() {
            this.sendTracking();
            if(this.heartbeatInterval)
                workerTimers.clearInterval(this.heartbeatInterval);
            this.heartbeatInterval = workerTimers.setInterval(() => {
                this.sendTracking();
        }, Constants.TRACK_INTERVAL);
        },
        sendTracking() {
            if(!this.impID) {
                TrackingService.trackHeartBeat({
                    lookingAt: "training_centre"
                });
                // console.log("heartbeat", "training centre");
            }
        }
    },
    watch: {
        lastTrainingCentreOrderUpdated: {
            deep: true,
            handler: function(nVal,oVal) {
                if(!_.isEqual(nVal,oVal)) {
                    // console.log("lastTrainingCentreOrderUpdated", nVal,oVal, this.vidpageDashboards);
                    const layouts = _.cloneDeep(this.layouts);
                    const changed = layouts["vidpage_" + nVal.widget_implementation_id];

                    if(changed) {
                        changed.orderBy = nVal.training_centre_order_at.replace("T",' ').substring(0,19);
                        
                        // console.log(changed);
                        this.updateDashboardById(changed);
                    }
                }
                this.getAndSortContent();
            }
        },
        lastLiveStreamUpdate() {
            this.getAndSortContent();
        },
        lastLiveStreamDelete() {
            this.getAndSortContent();
        },
        lastVideoUpdate() {
            this.getAndSortContent();
        },
        lastVideoDelete() {
            this.getAndSortContent();
        },
        currentProducts: {
            handler: function() {
                this.getAndSortContent();
            },
            deep: true
        },
        widgetsOnTheHomeScreen: {
            handler: function() {
                this.getAndSortContent();
            },
            deep: true
        },
    }
}
</script>

<style lang="scss" scoped>
    .video-screen {
        @include standardContainer;

        &.bm {
            min-height: calc(100vh - 120px);
        }

        padding-bottom: 0;

        @include respond(md) {
            padding-bottom: 0;
        }

        // &.rendered {
        //     padding: 0;
        // }
        
        
    }
    .video-screen-header {
        
        margin: 15px 0 20px;

        @include respond(sm) {
            display: flex;
            margin: 15px 0 10px;

        }

        & h1 {
            margin: 0;
            font-weight: 500;
            font-size: 20px;
            color: $input-color;
            letter-spacing: 0.12px;
            line-height: 22px;
            display: flex;

            @include respond(lg) {
                font-size: 28px;
                line-height: 25px;
            }

            & button {
                background-color: transparent;
                border: none;
                color: $checkbox-active;
                font-size: 12px;
                text-transform: uppercase;
                // width: 62px;
                margin-right: 10px;
                margin-bottom: 10px;
                cursor: pointer;
                text-align: left;
            }
        }

        & > div:last-child {
            margin-left: auto;
        }

    }
    #training-centre-search {
        position: relative;

        & input {
            background-color: #303030;
            height: 40px;
            font-size: 16px;
            padding-left: 40px;

            &::placeholder {
                color: $input-color;
            }

        }

        button {
            position: absolute;
            top: 12px;
            left: 12px;
            color: $input-color;
            background-color: transparent;
            border: none;
            cursor: pointer;
        }

    }
    .video-screen-body {

        @include respond(md) {
            display: grid;
            grid-template-columns: 185px repeat(auto-fit, minmax(250px, 1fr));
            gap: 35px;
        }
        
    }
    .video-screen-sidebar-block {
        & h2 {
            font-weight: 200;
            font-size: 24px;
            color: $input-color;
            letter-spacing: 0.1px;
            margin: 10px 0 20px;
        }

        & ul {
            margin: 0;
            padding: 0;
            list-style: none;

            & li {

                & a {
                    display: flex;
                    color: $input-color;
                    text-decoration: none;
                    font-size: 16px;
                    letter-spacing: 0.1px;

                    padding: 4px 10px;
                    width: 100%;
                    border-radius: 8px;

                    &.active {
                        background-color: $checkbox-active;
                    }

                    & span:last-child {
                        margin-left: auto;
                    }
                }
            }

        }
    }
</style>

<style lang="scss">
    .video-screen-summary .fullpage-section-header,
    .video-screen-particular .fullpage-section-header {
        margin-top: 0;
        margin-bottom: 10px;
    }
    .video-screen-summary .fullpage-section-header--title,
    .video-screen-particular .fullpage-section-header--title {
        font-weight: 200;
        font-size: 24px;
        color: $input-color;
        letter-spacing: 0.1px;
        margin: 10px 0;
    }

    .video-screen-header-right {

        display: flex;

        & .star-rating {

            margin-top: 15px;

            @include respond(lg) {
                margin-top: 0;
                
            }
        }

        & .star-rating__link {
            padding: 0;
        }
    }
</style>
