<template>
    <div>
        <form id="feedback-form" @submit.prevent>
			<div>
				<h2>Rate Our {{ widgetType }}</h2>
				<p>How useful is our <span>{{ widgetTitle }}</span> {{ widgetType }} for helping you generate trade ideas?</p>
				<div class="error">&nbsp;<span v-if="hasError">{{ error }}</span></div>
				
				<div class="star-rating-element">
					<div>

						<input class="star-input" type="radio" v-model="stars" id="star-null" checked :value="0"/>
						<input class="star-input" type="radio" v-model="stars" id="star-1" :value="1"/>
						<input class="star-input" type="radio" v-model="stars" id="star-2" :value="2"/>
						<input class="star-input" type="radio" v-model="stars" id="star-3" :value="3"/>
						<input class="star-input" type="radio" v-model="stars" id="star-4" :value="4"/>
						<input class="star-input" type="radio" v-model="stars" id="star-5" :value="5"/>
						<section class="star-section">
						<label class="star-label" for="star-1">
							<svg width="255" height="240" viewBox="0 0 51 48">
							<path d="m25,1 6,17h18l-14,11 5,17-15-10-15,10 5-17-14-11h18z"/>
							</svg>
						</label>
						<label class="star-label" for="star-2">
							<svg width="255" height="240" viewBox="0 0 51 48">
							<path d="m25,1 6,17h18l-14,11 5,17-15-10-15,10 5-17-14-11h18z"/>
							</svg>
						</label>
						<label class="star-label" for="star-3">
							<svg width="255" height="240" viewBox="0 0 51 48">
							<path d="m25,1 6,17h18l-14,11 5,17-15-10-15,10 5-17-14-11h18z"/>
							</svg>
						</label>
						<label class="star-label" for="star-4">
							<svg width="255" height="240" viewBox="0 0 51 48">
							<path d="m25,1 6,17h18l-14,11 5,17-15-10-15,10 5-17-14-11h18z"/>
							</svg>
						</label>
						<label class="star-label" for="star-5">
							<svg width="255" height="240" viewBox="0 0 51 48">
							<path d="m25,1 6,17h18l-14,11 5,17-15-10-15,10 5-17-14-11h18z"/>
							</svg>
						</label>
						<label class="star-label" for="star-null" @click="clear">
							Clear
						</label>  
						</section>
					</div>
					<div class="star-value">
						<span class="star-value-actual">{{ stars }}.0</span>
						<span class="star-value-total"> / 5</span>
					</div>
				</div>
				<div class="star-rating-comment">
					<base-input 
					forID="feedback-comment"
					elementType="textarea"
					v-model="comment"
					label="How can we improve it?"
					max="500"
					>
					</base-input>
				</div>
			</div>
			<div>	
				<div class="star-rating-submit">
					<base-button 
					mode="o-blue"
					@click.native="submit" 
					>Submit feedback</base-button>
				</div>
			</div>
        </form>
    </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';
import { eventBus } from '../../../main';
import { talkToServer } from '../../../utility/helper-functions';
const widgets = createNamespacedHelpers("widget");

export default {
    props: ["panel"],
	created() {console.log(this.panel);},
	computed: {
		...widgets.mapGetters(['getCodeByImplementationId']),
        widgetType() {
            if(this.getCodeByImplementationId(this.panel.implementation_id) === 'TCSTREAM')
                return "live stream series";
            if(this.getCodeByImplementationId(this.panel.implementation_id) === 'TCVIDEO')
                return "video course";

            return 'indicator';
        },
		hasError() {
			return this.error && this.incorrectStars
		},
		incorrectStars() {
			return this.stars < 1 || this.stars > 5;
		}
	},
	data() {
		return {
			comment: '',
			stars: 0,
			error: '',
			widgetTitle: this.panel.widgetTitle
		}
	},
	methods: {
		clear() {
			this.error = '';
		},
		submit() {
			if(this.incorrectStars) {
				this.error = "Star rating must be between 1 and 5."
				return;
			}

			const obj = {
				widget: this.panel.implementation_id,
				name: this.widgetTitle,
				star_rating: this.stars,
				comment: this.comment
			};

			talkToServer("/rating", "POST", null, obj, true)
			.then(() => {
				eventBus.$emit("close-modal");
				this.$store.dispatch("widget/setSingleRating", this.panel.implementation_id);
			})
			.catch(error => console.log(error));

		}
	}
}
</script>

<style lang="scss" scoped>

	form {
		display: grid;
		grid-template-rows: 1fr 110px;

		& > div {
			padding: 0px 30px;
		}

		& > div:last-child {
			background-color: $modal-footer;
		}
	}

	h2 {
		@include H2;
		text-transform: capitalize;

		
	}

	p {

		@include SubHead2;
		color: $input-color-filter;

		& span {
			font-style: italic;
		}
	}

	.star-rating-element {
		margin-top: 25px;
		height: 120px;
		// position: relative;
		display: flex;
		flex-direction: column;
		align-items: center;

		@include respond(sm) {
			flex-direction: row;
			align-items: initial;
			height: 70px;
		}
	}

	.error {
		text-align: center;
		color: $color-12;
	}

	.star-rating-comment {
		margin-top: 20px;

		& label {
			font-weight: bold;
			display: block;
			margin-bottom: 5px;
		}

	}

	.star-rating-submit {
		margin-top: 25px;
		text-align: center;

		@include respond(sm) {
			text-align: left;
		}
	}

	
	// select the svg element
	$svg: "";
	// select the path
	$path: "";
	// for each radio
	@for $i from 1 through 5 {
		// for each valid star
		@for $x from 1 through $i {
			// add checked input to selectors
			$svg: $svg + "#star-#{$i}:checked ~ ";
			$path: $path + "#star-#{$i}:checked ~ ";
			// add svg or path element sib to selectors
			// scope = section label svg [path]
			$svg: $svg + "section [for='star-#{$x}'] svg";
			$path: $path + "section [for='star-#{$x}'] svg path";
			// if not the last, we add a comma
			@if $x != $i {
				$svg: $svg + ", ";
				$path: $path + ", ";
			}
		}
		// if not the last, we add the comma
		@if $i != 5 {
			$svg: $svg + ", ";
			$path: $path + ", ";
		}
	}

	// style the active svg
	#{$svg} {
		transform: scale(1);
	}
	// style the active path
	#{$path} {
		fill: #FFBB00;
		stroke: darken(#FFBB00, 10%);
	}  

	.star-section {
		background-color: transparent!important;
		// width: 300px;
		// text-align: center;
		// position: absolute;
		// top: 0%; 
		// left: 50%;
		// transform: translate3d(-50%, -50%, 0);
	}

	.star-label {
		display: inline-block;
		width: 32px;
		text-align: center;
		cursor: pointer;

		margin: 5px auto;
		svg {
			width: 100%;
			height: auto;
			fill: white;
			stroke: #CCC;
			transform: scale(0.8);
			transition: transform 200ms ease-in-out;
			path {
				transition: fill 200ms ease-in-out,
				stroke 100ms ease-in-out;
			}
		}

		@include respond(sm) {
			width: 50px;
			margin: 0px auto;
		}
	}

	.star-label[for="star-null"] {
		display: block;
		// margin: 0 auto;
		color: $input-placeholder-color;
		font-size: 9px;
	}

	// body {
	//   text-align: center;
	//   background: #f0f0f0;
	// }

	.star-input {
		display: none;
	}

	.star-value {
		height: 56px;
		display: flex;
		align-items: center;
		

		@include respond(sm) {
			margin-left: 18px;
			// padding-top: 14px;
		}
	}
	.star-value-actual {
		@include H3;
		margin-right: 6px;
		text-align: right;

		@include respond(sm) {
			width: 45px;
			@include H1
		}
	}
	.star-value-total {
		@include H7;
		color: $input-placeholder-color;

		@include respond(sm) {
			@include H3;
		}
	}
</style>