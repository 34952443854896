<template>
    <div class="widget-shop">
        <div class="widget-config">
            <div class="shop">
                <div class="shop-tabs">
                    <component :is="componentToBeRendered"></component>
                </div>
                <div class="shop-bottom">
                <div
                class="col-2-lg-fs flex-center-lg"
                style="height:100%"
                >

                    <span style="width:100%"
                    >
                        <base-button
                        mode="o-blue fullwidth"
                        :link="false"
                        @click.native="navigate"
                        >{{ buttonText }}
                        </base-button>
                    </span>
                    <span
                    style="width:100%">
                        <base-button
                        mode="o-tranparent-grey fullwidth"
                        :link="false"
                        @click.native="dismiss"
                        >
                        <span>Don't show this again</span>
                        </base-button>
                    </span>

                </div>

            </div>
            </div>
        </div>
    </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';
import { eventBus } from '../../../main';
import { talkToServer } from '../../../utility/helper-functions';
import SquawkNotice from "./SquawkNotice.vue";
import SquawkOffer from "./SquawkOffer.vue";

const general = createNamespacedHelpers("general");

export default {
    components: {
        SquawkNotice,
        SquawkOffer,
    },
    computed: {
        buttonText() {
            if(this.componentToBeRendered === "squawk-offer")
                return "Back";

            return "Learn More";
        }
    },
    data() {
        return {
            componentToBeRendered: "squawk-notice"
        }
    },
    methods:  {
        ...general.mapActions(["setWarningDismissed"]),
        navigate() {
            if(this.componentToBeRendered === "squawk-notice")
                this.componentToBeRendered = "squawk-offer";
            else if(this.componentToBeRendered === "squawk-offer")
                this.componentToBeRendered = "squawk-notice";
        },
        dismiss() {
            eventBus.$emit("close-modal");
            this.setWarningDismissed();
            talkToServer('/setting/user/squawk-warning202308', "POST", null, { dismissed: true }, true);
        }
    }
}
</script>