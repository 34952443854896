<template>
    <div>
        <unplugged-notice
        v-if="!entity.quantum_mode"
        :inactiveMessage="'To activate our quantum software, please contact support for instructions and assistance.'"
        ></unplugged-notice>

        <div v-else class="client-profiles">
            <div style="padding-top: 40px;">
                Please enlarge your window or log in on a desktop device to manage clients.
            </div>
            <div class="client-profiles-tabs">
                <div class="client-profiles-tabs-left-tab">
                </div>
                <div class="client-profiles-main">
                    <div>
                        <tab-menu 
                        type="clientprofiles"
                        class="tab-menu-section"
                        :active="activeList"
                        :tabs="tabbedLists"
                        @menu-item-clicked="obj => switchTab(obj.id)"
                        ></tab-menu>
                    </div>
                    <div style="margin-top: 2px;">
                        <client-profile-overview
                        :activeList="activeList"
                        >
                        </client-profile-overview>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';
import { eventBus } from '../../../main';
import TabMenu from '../TabMenu.vue';
import ClientProfileOverview from './components/ClientProfileOverview.vue';
import UnpluggedNotice from './components/UnpluggedNotice.vue';
const general = createNamespacedHelpers("general");

export default {
    components: {
        ClientProfileOverview,
        TabMenu,
        UnpluggedNotice,
    },
    created() {
        eventBus.$on("client-switch-to", list => this.switchTab(list));
    },
    beforeDestroy() {
        eventBus.$off("client-switch-to");
    },
    computed: {
        ...general.mapGetters(["entity"]),
    },
    data() {
        return {
            activeList: "requested",
            tabbedLists: [
                {
                    id: "approved",
                    name: "Access granted",
                    icon: "access-granted"
                },
                {
                    id: "requested",
                    name: "Access requested",
                    icon: "access-request"
                }
                    
            ]
        }
    },
    methods: {
        switchTab(list) {
            this.activeList = list;
        }
    }
}
</script>

<style lang="scss">
    .client-profiles {
        padding: 20px 25px;
        background-color: $onboard-survey-background;

        & .client-profiles-main {
            padding: 20px;
        }

        & .client-profiles-tabs-left-tab-menu {
            padding: 20px 0 0 20px;

            & li {
                list-style: none;
                margin: 0;
                padding: 0;
                display: inline-block;
                width: 100%;

                &.active-team-menu-item {
                    background-color: $input-color;
                }

                & a {
                    color: $input-color;
                    display: inline-block;
                    padding: 10px;
                    width: calc(100% - 4px);
                    margin-left: 4px;
                    text-decoration: none;

                    &.active-team-menu-item {
                        background-color: $onboard-survey-card-background;
                      
                    }

                }
            }
        }

        & .client-profiles-tabs {
            display: none;

            @include respond(lg) {
                display: block;
                
            }
        }

        &.team .client-profiles-tabs {
            display: none;

            @include respond(lg) {
                display: grid;
                grid-template-columns: 200px 1fr;
            }
        }

        & .client-profiles-tabs-left-tab {
            border-right: 1px solid $partner-border-3;
        }

        & > div:first-child {
            text-align: center;
            color: $input-color-filter;

            @include respond(lg) {
                display: none;
            }
        }
    }
</style>