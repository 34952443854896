<template>
    <div class="terminal-count-down grey">
        <div class="video-player">
            <div><h2>{{ ended ? details.afterMessage : details.beforeMessage }}</h2></div>
            <flip-countdown
            v-if="!ended"
            :deadline="deadline"
            @timeElapsed="timeElapsed"></flip-countdown>
        </div>
        <div class="reminder-button" v-if="showReminder">
            <base-button 
            v-if="isReminderActive"
            @click.native="cancelReminder(video.id)"
            mode="video-button green">Reminder Set
            </base-button>

            <base-button
            v-else 
            @click.native="setReminder(video.id)"
            mode="video-button o-blue">Set Reminder
            </base-button>
        </div>
        <sunset-ribbon v-if="this.impID === 65" style="margin: 0 25px;"></sunset-ribbon>
    </div>
</template>

<script>
import FlipCountdown from 'vue2-flip-countdown';
import { usersTime } from '../../utility/datetime';
import ReminderMixin from '../../mixins/reminderMixin';
import SunsetRibbon from '../widgets/components/VideoPlayers/SunsetRibbon.vue';

export default {
    props: ["details", "video", "impID"],
     mixins: [ReminderMixin],
    components: {
        FlipCountdown,
        SunsetRibbon
    },
    created() {
        const now = usersTime(new Date(), true, true, true, {
                dateFormat: "y-m-d",
                timeFormat: "HH:mm:ss"
            }, true);

        if(this.deadline <= now)
            this.ended = true;

        this.manageReminderStatus();
    },
    computed: {
        deadline() {
            // console.log(this.details.deadLine);
            return usersTime(this.details.deadLine, true, true, true, {
                dateFormat: "y-m-d",
                timeFormat: "HH:mm:ss"
            }, true);
        }
    },
    data() {
        return {
            ended: false
        }
    },
    methods: {
        timeElapsed() {
            this.showReminder = false;
            this.ended = true;
        },
        manageReminderStatus() {
            this.showReminder = !this.ended;
            const active = this.activeReminders.findIndex(el => el == this.video.id) > -1;

            if(active)
                this.isReminderActive = true;
            else
                this.isReminderActive = false;
        }
    }
}
</script>

<style lang="scss">
    .terminal-count-down.grey {
        h2 {
            @include H6;
            text-align: center;
            color: $input-color;
            padding: 20px 0;

            @include respond(lg) {
                padding-top: 10px;
            }
        }

        & .flip-clock {
            padding-bottom: 25px;
        }

        & .flip-card {
            font-size: 32px;
        }

        & .flip-card__top, 
        & .flip-card__bottom,
        & .flip-card__back-bottom{
            background-color: $onboard-survey-background;
            color: $input-color;
        }

        & .flip-card__back::before,
        & .flip-card__back::after {
            background-color: #1F1F1F;
            color: $input-color;
        }

        & .flip-clock__slot {
            color: $input-color-filter;
            font-size: 13px;
        }

        .reminder-button {
            text-align: center;
            padding-bottom: 20px;

            & button {
                font-size: 14px;
                padding: 10px 15px;
                height: auto;
            }
        }

        .video-player {
            padding-left: 15px;
            padding-right: 15px;
        }
    }


    .size-md {
        .terminal-count-down.grey {
            & h2 {
                @include H2;
            }
            & .flip-card {
                font-size: 44px;
            }
            & .flip-clock__slot {
                
                font-size: 22px;
            }
        }
        
    }
    
</style>