import * as workerTimers from 'worker-timers';
import { nowUTC } from "../utility/datetime";

export default {
    created() {
        this.$nextTick(() => {
            this.handleUserLock();
            this.userLockHeartBeat = workerTimers.setInterval(() => {
                this.handleUserLock();
            }, 1000);
        });
    },
    beforeDestroy() {
        workerTimers.clearInterval(this.userLockHeartBeat);
        this.userLockHeartBeat = null;
    },
    data() {
        return {
            isUserEditingLocked: false,
            userLockHeartBeat: null
        }
    },
    methods: {
        handleUserLock() {
            let lockedUntil = this.listUserForUserLockEditing.locked_until;
            if(lockedUntil)
                lockedUntil = lockedUntil.replace("T", " ").substr(0,19);
            // console.log(nowUTC(), lockedUntil);
            let result = false;

            if(!lockedUntil)
                result = false;
            else if(nowUTC() < lockedUntil)
                result = true;    
            
            this.isUserEditingLocked = result;
        }
    }
}