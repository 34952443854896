<template>
    <div>  
        <div>
            <div class="form-header">
                <h2>Let's build your portal</h2>
                <p>This will help us tailor our solution to your specific business</p>
            </div>
            <form @submit.prevent>

                <!-- <base-input
                elementType="input"
                :class="{invalid: error.url}"
                inputType="text"
                :required="true"
                v-model="profile.url"
                forID="url"
                :placeholder="'https://yourdomain.com'"
                inputClass="partner-input"
                formControlClass=""
                label="Your membership site url"
                labelClass="medium-font"
                :invalid="error.url"
                :invalidMessage="error.url"
                @clear-validity="clearValidity"
                ></base-input> -->

                <base-input
                elementType="input"
                :class="{invalid: error.crm}"
                inputType="text"
                :required="true"
                v-model="profile.crm"
                forID="crm"
                :placeholder="'CRM name'"
                inputClass="partner-input"
                formControlClass=""
                label="Which client-portal CRM provider do you use? "
                labelClass="medium-font"
                :invalid="error.crm"
                :invalidMessage="error.crm"
                @clear-validity="clearValidity"
                ></base-input>

                <base-input
                elementType="input"
                :class="{invalid: error.customCrm} "
                inputType="checkbox"
                v-model="profile.customCrm"
                forID="customCrm"
                inputClass="partner-input"
                formControlClass="checkbox-left"
                label="We built our own"
                labelClass="medium-font"
                @clear-validity="clearValidity"
                >
                </base-input>
                <div class="error">{{ serverError }}</div>

                <div class="button-bar">

                    <base-button
                    mode="o-tranparent-grey o-width-125"
                    :link="false"
                    @click.native="back"
                    >Back
                    </base-button>

                    <base-button
                    mode="o-blue o-width-125"
                    :link="false"
                    @click.native="next"
                    :disabled="isProcessing"
                    :showSpinner="isProcessing"
                    >Continue
                    </base-button>
                    
                </div>
            </form>
        </div>
    </div>
</template>
<script>
import _ from 'lodash';
import { createNamespacedHelpers } from 'vuex';
import { talkToServer } from '../../../utility/helper-functions';
import BootUpMixin from "../../../mixins/bootUpMixin";

const general = createNamespacedHelpers("general");

const CONDITIONS = [
    // {test: (model) =>  model.url?.length > 14 && model.url?.length < 71 && model.url?.indexOf('https://') === 0 && model.url?.match(/\.\w{2,10}/)?.[0], message: 'URL must be 15 - 70 characters long and include https:// and a TLD, like .com', key: "url"},
    {test: (model) => model.crm?.length > 1 && model.crm?.length < 31, message: 'CRM must be 2 - 30 characters long', key: "crm"},
];

export default {
    mixins: [BootUpMixin],
    created() {
        if(!Object.prototype.hasOwnProperty.call(this.profile, 'customCrm'))
            this.profile.customCrm = false;
           

        this.profile = {...this.profile, ...this.brokerRegistrationData};
    },
    computed: {
        ...general.mapGetters(["brokerRegistrationData"]),
    },
    data() {
        return {
            profile: {},
            error: {},
            isProcessing: false,
            serverError: ""
        }
    },
    methods: {
        clearValidity(name) {
            delete this.error[name];
        },
        validateData() {
        this.error = {};
            CONDITIONS.forEach((condition) => {
                if (!condition.test(this.profile)) {
                    this.error[condition.key] = condition.message;
                }
            });
            // console.log(this.error)
        },
        next() {

            this.validateData();
            if(!_.isEmpty(this.error))
                return;

            this.isProcessing = true;

            const formData = {
                ...this.profile,
                // name: this.profile.name,
                // email: this.profile.email,
                // password: this.profile.password,
                // country_code: ''
                // refno: window.bootUp.refno,
                // password_confirmation: this.profile.password
            }

            // console.log(formData);


            talkToServer(`/b/register${window.bootUp.cacheParams}`, 'POST', null, formData).then(response => {
                console.log("reg", response);
                this.isProcessing = false;
                if (!response.success && response.error) {
                    console.log(response.error);
                } else {
                    // no need to log in user automatically
                    // this.saveUserData(response);
                        
                    // setTimeout(() => {
                    //     this.$emit('register-login-successful', {
                    //         data: response.data,
                    //         isLogin: true
                    //     });
                    // },2500);

                    this.$emit("partner-new-step", {});
                    
                }

            }).catch(err => {
                console.log(err);
                if(!err)
                    return;

                
                this.isProcessing = false;
                if(err.statusText)
                    this.serverError = err.statusText;
                else
                    this.serverError = "Something went wrong, please try again later.";
            });

        },
        back() {
            this.$emit("partner-new-step");
        },
    }
}

</script>