<template>
    <div class="widget-config">
        <div class="shop">
            <div class="shop-tabs">
                <tab-menu
                style="padding: 0 15px;"
                v-if="details.mode !== 'purchase'"
                type="widget-config"
                :active="activeTab"
                :tabs="tabbedMenu"
                @menu-item-clicked="changeTab"
                ></tab-menu>

                <div class="tabs-body"
                :class="activeTab">
                    <div class="tabs-body-content" v-if="activeTab === 'options'">

                        <form class="" @submit.prevent
                        >

                            <div class="widget-edit-top-row">
                                <div>
                                    <base-input
                                    elementType="input"
                                    :class="{invalid: false}"
                                    inputType="text"
                                    v-model="widgetname.val"
                                    forID="widgetname"
                                    inputClass=""
                                    formControlClass=""
                                    label="Edit name"
                                    :invalid="false"
                                    invalidMessage="Name must be 3 - 30 characters long"
                                    @clear-validity="clearValidity"
                                    ></base-input>
                                </div>
                                <div class="form-control-fs">

                                    <base-button
                                    mode="o-grey tiny-icon-button"
                                    :link="false"
                                    @click.native="deleteWidget"
                                    ><font-awesome-icon :icon="['fas', 'trash-alt']" />
                                    </base-button>
                                </div>
                            </div>


                            <vue-form-generator
                            :schema="schema"
                            :model="model"
                            :options="formOptions"
                            @model-updated="modelUpdated"></vue-form-generator>

                            <!-- <p v-if="errorMessage" class="error-message">{{ this.errorMessage }}</p> -->
                            <div id="aggregate-country-checkboxes" style="display: none; margin-top:10px;">
                                <label for="ALL" style="display: inline-block;">
                                <input type="checkbox" id="ALL" style="height: 14px;" :checked="allCountriesTicked" @change="allCountriesTickChange">
                                All countries
                                </label>
                                <label for="MAJ" style="display: inline-block; margin-left: 15px;">
                                    <input type="checkbox" id="MAJ" style="height: 14px;" :checked="majorCountriesTicked" @change="majorCountriesTickChange">
                                    Major Countries
                                </label>
                            </div>
                            <div id="aggregate-impact-checkboxes" style="display: none; margin-top:10px;">
                                <label for="impacts" style="display: inline-block;">
                                <input type="checkbox" id="impacts" style="height: 14px;" :checked="allImpactsTicked" @change="allImpactsTickChange">
                                All impact levels
                                </label>
                            </div>
                            <div class="errors help-block countries" style="display: none;">
                                <span>At least one country must be selected</span>
                            </div>
                            <div class="errors help-block impact" style="display: none;">
                                <span>At least one option must be selected</span>
                            </div>
                            
                        </form>


                    </div>
                    <div v-if="activeTab === 'instructions'">
                        <item-instruction
                        :item="widget"
                        :mode="details.mode"
                        :isCourse="details.isCourse"
                        :isLiveStream="details.isLiveStream"
                        @show-activation="activeTab = 'howactivation'"
                        ></item-instruction>
                    </div>
                    <div v-if="activeTab === 'howactivation'">
                        <how-activation-works
                        :isCourse="details.isCourse"
                        :isLiveStream="details.isLiveStream"
                        :product="details.product"
                        :impID="widget.implementation_id"
                        @back-to-instructions="activeTab = 'instructions'"
                        ></how-activation-works>
                    </div>
                </div>
            </div>
            <div class="shop-bottom">
                <!-- NEW -->
                <!-- <div v-if="details.mode === 'purchase'"
                class=""
                style="height:100%; display: flex; align-items: center;"
                >

                    <span style="width:100%" v-if="this.widget.code === 'TCVIDEO' || this.widget.code === 'TCSTREAM'">
                        <base-button
                        mode="green fullwidth"
                        :link="false"
                        @click.native="buyWidget"
                        >
                        <span>Buy {{ productName }} for {{ price }} per month</span>
                        
                        </base-button>
                    </span>
                    <span
                    v-if="!notOnAnyPlan"
                    style="width:100%">
                        <base-button
                        mode="o-blue fullwidth"
                        :link="false"
                        @click.native="upgradePlan"
                        >Upgrade your plan
                        </base-button>
                    </span>

                </div> -->
                <!-- NEW ENDS -->
                <!-- OLD -->
                <div v-if="details.mode === 'purchase'"
                class="col-2-lg-fs flex-center-lg"
                style="height:100%"
                >

                    <span style="width:100%"
                    v-if="highlightedBundle">
                        <base-button
                        mode="green fullwidth"
                        :link="false"
                        @click.native="buyHighlightedBundle"
                        >
                        <span>Buy it with {{ highlightedBundle.name }}</span>
                        </base-button>
                        <!--<base-button
                        mode="green fullwidth"
                        :link="false"
                        @click.native="buyWidget"
                        >
                            <span>Buy {{ productName }} for {{ price }} per month</span>
                        </base-button> -->
                    </span>
                    <!-- <span
                    v-if="!notOnAnyPlan"
                    style="width:100%">
                        <base-button
                        mode="o-blue fullwidth"
                        :link="false"
                        @click.native="upgradePlan"
                        >Upgrade your plan
                        </base-button>
                    </span> -->

                </div>
                <!-- OLD ENDS-->
                <div v-else-if="details.mode === 'edit'"
                class="col-2-lg-fs flex-center-lg"
                style="height:100%"
                >
                    <span style="width:100%">
                        <base-button
                        mode="green fullwidth"
                        :link="false"
                        @click.native="saveSettings"
                        >Save
                        </base-button>
                    </span>
                </div>

                <div v-else
                class="col-2-lg-fs flex-center-lg"
                style="height:100%; display: flex; align-items: center;"
                >

                    <span
                    v-if="!tabbedDashboards.length && details.widget.code !== 'ANALCHAT'"
                    style="width:100%">
                        <base-button
                        mode="o-blue fullwidth"
                        :link="false"
                        @click.native="addDashboard"
                        >{{ addToButtonText }}
                        </base-button>
                    </span>
                    <span
                    v-else-if="details.widgetDirectAddition"
                    style="width:100%">
                        <base-button
                        mode="green fullwidth"
                        :link="false"
                        @click.native="addWidget(activeDashboard)"
                        >{{ addToButtonText }}
                        </base-button>
                    </span>
                    <span
                    v-else
                    style="width:100%">
                        <base-button
                        mode="green fullwidth"
                        :link="false"
                        @click.native="prepareSlotSelection"
                        >{{ addToButtonText }}
                        </base-button>
                    </span>
                </div>

            </div>
        </div>

    </div>
</template>

<script>
import Swal from 'sweetalert2';
import VueFormGenerator from 'vue-form-generator';
import { eventBus } from '../../../main';
import { calculatePosition, formatAndVatPrice, isValidPromo, randomizeBetween, talkToServer } from '../../../utility/helper-functions';
// import CopyDashboard from "./CopyDashboard";
import {createNamespacedHelpers} from 'vuex';
import _ from "lodash"
// import WidgetPurchase from "./WidgetPurchase";
import ItemInstruction from "./ItemInstruction";
import HowActivationWorks from './HowActivationWorks.vue'
import TabMenu from '../TabMenu.vue';
const dashboards = createNamespacedHelpers('dashboard');
const payments = createNamespacedHelpers('payments');
const ecocalendar = createNamespacedHelpers('calendar');
import Intercom from '../../../mixins/intercomMixin';
import Constants from '../../../utility/constants';
import TrackingService from '../../../services/tracking/tracking.service';
// import 'vue-form-generator/dist/vfg.css'
export default {
    props: ['details'],
    mixins: [Intercom],
    components: {
        ItemInstruction,
        HowActivationWorks,
        "vue-form-generator": VueFormGenerator.component,
        // CopyDashboard,
        TabMenu
    },
    created() {
        

        this.widgetname.val = this.widgetTitle;
        this.renderSettingsForms();
        this.userModel = JSON.parse(JSON.stringify(this.model));

        //temp. until own anal-chat is developed, it needs to be handled differently to other widgets, if added somewhere, then remove, only render on one dashboard
        if(this.widget.code === "ANALCHAT" && this.analChatActiveOn) {
            this.analChatWidget = true;
            this.activeTab = 'options';
        }
        if(this.details.mode === 'edit')
            this.activeTab = 'options'

        this.buildUserModel();

    },
    mounted() {
        if(this.activeTab === 'options')
                this.moveEcocalCheckboxes();
    },
    computed: {
        ...dashboards.mapGetters(['dashboards', 'getDashboardById', 'layouts', 'activeDashboardByInstanceID', 'activeDashboardInfoByInstanceID', 'analChatActiveOn', 'tabbedDashboards']),
        ...payments.mapGetters(['availableWidgetsPriceInformation','promotionalProducts']),
        ...ecocalendar.mapState(['majorCountries','minorCountries','allImpacts']),
        activeDashboard() {
            return this.activeDashboardByInstanceID(this.$root.instanceID, "dashboard");
        },
        activeDashboardInfo() {
            return this.activeDashboardInfoByInstanceID(this.$root.instanceID, "dashboard");
        },
        currentWidgetDetails: function () {
            return this.availableWidgetsPriceInformation[this.widget.implementation_id];
        },
        widget: function () {
            return this.details.widget;
        },
        widgetTitle() {
            return this.widget.name;
        },
        notOnAnyPlan() {
            return !this.widget.planDetails?.planProducts?.filter(el => el.is_visible_front_end).length;
        },
        price() {
            return formatAndVatPrice(this.widget.planDetails?.priceNumeric)
        },
        addToButtonText() {
            const target = this.widget.code === 'ANALCHAT' ? 'home screen' : 'dashboard';

            return "Add To " + target;
        },
        productName() {
            if(this.details.isLiveStream)
                return "series";
            if(this.details.isCourse)
                return "course";

            return "indicator";
        },
        highlightedBundle() {
            let highlightedBundle = null;
            // const env = getServerEnv();
            // console.log(this.promotionalProducts);
            const orderedPromoProducts = _.orderBy(this.promotionalProducts, el => el.pricing.USD, "asc");
            orderedPromoProducts.forEach(el => {
                const found = el.widgets.find(w => w.implementation_id === this.details.widget.implementation_id);
               
                if(found && !highlightedBundle)
                    highlightedBundle = el;
            });
          
            
            return highlightedBundle;
        }
    },
    data() {
        return {
            tabbedMenu: [
                {
                    id: "instructions",
                    name: "Description"
                },
                {
                    id: "options",
                    name: "Options"
                }
            ],
            isExploreMode: window.bootUp.isExploreMode,
            activeTab: "instructions",
            widgetname: {
                val: '',
                isValid: true
            },
            model: {},
            schema: {
                fields: []
            },
            formOptions: {},
            userModel: {},
            modelChanged: false,
            slideDownComponent: "copy-dashboard",
            isSlideDownOpen: false,
            firstRender: true,
            analChatWidget: false,
            removeAnalChatDisabled: false,
            oldCountries: [],
            oldImpact: [],
            // addedCountryEventListeners: false,
            allCountriesTicked: false,
            majorCountriesTicked: false,
            allImpactsTicked: false

        }
    },
    // created() {
    //     console.log(this.details);
    // },
    // beforeDestroy() {
    //     console.log(this.model.name);
    // },
    methods: {
        ...dashboards.mapActions(['updateDashboardById', 'setAnalChatActiveOn']),
        backToShop() {
            this.$emit('shop-navigate-to', {
                view: "widget-list",
                id: null
            });
        },
        moveEcocalCheckboxes() {
            this.$nextTick(() => {
                const cCheckboxes = document.getElementById("aggregate-country-checkboxes");
                const countriesMainRow = document.querySelector(".ecocal-countries .mainRow");
                if(cCheckboxes && countriesMainRow) {
                    countriesMainRow.parentNode.insertBefore(cCheckboxes, countriesMainRow.nextSibling);
                    cCheckboxes.style.display = "block";
                }
                const iCheckboxes = document.getElementById("aggregate-impact-checkboxes");
                const impactsMainRow = document.querySelector(".ecocal-impact .mainRow");
                if(iCheckboxes && countriesMainRow) {
                    impactsMainRow.parentNode.insertBefore(iCheckboxes, impactsMainRow.nextSibling);
                    iCheckboxes.style.display = "block";
                }
                
            });
            
        },
        changeTab(tab) {
            this.activeTab = tab.id;
            this.renderSettingsForms();
            if(this.activeTab === 'options') {
                this.moveEcocalCheckboxes();
           
            }
        },
        
        allCountriesTickChange() {
            if(!this.allCountriesTicked) {
                this.model.countries = [...this.majorCountries, ...this.minorCountries];
            }
            else {
                this.model.countries = [];
            }
            this.modelUpdated(this.model.countries, "countries");
            this.processCountries();
        },
        majorCountriesTickChange() {
            if(!this.majorCountriesTicked) {
                this.model.countries = [...this.majorCountries];
            }else{
                this.model.countries = this.model.countries.filter(el => !this.majorCountries.includes(el));
            }
            this.modelUpdated(this.model.countries, "countries");
            this.processCountries();
            
        },
        allImpactsTickChange() {
            if(!this.allImpactsTicked) {
                this.model.impact = [...this.allImpacts];
            }else{
                this.model.impact = [];
            }
            this.modelUpdated(this.model.impact, "impact");
            this.processImpacts();
        },
        clearValidity(input) {
            console.log(input);
            this[input].isValid = true;
            this.errorMessage = null;
        },
        
        renderSettingsForms() {
            this.model = {};
            this.schema = {
                fields: []
            };
            this.formOptions = {};

            let userSettings = null;


            if(this.details.layout_id) {
                userSettings = this.activeDashboardInfo.elements[this.details.layout_id].userSettings;
            }

            for(const item in this.widget.settings) {
                if(this.widget.settings[item].formJSON &&
                // this.widget.settings[item].area.toLowerCase() === this.activeTab
                this.widget.settings[item].area.toLowerCase() === 'options' ||
                this.widget.settings[item].area.toLowerCase() === 'appearance'
                ) {
                    if(userSettings && (userSettings[item] || userSettings[item] === false)) {

                        this.model = {
                            ...this.model,
                            [item]: userSettings[item]
                        };
                        // console.log('we have user settings');
                    }else{
                        // console.log('we DON\'T have user settings');
                        this.model = { ...this.model, ...this.widget.settings[item].formJSON.model};
                    }

                    let field = this.widget.settings[item].formJSON.schema;
                    if(field.type === "switch") {
                        field.type = "checkbox";
                    }
                    this.schema.fields.push(field);
                    this.formOptions = this.widget.settings[item].formJSON.formOptions;
                }

            }
            if(userSettings && userSettings.widgetname && this.firstRender) {
                this.widgetname.val = userSettings.widgetname || this.widgetTitle;
            }
            if(userSettings && userSettings.countries)
                this.processCountries();
            if(userSettings && userSettings.impact)
                this.processImpacts();

            this.firstRender = false;
        },
        buildUserModel() {
            let userSettings = {};

            if(this.details.layout_id) {
                userSettings = this.activeDashboardInfo.elements[this.details.layout_id].userSettings;
            }

            const widgetname = userSettings.widgetname ? userSettings.widgetname : this.widgetname.val;

            this.userModel = {
                widgetname: widgetname,
                ...userSettings,
                ...this.userModel,
            };

            // console.log("this.userModel",this.userModel);
        },
        modelUpdated(newValue, schema) {
            let val = newValue;
            if(schema === "countries") {
                this.processCountries();
                val = this.model.countries;

            }
            if(schema === "impact") {
             
                this.processImpacts();
            }

            this.buildUserModel();
            this.userModel[schema] = val;
            this.modelChanged = true;

        },
        validateEcocalFilters() {
            if(this.details.widget.code !== 'CALENDAR')
                return true;
            
            if(!this.model.countries.length || !this.model.impact.length)
                return false;
            else
                return true;
        },
        processCountries() {
            const allCountries = [...this.majorCountries, ...this.minorCountries];
            
            if(this.model.countries.length === allCountries.length) {
                this.allCountriesTicked = true;
                this.majorCountriesTicked = true;
            }
            else {
                this.allCountriesTicked = false;
            }
            if(this.majorCountries.every(el => this.model.countries.includes(el)) && this.majorCountries.length === this.model.countries.length)
                this.majorCountriesTicked = true;
            else
                this.majorCountriesTicked = false;

            const countriesBox = document.querySelector(".ecocal-countries .field-wrap");
            const errorBlock = document.querySelector(".errors.help-block.countries");

            if(this.model.countries.length === 0 && countriesBox && errorBlock) {
                countriesBox.parentNode.insertBefore(errorBlock, countriesBox.nextSibling);
                errorBlock.style.display = "block";
            }else if(countriesBox && errorBlock){
                errorBlock.style.display = "none";
            }

        },
        processImpacts() {
            const impactsBox = document.querySelector(".ecocal-impact .field-wrap");
            const errorBlock = document.querySelector(".errors.help-block.impact");

            if(this.model.impact.length === 0 && impactsBox && errorBlock) {
                impactsBox.parentNode.insertBefore(errorBlock, impactsBox.nextSibling);
                errorBlock.style.display = "block";
            }else if(impactsBox && errorBlock){
                errorBlock.style.display = "none";
            }

            if(this.model.impact.length === this.allImpacts.length) {
                this.allImpactsTicked = true;
            }
            else {
                this.allImpactsTicked = false;
            }
        },
        saveSettings() {
            if(!this.validateEcocalFilters())
                return;

            const active = _.cloneDeep(this.activeDashboardInfo);

            if(this.modelChanged) {
                active.elements[this.details.layout_id].userSettings = this.userModel;
            }
            active.elements[this.details.layout_id].userSettings.widgetname = this.widgetname.val;

            this.updateDashboardById(active);

            eventBus.$emit("update-settings", this.details.layout_id);

            eventBus.$emit("close-modal");
            this.modelChanged = false;

        },
        buyWidget() {
            // if(this.widget.implementation_id === 75) {
            //     localStorage.setItem("promo", window.bootUp.product);
            //     const promo = this.promotionalProducts.find(el => el.id === window.bootUp.product)
            //     eventBus.$emit("nav-change", {
            //         instanceID: this.$root.instanceID,
            //             comp: "checkout",
            //             details: {
            //                 type: "promotion",
            //                 selectedItem: promo
            //             }
            //     });
            //     eventBus.$emit("close-modal");
            //     return;
            // }
            if(this.widget.planDetails.addonProducts[0]) {
                eventBus.$emit("nav-change", {
                    instanceID: this.$root.instanceID,
                    comp: "checkout",
                    details: {
                        type: "addon",
                        selectedItem: this.widget
                    }
                });
                // this.showModal(CheckoutOverview, {
                //     selectedItem: this.widget,
                //     type: 'addon'
                // }, {
                //     style: "width: 95% !important; height: 95% !important",
                //     resizable: true
                // });
                eventBus.$emit("close-modal");
                // console.log('buy widgetss');
            }
        },
        buyHighlightedBundle() {
            if(isValidPromo(this.highlightedBundle.id))
                localStorage.setItem("promo", this.highlightedBundle.id);

            eventBus.$emit("nav-change", {
                instanceID: this.$root.instanceID,
                    comp: "checkout",
                    details: {
                        type: "promotion",
                        selectedItem: this.highlightedBundle
                    }
            });
            eventBus.$emit("close-modal");
        },
        upgradePlan() {
            eventBus.$emit("close-modal");
            const instanceID = this.$root.instanceID;
            const modal = {
                instanceID,
                componentInModal: "terminal-plan-selector",
                classes: "terminal-modal simplified auto-height",
                mode: "auto",
                checkout: true,
                selectedItem: this.widget,
                type: 'plan'
            };
            eventBus.$emit('open-modal', modal);

            // eventBus.$emit("close-modal");
            // setTimeout(() => {
            //     const instanceID = this.$root.instanceID;
            //     const modal = {
            //         instanceID,
            //         componentInModal: "terminal-plan-selector",
            //         classes: "terminal-modal simplified auto-height",
            //         mode: "auto"
            //     };

            //     eventBus.$emit('open-modal', modal);
            // });
        },
        deleteWidget() {

            Swal.fire({
                title: 'Are you sure?',
                type: 'warning',
                text: 'You can always add it back to a dashboard later',
                showCancelButton: true,
                cancelButtonColor: '#1d9deb',
                confirmButtonColor: '#ff5656',
                confirmButtonText: 'Yes, delete it!'
            }).then((result) => {
                if (result.value) {
                    // console.log("this details", this.details);
                    eventBus.$emit("close-widget", {
                        instanceID: this.$root.instanceID,
                        layoutID: this.details.layout_id,
                    });
                    eventBus.$emit("close-modal");
                }
            });


        },
        openDuplicator() {
            this.isSlideDownOpen = !this.isSlideDownOpen;
        },
        addWidget(dashboardID) {
            if(!this.validateEcocalFilters())
                return;

            if(this.isExploreMode) {
                Swal.fire({
                    customClass: {
                        cancelButton: 'flat'
                    },
                    title: 'Create Free Account',
                    type: 'info',
                    text: 'You’ll be able to take unlimited trials of all our widgets and customise the terminal to your preferences.',
                    showCancelButton: true,
                    cancelButtonText: 'No thanks',
                    confirmButtonColor: '#1d9deb',
                    confirmButtonText: 'Create Account'
                }).then((result) => {
                    if (result.value) {
                        eventBus.$emit('close-modal');
                        this.$store.dispatch('auth/setLoginFormMode', 'register');
                        eventBus.$emit('logout-successful');
                    }
                });

                return;
            }

            let panelLayoutID;

            // if(!this.currentWidgetDetails?.included) {
            //     Swal.fire({
            //         title: 'Action Required!',
            //         type: 'warning',
            //         text: 'You don’t currently have access to this widget, click below to purchase.',
            //         showCancelButton: true,
            //         cancelButtonColor: '#1d9deb',
            //         confirmButtonColor: '#ff5656',
            //         confirmButtonText: 'Buy'
            //     }).then((result) => {
            //         if (result.value) {
            //             this.readonly = true;
            //             this.activeTab = "purchase"
            //         }
            //     });
            //     return;
            // }
                // until our analyst chat is built we use intercom and need to intercept this event and callback to initialize intercom
            
            if(this.widget.code === "ANALCHAT") {
                const obj = {
                    dashboardID: 999999
                };

                talkToServer("/setting/user/analyst-chat", "POST", null, obj, true).then(() => {
                    this.setAnalChatActiveOn(dashboardID);
                    eventBus.$emit("ANALCHAT_CHECK");
                    Swal.fire({
                        title: 'Done',
                        html: "The analyst chat widget has been added to the Home screen.",
                        type: 'info',
                        confirmButtonColor: '#1d9deb',
                        confirmButtonText: 'OK'
                        // timer: 1500
                    });
                });


            }else{

                this.buildUserModel();
                const layoutID = randomizeBetween(1000000, 9999999);
                const implID = this.widget.implementation_id;
                const dash = parseInt(dashboardID);
                let currentDashboard = _.cloneDeep(this.getDashboardById(dash));
                const thisElements = currentDashboard.elements;

                // let calculatedHeight = Object.keys(thisElements).map(key => thisElements[key].layout.h).reduce((a, b) => a + b, 0);



                Object.values(thisElements).forEach(value => {
                    if(value.layout.slot === this.details.slot)
                        delete thisElements[value.i];
                });

                thisElements[layoutID] = {};

                // console.log("this.userModel",this.userModel);

                thisElements[layoutID].widgetCode = this.widget.code;
                thisElements[layoutID].userSettings = {
                    ...this.userModel,
                    widgetname: this.widgetname.val
                };

                panelLayoutID = currentDashboard.panelLayoutID;
                const panelProps = Constants.PANELLAYOUTIDS[panelLayoutID];

                const parameters = calculatePosition(this.details.slot, panelProps, layoutID);
                // console.log("para",parameters);
                //let height = (this.widget.code === 'CURRMETER') ? (window.bootUp.grid / 3): (window.bootUp.grid / 4);
                thisElements[layoutID].layout = {
                    ...parameters,
                    implementation: implID
                };

                this.updateDashboardById(currentDashboard);
                eventBus.$emit("scroll-into-widget", layoutID);
            }


  
            const obj = {
                implementation_id: this.widget.implementation_id,
                name: this.widget.name
            }
            if(panelLayoutID)
                obj.panel_layout = panelLayoutID;

            TrackingService.sendEvent("added_widget", obj);
            eventBus.$emit("added_widget");


            eventBus.$emit("close-modal");
        },
        prepareSlotSelection() {
            if(this.widget.code === "ANALCHAT") {
                const dashboardID = 999999
                const obj = {
                    dashboardID: dashboardID
                };

                talkToServer("/setting/user/analyst-chat", "POST", null, obj, true).then(() => {
                    this.setAnalChatActiveOn(dashboardID);
                    eventBus.$emit("ANALCHAT_CHECK");
                    eventBus.$emit("close-modal");
                });

                const obj2 = {
                    implementation_id: this.widget.implementation_id,
                    name: this.widget.name
                }

                TrackingService.sendEvent("added_widget", obj2);

                return;
            }

            this.buildUserModel();
            const implID = this.widget.implementation_id;

            const widgetObj = {
                layout: {
                    implementation: implID,
                },
                userSettings: {
                    ...this.userModel,
                    widgetname: this.widgetname.val
                },
                widgetCode: this.widget.code
            }

            eventBus.$emit("nav-change", {
                instanceID: this.$root.instanceID,
                comp: "dashboard"
            });

    

            setTimeout(() => {
                eventBus.$emit("find-slot-for-widget", {
                    instanceID: this.$root.instanceID,
                    widgetObj
                });
            }, 100);


            eventBus.$emit("close-modal");
        },
        removeAnalChat() {

            this.removeAnalChatDisabled = true;
            const obj = {
                dashboardID: null
            };

            talkToServer("/setting/user/analyst-chat", "POST", null, obj, true).then(() => {
                this.shutDownIntercom();
                this.setAnalChatActiveOn(null);
                eventBus.$emit("close-modal");
            })
            .catch(() => this.removeAnalChatDisabled = false);


        },
        addDashboard() {
            eventBus.$emit("close-modal");
            setTimeout(() => {
                if(!this.tabbedDashboards.length) {
                    const modal = {
                        instanceID: this.$root.instanceID,
                        mode: 'wide',
                        classes: 'terminal-modal',
                        footer: true,
                        // panel: {}
                    };

                    modal.title = "Create a New Dashboard";

                    modal.componentInModal = "edit-dashboard";

                    eventBus.$emit('open-modal', modal);

                    return;

                }
            }, 300);
        }

    },

}

</script>

<style lang="scss" scoped>

    .widget-config {
        height: 100%;
        margin-top:0px;

        & .tabs-body {
            margin-top: 30px;
            overflow-y: auto;
            overflow-x: hidden;

            &.options {
                height: 390px;
            }

            @include scrollbarDark;
        }
    }

    .widget-shop-header-top {
        display: grid;

        @include respond(lg) {
            grid-template-columns: 1fr 1fr 1fr;
            align-items: end;
        }

        //  & h3 {
        //      text-transform: capitalize;
        //  }
        // &-name {



        // }

        &-buttons {

            @include respond(lg) {
                margin-left: 15px;
            }
        }
    }

    // @include settings;

    // .settings-body {
    //     margin: 0;
    // }


</style>
<style lang="scss">
    input[type="checkbox"] {
        width: auto !important;
    }

    .tweetscan-default-board
    // ,
    // .ecocal-impact
    {
        display: none !important;
    }

    .video-optimizer-div {

        @include respond(lg) {
            margin: 0 100px;
        }

    }

    .widget-edit-top-row {
        margin-bottom: 10px;
        display: grid;
        grid-template-columns: 1fr 115px;
        column-gap: 15px;
        align-items: self-end;


    }

    .errors.help-block {
        & span {
            display: block;
            color: $color-12;
            font-size: 12px;
        }
    }
    
</style>
