<template>
    <div class="bug-reports-wrapper">
        <div class="bug-reports-top">
            <h2>Report a Bug</h2>
            <div class="instruction-row">
                        <p>To help us fix your issue quickly, please complete the form below. Include as many details as possible and screenshots.</p>
                        <p>Our team will respond to you via email within 48 hours of you sending the report.</p>
                    </div>
            <div class="modal-form-body">
                <form @submit.prevent>

                    <div class="form-group featured required label-transition field-input">
                        <label for="problem" class="focused"><span>What problem have you noticed? *</span></label>
                        <div class="field-wrap">
                            <div class="wrapper">
                                <textarea id="problem" type="text" maxlength="500" placeholder="What problem have you noticed? *" required="required" @keyup="validateForm" class="form-control-fs" v-model="problem.val" :class="{'invalid-input': problem.error}"></textarea>
                                <div class="char-count" style="margin-top: -20px; text-align: right;">
                                    <span class="char-count-current">{{ problem.val.length }}</span>
                                    <span class="char-count-maximum">/ 500</span>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="form-group featured required label-transition field-input">
                        <label for="bug-report-device" class="focused"><span>What device are you using the terminal on?</span></label>
                        <div class="field-wrap">
                            <div class="wrapper">
                                <select id="bug-report-device" class="form-control-fs" v-model="device.val"
                                :class="{'invalid-input': device.error}"
                                >
                                    <option value="N/A" hidden selected disabled>Select your device...</option>
                                    <option value="PC (Windows)">PC (Windows)</option>
                                    <option value="Mac Computer">Mac Computer</option>
                                    <option value="Android Mobile">Android Mobile</option>
                                    <option value="iOS Mobile">iOS Mobile</option>
                                    <option value="Other">Other...</option>
                                </select>
                            </div>
                        </div>
                    </div>

                    <div class="form-group featured required label-transition field-input">
                        <label for="bug-report-browser" class="focused"><span>What device are you using the terminal on?</span></label>
                        <div class="field-wrap">
                            <div class="wrapper">
                                <select id="bug-report-browser" class="form-control-fs" v-model="browser.val"
                                :class="{'invalid-input': browser.error}"
                                >
                                    <option value="N/A" selected disabled hidden>Select your browser...</option>
                                    <option value="Google Chrome">Google Chrome</option>
                                    <option value="Mozilla Firefox">Mozilla Firefox</option>
                                    <option value="Microsoft Edge">Microsoft Edge</option>
                                    <option value="Safari">Safari</option>
                                    <option value="Chromium">Chromium</option>
                                    <option value="Internet Explorer">Internet Explorer</option>
                                    <option value="Opera">Opera</option>
                                    <option value="Other">Other...</option>
                                </select>
                            </div>
                        </div>
                    </div>

                    <div class="form-group featured required label-transition field-input">
                        <label for="bug-report-first_noticed" class="focused"><span>When did you first notice the problem?</span></label>
                        <div class="field-wrap">
                            <div class="wrapper">
                                <input id="bug-report-first_noticed" type="text" maxlength="50" placeholder="When did you first notice the problem?" required="required" class="form-control-fs" v-model="noticed.val" :class="{'invalid-input': noticed.error}" />
                                <div class="char-count" style="margin-top: -20px; margin-bottom:10px; text-align: right;">
                                    <span class="char-count-current">{{ noticed.val.length }}</span>
                                    <span class="char-count-maximum">/ 50</span>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div>
                        <vue-dropzone
                        ref="bugImgUpload"
                        id="dropzone-multi-img"
                        :options="dropzoneOptions"
                        @vdropzone-error="errorAdd"
                        @vdropzone-complete="updateUploadedFile"
                        @vdropzone-file-added="fileAdded"
                        @vdropzone-removed-file="removeAllFiles"
                        ></vue-dropzone>
                        <div class="error-message">{{ uploadError }}</div>
                    </div>
                

                </form>
            </div>
        </div>
        
        
        
        <div class="modal-form-bottom">
            
            <div class="error-message align-right">
                <span v-if="!formIsValid">Please fill in the highlighted field.</span>
            </div>
            <base-button mode="o-blue" :disabled="uploading" @click.native="submitForm">Send Report</base-button>
    
        </div>
    </div>
</template>

<script>
import vue2Dropzone from 'vue2-dropzone';
import 'vue2-dropzone/dist/vue2Dropzone.min.css';
import AppService from "./../../../services/app.service";
import OnBoardMixin from "../../../mixins/onBoardMixin";
import Swal from "sweetalert2";
import {eventBus} from "../../../main";
export default {
    mixins: [OnBoardMixin],
    components: {
        vueDropzone: vue2Dropzone,
    },
    data() {
        return {
            uploadedFile: null,
            uploading: false,
            problem: {
                val: '',
                error: false
            },
            device: {
                val: 'N/A',
                error: false
            },
            browser: {
                val: 'N/A',
                error: false
            },
            noticed: {
                val: '',
                error: false
            },
            dropzoneOptions: {
                url: 'https://httpbin.org/post',
                addRemoveLinks: true,
                maxFilesize: 1,
                acceptedFiles: "image/*",
                dictDefaultMessage: "Drop screenshots here to upload"
                // headers: { "My-Awesome-Header": "header value" }
            },
            formIsValid: true,
            errorMessage: null,
            uploadError: ''

        }
    },
    methods: {
        submitForm() {
            this.validateForm();
            if(this.formIsValid) {
                this.uploading = true;
                if(this.uploadedFile) {
                    let image = this.convertFieldDataToFile(this.uploadedFile);
                    AppService.uploadImage(image).then(result => {
                        if(result?.data?.success) {
                            let images = [result.data.data.url];
                            this.saveBugReport(images);
                        }
                    })
                } else {
                    this.saveBugReport([]);
                }
            }
        },
        saveBugReport: function (images) {
            const obj = {
                problem: this.problem.val,
                device: this.device.val,
                browser: this.browser.val,
                first_noticed: this.noticed.val,
                images: images
            }
            AppService.submitBugReport(obj).then(() => {
                eventBus.$emit('close-modal');
                Swal.fire({
                    title: 'Thank You!',
                    text: "Your bug report has been submitted successfully. Reporting any issues help us improve our products and services for you.",
                    type: 'info',
                    confirmButtonColor: '##1d9deb',
                    confirmButtonText: 'OK'
                })
            })
            .catch(() => {
                Swal.fire({
                    title: 'Error',
                    text: "Something has gone wrong. Please try again later",
                    type: 'info',
                    confirmButtonColor: '##1d9deb',
                    confirmButtonText: 'OK'
                });
            })
            .finally(() => this.uploading = false);
        },
        validateForm() {
            this.formIsValid = true;
            this.problem.error = false;
            // console.log("prob",this.problem.val)
            if(this.problem.val === '') {
                this.problem.error = true;
                this.formIsValid = false;
            }
        },
        clearValidity(input) {
            // console.log(input);
            this[input].isValid = true;
            this.errorMessage = null;
        },
        removeAllFiles() {
            const currentFile = this.$refs.bugImgUpload.getAcceptedFiles();
            if (currentFile.length) {
                const toBeDeleted = currentFile[0];
                this.$refs.bugImgUpload.removeFile(toBeDeleted);
            }
        },
        fileAdded() {
            this.removeAllFiles();
            this.uploading = true;
        },
        updateUploadedFile: function (response) {
            if(response.status === "success") {
                this.uploadError = '';
                this.uploadedFile = response;
            } else {
                this.uploadedFile = null;
            }
            this.uploading = false;
        },
        errorAdd(file, message) {
            this.$refs.bugImgUpload.removeFile(file);
            this.uploadError = message;
        }
    }
}
</script>

<style lang="scss">
    .bug-reports-top,
    .terminal-support-top,
    .modal-form-bottom {
        padding: 0 25px;
    }
    .modal-form-bottom {
        background-color: $modal-footer;
        margin-top: 15px;
        padding-top: 10px;
        padding-bottom: 25px; 
        text-align: right;
    }
    .bug-reports-top .modal-form-body,
    .terminal-support-top .modal-form-body {
        @include scrollbarDark;
        padding-right: 5px;
        //Poppins
       

        //Roboto
        // max-height: 392px;
    }
    select {
        font-family: Poppins;
    }
    
    .bug-reports-wrapper,
    .terminal-support-wrapper {

        & h2 {
            @include H2;
        } 
        // padding: 0 25px;

        & .label-transition {

            & input,
            & select,
            & textarea {

                &:-webkit-autofill,
                &:-webkit-autofill:hover, 
                &:-webkit-autofill:focus, 
                &:-webkit-autofill:active{
                    -webkit-box-shadow: 0 0 0 30px $onboard-survey-card-background inset !important;
                }
            }

        } 
    }

    .analyst-desk .bug-reports-wrapper {

        & .label-transition select {
            background-color: $onboard-survey-card-background;
        }

        & .label-transition label.focused {
            top: 10px;
        }
    }

    .bug-reports-wrapper {
        
    }

    .instruction-row p,
    .dropzone-header {
        color: $input-color-filter;
    }

 
    .label-transition textarea {

        @include scrollbarDark;
    
        &::placeholder {
            color: $input-color-filter;
            font-weight: 300;
        }
    }


</style>

<style lang="scss">

    #dropzone-multi-img {
        background-color: transparent;
        border: 1px solid $input-border-color;
        border-radius: 10px;
        margin-bottom: 5px;
    }

    .dz-message {
        color: $input-color-filter;
    }

    .vue-dropzone#dropzone-multi-img > .dz-preview .dz-remove {
        left: 50%;
        transform: translateX(-50%);
        margin-left: 0;
    }
</style>
